import { WALLET_DATA_RECEIVED, WALLET_LOADER, LOGOUT } from '../actions/types';

const INITIAL_STATE = {
    walletData: [],
    walletLoader: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case WALLET_DATA_RECEIVED:
            return { ...state, walletData: action.payload };
        case WALLET_LOADER:
            return { ...state, walletLoader: action.payload };
        case LOGOUT:
            return { ...state, walletData: INITIAL_STATE.walletData };
        default:
            return state;
    }
}
