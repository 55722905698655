import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import { isMobile } from 'react-device-detect';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import NotificationManager from 'react-notifications/lib/NotificationManager';

import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import WSButton from '../../components/Button/WSButton';
import WSTextField from '../../components/TextField/WSTextField';

import withUser from '../HOC/HOCwithUser.jsx';
import * as actions from '../../actions';

import '../../assets/css/style.css';
import 'react-phone-input-2/lib/style.css';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme?.palette?.background?.paper,
    },
});

const basicDetailValidation = yup.object().shape({
    name: yup.string().required('Name is required.'),
    phone: yup.string().required('Phone Number is required.'),
    email: yup.string().required('Email Id is required.'),
});

export function BasicCustomerProfile(props) {

    const [loaderButton, setLoaderButton] = useState(false);
    const [phoneNoActive, setPhoneNoActive] = useState(false);

    const providerData = useSelector(state => state?.auth?.userData);

    const updateBankDetailAPI = (values) => {
        const { updateProfileAction } = props;
        const { name, phone } = values;
        updateProfileAction(
            {
                name,
                phone,
            },
            onDone
        );
    };

    const onDone = (success) => {
        if (success) {
            NotificationManager.success('Basic Profile Updated', null, 2000);
        } else {
            NotificationManager.error(
                'Basic Profile Update Failed',
                null,
                2000
            );
        }
    };

    const { theme } = props;
    return (
        <Formik
            initialValues={{
                name: (providerData && providerData?.name) || '',
                phone: String(providerData && providerData?.phone) || '971',
                email: (providerData && providerData?.email) || '',
            }}
            validationSchema={basicDetailValidation}
            onSubmit={(values) => updateBankDetailAPI(values)}
        >
            {(props) => (
                <form
                    className="dialog-form dialog-form-new"
                    autoComplete="off"
                >
                    <div className="row col-md-12 p-0">
                        <div
                            className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'
                                }`}
                        >
                            <span
                                className="profile-label"
                                style={{ paddingLeft: isMobile && 15 }}
                            >
                                Name{' '}
                                <span className="primary-color">*</span>
                            </span>
                            <WSTextField
                                error={
                                    props?.errors?.name && props?.touched?.name
                                        ? true
                                        : false
                                }
                                helperText={
                                    props?.errors?.name && props?.touched?.name
                                        ? props?.errors?.name
                                        : ''
                                }
                                className="login-form-textinput"
                                id="name"
                                name="name"
                                placeholder={'Bank Name'}
                                value={props?.values?.name}
                                onChange={props.handleChange('name')}
                                disabled={loaderButton}
                            />
                        </div>
                        <div
                            className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'
                                }`}
                        >
                            <span
                                className="profile-label"
                                style={{ paddingLeft: isMobile && 15 }}
                            >
                                Email Id{' '}
                                <span className="primary-color">*</span>
                            </span>
                            <WSTextField
                                error={
                                    props?.errors?.email &&
                                        props?.touched?.email
                                        ? true
                                        : false
                                }
                                helperText={
                                    props?.errors?.email &&
                                        props?.touched?.email
                                        ? props?.errors?.email
                                        : ''
                                }
                                className="login-form-textinput"
                                id="email"
                                name="email"
                                placeholder={'Email Id'}
                                value={props?.values?.email}
                                onChange={props.handleChange('email')}
                                disabled={true}
                            />
                        </div>
                        <span className="w-100"></span>
                        <div
                            className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'
                                }`}
                        >
                            <span
                                className="profile-label"
                                style={{ paddingLeft: isMobile && 15 }}
                            >
                                Phone Number{' '}
                                <span className="primary-color">*</span>
                            </span>

                            <PhoneInput
                                enableSearch
                                autoFormat
                                countryCodeEditable={false}
                                disableSearchIcon
                                placeholder="Phone Number"
                                value={props?.values?.phone}
                                containerStyle={{ marginTop: 25 }}
                                inputStyle={{
                                    backgroundColor: '#f6f9ff',
                                    width: '100%',
                                    height: 50,
                                    borderRadius: 0,
                                    borderColor: phoneNoActive && '#e24f36',
                                }}
                                inputClass="border-shadow-none"
                                buttonStyle={{
                                    backgroundColor: '#f6f9ff',
                                    borderRadius: 0,
                                    borderColor: phoneNoActive && '#e24f36',
                                }}
                                onChange={(phone) =>
                                    props.setFieldValue('phone', phone)
                                }
                                onFocus={() =>
                                    setPhoneNoActive(true)
                                }
                                onBlur={() =>
                                    setPhoneNoActive(false)
                                }
                            />
                            {props?.errors?.phone && props?.touched?.phone && (
                                <div className="error-text">
                                    {props?.errors?.phone}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex-justify-spacebetween mt-2">
                        <div>
                            <WSButton
                                style={{
                                    backgroundColor:
                                        theme?.palette?.primary?.main,
                                    color: '#ffffff',
                                    margin: !isMobile && 20,
                                    borderRadius: 30,
                                }}
                                onClick={props.handleSubmit}
                            >
                                Save Updates
                                </WSButton>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}

export default withTheme()(withStyles(styles)(injectIntl(withUser(BasicCustomerProfile))));
