import React, { Component } from 'react';

import WalletList from './WalletList';

export function CustomerWalletList(props) {
    return (
        <div>
            <WalletList {...props} />
        </div>
    );
}

export default CustomerWalletList;
