import React from 'react';
import PropTypes from "prop-types";

import { Cancel, CheckCircle } from '@material-ui/icons';

const WSPasswordError = props => {
    const {
        isValid,
        label
    } = props;
    return (
        <div className="flex-align-center">
            {isValid ? <CheckCircle fontSize="small" style={{ color: 'green' }} /> : <Cancel fontSize="small" color="primary" />}&nbsp;
            <span className={isValid ? 'password-no-error' : 'password-error'}>{label}</span>
        </div>
    );
};

WSPasswordError.propTypes = {
    isValid: PropTypes.bool,
    label: PropTypes.string,
};

export default WSPasswordError;