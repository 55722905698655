export const PROVIDER_TYPE_LOADER = 'provider_type';
export const PROVIDER_TYPE_DATA_RECEIVED = 'provider_type_data';
export const USER_DATA_RECEIVED = 'user_data';
export const PROVIDER_PROFILE_UPDATE = 'provider_data_update';
export const GET_NATIONALITY_DATA = 'get_nationality_data';
export const GET_LANGUAGES_DATA = 'get_languages_data';
export const BOOKING_DATA_RECEIVED = 'booking_data';
export const BOOKING_LOADER = 'booking_loader';
export const THEME_DATA_RECEIVED = 'theme_settings';
export const CHANGE_LANGUAGE = 'get_language';
export const FAVOURITE_DATA_RECEIVED = 'favourite_data';
export const LOGOUT = 'logout';
export const PROVIDER_DATA_RECEIVED = 'provider_data';
export const PROVIDER_LOADER = 'provider_loading';
export const CATEGORY_DATA_RECEIVED = 'category_data';
export const TOP_CATEGORY_DATA_RECEIVED = 'topcategory_data';
export const CATEGORY_LOADER = 'provider_loading';
export const SERVICE_DATA_RECEIVED = 'service_data';
export const PROMOCODE_DATA_RECEIVED = 'promocode_data';
export const CARD_LOADER = 'card_loader';
export const CARD_LIST = 'card_list';
export const FEATURED_LIST_RECEIVED = 'featured_provider_list';
export const FEATURED_PROVIDER_LOADER = 'featured_provider_loader';
export const NEW_PROVIDER_LIST_RECEIVED = 'new_provider_list';
export const NEW_PROVIDER_LOADER = 'new_provider_loader';
export const FAVOURITE_PROVIDER_LIST_RECEIVED = 'favourite_provider_list';
export const FAVOURITE_HOME_PROVIDER_LOADER = 'favourite_provider_loader';
export const RECENT_LIST_RECEIVED = 'recent_provider';
export const RECENT_PROVIDER_LOADER = 'recent_provider_loader';
export const REMOVE_HEADER_FOOTER = 'remove_header_footer';
export const FAVOURITE_PROVIDER_LOADER = 'favourite_provider_loader';
export const FAVOURITE_LOADER = 'favourite_loader';
export const WALLET_DATA_RECEIVED = 'wallet_data';
export const WALLET_LOADER = 'wallet_loader';
export const NOTIFICATION_DATA_RECEIVED = 'notification_data';
export const NOTIFICATION_LOADER = 'notification_loader';
export const FAVOURITE_LIST_RECEIVED = 'favourite_data';
export const AUTH_LOADER = 'auth_loader';
export const SUBCATEGORY_LOADER = 'subcategory_loader';
export const SUBCATEGORY_DATA_RECEIVED = 'subcategory_data_received';
export const PROVIDER_SERVICE_DATA_RECEIVED = 'provider_service_data';
export const PROVIDER_SERVICE_LOADER = 'provider_service_loader';
export const LOGIN_TRIGGERED = 'login_triggered';
export const WISHLIST_DATA_RECEIVED = 'wishlist_data';
export const WISHLIST_LOADER = 'wishlist_loader';
export const CMS_DATA_RECEIVED = 'cms_data_received';
export const MAINTAINENCE_MODE = 'maintainence';
export const HOME_BANNERS_RECEIVED = 'home_banners_received';
export const SETTINGS_LOADER = 'settings_Loader';
export const CART_RECEIVED = 'cart_data_received';
export const CART_LOADER = 'cart_loader';
export const GET_CURRENT_LOCATION = 'current_location';
export const INBOX_LIST = 'inbox_listing';
export const INBOX_LIST_LOADING = 'inbox_list_loading';
export const INBOX_DETAILS_RECEIVED = 'inbox_detail';
export const CLEAR_INBOX_DETAILS = 'clear_inbox_detail';
export const INBOX_ADD = 'inbox_add';
export const INBOX_DETAIL_LOADER = 'inbox_detail_loader';
export const INBOX_LIST_REMOVE = 'inbox_list_remove';
export const SELECTED_PROVIDER_ID = 'selected_provider_id';
