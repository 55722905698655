import {
    FAVOURITE_DATA_RECEIVED,
    FAVOURITE_LOADER,
    LOGOUT,
} from '../actions/types';

const INITIAL_STATE = {
    favouriteData: [],
    favouriteLoader: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FAVOURITE_DATA_RECEIVED:
            return { ...state, favouriteData: action.payload };
        case FAVOURITE_LOADER:
            return { ...state, favouriteLoader: action.payload };
        case LOGOUT:
            return INITIAL_STATE;
        default:
            return state;
    }
}
