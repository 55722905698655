import axios from 'axios';

import {
    errorResponse,
    validateApiData,
} from '../../utils/validators/validators';
import { SERVER_URL } from '../../utils/config/constants';

export const loginApi = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/weblogin`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};
