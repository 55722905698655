import axios from 'axios';

import {
    errorResponse,
    validateApiData,
} from '../../utils/validators/validators';
import { SERVER_URL } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

export const serviceProvider = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-service/list/`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const allProviderListing = async (params) => {
    let authToken = getCookies();
    let obj = {
        type: 1,
        filter: params.filter,
        featured: params.featured,
        category: params.category,
    };
    if (params.searchTerm) {
        obj['searchTerm'] = params.searchTerm;
    }
    let str = '';
    for (let key in obj) {
        if (str != '') {
            str += '&';
        }
        str += key + '=' + obj[key];
    }
    try {
        let url = `${SERVER_URL}/user/search-records?${str}`;
        if (params && (params.bookingType == 0 || params.bookingType == 1)) {
            url += `&bookingType=${params.bookingType}&currentDate=${params.currentDate}&offset=${params.offset}&bookingDate=${params.bookingDate}&bookingTime=${params.bookingTime}`;
        }
        const { data } = await axios({
            method: 'get',
            url: url,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerFilterListing = async (params) => {
    let authToken = getCookies();
    let obj = {
        type: params.type,
        filter: params.filter,
        featured: params.featured,
    };
    if (params.text) {
        obj['text'] = params.text;
    }
    let str = '';
    for (let key in obj) {
        if (str != '') {
            str += '&';
        }
        str += key + '=' + obj[key];
    }


    try {
        let url = `${SERVER_URL}/user/search-records?${str}`;
        if (params && (params.bookingType == 0 || params.bookingType == 1)) {
            url += `&bookingType=${params.bookingType}&currentDate=${params.currentDate}&offset=${params.offset}&bookingDate=${params.bookingDate}&bookingTime=${params.bookingTime}`;
        }
        const { data } = await axios({
            method: 'get',
            data: params,
            url: url,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const searchProviderListing = async (params) => {
    let authToken = getCookies();
    let obj = {
        type: params.type,
        filter: params.filter,
        featured: params.featured,
    };
    if (params.searchTerm) {
        obj['searchTerm'] = params.searchTerm;
    }
    let str = '';
    for (let key in obj) {
        if (str != '') {
            str += '&';
        }
        str += key + '=' + obj[key];
    }


    try {
        let url = `${SERVER_URL}/user/search-records?${str}`;
        if (params && (params.bookingType == 0 || params.bookingType == 1)) {
            url += `&bookingType=${params.bookingType}&currentDate=${params.currentDate}&offset=${params.offset}&bookingDate=${params.bookingDate}&bookingTime=${params.bookingTime}`;
        }
        const { data } = await axios({
            method: 'get',
            data: params,
            url: url,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const serviceProviderList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-service/list`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const featuredList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/user/featured-providers`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const newProviderList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/user/new-providers`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const favouriteProviderList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/user/favourite-providers`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const recentList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/user/recent-providers`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const getInboxList = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get', //you can set what request you want to be
            url: `${SERVER_URL}/inbox/my`,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
        // If data is not valid
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const providerTimeSlot = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/slots/list`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerSetTimeSlot = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/slots/set-availability`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const getProviderTypeProfile = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            data: params,
            url: `${SERVER_URL}/provider/get-provider-type-profile?providerTypeId=${params.providerTypeId}`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const updateProviderTypeProfile = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider/update-provider-type-params`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

// Provider Full Profile Update API
export const getFullProviderProfile = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/user/check`,
            headers: { 'x-auth-token': authToken },
        });
        return data;
    } catch (e) {
        return e;
    }
};

export const updateFullProviderProfile = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider/create-profile`,
            headers: { 'x-auth-token': authToken },
        });
        return data;
    } catch (e) {
        return e;
    }
};

export const updateProfileImage = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider/image`,
            headers: { 'x-auth-token': authToken },
        });
        return data;
    } catch (e) {
        return e;
    }
};

// Get Nationality API
export const getNationality = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/nationality/list`,
            headers: { 'x-auth-token': authToken },
        });
        return data;
    } catch (e) {
        return e;
    }
};

// Get Languages API
export const getLanguages = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/language/list`,
            headers: { 'x-auth-token': authToken },
        });
        return data;
    } catch (e) {
        return e;
    }
};
