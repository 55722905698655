import React from 'react';
import PropTypes from "prop-types";

import { Button } from '@material-ui/core';

import { isBrowser, isTablet, isMobile } from 'react-device-detect';

const WSButton = props => {
    const {
        variant,
        name,
        id,
        color,
        disabled,
        fullWidth,
        autoFocus,
        size,
        style,
        className,
        onClick,
        children,
        ariaLabel,
        target
    } = props;
    const sizeStyle = { fontSize: isMobile ? '12px' : '14px' }
    let styles = Object.assign({},
        sizeStyle,
        style,
    );
    return (
        <Button
            variant={variant}
            name={name}
            id={id}
            color={color}
            disabled={disabled}
            fullWidth={fullWidth}
            autoFocus={autoFocus}
            size={size}
            style={styles}
            className={className}
            onClick={onClick}
            aria-label={ariaLabel}
            target={target}
        >
            {children}
        </Button>
    );
};

WSButton.defaultProps = {
    variant: 'contained',
    color: 'primary',
    disabled: false,
    fullWidth: false,
    autoFocus: false,
    size: isBrowser ? "large" : isTablet ? "medium" : "small",
};

WSButton.propTypes = {
    variant: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    autoFocus: PropTypes.bool,
    size: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.element,
    ariaLabel: PropTypes.string,
    target: PropTypes.string,
};

export default WSButton;