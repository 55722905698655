import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { tConvert } from '../../../../utils/config/constants';

const ReceivedMessageCell = ({ conversation, user }) => {
    return (
        <div className="d-flex flex-nowrap chat-item">
            <LazyLoadImage
                width="50px"
                height="50px"
                className="rounded-circle avatar size-40 align-self-end"
                src={conversation.user.avatar.small}
                alt="coversation"
            />

            <div className="bubble">
                <div className="message">{conversation.text}</div>
                <div className="time text-muted text-right mt-2">
                    {conversation.user.name} -&nbsp;
                    {tConvert(new Date(conversation.created_at))}
                </div>
            </div>
        </div>
    );
};

export default ReceivedMessageCell;
