import React, { Component } from 'react';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { Paper, CircularProgress } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';

function MultiAddressPanel(props) {

    const [selectedAddress, setSelectedAddress] = useState({})
    const [selected, setSelected] = useState(props?.selectedKeys ? props?.selectedKeys : [])

    const selectAll = event => {
        const { userData, setBookingAddress } = props;
        if (event?.target?.checked) {
            setSelected(userData?.addresses.map(n => n?.key)),
                () => {
                    setBookingAddress(selected);
                }
            return;
        }

        setSelected([]), () => {
            setBookingAddress(selected);
        }
    };

    const handleClick = (event, id) => {
        const { setBookingAddress } = props;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected), () => {
            setBookingAddress(selected);
        }
    };

    const isSelected = id => selected.indexOf(id) !== -1;

    const { userData, addNewAddress } = props;
    const rowCount = userData?.addresses?.length;
    const numSelected = selected?.length;
    if (!selectedAddress) {
        return (
            <div className="container">
                <Paper>
                    <CircularProgress />
                </Paper>
            </div>
        );
    }
    return (
        <Paper className="card-spacing">
            <div className="col-md-12 main-panel">
                <div>
                    <h4 className="m-0">
                        <FormattedMessage
                            id="address.choosetitle"
                            defaultMessage="Choose Address"
                        />
                    </h4>
                    <div className="d-flex align-center">
                        <Checkbox
                            value="primary"
                            color="primary"
                            className="add-check-padding"
                            checked={numSelected === rowCount}
                            onChange={event => selectAll(event)}
                        />
                        <p style={{ color: '#000000', marginBottom: 0 }}>
                            <FormattedMessage
                                id="address.selectall"
                                defaultMessage="Select All"
                            />
                        </p>
                    </div>
                </div>
                {userData?.addresses.map((address, index) => {
                    const isSelecteds = isSelected(address?.key);
                    return (
                        <div className="d-flex align-center" key={address?.key}>
                            <Checkbox
                                className="add-check-padding"
                                onClick={event => handleClick(event, address?.key)}
                                color="primary"
                                checked={isSelecteds}
                            />
                            <p style={{ marginBottom: 0 }}>
                                {address?.name}, {address?.phone},&nbsp;
                                    {address?.address_line1}
                            </p>
                        </div>
                    );
                })}
            </div>
        </Paper>
    );
}

const composedComponent = compose(withTheme());

export default composedComponent(MultiAddressPanel);