import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';

const sendProps = props => {
    const { classes, ...rest } = props;
    return rest;
};
const ThemeWrapper = Component =>
    class WithCustomTheme extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isLoading: true
            };
        }

        // Set up HOC
        async componentDidMount() {
            const { settings, getThemeSettingsAction } = this.props;
            if (settings.colors) {
                this.setState({ isLoading: false });
            } else {
                const response = await getThemeSettingsAction();
                if (response) {
                    this.setState({ isLoading: false });
                } else {
                    this.setState({
                        isLoading: false,
                        error: {
                            error: true,
                            message: 'Cant find theme settings'
                        }
                    });
                }
            }
        }

        render() {
            return <Component {...sendProps(this.props)} {...this.state} />;
        }
    };
const mapStateToProps = ({ settings }) => {
    return {
        settings
    };
};
const composedWithTheme = compose(
    connect(
        mapStateToProps,
        actions
    ),
    ThemeWrapper
);
export default composedWithTheme;
