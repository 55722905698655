import { serviceRating } from '../apiHelpers/rating/list';

export const serviceRatingAction = (params, done) => async (dispatch) => {
    const response = await serviceRating(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};
