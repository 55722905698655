import React, { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import Card from 'react-credit-cards';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage, injectIntl } from 'react-intl';
import Cookies from 'universal-cookie';

import { withTheme } from '@material-ui/core/styles';
import { Paper, CircularProgress } from '@material-ui/core';

import * as actions from '../../actions';

import HOCLoader from '../../components/HOC/HOCLoader';

import WSButton from '../Button/WSButton';

import 'react-credit-cards/es/styles-compiled.css';


export const cookies = new Cookies();

let that;

const digitsOnly = (value) => /^\d+$/.test(value);

const cardPaymentValidation = yup.object().shape({
    number: yup
        .string()
        .required('Card Number is required.')
        .test('Digits only', 'Card Number should have digits only', digitsOnly)
        .matches(
            /^\s*(?:[0-9]{16})\s*$/,
            'Card Number must be exact 16 digits long'
        ),
    name: yup
        .string()
        .required('Card Holder Name is required.')
        .matches(
            /^[A-Za-z ]+$/,
            'Card Holder Name should have characters only'
        ),
    expiryMonth: yup.string().required('Expiry Month is required.'),
    expiryYear: yup.string().required('Expiry Year is required.'),
    cvc: yup
        .string()
        .required('CVC is required.')
        .test('Digits only', 'CVC should have digits only', digitsOnly)
        .matches(/^\s*(?:[0-9]{3})\s*$/, 'CVC must be exact 3 digits long'),
});

function AddNewCard(props, form) {

    const [number, setNumber] = useState('4111111111111111')
    const [name, setName] = useState('John Doe')
    const [expiryMonth, setExpiryMonth] = useState('02')
    const [expiryYear, setExpiryYear] = useState('2023')
    const [cvc, setCvc] = useState('582')
    const [transactionId, setTransactionId] = useState('')
    const [issuer, setIssuer] = useState('')
    const [focused, setFocused] = useState('')
    const [booking, setBooking] = useState('')
    const [loaderButton, setLoaderButton] = useState(false)
    const [redirectBookingSuccess, setRedirectBookingSuccess] = useState(false)

    useEffect(() => {
        that = this;
        const { cardListAction } = props;
        // cardListAction();
    }, []);

    const cardList = useSelector(state => state?.bookings?.cardList);
    const cardLoader = useSelector(state => state?.bookings?.cardLoader);

    const onDone = (success, data) => {
        if (success) {
            setBooking(data?.data)
        }
    };

    const renderButton = (submit) => {
        const { action } = props;
        if (loaderButton) {
            return (
                <Fragment>
                    <view
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress style={{ marginRight: 10 }} />
                        <h4>Please Wait...</h4>
                    </view>
                    <view>
                        <h4 className="mt-0 mb-0 text-center">
                            We're processing your card details
                        </h4>
                        <span className="font-14 text-center">
                            This process may take a few seconds, so please do
                            not refresh the page.
                        </span>
                    </view>
                </Fragment>
            );
        } else {
            return (
                <button className="btn btn-primary btn-block" onClick={submit}>
                    <FormattedMessage
                        id={
                            action === 'addCard'
                                ? 'payment.add'
                                : action === 'addMoney'
                                    ? 'payment.addAmount'
                                    : 'payment.paynow'
                        }
                        defaultMessage={
                            action === 'addCard'
                                ? 'Add'
                                : action === 'addMoney'
                                    ? 'Add Amount'
                                    : 'Pay Now'
                        }
                    />
                </button>
            );
        }
    };

    const handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            setIssuer(issuer)
        }
    };

    const handleInputFocus = ({ target }) => {
        setFocused(target?.name)
    };

    const handleClose = () => {
        const { redirectToList } = props;
        redirectToList();
    };

    const checkExpDate = (values) => {
        const { expiryMonth, expiryYear } = values || {};
        const todayDate = new moment().format('MM/YYYY');
        const test = new moment(todayDate, 'MM/YYYY').isBefore(
            moment(`${expiryMonth}/${expiryYear}`, 'MM/YYYY')
        );
        return test;
    };

    const {
        intl,
        showManageCards,
        bookingId,
        bookingDateTime,
        bookingAmount,
        selectedService,
    } = props;
    const { formatMessage } = intl;
    if (redirectBookingSuccess) {
        return (
            <Redirect
                to={{
                    pathname: `/user/bookingreceipt`,
                    state: {
                        bookingId: bookingId,
                        bookingDateTime: bookingDateTime,
                        transactionId: transactionId,
                        bookingAmount: bookingAmount,
                        selectedService: selectedService,
                    },
                }}
            />
        );
    }
    return (
        <div>
            <Paper>
                <HOCLoader loading={cardLoader}>
                    <div className="container">
                        {showManageCards && (
                            <WSButton
                                onClick={handleClose}
                                style={{ marginTop: 10 }}
                            >
                                <FormattedMessage
                                    id="manageCards"
                                    defaultMessage="Manage Cards"
                                />
                            </WSButton>
                        )}
                        <h3>
                            <FormattedMessage
                                id="payment.addnew"
                                defaultMessage="Enter Debit/Credit Card Details"
                            />
                        </h3>
                        <div className="row">
                            <div className="col-md-6">
                                <Card
                                    number={number}
                                    name={name}
                                    expiry={`${expiryMonth}${expiryYear}`}
                                    cvc={cvc}
                                    focused={focused}
                                    callback={handleCallback}
                                />
                            </div>
                            <div className="col-md-6">
                                <Formik
                                    initialValues={{
                                        number: number || '',
                                        name: name || '',
                                        expiryMonth: expiryMonth || '',
                                        expiryYear: expiryYear || '',
                                        cvc: cvc || '',
                                    }}
                                    validationSchema={cardPaymentValidation}
                                    onSubmit={(values) => {
                                        if (checkExpDate(values)) {
                                            // write payment gateway code here
                                        } else {
                                            NotificationManager.error(
                                                'Expiry date is invalid'
                                            );
                                        }
                                    }}
                                >
                                    {(props) => (
                                        <form ref={(c) => (form = c)}>
                                            <div className="form-group">
                                                <input
                                                    type="tel"
                                                    name="number"
                                                    id="number"
                                                    value={
                                                        props?.values?.number
                                                    }
                                                    className="form-control"
                                                    placeholder={formatMessage(
                                                        {
                                                            id:
                                                                'payment.cardnumber',
                                                        }
                                                    )}
                                                    pattern="[\d| ]{16,22}"
                                                    maxLength={16}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            'number',
                                                            e?.target?.value
                                                        );
                                                        setNumber(e?.target?.value)
                                                    }}
                                                    onFocus={handleInputFocus}
                                                />
                                                {props?.errors?.number &&
                                                    props?.touched
                                                        ?.number && (
                                                        <small
                                                            style={{
                                                                color:
                                                                    'red',
                                                            }}
                                                        >
                                                            {
                                                                props?.errors
                                                                    ?.number
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    value={
                                                        props?.values?.name
                                                    }
                                                    className="form-control"
                                                    placeholder={formatMessage(
                                                        {
                                                            id:
                                                                'payment.nameplaceholder',
                                                        }
                                                    )}
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            'name',
                                                            e?.target?.value
                                                        );
                                                        setName(e?.target?.value)
                                                    }}
                                                    onFocus={handleInputFocus}
                                                />
                                                {props?.errors?.name &&
                                                    props?.touched?.name && (
                                                        <small
                                                            style={{
                                                                color:
                                                                    'red',
                                                            }}
                                                        >
                                                            {
                                                                props?.errors
                                                                    ?.name
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <input
                                                        type="tel"
                                                        name="expiryMonth"
                                                        id="expiryMonth"
                                                        value={
                                                            props?.values
                                                                ?.expiryMonth
                                                        }
                                                        className="form-control"
                                                        placeholder={formatMessage(
                                                            {
                                                                id:
                                                                    'payment.expMonth',
                                                            }
                                                        )}
                                                        pattern="\d\d/\d\d"
                                                        maxLength={2}
                                                        onChange={(e) => {
                                                            props.setFieldValue(
                                                                'expiryMonth',
                                                                e?.target
                                                                    ?.value
                                                            );
                                                            setExpiryMonth(e?.target?.value)
                                                        }}
                                                        onFocus={handleInputFocus}
                                                    />
                                                    {props?.errors
                                                        ?.expiryMonth &&
                                                        props?.touched
                                                            ?.expiryMonth && (
                                                            <small
                                                                style={{
                                                                    color:
                                                                        'red',
                                                                }}
                                                            >
                                                                {
                                                                    props
                                                                        ?.errors
                                                                        ?.expiryMonth
                                                                }
                                                            </small>
                                                        )}
                                                </div>
                                                <div className="col-4">
                                                    <input
                                                        type="tel"
                                                        name="expiryYear"
                                                        id="expiryYear"
                                                        value={
                                                            props?.values
                                                                ?.expiryYear
                                                        }
                                                        className="form-control"
                                                        placeholder={formatMessage(
                                                            {
                                                                id:
                                                                    'payment.expYear',
                                                            }
                                                        )}
                                                        pattern="\d\d/\d\d"
                                                        maxLength={4}
                                                        onChange={(e) => {
                                                            props.setFieldValue(
                                                                'expiryYear',
                                                                e?.target
                                                                    ?.value
                                                            );
                                                            setExpiryYear(e?.target?.value)
                                                        }}
                                                        onFocus={handleInputFocus}
                                                    />
                                                    {props?.errors
                                                        ?.expiryYear &&
                                                        props?.touched
                                                            ?.expiryYear && (
                                                            <small
                                                                style={{
                                                                    color:
                                                                        'red',
                                                                }}
                                                            >
                                                                {
                                                                    props
                                                                        ?.errors
                                                                        ?.expiryYear
                                                                }
                                                            </small>
                                                        )}
                                                </div>
                                                <div className="col-4">
                                                    <input
                                                        type="tel"
                                                        name="cvc"
                                                        id="cvv"
                                                        value={
                                                            props?.values?.cvc
                                                        }
                                                        className="form-control"
                                                        placeholder={formatMessage(
                                                            {
                                                                id:
                                                                    'payment.cvc',
                                                            }
                                                        )}
                                                        pattern="\d{3,4}"
                                                        maxLength={3}
                                                        onChange={(e) => {
                                                            props.setFieldValue(
                                                                'cvc',
                                                                e?.target
                                                                    ?.value
                                                            );
                                                            setCvc(e?.target?.value)
                                                        }}
                                                        onFocus={handleInputFocus}
                                                    />
                                                    {props?.errors?.cvc &&
                                                        props?.touched
                                                            ?.cvc && (
                                                            <small
                                                                style={{
                                                                    color:
                                                                        'red',
                                                                }}
                                                            >
                                                                {
                                                                    props
                                                                        ?.errors
                                                                        ?.cvc
                                                                }
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <input
                                                type="hidden"
                                                name="issuer"
                                                value={issuer}
                                            />
                                            <br />
                                            <div className="form-actions">
                                                {renderButton(
                                                    props.handleSubmit
                                                )}
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </HOCLoader>
            </Paper>
        </div>
    );
}

const composedComponent = compose(
    withTheme(),
    connect(null, actions)
);

export default composedComponent(injectIntl(AddNewCard));
