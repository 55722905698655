import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
    convertTimestampToDate,
    ROLE_PROVIDER,
    ROLE_CUSTOMER
} from '../../../../utils/config/constants';
let current = new Date();
current.getTime();
class UserCell extends Component {
    constructor() {
        super();
        this.state = { isUserClicked: false };
    }
    render() {
        const { chat, selectedSectionId, onSelectUser, userData } = this.props;
        const { isUserClicked } = this.state;
        return (
            <div
                className={`chat-user-item ${
                    selectedSectionId === chat.id ? 'active' : ''
                    }`}
                onClick={() => {
                    onSelectUser(chat);
                    this.setState({ isUserClicked: true });
                }}
            >
                <div className="chat-user-row row align-items-center">
                    <div className="chat-avatar col-xl-2 col-3">
                        <div className="chat-avatar-mode">
                            <LazyLoadImage
                                width="50px"
                                height="50px"
                                src={userData.role === ROLE_CUSTOMER ? chat.provider.avatar.small : chat.customer.avatar.small}
                                className="rounded-circle size-40"
                                alt={'hello'}
                            />
                            <span
                                className={`chat-mode small ${chat.status}`}
                            />
                        </div>
                    </div>
                    <div className="chat-info col-xl-10 col-6">
                        <div className="d-flex">
                            <div>
                                {userData.role === ROLE_PROVIDER && (
                                    <span className="name h4">
                                        {chat.customer ? chat.customer.name : ''}
                                    </span>
                                )}
                                {userData.role === ROLE_CUSTOMER && (
                                    <span className="name h4">
                                        {chat.provider ? chat.provider.name : ''}
                                    </span>
                                )}
                                <div className="chat-info-des">
                                    {chat.lastMessage.substring(0, 25) + '...'}
                                </div>
                            </div>
                            <div className="last-message-time ml-auto">
                                {convertTimestampToDate(chat.created_at)}
                            </div>
                            {userData.role === ROLE_PROVIDER &&
                                !isUserClicked &&
                                chat.providerUnreadCount > 0 && (
                                    <div className="bg-primary rounded-circle badge text-white he-20">
                                        {chat.providerUnreadCount}
                                    </div>
                                )}
                            {userData.role === ROLE_CUSTOMER &&
                                !isUserClicked &&
                                chat.customerUnreadCount > 0 && (
                                    <div className="bg-primary rounded-circle badge text-white he-20">
                                        {chat.customerUnreadCount}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserCell;