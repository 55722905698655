import {
    INBOX_LIST,
    INBOX_ADD,
    INBOX_DETAILS_RECEIVED,
    CLEAR_INBOX_DETAILS,
    INBOX_LIST_LOADING,
    LOGOUT,
    INBOX_DETAIL_LOADER,
    INBOX_LIST_REMOVE,
} from '../actions/types';

const INITIAL_STATE = {
    inboxList: [],
    inboxListLoader: true,
    inboxDetailLoader: false,
    uploadingImage: false,
    error: {
        error: false,
        message: '',
    },
    inboxDetails: {
        chats: [],
        pages: 1,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case INBOX_LIST:
            return { ...state, inboxList: action.payload };
        case INBOX_LIST_REMOVE:
            return { ...state, inboxList: [] };
        case INBOX_DETAILS_RECEIVED:
            return { ...state, inboxDetails: action.payload };
        case CLEAR_INBOX_DETAILS:
            return { ...state, inboxDetails: INITIAL_STATE.inboxDetails };
        case INBOX_DETAIL_LOADER:
            return { ...state, inboxDetailLoader: action.payload };
        case INBOX_ADD:
            return {
                ...state,
                inboxDetails: { ...state.inboxDetails, chats: action.payload },
            };
        case INBOX_LIST_LOADING:
            return { ...state, inboxListLoader: action.payload };
        case LOGOUT:
            return INITIAL_STATE;
        default:
            return state;
    }
}
