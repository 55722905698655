import React, { Fragment, Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as yup from "yup";
import { Formik, Form } from 'formik';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
    Paper,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountBalance from '@material-ui/icons/AccountBalance';

import * as actions from '../../actions';

import {
    ROLE_PROVIDER,
    ROLE_CUSTOMER,
    getLanguages
} from '../../utils/config/constants';

import 'react-image-crop/dist/ReactCrop.css';
import '../../assets/css/style.css';
import "react-datepicker/dist/react-datepicker.css";

const handleDropRejected = (...args) =>
    NotificationManager.error(
        <FormattedMessage
            id="errorUploading10mb"
            defaultMessage="Error uploading Image, try another image with maximum size 25MB"
        />,
        'Error'
    );

class UpdateProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crop: {
                aspect: 1 / 1
            },
            existingImage: '',
            files: [],
            showOld: true,
            language: props.user.language.language,
            providerTypesError: '',
            seletedDate: null,
            seleteDate: null,
            focused: null,
            value: 'basicDetailTab',
        };
    }

    handleDrop = (files) => {
        this.setState({
            files: files.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            ),
            showOld: false
        });
    }

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    };

    onCropComplete = async (crop, pixelCrop) => {
        if (crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    };

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );
        return new Promise((resolve, reject) => {
            this.fileUrl = canvas.toDataURL();
            resolve(this.fileUrl);
        });
    }

    onDone = (success, data) => {
        const { getLoggedInUserAction, updateDone } = this.props;
        this.setState({ loaderButton: false });
        if (success) {
            NotificationManager.success(
                <FormattedMessage
                    id="profileUpdatedSuccess"
                    defaultMessage="Profile Updated Successfully"
                />,
                'Success'
            );
            getLoggedInUserAction();
            updateDone();
        } else {
            NotificationManager.error(data, 'Error');
        }
    };

    languageInputHandler = (name, props, textKey) => {
        const enableValue = props.values && props.values[name] && props.values[name].match('Other')
        return (
            <Fragment>
                <select id={name} className="form-control"
                    name={name} value={props.values[name]}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                >
                    <option value="">Select Language</option>
                    {getLanguages && (
                        getLanguages.map(lang => <option key={lang} value={lang}>{lang}</option>)
                    )}
                </select>
                {/* {enableValue
                    &&
                    <Fragment>
                        <hr />
                        <h6 className="heading">Add Other Language</h6>

                        <input type="text" className="form-control"
                            name={textKey}
                            id={textKey}
                            placeholder='Please Type Your Language'
                            onChange={props.handleChange}
                            value={props.values[textKey]} />
                    </Fragment> */}
                {props.errors[textKey] && props.touched[textKey] && (
                    <div className="error-text">{props.errors[textKey]}</div>
                )}
            </Fragment>
        )

    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const {
            files,
            crop,
            loader,
            croppedImageUrl,
            seletedDate,
            photographyServicesError,
            providerTypesError,
            showOld,
            providerTypeError,
            value
        } = this.state;
        const thumbs = files.map((file, index) => (
            <div key={index}>
                <h3>
                    <FormattedMessage
                        id="customer.originalimage"
                        defaultMessage="Original Image"
                    />
                </h3>
                <ReactCrop
                    src={file.preview}
                    imageStyle={{
                        width: '100%',
                        height: '100%',
                        maxHeight: 'none'
                    }}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    crop={crop}
                    onChange={crop => {
                        this.setState({ crop });
                    }}
                />
            </div>
        ));
        const {
            user,
            providerTypeData,
            intl,
            proUpdateProfileAction,
            updateProfileAction
        } = this.props;
        const { formatMessage } = intl;
        let initialValues = {};
        let yupSchema;
        if (user.role === ROLE_PROVIDER) {
            initialValues = {
                name: user.name,
                email: user.email,
                description: user.description,
                businessName: user.businessName,
                phone: user.phone,
                contactNo_Office: user.contactNo_Office,
                contactNo_Resi: user.contactNo_Resi,
                nationality: user.nationality,
                bankName: user.bankName,
                bankAccountNo: user.bankAccountNo,
                iGProfile: user.iGProfile,
                fBProfile: user.fBProfile,
                website: user.website,
                sex: user.sex,
                languageSpoken1: (getLanguages.includes(user.languageSpoken1) && user.languageSpoken1 !== 'Other') ? user.languageSpoken1 : 'Other',
                languageSpoken2: (getLanguages.includes(user.languageSpoken2) && user.languageSpoken2 !== 'Other') ? user.languageSpoken2 : 'Other',
                languageSpoken3: (getLanguages.includes(user.languageSpoken3) && user.languageSpoken3 !== 'Other') ? user.languageSpoken3 : 'Other',
                iBANNo: user.iBANNo,
                vat_TRN_No: user.vat_TRN_No,
                establishedOn: moment.unix(
                    user.establishedOn / 1000
                ).format(
                    'DD-MM-YYYY'
                ),
                providerTypes: user.providerTypes,
                textInputLanguageSpoken1: !getLanguages.includes(user.languageSpoken1) ? user.languageSpoken1 : '',
                textInputLanguageSpoken2: !getLanguages.includes(user.languageSpoken2) ? user.languageSpoken2 : '',
                textInputLanguageSpoken3: !getLanguages.includes(user.languageSpoken3) ? user.languageSpoken3 : '',
            }
            yupSchema = yup.object().shape({
                name: yup.string().required('This field is required'),
                email: yup.string().required('This field is required'),
                businessName: yup.string().required('This field is required'),
                contactNo_Office: yup.number(),
                phone: yup.number().required('This Field is required'),
                nationality: yup.string().required('This Field is required'),
                bankName: yup.string().required('This Field is required'),
                bankAccountNo: yup.string().required('This Field is required'),
                iGProfile: yup.string().url('Link must be a valid URL'),
                fBProfile: yup.string().url('Link must be a valid URL'),
                sex: yup.string().required('This Field is required'),
                languageSpoken1: yup.string().required('This Field is required'),
                textInputLanguageSpoken1: yup.string().when('languageSpoken1', {
                    is: 'Other',
                    then: fieldSchema => fieldSchema.required('This Field is required'),
                }),
                iBANNo: yup.string().required('This Field is required'),
                vat_TRN_No: yup.string().required('This Field is required')
            });
        } else {
            initialValues = {
                name: user.name,
                email: user.email,
                phone: user.phone,
            }
            yupSchema = yup.object().shape({
                name: yup.string().required('This field is required'),
                email: yup.string().required('This field is required'),
                phone: yup.number().required('This Field is required')
            });
        }
        return (
            // <HOCSectionLoader loading={loader}>
            <div>
                <AppBar position="static" color="default">
                    <Tabs
                        className="profile-tabs-dash"
                        value={value}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    // centered
                    >
                        <Tab
                            label={formatMessage({
                                id: 'customer.basicprofile'
                            })}
                            icon={<PhoneIcon />}
                            value="basicDetailTab"
                        />
                        <Tab
                            label={formatMessage({
                                id: 'customer.bankDetail'
                            })}
                            icon={<AccountBalance />}
                            value="bankDetailtab"
                        />
                    </Tabs>
                </AppBar>
                <Formik
                    // Sets up our default values
                    initialValues={initialValues}
                    validationSchema={yupSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        if (values.providerTypes < 1) {
                            this.setState({
                                providerTypesError: 'Select atleast one Category.'
                            });
                            return false;
                        }
                        if (croppedImageUrl) {
                            values.image = croppedImageUrl;
                        }
                        values.id = user.id;
                        if (user.role === ROLE_PROVIDER) {
                            const formateDate = moment(values.establishedOn, 'DD-MM-YYYY').format('YYYY-MM-DD')
                            values.languageSpoken1 = (values.languageSpoken1 === 'Other') ? values.textInputLanguageSpoken1 : values.languageSpoken1
                            values.languageSpoken2 = (values.languageSpoken2 === 'Other') ? values.textInputLanguageSpoken2 : values.languageSpoken2
                            values.languageSpoken3 = (values.languageSpoken3 === 'Other') ? values.textInputLanguageSpoken3 : values.languageSpoken3
                            values.establishedOn = seletedDate ? seletedDate : formateDate
                            proUpdateProfileAction(values, this.onDone);
                        } else {
                            updateProfileAction(
                                values,
                                this.onDone
                            );
                        }
                    }}
                >
                    {props => (
                        <Paper style={{ width: '100%', padding: 20 }}>
                            <Form className="form_b">
                                <div className="col-md-12">
                                    <div
                                        className="row"
                                        noValidate
                                        autoComplete="off"
                                        id="update-profile"
                                    >
                                        {value === 'basicDetailTab' && <Fragment>
                                            <div className="row col-md-12 align-center">
                                                {showOld && (
                                                    <div className="col-md-3 mr-4">
                                                        {user.image && (
                                                            <div>
                                                                <h5>
                                                                    <FormattedMessage
                                                                        id="customer.existingimage"
                                                                        defaultMessage="Existing Image"
                                                                    />
                                                                </h5>
                                                                <Dropzone
                                                                    onDrop={this.handleDrop}
                                                                    accept="image/jpeg,image/jpg,image/png"
                                                                    multiple={false}
                                                                    maxSize={10000000}
                                                                    onDropRejected={handleDropRejected}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <LazyLoadImage
                                                                                width={'200px'}
                                                                                height={'200px'}
                                                                                className="profile-pic"
                                                                                alt="existingImage"
                                                                                src={user.role == ROLE_CUSTOMER
                                                                                    ? user.image.medium
                                                                                    : user.proImage
                                                                                        .medium
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                <div className="col-md-2 mt-3 my-3">
                                                    <Dropzone
                                                        onDrop={this.handleDrop}
                                                        accept="image/jpeg,image/jpg,image/png"
                                                        multiple={false}
                                                        maxSize={10000000}
                                                        onDropRejected={handleDropRejected}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <h5 className={isMobile && 'font-14'}>
                                                                    <FormattedMessage
                                                                        id="customer.drop10"
                                                                        defaultMessage="Drop Image here to Upload"
                                                                    />
                                                                </h5>
                                                                <p>
                                                                    <FormattedMessage
                                                                        id="customer.dropfiles"
                                                                        defaultMessage="Drop files here, or click to select files"
                                                                    />
                                                                </p>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                                {thumbs.length > 0 && (
                                                    <div className="col-md-4">{thumbs}</div>
                                                )}
                                                <div className="col-md-5">
                                                    {croppedImageUrl && (
                                                        <div>
                                                            <h3>
                                                                <FormattedMessage
                                                                    id="customer.finalimage"
                                                                    defaultMessage="Final Image"
                                                                />
                                                            </h3>
                                                            <LazyLoadImage
                                                                width={'200px'}
                                                                height={'200px'}
                                                                alt="Crop"
                                                                src={croppedImageUrl}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Fragment>}
                                        <div className="row col-md-12 mt-4">
                                            {value === 'basicDetailTab' && <Fragment>
                                                <div className="col-md-12 col-xs-12">
                                                    Basic Detail
                                            </div>
                                                <div className="col-md-4 col-xs-12">
                                                    <h6 className="heading">Name</h6>
                                                    <input type="text" className="form-control"
                                                        name="name"
                                                        id="name"
                                                        placeholder="Name"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.name} />
                                                    {props.errors.name && props.touched.name && (
                                                        <div className="error-text">{props.errors.name}</div>
                                                    )}
                                                </div>
                                            </Fragment>}
                                            {user.role === ROLE_PROVIDER && value === 'basicDetailTab' && (
                                                <div className="col-md-4 col-xs-12">
                                                    <h6 className="heading">Business Name</h6>
                                                    <input type="text" className="form-control"
                                                        name="businessName"
                                                        id="businessName"
                                                        placeholder="Business Name"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.businessName} />
                                                    {props.errors.businessName && props.touched.businessName && (
                                                        <div className="error-text">{props.errors.businessName}</div>
                                                    )}
                                                </div>
                                            )}
                                            {value === 'basicDetailTab' &&
                                                <div className="col-md-4 col-xs-12">
                                                    <h6 className="heading">Email Id</h6>
                                                    <input type="text" className="form-control"
                                                        disabled={true}
                                                        name="email"
                                                        id="email"
                                                        placeholder="Email Id"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.email} />
                                                    {props.errors.email && props.touched.email && (
                                                        <div className="error-text">{props.errors.email}</div>
                                                    )}
                                                </div>}
                                            {user.role === ROLE_PROVIDER && value === 'basicDetailTab' && (
                                                <Fragment>
                                                    <div className="col-md-4 col-xs-12">
                                                        <h6 className="heading">Website</h6>
                                                        <input type="text" className="form-control"
                                                            name="website"
                                                            id="website"
                                                            placeholder="Website"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.website} />
                                                        {props.errors.website && props.touched.website && (
                                                            <div className="error-text">{props.errors.website}</div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-4 col-xs-12">
                                                        <h6 className="heading">GENDER</h6>
                                                        <select id="sex" className="form-control"
                                                            name="sex" value={props.values.sex}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                        >
                                                            <option value="">Select Gender</option>
                                                            <option value={'Male'}>Male</option>
                                                            <option value={'Female'}>Female</option>
                                                        </select>
                                                        {props.errors.sex && props.touched.sex && (
                                                            <div className="error-text">{props.errors.sex}</div>
                                                        )}
                                                    </div>
                                                </Fragment>
                                            )}
                                            {value === 'basicDetailTab' &&
                                                <div className="col-md-4 col-xs-12">
                                                    <h6 className="heading">Mobile Number</h6>
                                                    <input type="number" className="form-control"
                                                        name="phone"
                                                        id="phone"
                                                        placeholder="Mobile Number"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.phone} />
                                                    {props.errors.phone && props.touched.phone && (
                                                        <div className="error-text">{props.errors.phone}</div>
                                                    )}
                                                </div>
                                            }
                                            {user.role === ROLE_PROVIDER && (
                                                <Fragment>
                                                    {value === 'basicDetailTab' && <Fragment>
                                                        <div className="col-md-4 col-xs-12">
                                                            <h6 className="heading">Contact No office</h6>
                                                            <input type="number" className="form-control"
                                                                name="contactNo_Office"
                                                                id="contactNo_Office"
                                                                placeholder="Contact No office"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.contactNo_Office} />
                                                            {props.errors.contactNo_Office && props.touched.contactNo_Office && (
                                                                <div className="error-text">{props.errors.contactNo_Office}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-4 col-xs-12">
                                                            <h6 className="heading">Contact No Residence</h6>
                                                            <input type="number" className="form-control"
                                                                name="contactNo_Resi"
                                                                id="contactNo_Resi"
                                                                placeholder="Contact No Residence"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.contactNo_Resi} />
                                                            {props.errors.contactNo_Resi && props.touched.contactNo_Resi && (
                                                                <div className="error-text">{props.errors.contactNo_Resi}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-4 col-xs-12">
                                                            <h6 className="heading">Nationality</h6>
                                                            <select id="nationality" className="form-control"
                                                                name="nationality" value={props.values.nationality}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                            >
                                                                <option value="">Select Nationality</option>
                                                                <option value={'Emirati'}>Emirati</option>
                                                                <option value={"Egyption"}>Egyption</option>
                                                                <option value={'Indian'}>Indian</option>
                                                            </select>
                                                            {props.errors.nationality && props.touched.nationality && (
                                                                <div className="error-text">{props.errors.nationality}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-4 col-xs-12">
                                                            <h6 className="heading">Company Established date</h6>
                                                            {/* <input type="date" 
                                                            defaultValue={props.values.establishedOn} 
                                                            data-date-format="dd-mm-yyyy" 
                                                            className="form-control"
                                                            name="establishedOn"
                                                            id="establishedOn"
                                                            placeholder="Company Established date"
                                                            onChange={props.handleChange}
                                                            value={props.values.establishedOn} /> */}
                                                            <DatePicker
                                                                name="establishedOn"
                                                                id="establishedOn"
                                                                selected={seletedDate}
                                                                onChange={(date) => this.setState({ seletedDate: date })}
                                                                value={seletedDate ? seletedDate : props.values.establishedOn}
                                                                dateFormat="dd-MM-yyyy"
                                                                className="form-control"
                                                                maxDate={new Date()}
                                                            />
                                                            {props.errors.establishedOn && props.touched.establishedOn && (
                                                                <div className="error-text">{props.errors.establishedOn}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-12 col-xs-12 mt-4">
                                                            Social Media Links
                                                    </div>
                                                        <div className="col-md-4 col-xs-12">
                                                            <h6 className="heading">IG Profile Link</h6>
                                                            <input type="text" className="form-control"
                                                                name="iGProfile"
                                                                id="iGProfile"
                                                                placeholder="IG Profile Link"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.iGProfile} />
                                                            {props.errors.iGProfile && props.touched.iGProfile && (
                                                                <div className="error-text">{props.errors.iGProfile}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-4 col-xs-12">
                                                            <h6 className="heading">FB Profile Link</h6>
                                                            <input type="text" className="form-control"
                                                                name="fBProfile"
                                                                id="fBProfile"
                                                                placeholder="FB Profile Link"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.fBProfile} />
                                                            {props.errors.fBProfile && props.touched.fBProfile && (
                                                                <div className="error-text">{props.errors.fBProfile}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-12 col-xs-12 mt-4">
                                                            Languages
                                                     </div>
                                                        <div className="col-md-4 col-xs-12">
                                                            <h6 className="heading">Primary Language</h6>
                                                            {this.languageInputHandler("languageSpoken1", props, "textInputLanguageSpoken1")}

                                                            {props.errors.languageSpoken1 && props.touched.languageSpoken1 && (
                                                                <div className="error-text">{props.errors.languageSpoken1}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-4 col-xs-12">
                                                            <h6 className="heading">Secondary Language</h6>
                                                            {this.languageInputHandler("languageSpoken2", props, "textInputLanguageSpoken2")}
                                                            {props.errors.languageSpoken2 && props.touched.languageSpoken2 && (
                                                                <div className="error-text">{props.errors.languageSpoken2}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-4 col-xs-12">
                                                            <h6 className="heading">Additional Language</h6>
                                                            {this.languageInputHandler("languageSpoken3", props, "textInputLanguageSpoken3")}

                                                            {props.errors.languageSpoken3 && props.touched.languageSpoken3 && (
                                                                <div className="error-text">{props.errors.languageSpoken3}</div>
                                                            )}
                                                        </div>
                                                    </Fragment>}
                                                    {value === 'bankDetailtab' && <Fragment>
                                                        <div className="col-md-12 col-xs-12 mt-4">
                                                            Bank Details
                                                    </div>
                                                        <div className="col-md-6 col-xs-12">
                                                            <h6 className="heading">Bank Name</h6>
                                                            <input type="text" className="form-control"
                                                                name="bankName"
                                                                id="bankName"
                                                                placeholder="Bank Name"
                                                                onChange={props.handleChange}
                                                                value={props.values.bankName} />
                                                            {props.errors.bankName && props.touched.bankName && (
                                                                <div className="error-text">{props.errors.bankName}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-6 col-xs-12">
                                                            <h6 className="heading">Bank Account No</h6>
                                                            <input type="number" className="form-control"
                                                                name="bankAccountNo"
                                                                id="bankAccountNo"
                                                                placeholder="Bank Account No"
                                                                onChange={props.handleChange}
                                                                value={props.values.bankAccountNo} />
                                                            {props.errors.bankAccountNo && props.touched.bankAccountNo && (
                                                                <div className="error-text">{props.errors.bankAccountNo}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h6 className="heading">Iban NO</h6>
                                                            <input type="text" className="form-control"
                                                                name="iBANNo"
                                                                id="iBANNo"
                                                                placeholder="IBanNo"
                                                                onChange={props.handleChange}
                                                                value={props.values.iBANNo} />
                                                            {props.errors.iBANNo && props.touched.iBANNo && (
                                                                <div className="error-text">{props.errors.iBANNo}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h6 className="heading">Vat Trn No</h6>
                                                            <input type="text" className="form-control"
                                                                name="vat_TRN_No"
                                                                id="vat_TRN_No"
                                                                placeholder="Vat Trn No"
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.vat_TRN_No} />
                                                            {props.errors.vat_TRN_No && props.touched.vat_TRN_No && (
                                                                <div className="error-text">{props.errors.vat_TRN_No}</div>
                                                            )}
                                                        </div>
                                                    </Fragment>}
                                                    {value === 'basicDetailTab' && <Fragment>
                                                        <div className="col-md-12 col-xs-12 mt-4">
                                                            Short Brief
                                                    </div>
                                                        <div className="col-md-12 col-xs-12">
                                                            <h6 className="heading">Description</h6>
                                                            <textarea
                                                                name="description"
                                                                id="description"
                                                                className="form-control"
                                                                placeholder="Description"
                                                                onChange={props.handleChange}
                                                            >
                                                                {props.values.description}
                                                            </textarea>
                                                            {props.errors.description && props.touched.description && (
                                                                <div className="error-text">{props.errors.description}</div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-12 col-xs-12 mt-4">Choose Your Profession</div>
                                                        <div className="col-md-12 col-xs-12">
                                                            <Select
                                                                id="providerTypes"
                                                                name="providerTypes"
                                                                multiple
                                                                value={props.values.providerTypes}
                                                                onChange={event => {
                                                                    props.setFieldValue("providerTypes", event.target.value)
                                                                    this.setState({ providerTypeError: '' });
                                                                }}
                                                            >
                                                                {providerTypeData &&
                                                                    providerTypeData.map(type => {
                                                                        return <MenuItem value={type.id}>{type.name}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                            <FormHelperText style={{ color: 'red', marginBottom: '10px' }}>{providerTypeError}</FormHelperText>
                                                        </div>
                                                        {photographyServicesError != '' &&
                                                            <div className="col-md-12 col-xs-12" style={{ color: 'red' }}>
                                                                {providerTypesError}
                                                            </div>
                                                        }
                                                    </Fragment>}
                                                </Fragment>
                                            )}
                                            <div className="col-md-12 mt-4 ">
                                                <button type="submit" class="btn btn-danger" >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Paper>
                    )
                    }
                </Formik>
            </div>
        );
    }
}

export default connect(
    null,
    actions
)(injectIntl(UpdateProfile));