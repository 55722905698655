import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { withStyles } from '@material-ui/core/styles';
import { Card, CircularProgress } from '@material-ui/core';

import * as actions from '../../actions';

import { validateGalleryCreateForm } from '../../utils/validators/validateForm';

import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';

import WSButton from '../Button/WSButton';

import 'react-image-crop/dist/ReactCrop.css';

const styles = {
    width: '100%',
    height: '100%',
    formControl: {
        minWidth: '90%',
        margin: '15px'
    }
};

const handleDropRejected = (...args) =>
    NotificationManager.error(
        <FormattedMessage
            id="errorUploading25mb"
            defaultMessage="Error uploading Image, try another image with maximum size 25MB"
        />,
        'Error'
    );

class ServiceGalleryUpdate extends Component {
    constructor(props) {
        super();
        this.state = {
            loaderButton: false,
            pass: false,
            noResponse: false,
            errorMessage: '',
            validate: true,
            serviceId: props.serviceId,
            existingImage: '',
            files: [],
            data: '',
            showOld: true,
            crop: {
                aspect: 1 / 1
            },
            croppedImageUrl: '',
            imageError: '',
            imgIndex: props.imageData ? props.imageData.index : '',
            oldImage: props.imageData ? props.imageData.image : ''
        };
    }

    submit = async () => {
        const { croppedImageUrl, imageError } = this.state;
        const result = await validateGalleryCreateForm(
            {
                image: croppedImageUrl
            },
            this.handleError
        );

        if (result) {
            this.addGalleryImage();
        } else {
            NotificationManager.error(imageError, 'Error');
        }
    };

    handleError = params => {
        this.setState(params);
    };

    addGalleryImage = () => {
        const { croppedImageUrl, imgIndex } = this.state;
        const { addServiceGalleryImageAction, service } = this.props;
        this.setState({ loader: true });
        addServiceGalleryImageAction(
            {
                file: croppedImageUrl,
                index: imgIndex,
                categoryId: service.category.key,
                serviceId: service.service.key
            },
            this.onAddDone
        );
    };

    onAddDone = (success, data) => {
        const { history } = this.props;
        this.setState({ loader: false });
        if (success) {
            NotificationManager.success(data.message, 'Success');
            history.push(`/provider/service/gallery`);
        }
    };

    handleDrop = (files) => {
        this.setState({
            files: files.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            ),
            showOld: false
        });
    }

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    };

    onCropComplete = async (crop, pixelCrop) => {
        if (crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    };

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );
        return new Promise((resolve, reject) => {
            this.fileUrl = canvas.toDataURL();
            resolve(this.fileUrl);
        });
    }

    renderButton = () => {
        const { loader } = this.state;
        if (loader) {
            return <CircularProgress />;
        }
        return (
            <WSButton
                variant="raised"
                className="jr-btn"
                onClick={this.submit}
            >
                <FormattedMessage id="update" defaultMessage="Update" />
            </WSButton>
        );
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render() {
        const { files, crop, oldImage } = this.state;
        const thumbs = files.map(file => (
            <div>
                <h3>
                    <FormattedMessage
                        id="customer.originalimage"
                        defaultMessage="Original Image"
                    />
                </h3>
                <ReactCrop
                    src={file.preview}
                    imageStyle={{
                        width: '100%',
                        height: '100%'
                    }}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    crop={crop}
                    onChange={crop => {
                        this.setState({ crop });
                    }}
                />
            </div>
        ));
        const { croppedImageUrl, showOld } = this.state;
        const baseStyle = {
            width: 200,
            height: 200,
            borderWidth: 2,
            borderColor: '#666',
            borderStyle: 'dashed',
            borderRadius: 5
        };
        const activeStyle = {
            borderStyle: 'solid',
            borderColor: '#6c6',
            backgroundColor: '#eee'
        };
        const rejectStyle = {
            borderStyle: 'solid',
            borderColor: '#c66',
            backgroundColor: '#eee'
        };
        return (
            <Card className="col-md-12">
                <form
                    className="row"
                    noValidate
                    autoComplete="off"
                    id="gallery-create"
                >
                    <div className="row col-md-12">
                        {showOld && (
                            <div className="col-md-2 mr-4">
                                {oldImage && (
                                    <div>
                                        <h5>
                                            <FormattedMessage
                                                id="customer.existingimage"
                                                defaultMessage="Existing Image"
                                            />
                                        </h5>
                                        <LazyLoadImage
                                            width={'200px'}
                                            height={'200px'}
                                            alt="existingImage"
                                            src={oldImage}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="col-md-2">
                            <h5 className={isMobile && 'font-14'}>
                                <FormattedMessage
                                    id="customer.drop"
                                    defaultMessage="Drop Image here to Upload"
                                />
                            </h5>
                            <Dropzone
                                onDrop={this.handleDrop}
                                accept="image/jpeg,image/jpg,image/png"
                                multiple={false}
                                maxSize={25000000}
                                onDropRejected={handleDropRejected}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject,
                                    acceptedFiles,
                                    rejectedFiles
                                }) => {
                                    let styles = { ...baseStyle };
                                    styles = isDragActive
                                        ? { ...styles, ...activeStyle }
                                        : styles;
                                    styles = isDragReject
                                        ? { ...styles, ...rejectStyle }
                                        : styles;

                                    {
                                        return (
                                            <div
                                                {...getRootProps()}
                                                style={styles}
                                            >
                                                <input {...getInputProps()} />
                                                <p>
                                                    <FormattedMessage
                                                        id="customer.dropfiles"
                                                        defaultMessage="Drop files here, or click to select files"
                                                    />
                                                </p>
                                            </div>
                                        );
                                    }
                                }}
                            </Dropzone>
                        </div>
                        <div className="col-md-5">{thumbs}</div>
                        <div className="col-md-5">
                            {croppedImageUrl && (
                                <div>
                                    <h3>
                                        <FormattedMessage
                                            id="customer.finalimage"
                                            defaultMessage="Final Image"
                                        />
                                    </h3>
                                    <LazyLoadImage
                                        width={'200px'}
                                        height={'200px'}
                                        alt="Crop"
                                        src={croppedImageUrl}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 col-xs-12 mt-3">
                        {this.renderButton()}
                    </div>
                </form>
            </Card>
        );
    }
}

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default withCustomTheme(composedComponent(ServiceGalleryUpdate));