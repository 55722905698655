import React, { Component, useState } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';

import { FormControl, FormLabel } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import withUser from '../HOC/HOCwithUser.jsx';
import GridItem from '../Grid/GridItem.jsx';
import GridContainer from '../Grid/GridContainer.jsx';

function SlotDateTimePanel(props) {

    const [dateTimeValue, setDateTimeValue] = useState('')

    const { open, isValidDate, timeFormat, intl, onChange, isDateAvailable } = props;
    return (
        <div className="col-md-12">
            <h4>
                <FormattedMessage
                    id="datetime.heading"
                    defaultMessage="Date and Time"
                />
            </h4>

            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Datetime
                            open={open}
                            isValidDate={isValidDate}
                            timeFormat={timeFormat}
                            viewDate={moment()}
                            color="primary"
                            inputProps={{
                                placeholder: intl.formatMessage(
                                    {
                                        id: 'Click here to choose date'
                                    }
                                )
                            }}
                            value={dateTimeValue}
                            onChange={onChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem><FormLabel className="mt-2 mb-2 text-primary">{isDateAvailable === false ? 'This date is set as unavailable' : ''}</FormLabel></GridItem>
            </GridContainer>
        </div>
    );
}
export default withTheme()(withUser(injectIntl(SlotDateTimePanel)));
