import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import * as actions from '../../actions';

import ServiceMediaUrlUpdate from '../../components/Providers/ServiceMediaUrlUpdate.js';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

export class Update extends Component {
    constructor(props) {
        super();
        this.state = {
            data: [],
            loader: true,
            service: props.location.service ? props.location.service : '',
            url: props.location.url ? props.location.url : '',
            indexValue: props.location.indexValue
                ? props.location.indexValue
                : 0,
        };
    }

    componentDidMount() {
        const {
            location,
            history,
            match,
            providerServiceVideoListAction,
        } = this.props;
        if (!location.service) {
            history.push(`/provider/service/url`);
        }
        // providerServiceVideoListAction(
        //     {
        //         providerServiceId: match.params.serviceId
        //     },
        //     this.onDone
        // );
    }

    // onDone = (success, data) => {
    // const { history } = this.props;
    //     if (success) {
    //         this.setState({ data: data.data, loader: false });
    //     } else {
    //         history.push(`provider/service/url`);
    //     }
    // };

    render() {
        const { classes } = this.props;
        const { service, url, indexValue } = this.state;
        return (
            <div>
                <div className={isMobile ? 'row mb-2' : 'row mb-4'}>
                    <div className="col-md-12">
                        <Card className={classes.card}>
                            <Toolbar>
                                <h3>Update Youtube Url</h3>
                            </Toolbar>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ServiceMediaUrlUpdate
                            service={service}
                            url={url}
                            indexValue={indexValue}
                            {...this.props}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles), connect(null, actions));

export default composedComponent(Update);
