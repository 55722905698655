import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import * as yup from "yup";
import moment from 'moment';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import PhoneInput from 'react-phone-input-2';

import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import WSButton from "../../components/Button/WSButton";
import WSTextField from "../../components/TextField/WSTextField";
import Select from "../../components/Select/Select";
import Option from "../../components/Select/Option";

import '../../assets/css/style.css';
import 'react-phone-input-2/lib/style.css';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme?.palette?.background?.paper
    },
});

const basicDetailValidation = yup.object().shape({
    name: yup.string().required('Name is required.'),
    igProfile: yup.string().required('IG Profile is required.'),
    phoneNo: yup.string().required('Phone Number is required.'),
    fbProfile: yup.string().required('FB Profile is required.'),
    website: yup.string().required('Website is required.'),
    resNo: yup.string().required('Contact of Residence is required.'),
    nationality: yup.string().required('Nationality is required.'),
    shortBrief: yup.string().required('Short Brief is required.'),
    email: yup.string().required('Email Id is required.'),
    gender: yup.string().required('Gender is required.'),
    language1: yup.string().required('Primary Language is required.'),
});


const ProviderBasicInformation = props => {
    const [loaderButton, setLoaderButton] = useState(false);
    const [phoneNoActive, setPhoneNoActive] = useState(false);
    const [resNoActive, setResNoActive] = useState(false);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const { getLanguagesAction, getNationalityAction } = props;
        getNationalityAction()
        getLanguagesAction()
    },[]);
    const usersData = useSelector(state => state?.auth?.userData);
    const nationalityData = useSelector(state => state?.auth?.nationalityData);
    const languagesData = useSelector(state => state?.auth?.languagesData);

    const updateBasicDetailAPI = (values) => {
        const { updateFullProviderProfileAction } = props;
        const {
            name,
            igProfile,
            phoneNo,
            fbProfile,
            resNo,
            nationality,
            shortBrief,
            email,
            gender,
            language1,
            language2,
            language3,
            website
        } = values
        updateFullProviderProfileAction({
            name,
            email,
            sex: gender,
            shortBrief,
            iGProfile: igProfile,
            fBProfile: fbProfile,
            nationality,
            languageSpoken1: language1,
            languageSpoken2: language2,
            languageSpoken3: language3,
            phone: phoneNo,
            contactNo_Resi: resNo,
            website
        }, onDone)
    }

    const onDone = (success) => {
        if (success) {
            NotificationManager.success('Basic Details Updated', null, 2000);
        } else {
            NotificationManager.error('Basic Details Update Failed', null, 2000);
        }
    };

    useEffect(() => {
        if (usersData) {
            setUserData(usersData)
        }
    },[usersData]);

        const { theme } = props;
        return (
            <Formik
                initialValues={{
                    name: userData && userData?.name || '',
                    igProfile: userData && userData?.iGProfile || '',
                    phoneNo: String(userData && userData?.phone) || '971',
                    fbProfile: userData && userData?.fBProfile || '',
                    resNo: String(userData && userData?.contactNo_Resi) || '971',
                    nationality: userData && userData?.nationality || '',
                    shortBrief: userData && userData?.shortBrief || '',
                    email: userData && userData?.email || '',
                    gender: userData && userData?.sex || '',
                    language1: userData && userData?.languageSpoken1 || '',
                    language2: userData && userData?.languageSpoken2 || '',
                    language3: userData && userData?.languageSpoken3 || '',
                    createdOn: userData && userData?.createdOn || '',
                    created_On: userData && moment(userData?.created_at).format("YYYY-MM-DD") || '',
                    website: userData && userData?.website || '',
                }}
                enableReinitialize
                validationSchema={basicDetailValidation}
                onSubmit={(values) => updateBasicDetailAPI(values)}
            >
                {props => (
                    <form
                        className="dialog-form dialog-form-new"
                        autoComplete="off"
                    >
                        <div className="row col-md-12 p-0">
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Name <span className="primary-color">*</span></span>
                                <WSTextField
                                    error={props?.errors?.name && props?.touched?.name ? true : false}
                                    helperText={props?.errors?.name && props?.touched?.name ? props?.errors?.name : ''}
                                    className="login-form-textinput"
                                    id="name"
                                    name="name"
                                    placeholder={'Name'}
                                    value={props?.values?.name}
                                    onChange={props.handleChange('name')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <div className={`col-md-3 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Gender <span className="primary-color">*</span></span>
                                <Select
                                    name="gender"
                                    id="gender"
                                    className="form-control form-control-select w-100 mt-25"
                                    onChange={props.handleChange('gender')}
                                    value={props?.values?.gender}
                                >
                                    <Option value="">Select Gender</Option>
                                    <Option value="Male">Male</Option>
                                    <Option value="Female">Female</Option>
                                    <Option value="Undisclosed">Undisclosed</Option>
                                </Select>
                                {props?.errors?.gender && props?.touched?.gender && (
                                    <div className="error-text">{props?.errors?.gender}</div>
                                )}
                            </div>
                            <div className={`col-md-3 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Nationality <span className="primary-color">*</span></span>
                                <Select
                                    name="nationality"
                                    id="nationality"
                                    className="form-control form-control-select w-100 mt-25"
                                    onChange={props.handleChange('nationality')}
                                    value={props?.values?.nationality}
                                >
                                    <Option value="">Select Nationality</Option>
                                    {nationalityData && nationalityData.map(nationality => {
                                        return <Option value={nationality?.name} key={nationality?.id}>{nationality?.name}</Option>
                                    })}
                                </Select>
                                {props?.errors?.nationality && props?.touched?.nationality && (
                                    <div className="error-text">{props?.errors?.nationality}</div>
                                )}
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Email Id</span>
                                <WSTextField
                                    error={props?.errors?.email && props?.touched?.email ? true : false}
                                    helperText={props?.errors?.email && props?.touched?.email ? props?.errors?.email : ''}
                                    className="login-form-textinput"
                                    id="email"
                                    name="email"
                                    placeholder={'Email Id'}
                                    value={props?.values?.email}
                                    onChange={props.handleChange('email')}
                                    disabled
                                />
                            </div>
                            <div className={`col-md-3 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Phone Number <span className="primary-color">*</span></span>
                                <PhoneInput
                                    country={'ae'}
                                    enableSearch
                                    autoFormat
                                    countryCodeEditable={false}
                                    disableSearchIcon
                                    placeholder="Phone Number"
                                    value={props?.values?.phoneNo}
                                    containerStyle={{ marginTop: 25 }}
                                    inputStyle={{ backgroundColor: '#f6f9ff', width: '100%', height: 50, borderRadius: 0, borderColor: phoneNoActive && '#e24f36' }}
                                    inputClass="border-shadow-none"
                                    buttonStyle={{ backgroundColor: '#f6f9ff', borderRadius: 0, borderColor: phoneNoActive && '#e24f36' }}
                                    onChange={phone => props.setFieldValue('phoneNo', phone)}
                                    onFocus={() => setPhoneNoActive(true)}
                                    onBlur={() => setPhoneNoActive(false)}
                                />
                                {props?.errors?.phoneNo && props?.touched?.phoneNo && (
                                    <div className="error-text">{props?.errors?.phoneNo}</div>
                                )}
                            </div>
                            <div className={`col-md-3 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Contact of Residence <span className="primary-color">*</span></span>
                                <PhoneInput
                                    country={'ae'}
                                    enableSearch
                                    autoFormat
                                    countryCodeEditable={false}
                                    disableSearchIcon
                                    placeholder="Contact of Residence"
                                    value={props?.values?.resNo}
                                    containerStyle={{ marginTop: 25 }}
                                    inputStyle={{ backgroundColor: '#f6f9ff', width: '100%', height: 50, borderRadius: 0, borderColor: resNoActive && '#e24f36' }}
                                    inputClass="border-shadow-none"
                                    buttonStyle={{ backgroundColor: '#f6f9ff', borderRadius: 0, borderColor: resNoActive && '#e24f36' }}
                                    onChange={phone => props.setFieldValue('resNo', phone)}
                                    onFocus={() => setResNoActive(true)}
                                    onBlur={() => setResNoActive(false)}
                                />
                                {props?.errors?.resNo && props?.touched?.resNo && (
                                    <div className="error-text">{props?.errors?.resNo}</div>
                                )}
                            </div>
                            <div className={`col-md-2 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Created On</span>
                                <WSTextField
                                    error={props?.errors?.createdOn && props?.touched?.createdOn ? true : false}
                                    helperText={props?.errors?.createdOn && props?.touched?.createdOn ? props?.errors?.createdOn : ''}
                                    className="login-form-textinput"
                                    id="createdOn"
                                    name="createdOn"
                                    type="date"
                                    placeholder={'Created On'}
                                    value={props?.values?.created_On}
                                    onChange={props.handleChange('createdOn')}
                                    disabled
                                />
                            </div>
                            <div className={`col-md-12 col-xs-12 postion-relative mb-2 mt-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Short Brief <span className="primary-color">*</span></span>
                                <WSTextField
                                    error={props?.errors?.shortBrief && props?.touched?.shortBrief ? true : false}
                                    helperText={props?.errors?.shortBrief && props?.touched?.shortBrief ? props?.errors?.shortBrief : ''}
                                    className="login-form-textinput"
                                    id="shortBrief"
                                    name="shortBrief"
                                    rows={3}
                                    rowsMax={5}
                                    multiline
                                    inputProps={{ maxLength: 500 }}
                                    placeholder={'Brief'}
                                    value={props?.values?.shortBrief}
                                    onChange={props.handleChange('shortBrief')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <span className='w-100'></span>
                            <span className={isMobile ? 'w-100 profile-header-label pl-15 mt-20' : 'w-100 profile-header-label'}>Social Links & Website</span>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>IG Profile <span className="primary-color">*</span></span>
                                <WSTextField
                                    error={props?.errors?.igProfile && props?.touched?.igProfile ? true : false}
                                    helperText={props?.errors?.igProfile && props?.touched?.igProfile ? props?.errors?.igProfile : ''}
                                    className="login-form-textinput"
                                    id="igProfile"
                                    name="igProfile"
                                    placeholder={'IG Profile'}
                                    value={props?.values?.igProfile}
                                    onChange={props.handleChange('igProfile')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>FB Profile <span className="primary-color">*</span></span>
                                <WSTextField
                                    error={props?.errors?.fbProfile && props?.touched?.fbProfile ? true : false}
                                    helperText={props?.errors?.fbProfile && props?.touched?.fbProfile ? props?.errors?.fbProfile : ''}
                                    className="login-form-textinput"
                                    id="fbProfile"
                                    name="fbProfile"
                                    placeholder={'FB Profile'}
                                    value={props?.values?.fbProfile}
                                    onChange={props.handleChange('fbProfile')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Website <span className="primary-color">*</span></span>
                                <WSTextField
                                    error={props?.errors?.website && props?.touched?.website ? true : false}
                                    helperText={props?.errors?.website && props?.touched?.website ? props?.errors?.website : ''}
                                    className="login-form-textinput"
                                    id="website"
                                    name="website"
                                    placeholder={'Website'}
                                    value={props?.values?.website}
                                    onChange={props.handleChange('website')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <span className={isMobile ? 'w-100 profile-header-label pl-15 mt-20' : 'w-100 profile-header-label'}>Languages</span>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Primary Language <span className="primary-color">*</span></span>
                                <Select
                                    name="language1"
                                    id="language1"
                                    className="form-control form-control-select w-100 mt-25"
                                    onChange={props.handleChange('language1')}
                                    value={props?.values?.language1}
                                >
                                    <Option value="">Select Primary Language</Option>
                                    {languagesData && languagesData.map(language => {
                                        return <Option value={language?.id}>{language?.name}</Option>
                                    })}
                                </Select>
                                {props?.errors?.language1 && props?.touched?.language1 && (
                                    <div className="error-text">{props?.errors?.language1}</div>
                                )}
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Secondary Language</span>
                                <Select
                                    name="language2"
                                    id="language2"
                                    className="form-control form-control-select w-100 mt-25"
                                    onChange={props.handleChange('language2')}
                                    value={props?.values?.language2}
                                >
                                    <Option value="">Select Secondary Language</Option>
                                    {languagesData && languagesData.map(language => {
                                        return <Option value={language?.id}>{language?.name}</Option>
                                    })}
                                </Select>
                                {props?.errors?.language2 && props?.touched?.language2 && (
                                    <div className="error-text">{props?.errors?.language2}</div>
                                )}
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Additional Language</span>
                                <Select
                                    name="language3"
                                    id="language3"
                                    className="form-control form-control-select w-100 mt-25"
                                    onChange={props.handleChange('language3')}
                                    value={props?.values?.language3}
                                >
                                    <Option value="">Select Additional Language</Option>
                                    {languagesData && languagesData.map(language => {
                                        return <Option value={language?.id}>{language?.name}</Option>
                                    })}
                                </Select>
                                {props?.errors?.language3 && props?.touched?.language3 && (
                                    <div className="error-text">{props?.errors?.language3}</div>
                                )}
                            </div>
                        </div>
                        <div className="flex-justify-spacebetween mt-2">
                            <div>
                                <WSButton
                                    style={{
                                        backgroundColor: theme?.palette?.primary?.main,
                                        color: '#ffffff',
                                        margin: !isMobile && 20,
                                        borderRadius: 30
                                    }}
                                    onClick={props.handleSubmit}
                                >
                                    Save Updates
                                </WSButton>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        );
}

export default withStyles(styles)(withTheme()(ProviderBasicInformation));