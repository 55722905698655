import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';

import * as actions from '../../actions';

import CustomScrollbars from 'utils/CustomScrollbars';

import HOCSectionLoader from '../HOC/HOCSectionLoader';

import Conversation from '../chatPanel/Conversation/index';
import ChatUserList from '../../components/chatPanel/ChatUserList';

class ChatPanel extends Component {
    constructor() {
        super();
        this.state = {
            drawerState: false,
            selectedSectionId: '',
            selectedTabIndex: 0,
            userState: 1,
            selectedUser: null,
            message: '',
            chatUsers: [],
            conversation: null,
            data: [],
            leftLoader: true,
            errorShow: '',
            noResponse: false,
            conversationData: [],
            detailChat: '',
            size: 100,
        };
    }

    componentDidMount() {
        this.getData();
        const { inboxList, inboxDetails } = this.props;
        this.setState({
            chatUsers: inboxList,
            conversationData: inboxDetails.chats,
            detailChat: inboxDetails,
        });
    }

    componentWillUnmount() {
        const { removeInboxList } = this.props;
        removeInboxList();
    }

    componentDidUpdate(prevProps, prevState) {
        const { inboxList, inboxDetails } = this.props;
        if (prevProps.inboxList.length !== inboxList.length) {
            this.setState({
                chatUsers: inboxList,
                conversationData: inboxDetails.chats,
                detailChat: inboxDetails,
            });
        }

        if (prevProps.inboxDetails.chats.length !== inboxDetails.chats.length) {
            this.setState({
                conversationData: inboxDetails.chats,
                detailChat: inboxDetails,
            });
        }
    }

    getData = () => {
        const { getInboxListAction } = this.props;
        getInboxListAction();
    };

    getDetailData = (selectedUser) => {
        const { getInboxDetailsAction, inboxDetails } = this.props;
        const { size } = this.state;
        getInboxDetailsAction({
            // topTime: inboxDetails.topTime,
            messageroom: selectedUser.messageroom,
            size: size,
            type: true,
        });
    };

    Communication = () => {
        const {
            message,
            selectedUser,
            conversationData,
            messageroom,
        } = this.state;
        const { userData, width } = this.props;
        return (
            <div className="chat-main">
                <div className="chat-main-header">
                    <IconButton
                        className="d-block d-xl-none chat-btn"
                        aria-label="Menu"
                        onClick={this.onToggleDrawer}
                    >
                        <i className="zmdi zmdi-comment-text" />
                    </IconButton>
                    <div className="chat-main-header-info">
                        <div className="chat-avatar mr-2">
                            <div className="chat-avatar-mode">
                                <LazyLoadImage
                                    src={selectedUser.thumb}
                                    className="rounded-circle size-60"
                                    alt=""
                                />
                                <span
                                    className={`chat-mode ${selectedUser.status}`}
                                />
                            </div>
                        </div>
                        <div className="chat-contact-name">
                            {selectedUser.name}
                        </div>
                    </div>
                </div>
                <CustomScrollbars
                    className="chat-list-scroll scrollbar"
                    style={{
                        height:
                            width >= 1200
                                ? 'calc(100vh - 300px)'
                                : 'calc(100vh - 255px)',
                    }}
                >
                    <Conversation
                        userData={userData}
                        conversationData={conversationData}
                        selectedUser={selectedUser}
                        messageroom={messageroom}
                    />
                </CustomScrollbars>
                <div className="chat-main-footer">
                    <div
                        className="d-flex flex-row align-items-center"
                        style={{ maxHeight: 51 }}
                    >
                        <div className="col-md-12">
                            <textarea
                                id="required"
                                className="border-0 form-control chat-textarea"
                                onKeyUp={this._handleKeyPress}
                                onChange={this.updateMessageValue}
                                value={message}
                                placeholder="Type and hit enter to send message"
                            />
                        </div>
                        <div className="chat-sent">
                            <IconButton
                                onClick={this.submitComment}
                                aria-label="Send message"
                            >
                                <i className="zmdi  zmdi-mail-send" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    ChatUsers = () => {
        const { width, inboxList, userData } = this.props;
        const { chatUsers, selectedSectionId } = this.state;
        return (
            <div className="col-md-12">
                <AppBar
                    position="static"
                    className="no-shadow chat-tabs-header"
                >
                    <h4 className="inbox-heading">Inbox</h4>
                </AppBar>
                <CustomScrollbars
                    className="chat-sidenav-scroll scrollbar"
                    style={{
                        height:
                            width >= 1200
                                ? 'calc(100vh - 328px)'
                                : 'calc(100vh - 202px)',
                    }}
                >
                    {inboxList.length === 0 ? (
                        <div className="p-5" style={{ color: '#000000' }}>
                            No Chat Found
                        </div>
                    ) : (
                        <ChatUserList
                            userData={userData}
                            chatUsers={chatUsers}
                            selectedSectionId={selectedSectionId}
                            onSelectUser={this.onSelectUser}
                        />
                    )}
                </CustomScrollbars>
            </div>
        );
    };

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitComment();
        }
    };

    handleChange = (event, value) => {
        this.setState({ selectedTabIndex: value });
    };

    handleChangeIndex = (index) => {
        this.setState({ selectedTabIndex: index });
    };

    onSelectUser = (user) => {
        const { drawerState } = this.props;
        const { chatUsers } = this.state;
        this.setState({
            selectedSectionId: user.id,
            drawerState: drawerState,
            selectedUser: user,
            conversation: chatUsers.find((data) => data.id === user.id),
            messageroom: user.messageroom,
        });
        this.getDetailData(user);
    };

    showCommunication = (userData) => {
        const { selectedUser } = this.state;
        return (
            <div className="chat-box">
                <div className="chat-box-main">
                    {selectedUser === null ? (
                        <div>
                            <i className="zmdi zmdi-comment s-128 text-muted" />
                            <h3
                                className={
                                    isMobile
                                        ? 'text-muted font-16'
                                        : 'text-muted'
                                }
                            >
                                Select User to Chat
                            </h3>
                        </div>
                    ) : (
                        this.Communication()
                    )}
                </div>
            </div>
        );
    };

    submitComment = () => {
        const { sendMessageAction } = this.props;
        const { message, detailChat } = this.state;
        sendMessageAction({
            text: message,
            messageroom: detailChat.messageroom,
            toId: detailChat.provider_id,
        });
        if (message !== '') {
            this.setState({ message: '' });
            //     const updatedConversation = conversationData.concat({
            //         text: message,
            //         created_at: Moment(new Date()).valueOf()
            //     });
            //     this.setState({
            //         conversation: {
            //             ...conversation,
            //             conversationData: updatedConversation
            //         },
            //         message: '',
            //         conversationList: conversationList.map(
            //             conversationData => {
            //                 if (
            //                     conversationData.id === conversation.id
            //                 ) {
            //                     return {
            //                         ...conversation,
            //                         conversationData: updatedConversation
            //                     };
            //                 } else {
            //                     return conversationData;
            //                 }
            //             }
            //         )
            //     });
        }
    };

    updateMessageValue = (evt) => {
        this.setState({
            message: evt.target.value,
        });
    };

    onToggleDrawer = () => {
        const { drawerState } = this.state;
        this.setState({
            drawerState: !drawerState,
        });
    };

    render() {
        const { drawerState, errorShow, noResponse } = this.state;
        const { inboxListLoader, inboxDetailLoader, userData } = this.props;
        return (
            <div className="app-wrapper app-wrapper-module">
                <HOCSectionLoader
                    loading={inboxListLoader}
                    errorShow={errorShow}
                    noResponse={noResponse}
                >
                    <div className="app-module chat-module animated slideInUpTiny animation-duration-3">
                        <div className="chat-module-box">
                            <div className="col-md-12">
                                <div className="row align-items-center">
                                    <div className="col-md-4">
                                        <div className="d-block d-xl-none">
                                            <Drawer
                                                open={drawerState}
                                                onClose={this.onToggleDrawer}
                                            >
                                                {this.ChatUsers()}
                                            </Drawer>
                                        </div>
                                        <div className="chat-sidenav d-none d-xl-flex">
                                            {this.ChatUsers()}
                                        </div>
                                    </div>
                                    <div className="col-md-8 text-center">
                                        <HOCSectionLoader
                                            loading={inboxDetailLoader}
                                            errorShow={errorShow}
                                            noResponse={noResponse}
                                        >
                                            {this.showCommunication(userData)}
                                        </HOCSectionLoader>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </HOCSectionLoader>
            </div>
        );
    }
}
const mapStateToProps = ({ inbox }) => {
    return {
        inboxList: inbox.inboxList,
        inboxDetails: inbox.inboxDetails,
        inboxDetailLoader: inbox.inboxDetailLoader,
        inboxListLoader: inbox.inboxListLoader,
    };
};
export default connect(mapStateToProps, actions)(ChatPanel);
