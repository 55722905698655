import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NotificationManager } from 'react-notifications';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { withStyles } from '@material-ui/core/styles';
import {
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { CloudUpload } from '@material-ui/icons';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import AddIcon from '@material-ui/icons/Add';

import * as actions from '../../actions';

import { SERVER_URL } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

import WSButton from '../../components/Button/WSButton.jsx';
import DeleteAlert from '../../components/Alert/DeleteAlert.jsx';

import 'react-image-crop/dist/ReactCrop.css';

const handleDropRejected = (...args) =>
    NotificationManager.error(
        <FormattedMessage
            id="errorUploading25mb"
            defaultMessage="Error uploading Image, try another image with maximum size 25MB"
        />,
        'Error'
    );

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.secondary.main,
    },
    title: {
        flex: '0 0 auto',
    },
});

let GalleryListToolbar = (props) => {
    const {
        numSelected,
        classes,
        deleteAction,
        creategallery,
        showUploadImageForm,
        totalCount,
        shareImagesToCustomer,
        isShared,
    } = props;
    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                        }}
                    >
                        {isMobile ? (
                            <h5
                                style={{ fontWeight: 400 }}
                                className={isMobile && 'font-14'}
                            >
                                Upload Images to share with <br /> Customer for
                                selection :
                            </h5>
                        ) : (
                            <h4
                                style={{ fontWeight: 400 }}
                                className={isMobile && 'font-14'}
                            >
                                Upload Images to share with Customer for
                                selection :
                            </h4>
                        )}
                        <WSButton
                            disabled={isShared ? true : false}
                            style={{
                                margin: '0 10px',
                                width: 100,
                                alignSelf: 'center',
                                marginBottom: isMobile && 15,
                            }}
                            variant="raised"
                            className="jr-btn"
                            onClick={shareImagesToCustomer}
                        >
                            {isShared && (
                                <FormattedMessage
                                    id="SharedButton"
                                    defaultMessage="Shared"
                                />
                            )}
                            {!isShared && (
                                <FormattedMessage
                                    id="ShareButton"
                                    defaultMessage="Share"
                                />
                            )}
                        </WSButton>
                    </div>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon onClick={deleteAction} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    totalCount !== 0 &&
                    !showUploadImageForm &&
                    !isShared && (
                        <Tooltip title="Add">
                            <WSButton
                                variant="fab"
                                ariaLabel="Add"
                                onClick={creategallery}
                                style={{
                                    padding: 0,
                                }}
                            >
                                <AddIcon />
                            </WSButton>
                        </Tooltip>
                    )
                )}
            </div>
        </Toolbar>
    );
};

GalleryListToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

GalleryListToolbar = withStyles(toolbarStyles)(GalleryListToolbar);

function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

class SelectedBookingImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            selected: [],
            deleteAlert: false,
            deleteSelected: '',
            existingImage: '',
            files: [],
            showOld: true,
            crop: {
                aspect: 10 / 9,
            },
            croppedImageUrl: '',
            imageError: '',
            imgIndex: 0,
            showUploadImageForm: false,
            ErrorColor: 'black',
            bookingToken: props.bookingToken || '',
            sharedImages: props.sharedImages || [],
            isShared: props.isShared || false,
            selectedImages: [],
            totalUploadedPhotos: 0,
            totalPhotos: 0,
            totalUploadedPercentage: 0,
        };
    }

    componentDidMount() {
        const { sharedImages } = this.state;
        let images = sharedImages;
        let selectedImages = sharedImages;
        if (images && images.length > 0) {
            images = images.map((image) => {
                return {
                    id: image.id,
                    src: image.filename,
                    thumbnail: image.filename,
                };
            });
            selectedImages = selectedImages.filter((image) => {
                return image.isSelected;
            });
            if (selectedImages && selectedImages.length > 0) {
                selectedImages = selectedImages.map((image) => {
                    let images = { ...image };
                    images.id = image.id;
                    images.src = image.filename;
                    images.thumbnail = image.filename;
                    images.isSelected = true;
                    return images;
                });
            }
        }
        this.setState({
            images,
            selectedImages,
        });
    }

    selectedImages = (index, image) => {
        const { images } = this.state;
        let Images = images.slice();
        let img = Images[index];
        if (img.hasOwnProperty('isSelected')) img.isSelected = !img.isSelected;
        else img.isSelected = true;
        let selected = Images.filter((image) => {
            return image.isSelected;
        });
        this.setState({
            images: Images,
            selected,
        });
    };

    shareImagesToCustomer = () => {
        const { bookingToken } = this.state;
        const { sharedBookingImagesAction } = this.props;
        sharedBookingImagesAction(
            {
                bookingToken: bookingToken,
            },
            this.onSharedDone
        );
    };

    onSharedDone = (success, data) => {
        if (success) {
            this.setState({ isShared: true });
            NotificationManager.success(data.message, 'Success');
        } else {
            this.setState({ deleteAlert: false });
            NotificationManager.error(data.message, 'Error');
        }
    };

    deleteRecord = (id) => {
        const { selected } = this.state;
        let ids = selected.map((selectedImg) => {
            return selectedImg.id;
        });
        this.setState({ deleteAlert: true, deleteSelected: ids });
    };

    deleteGallery = () => {
        const { deleteSelected, bookingToken } = this.state;
        const { deleteSharedBookingImagesAction } = this.props;
        deleteSharedBookingImagesAction(
            {
                id: deleteSelected,
                bookingToken: bookingToken,
            },
            this.onDeleteDone
        );
    };

    onDeleteDone = (success, data) => {
        let { deleteSelected, images } = this.state;
        if (success) {
            images = images.filter((image) => {
                return deleteSelected.indexOf(image.id) > -1 ? false : true;
            });
            this.setState({
                deleteAlert: false,
                images,
                selected: [],
                showUploadImageForm: images.length > 0 ? false : true,
            });
            NotificationManager.success(data.message, 'Success');
        } else {
            this.setState({
                deleteAlert: false,
                showUploadImageForm: images.length > 0 ? false : true,
            });
            NotificationManager.error(data.message, 'Error');
        }
    };

    handleCloseDelete = () => {
        this.setState({ deleteAlert: false });
    };

    showImageUploadForm = () => {
        this.setState({
            showUploadImageForm: true,
        });
    };

    handleDrop = async (files) => {
        const { images, totalUploadedPhotos } = this.state;
        this.setState({ loader: true });
        let success = false;
        let uploadedImages = [...images];
        let uploadedCount = 0;
        if (files && files.length > 0) {
            for (let file of files) {
                this.setState({
                    totalPhotos: files.length,
                    totalUploadedPercentage: 0,
                });
                let response = await this.addGalleryImage(file);
                if (response.status === 'OK') {
                    ++uploadedCount;
                    success = true;
                    uploadedImages.push({
                        id: response.data.insertedId,
                        src: response.data.filename,
                        thumbnail: response.data.filename,
                    });
                    this.setState({
                        totalUploadedPhotos: totalUploadedPhotos + 1,
                    });
                } else {
                    this.setState({
                        totalUploadedPercentage: 0,
                    });
                }
            }
        }

        if (success) {
            NotificationManager.success(
                `${uploadedCount} Images uploaded Successfully.`,
                'Success'
            );
            this.setState({
                loader: false,
                showUploadImageForm: false,
                images: uploadedImages,
                totalUploadedPhotos: 0,
                totalPhotos: 0,
                totalUploadedPercentage: 0,
                files: [],
                croppedImageUrl: '',
            });
        } else {
            this.setState({
                loader: false,
                totalUploadedPhotos: 0,
                totalPhotos: 0,
                totalUploadedPercentage: 0,
                files: [],
                croppedImageUrl: '',
            });
        }
    };

    addGalleryImage = (file) => {
        const { bookingToken, croppedImageUrl } = this.state;
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('bookingToken', bookingToken);
            formData.append('file', file, 'hello.jpg');
            return axios({
                method: 'post',
                data: formData,
                url: `${SERVER_URL}/booking/share-booking-images`,
                headers: { 'x-auth-token': getCookies() },
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader(
                              'content-length'
                          ) ||
                          progressEvent.target.getResponseHeader(
                              'x-decompressed-content-length'
                          );
                    if (totalLength !== null) {
                        let progress = Math.round(
                            (progressEvent.loaded * 100) / totalLength
                        );
                        this.setState({
                            totalUploadedPercentage: progress,
                        });
                    }
                },
            })
                .then(
                    (response) => {
                        resolve(response.data);
                    },
                    function (err) {
                        resolve({ status: 'NOK' });
                    }
                )
                .catch((err) => {
                    resolve({ status: 'NOK' });
                });
        });
    };

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    };

    onCropComplete = async (crop, pixelCrop) => {
        if (crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    };

    getCroppedImg = (image, pixelCrop, fileName) => {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );
        return new Promise((resolve, reject) => {
            this.fileUrl = canvas.toDataURL();
            resolve(this.fileUrl);
        });
    };

    renderButton = () => {
        const {
            loader,
            totalUploadedPhotos,
            totalPhotos,
            totalUploadedPercentage,
        } = this.state;
        if (loader) {
            return (
                <div
                    style={{
                        color: 'green',
                        fontSize: '14px',
                        fontWeight: 600,
                    }}
                >
                    <div>
                        {totalUploadedPhotos} of {totalPhotos} Photos
                    </div>
                    <CircularProgress style={{ margin: '15px' }} />
                    <div>{totalUploadedPercentage} % Progress</div>
                </div>
            );
        }
    };

    render() {
        const { match } = this.props;
        const {
            selected,
            images,
            selectedImages,
            croppedImageUrl,
            showOld,
            imgIndex,
            showUploadImageForm,
            files,
            ErrorColor,
            crop,
            isShared,
            deleteAlert,
        } = this.state;
        const baseStyle = {
            width: 200,
            height: 200,
            borderWidth: 2,
            borderColor: '#666',
            borderStyle: 'dashed',
            borderRadius: 5,
        };
        const activeStyle = {
            borderStyle: 'solid',
            borderColor: '#6c6',
            backgroundColor: '#eee',
        };
        const rejectStyle = {
            borderStyle: 'solid',
            borderColor: '#c66',
            backgroundColor: '#eee',
        };
        const thumbs = files.map((file, index) => (
            <div key={index}>
                <h3 style={{ color: ErrorColor }}>Select Image for crop</h3>
                <ReactCrop
                    src={file.preview}
                    imageStyle={{
                        width: '100%',
                        //height: '100%',
                        maxHeight: 'inherit',
                    }}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    crop={crop}
                    onChange={(crop) => {
                        this.setState({ crop });
                    }}
                />
            </div>
        ));
        return (
            <div class="row">
                <div class="col-md-12">
                    <GalleryListToolbar
                        numSelected={selected.length}
                        deleteAction={this.deleteRecord}
                        match={match}
                        serviceId={1}
                        creategallery={this.showImageUploadForm}
                        showUploadImageForm={showUploadImageForm}
                        shareImagesToCustomer={this.shareImagesToCustomer}
                        totalCount={images.length}
                        isShared={isShared}
                    />
                    {(images.length === 0 || showUploadImageForm) && (
                        <div style={{ margin: '0 20px' }}>
                            <div className="row col-md-12">
                                <div className="col-md-6 m-auto">
                                    <h4
                                        className={
                                            isMobile
                                                ? 'text-center font-14'
                                                : 'text-center'
                                        }
                                    >
                                        <FormattedMessage
                                            id="customer.drop"
                                            defaultMessage="Drop Image here to Upload"
                                        />
                                    </h4>
                                    <Dropzone
                                        onDrop={this.handleDrop}
                                        accept="image/jpeg,image/jpg,image/png"
                                        multiple={true}
                                        maxSize={25000000}
                                        onDropRejected={handleDropRejected}
                                    >
                                        {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive,
                                            isDragAccept,
                                            isDragReject,
                                            acceptedFiles,
                                            rejectedFiles,
                                        }) => {
                                            let styles = { ...baseStyle };
                                            styles = isDragActive
                                                ? { ...styles, ...activeStyle }
                                                : styles;
                                            styles = isDragReject
                                                ? { ...styles, ...rejectStyle }
                                                : styles;
                                            {
                                                return (
                                                    <div
                                                        {...getRootProps()}
                                                        style={styles}
                                                        className="dropbox"
                                                    >
                                                        <div className="inner">
                                                            <input
                                                                {...getInputProps()}
                                                            />
                                                            <CloudUpload />
                                                            <p>
                                                                <FormattedMessage
                                                                    id="customer.dropfile"
                                                                    defaultMessage="Drop multiple files here, or click to select files"
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }}
                                    </Dropzone>
                                </div>
                                {/* <div className="col-md-4">{thumbs}</div>
                                    <div className="col-md-4">
                                        {croppedImageUrl && (
                                            <div>
                                                <h3>
                                                    <FormattedMessage
                                                        id="customer.finalimage"
                                                        defaultMessage="Final Image"
                                                    />
                                                </h3>
                                                <LazyLoadImage
                                                    style={{width:'100%'}}
                                                    alt="Crop"
                                                    src={croppedImageUrl}
                                                />
                                            </div>
                                        )}
                                    </div> */}
                            </div>
                            <div
                                className="col-md-12 col-xs-12 mt-3"
                                style={{ paddingBottom: 10 }}
                            >
                                {this.renderButton()}
                            </div>
                        </div>
                    )}
                    <div style={{ margin: '0 20px' }}>
                        <Gallery
                            images={images}
                            showImageCount={true}
                            enableImageSelection={isShared ? false : true}
                            onSelectImage={(selectedImages) => {
                                this.selectedImages(selectedImages);
                            }}
                        />
                    </div>
                    <DeleteAlert
                        open={deleteAlert}
                        close={deleteAlert}
                        handleNo={this.handleCloseDelete}
                        handleYes={this.deleteGallery}
                    />
                </div>
                {selectedImages && selectedImages.length > 0 && (
                    <div class="col-md-12" style={{ margin: '25px 0' }}>
                        <div style={{ margin: '0 20px' }}>
                            <h4
                                style={{ fontWeight: 400 }}
                                className={isMobile && 'font-14'}
                            >
                                &nbsp; Selected Images by customer :&nbsp;
                            </h4>
                            <Gallery
                                images={selectedImages}
                                showImageCount={true}
                                enableImageSelection={false}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const composedComponent = compose(
    withStyles(toolbarStyles),
    connect(null, actions)
);

export default composedComponent(SelectedBookingImages);
