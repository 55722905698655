import {
    CATEGORY_DATA_RECEIVED,
    CATEGORY_LOADER,
    SERVICE_DATA_RECEIVED,
    PROVIDER_SERVICE_DATA_RECEIVED,
    PROVIDER_SERVICE_LOADER,
    SUBCATEGORY_DATA_RECEIVED,
    SUBCATEGORY_LOADER,
    TOP_CATEGORY_DATA_RECEIVED,
} from '../actions/types';

const INITIAL_STATE = {
    categoryData: [
        { services: [] },
        { services: [] },
        { services: [] },
        { services: [] },
    ],
    topCategoryData: [
        { services: [] },
        { services: [] },
        { services: [] },
        { services: [] },
    ],
    serviceData: [],
    subcategoryData: [],
    categoryLoader: true,
    subcategoryLoader: true,
    providerServiceData: [],
    providerServiceLoader: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CATEGORY_DATA_RECEIVED:
            return { ...state, categoryData: action.payload };
        case TOP_CATEGORY_DATA_RECEIVED:
            return { ...state, topCategoryData: action.payload };
        case SUBCATEGORY_DATA_RECEIVED:
            return { ...state, subcategoryData: action.payload };
        case SERVICE_DATA_RECEIVED:
            return { ...state, serviceData: action.payload };
        case CATEGORY_LOADER:
            return { ...state, categoryLoader: action.payload };
        case SUBCATEGORY_LOADER:
            return { ...state, subcategoryLoader: action.payload };
        case PROVIDER_SERVICE_DATA_RECEIVED:
            return { ...state, providerServiceData: action.payload };
        case PROVIDER_SERVICE_LOADER:
            return { ...state, providerServiceLoader: action.payload };
        default:
            return state;
    }
}
