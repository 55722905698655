import React, { Component, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NotificationManager } from 'react-notifications';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import withUser from '../HOC/HOCwithUser.jsx';
import HOCLoader from '../HOC/HOCLoader.jsx';
import MenuButton from '../Menu/MenuButton';
import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';
import AddNewCard from '../Booking/AddNewCard.jsx';
import WSButton from '../Button/WSButton.jsx';
import DeleteAlert from '../Alert/DeleteAlert.jsx';

import * as actions from '../../actions';
import { useEffect } from 'react';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function CardListHead(props) {

    const createSortHandler = property => event => {
        const { onRequestSort } = props;
        onRequestSort(event, property);
    };

    const { formatMessage } = props;
    const rows = [
        {
            id: 'brand',
            label: formatMessage({
                id: 'card.brand'
            })
        },
        {
            id: 'exp_month',
            label: formatMessage({
                id: 'card.exp_month'
            })
        },
        {
            id: 'last4',
            label: formatMessage({
                id: 'card.last4'
            })
        },
        {
            id: 'exp_year',
            label: formatMessage({
                id: 'card.exp_year'
            })
        },
        {
            id: 'action',
            label: formatMessage({
                id: 'card.action'
            })
        }
    ];
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount
    } = props;

    return (
        <TableHead>
            <TableRow>
                {rows.map(
                    row => (
                        <TableCell
                            key={row?.id}
                            align={row?.numeric ? 'right' : 'left'}
                            padding={
                                row?.disablePadding ? 'none' : 'default'
                            }
                            sortDirection={
                                orderBy === row?.id ? order : false
                            }
                        >
                            <Tooltip
                                title="Sort"
                                placement={
                                    row?.numeric
                                        ? 'bottom-end'
                                        : 'bottom-start'
                                }
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={orderBy === row?.id}
                                    direction={order}
                                    onClick={createSortHandler(row?.id)}
                                >
                                    {row?.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    // this
                )}
            </TableRow>
        </TableHead>
    );
}

CardListHead.propTypes = {
    numSelected: PropTypes?.number?.isRequired,
    onRequestSort: PropTypes?.func?.isRequired,
    onSelectAllClick: PropTypes?.func?.isRequired,
    order: PropTypes?.string?.isRequired,
    orderBy: PropTypes?.string?.isRequired,
    rowCount: PropTypes?.number?.isRequired
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme?.spacing?.unit
    },
    highlight:
        theme?.palette?.type === 'light'
            ? {
                color: theme?.palette?.secondary?.main,
                backgroundColor: lighten(theme?.palette?.secondary?.light, 0.85)
            }
            : {
                color: theme?.palette?.text?.primary,
                backgroundColor: theme?.palette?.secondary?.dark
            },
    spacer: {
        flex: '1 1 100%'
    },
    actions: {
        color: theme?.palette?.secondary?.main
    },
    title: {
        flex: '0 0 auto'
    }
});

let CardListToolbar = props => {
    const { numSelected, classes, settings, onAddClick } = props;

    return (
        <Toolbar
            className={classNames(classes?.root, {
                [classes?.highlight]: numSelected > 0
            })}
        >
            <div className={classes?.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <h6 style={{ fontWeight: 500, fontSize: '16px' }}>
                        <FormattedMessage
                            id="card.heading"
                            defaultMessage="Card List"
                        />
                    </h6>
                )}
            </div>
            <div className={classes?.spacer} />
            <div className={classes?.actions}>
                {
                    <Tooltip title="Add">
                        <WSButton
                            className="addNewButton"
                            variant="fab"
                            ariaLabel="Add"
                            onClick={onAddClick}
                            style={{
                                padding: 0,
                                height: 40,
                            }}
                        >
                            Add New
                        </WSButton>
                    </Tooltip>
                }
            </div>
        </Toolbar>
    );
};

CardListToolbar.propTypes = {
    classes: PropTypes?.object?.isRequired,
    numSelected: PropTypes?.number?.isRequired,
    mainbalance: PropTypes?.number
};

CardListToolbar = withStyles(toolbarStyles)(CardListToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme?.spacing?.unit * 3
    },
    table: {
        minWidth: 600
    },
    tableWrapper: {
        overflowX: 'auto'
    }
});

function CardList(props) {

    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState('created_at')
    const [selected, setSelected] = useState([])
    const [data, setData] = useState(props?.cardList?.sources ? props?.cardList?.sources?.data : [])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [clickValue, setClickValue] = useState('')
    const [itemId, setItemId] = useState('')
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [cardSelected, setCardSelected] = useState('')

    const actionCallbacks = (action, res) => {
        if (action === 'delete') {
            setClickValue('delete')
            setItemId(res?.key)
        }
    }
    useEffect(() => {
        const { cardListAction } = props;
        cardListAction();
    }, []);

    useEffect((prevProps) => {
        const { cardList } = props;
        if (
            prevProps?.cardList?.sources?.data !== cardList?.sources?.data
        ) {
            setData(cardList.sources.data)
        }
    }, []);


    const handleRequestSort = (event, property) => {
        let orders = 'desc';
        if (orderBy === property && order === 'desc') {
            orders = 'asc';
        }
        setOrder(orders)
        setOrderBy(property)
    };

    const handleSelectAllClick = event => {
        if (event?.target?.checked) {
            setSelected(state?.data.map(n => n?.key))
            return;
        }
        setSelected([])
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected)
    };

    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event?.target?.value)
    };

    const isSelected = id => selected.indexOf(id) !== -1;

    const onAddClick = () => {
        setShowCreateForm(true)
    };

    const redirectToList = () => {
        setShowCreateForm(false)
    };

    const renderAddForm = () => {
        return (
            <div>
                <AddNewCard
                    getBack={true}
                    redirectToList={redirectToList}
                    showManageCards={true}
                    action='addCard'
                />
            </div>
        );
    };

    const alertDeleteCard = cardId => {
        setDeleteAlert(true)
        setCardSelected(cardId)
    };

    const handleYes = () => {
        setDeleteAlert(false)
        deleteCard(cardSelected);
    };

    const handleNo = () => {
        setDeleteAlert(false)
    };

    const closeDeleteAlert = () => {
        setDeleteAlert(false)
    };

    const deleteCard = cardId => {
        const { deleteCardAction } = props;
        deleteCardAction(
            {
                cardToken: cardId
            },
            onDeleteDone
        );
    };
    const onDeleteDone = (success, data) => {
        if (success) {
            NotificationManager.success(data?.message, 'Success');
        }
    };
    const { intl, cardLoader, settings } = props;
    const { formatMessage } = intl;
    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, data?.length - page * rowsPerPage);

    if (clickValue) {
        return (
            <Redirect
                to={{
                    pathname: `/address/${clickValue}`,
                    state: { itemId: itemId }
                }}
            />
        );
    }

    if (showCreateForm) {
        return renderAddForm()
    }
    return (
        <HOCLoader loading={cardLoader}>
            <Paper>
                <CardListToolbar
                    numSelected={selected?.length}
                    settings={settings}
                    onAddClick={onAddClick}
                />
                <div className="tableWrapper-table">
                    <Table
                        class="table dashboard-tables"
                        aria-labelledby="tableTitle"
                    >
                        <CardListHead
                            numSelected={selected?.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data?.length}
                            formatMessage={intl.formatMessage}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map(n => {
                                    const isSelected = isSelected(
                                        n?.key
                                    );
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n?.id}
                                            selected={isSelected}
                                        >
                                            <TableCell className="status-cell">
                                                <div className="badge badge-success">
                                                    {n?.brand}
                                                </div>
                                            </TableCell>
                                            <TableCell scope="row">
                                                {n?.exp_month}
                                            </TableCell>
                                            <TableCell>{n?.last4}</TableCell>
                                            <TableCell>
                                                {n?.exp_year}
                                            </TableCell>

                                            <TableCell>
                                                <MenuButton
                                                    rowData={n}
                                                    items={[
                                                        {
                                                            action:
                                                                'delete',
                                                            label: formatMessage(
                                                                {
                                                                    id:
                                                                        'card.delete'
                                                                }
                                                            )
                                                        }
                                                    ]}
                                                    menuId={'cardMenu'}
                                                    menuCallback={(
                                                        action,
                                                        rowData
                                                    ) =>
                                                        alertDeleteCard(
                                                            rowData?.id
                                                        )
                                                    }
                                                    iconType={MoreVertIcon}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{ height: 49 * emptyRows }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    className="dashboard-table-pagination"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': formatMessage({
                            id: 'pagination.previous'
                        })
                    }}
                    nextIconButtonProps={{
                        'aria-label': formatMessage({
                            id: 'pagination.next'
                        })
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <DeleteAlert
                open={deleteAlert}
                close={closeDeleteAlert}
                handleNo={handleNo}
                handleYes={handleYes}
            />
        </HOCLoader>
    );
}

CardList.propTypes = {
    classes: PropTypes?.object?.isRequired
};
const mapStateToProps = ({ bookings }) => {
    return { cardList: bookings?.cardList, cardLoader: bookings?.cardLoader };
};
const composedComponent = compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        actions
    )
);

export default composedComponent(
    withCustomTheme(withUser(injectIntl(CardList)))
);
