import {
    THEME_DATA_RECEIVED,
    CHANGE_LANGUAGE,
    CMS_DATA_RECEIVED,
    MAINTAINENCE_MODE,
    HOME_BANNERS_RECEIVED,
    SETTINGS_LOADER,
    GET_CURRENT_LOCATION,
} from '../actions/types';

const INITIAL_STATE = {
    colors: {
        bookingStatusColors: {
            1: '#FF9800',
            2: '#00BCD4',
            3: '#F44336',
            4: '#F44336',
            5: '#00BCD4',
            6: '#4CAF50',
            7: '#4CAF50',
            8: '#4CAF50',
            9: '#FF9800',
        },
        BACKGROUND: {
            HEX: '#e24f36',
            RGB1: 'rgba(238,242,245,1)',
            RGB2: 'rgba(238,242,245,0)',
        },
        SECONDARY: {
            HEX: '#e24f36',
            RGB1: 'rgba(244,33,86,1)',
            RGB2: 'rgba(244,33,86,0)',
        },
        PRIMARY: {
            HEX: '#e24f36',
            RGB1: 'rgba(37,43,51,1)',
            RGB2: 'rgba(37,43,51,0)',
        },
        DARK: {
            HEX: '#323A45',
            RGB1: 'rgba(50,58,69,1)',
            RGB2: 'rgba(50,58,69,0)',
        },
        LIGHT: {
            HEX: '#FDFDFD',
            RGB1: 'rgba(253,253,253,1)',
            RGB2: 'rgba(253,253,253,0)',
        },
        BLUE: {
            HEX: '#2F7ABC',
            RGB1: 'rgba(47,122,188,1)',
            RGB2: 'rgba(47,122,188,0)',
        },
        RATING: {
            HEX: '#e78d26',
            RGB1: 'rgba(231,141,38,1)',
            RGB2: 'rgba(231,141,38,0)',
        },
        MONEY: {
            HEX: '#41c7a8',
            RGB1: 'rgba(65,199,168,1)',
            RGB2: 'rgba(65,199,168,0)',
        },
        ICON_LIGHT: {
            HEX: '#b6c1d3',
            RGB1: 'rgba(182,193,211,1)',
            RGB2: 'rgba(182,193,211,0)',
        },

        LOADING_PRIMARY: {
            HEX: '#EEEEEE',
            RGB1: 'rgba(238,238,238,1)',
            RGB2: 'rgba(238,238,238,0)',
        },
        LOADING_SECONDARY: {
            HEX: '#FFF',
            RGB1: 'rgba(255,255,255,1)',
            RGB2: 'rgba(255,255,255,0)',
        },
    },
    constants: {},
    currentLanguage: 'en',
    cmsLinks: [],
    maintainence: false,
    settingsLoader: false,
    currentLocation: { latitude: '', longitude: '', pincode: '' },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case THEME_DATA_RECEIVED:
            return {
                ...state,
                colors: { ...INITIAL_STATE.colors, ...action.payload.colors },
                constants: {
                    ...INITIAL_STATE.constants,
                    ...action.payload.constants,
                },
            };
        case GET_CURRENT_LOCATION:
            return {
                ...state,
                currentLocation: action.payload,
            };
        case SETTINGS_LOADER:
            return {
                ...state,
                settingsLoader: action.payload,
            };
        case HOME_BANNERS_RECEIVED:
            return {
                ...state,
                banners: action.payload,
            };
        case CMS_DATA_RECEIVED:
            return {
                ...state,
                cmsLinks: action.payload,
            };
        case CHANGE_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload,
            };
        case MAINTAINENCE_MODE:
            return {
                ...state,
                maintainence: action.payload,
            };
        default:
            return state;
    }
}
