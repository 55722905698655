import React, { Fragment, Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import {
    MenuItem,
    Card,
    CircularProgress,
    Select,
    FormControl,
    InputLabel,
    Paper,
    FormHelperText,
} from '@material-ui/core';

import * as actions from '../../actions';

import { validateServiceDetails } from '../../utils/validators/validateForm';

import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';

import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

import MultiAddressPanel from './MultiAddressPanel';

const styles = {
    width: '100%',
    height: '100%',
    formControl: {
        minWidth: '90%',
        margin: '15px',
    },
};

class ServiceCreate extends Component {
    constructor(props) {
        super();
        this.state = {
            error: '',
            category: 'selectCategory',
            categoryError: '',
            subCategory: 'selectSubCategory',
            subCategoryError: '',
            service: 'selectService',
            serviceError: '',
            loaderButton: false,
            pass: false,
            noResponse: false,
            errorMessage: '',
            type_id: 1,
            selectedAddresses: [],
            providerData: [],
            categoryData: [],
            subCategoryData: [],
            allSubcategoryData: [],
            resetSubCategoryData: [],
            serviceData: [],
            resetServiceData: [],
            showAddOwnSection: false,
            showService: true,
            name: '',
            nameError: '',
            description: '',
            descriptionError: '',
            price: 0,
            priceError: '',
            validate: true,
            addressError: '',
            displayAddress: props?.userData?.addresses.filter(
                (address) => address?.is_default === 1
            )[0],
            addAddress: false,
            showPackageRemoveOption: false,
            packages: [
                {
                    name: '',
                    nameError: '',
                    description: '',
                    descriptionError: '',
                    costPrice: '',
                    costPriceError: '',
                    price: '',
                    priceError: '',
                },
            ],
            faqs: [],
        };
    }

    componentDidMount() {
        const {
            allSubcategoryData,
            providerData,
            getFullProviderProfileAction,
        } = this.props;
        getFullProviderProfileAction();
        const {
            serviceData,
            subCategoryData,
            resetSubCategoryData,
            resetServiceData,
        } = this.state;
        let categories = providerData?.providerTypes;
        let categoryData =
            categories &&
            categories.map((category) => {
                return category?.providerType;
            });
        categoryData &&
            categoryData.unshift({
                id: 'selectCategory',
                name: 'Please Select Category',
            });
        allSubcategoryData.unshift({
            id: 'selectSubCategory',
            name: 'Please Select Sub Category',
            services: [
                {
                    key: 'selectService',
                    heading: 'Please Select Service',
                },
            ],
        });
        serviceData.unshift({
            key: 'selectService',
            heading: 'Please Select Service',
        });
        resetServiceData.unshift({
            key: 'selectService',
            heading: 'Please Select Service',
        });
        subCategoryData.unshift({
            id: 'selectSubCategory',
            name: 'Please Select Sub Category',
        });
        resetSubCategoryData.unshift({
            id: 'selectSubCategory',
            name: 'Please Select Sub Category',
        });
        allSubcategoryData.filter((cat) => {
            cat?.services?.length === 0 &&
                cat.services.unshift({
                    key: 'noService',
                    heading: 'No Available Service',
                });
        });
        this.setState({
            categoryData,
            allSubcategoryData,
            serviceData,
            resetSubCategoryData,
            resetServiceData,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps?.providerData) {
            return { providerData: nextProps?.providerData };
        } else return null;
    }

    submit = async () => {
        const {
            name,
            description,
            price,
            packages,
            faqs,
            selectedAddresses,
            subCategory,
            service,
            showAddOwnSection,
        } = this.state;
        const result = await validateServiceDetails(
            {
                packages,
                faqs,
                selectedAddresses,
                name,
                description,
                price,
                subCategory,
                service,
                showAddOwnSection,
            },
            this.handleError
        );
        if (result) {
            this.addService();
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Error'
            );
        }
    };

    addService = () => {
        const { addServiceAction } = this.props;
        this.setState({ loader: true });
        const {
            packages,
            faqs,
            selectedAddresses,
            name,
            description,
            price,
            category,
            subCategory,
            service,
        } = this.state;
        addServiceAction(
            {
                serviceName: name,
                mainCategoryId: category,
                serviceId: service,
                categoryId: subCategory,
                addressIds: selectedAddresses,
                hourlyPrice: price,
                description: description,
                packages: packages,
                faq: faqs,
            },
            this.onAddDone
        );
    };

    handleError = (params) => {
        this.setState(params);
    };

    onAddDone = (success, data) => {
        const { history, match } = this.props;
        this.setState({ loader: false });
        if (success) {
            NotificationManager.success(data?.message, 'Success');
            history.push(`${match?.url}`);
        } else {
            NotificationManager.error(data?.message, 'Error');
        }
    };

    renderButton = () => {
        const { loader } = this.state;
        if (loader) {
            return <CircularProgress />;
        }
        return (
            <WSButton variant="raised" className="jr-btn" onClick={this.submit}>
                <FormattedMessage id="add" defaultMessage="Add" />
            </WSButton>
        );
    };

    getSubCategory = (catId) => {
        const {
            resetSubCategoryData,
            resetServiceData,
            allSubcategoryData,
            providerData,
        } = this.state;
        const { subcategoryData } = this.props;
        const filterData = providerData?.providerTypes.filter(
            (item) => item?.providerType?.id === catId
        );
        const subCategories = filterData && filterData[0]?.subcategories;
        const subCategoryId =
            subCategories && subCategories[0] && subCategories[0]?.id;
        const services = subcategoryData.find(
            (cat) => cat?.key === subCategoryId
        );
        this.setState({
            subCategoryData:
                subCategories && subCategories?.length
                    ? subCategories
                    : resetSubCategoryData,
            subCategory:
                subCategories && subCategories?.length
                    ? subCategories[0] && subCategories[0]?.id
                    : 'selectSubCategory',
            serviceData: services?.services?.length
                ? services?.services
                : resetServiceData,
            service: services?.services?.length
                ? services?.services &&
                services?.services[0] &&
                services?.services[0]?.key
                : 'noService',
        });
    };

    getServices = (subCatId) => {
        const { subCategoryData } = this.state;
        const { resetServiceData } = this.state;
        const { subcategoryData } = this.props;
        const services = subcategoryData.find((cat) => cat?.key === subCatId);
        this.setState({
            serviceData: services?.services?.length
                ? services?.services
                : resetServiceData,
            service: services?.services?.length
                ? services?.services &&
                services?.services[0] &&
                services?.services[0]?.key
                : 'noService',
        });
    };

    handleCategoryChange = (event) => {
        this.setState({
            [event.target.name]: event?.target?.value,
            subCategoryError: '',
        });
        this.getSubCategory(event?.target?.value);
    };

    handleSubCategoryChange = (event) => {
        this.setState({
            [event.target.name]: event?.target?.value,
            subCategoryError: '',
        });
        this.getServices(event?.target?.value);
    };

    handleServiceChange = (event) => {
        let serviceData = this.state?.serviceData;
        let selectedService = serviceData.filter((service) => {
            return service?.key === event?.target?.value;
        });
        let price =
            selectedService &&
                selectedService?.length > 0 &&
                selectedService[0]?.price
                ? selectedService[0]?.price
                : 0;
        this.setState({
            price: price ? price : 0,
            [event.target.name]: event?.target?.value,
            serviceError: '',
        });
    };

    handlePackageNameChange = (index, event) => {
        const { packages } = this.state;
        const packageValues = packages;
        packageValues[index].name = event?.target?.value;
        packageValues[index].nameError = '';
        this.setState({ packages: packageValues });
    };

    handleCpChange = (index, event) => {
        const { packages } = this.state;
        let packageValues = packages;
        packageValues[index].costPrice = event?.target?.value;
        packageValues[index].costPriceError = '';
        this.setState({ packages: packageValues });
    };

    handlePriceChange = (index, event) => {
        const { packages } = this.state;
        let packageValues = packages;
        packageValues[index].price = event?.target?.value;
        packageValues[index].priceError = '';
        this.setState({ packages: packageValues });
    };

    handleDescChange = (index, event) => {
        const { packages } = this.state;
        let packageValues = packages;
        packageValues[index].description = event?.target?.value;
        packageValues[index].descriptionError = '';
        this.setState({ packages: packageValues });
    };

    handleQuestionChange = (index, event) => {
        const { faqs } = this.state;
        const faqValues = faqs;
        faqValues[index].question = event?.target?.value;
        faqValues[index].questionError = '';
        this.setState({ faqs: faqValues });
    };

    handleAnswerChange = (index, event) => {
        const { faqs } = this.state;
        const faqValues = faqs;
        faqValues[index].answer = event?.target?.value;
        faqValues[index].answerError = '';
        this.setState({ faqs: faqValues });
    };

    addYourOwnItem = () => {
        this.setState((prevState, props) => ({
            showAddOwnSection: !prevState?.showAddOwnSection,
            serviceError: '',
        }));
    };

    setAddress = (keys) => {
        this.setState({ selectedAddresses: keys, addressError: '' });
    };

    closeAddressPopup = () => {
        this.setState({ addAddress: false });
    };

    addNewAddress = () => {
        this.setState({ addAddress: true });
    };

    renderMultiPackageForm = (
        index,
        {
            name,
            nameError,
            description,
            descriptionError,
            costPrice,
            costPriceError,
            price,
            priceError,
        },
        service
    ) => {
        return (
            <div className="row">
                <div className="col-md-3 col-xs-12">
                    <WSTextField
                        error={nameError ? true : false}
                        helperText={nameError}
                        name={name}
                        label="Package Name"
                        id={index}
                        placeholder="Add Package Name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) =>
                            this.handlePackageNameChange(index, event)
                        }
                        disabled={(service === 'noService' || service === 'selectService') ? true : false}
                    />
                </div>
                <div className="col-md-3 col-xs-12">
                    <WSTextField
                        error={costPriceError ? true : false}
                        helperText={costPriceError}
                        name={costPrice}
                        label="Cost Price"
                        id={index}
                        placeholder="Add Cost Price"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) => this.handleCpChange(index, event)}
                        disabled={(service === 'noService' || service === 'selectService') ? true : false}
                    />
                </div>
                <div className="col-md-3 col-xs-12">
                    <WSTextField
                        error={descriptionError ? true : false}
                        helperText={descriptionError}
                        name={description}
                        label="Package Description"
                        id={index}
                        placeholder="Add Package Description"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) =>
                            this.handleDescChange(index, event)
                        }
                        disabled={(service === 'noService' || service === 'selectService') ? true : false}
                    />
                </div>
                <div className="col-md-3 col-xs-12">
                    <WSTextField
                        error={priceError ? true : false}
                        helperText={priceError}
                        name={price}
                        label="Discounted Price"
                        id={index}
                        placeholder="Add Discounted Price"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) =>
                            this.handlePriceChange(index, event)
                        }
                        disabled={(service === 'noService' || service === 'selectService') ? true : false}
                    />
                </div>
                {this.state?.showPackageRemoveOption && (
                    <div className="col-md-12 col-xs-12">
                        <WSButton
                            variant="outlined"
                            onClick={() => this.removeItem(index)}
                        >
                            <FormattedMessage
                                id="remove"
                                defaultMessage="Remove"
                            />
                        </WSButton>
                    </div>
                )}
            </div>
        );
    };

    renderMultiFAQForm = (
        index,
        { question, questionError, answer, answerError },
        service
    ) => {
        return (
            <div className="row">
                <div className="col-md-4 col-xs-12">
                    <WSTextField
                        error={questionError ? true : false}
                        helperText={questionError}
                        name={question}
                        label="Question"
                        id={index}
                        placeholder="Add Question"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) =>
                            this.handleQuestionChange(index, event)
                        }
                        disabled={(service === 'noService' || service === 'selectService') ? true : false}
                    />
                </div>
                <div className="col-md-4 col-xs-12">
                    <WSTextField
                        error={answerError ? true : false}
                        helperText={answerError}
                        name={answer}
                        label="Answer"
                        id={index}
                        placeholder="Add Answer"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(event) =>
                            this.handleAnswerChange(index, event)
                        }
                        disabled={(service === 'noService' || service === 'selectService') ? true : false}
                    />
                </div>
                <div className="col-md-4 col-xs-12">
                    <WSButton
                        variant="outlined"
                        onClick={() => this.removeFAQItem(index)}
                    >
                        <FormattedMessage id="remove" defaultMessage="Remove" />
                    </WSButton>
                </div>
            </div>
        );
    };

    addNewItem = () => {
        const { packages } = this.state;
        this.setState(
            (prevState) => ({
                showPackageRemoveOption: true,
                packages: [
                    ...prevState?.packages,
                    {
                        name: '',
                        nameError: '',
                        description: '',
                        descriptionError: '',
                        costPrice: '',
                        costPriceError: '',
                        price: '',
                        priceError: '',
                    },
                ],
            }),
            () => console.log(packages)
        );
    };

    removeItem = (index) => {
        const { packages } = this.state;
        let newPackages = [...packages];
        newPackages.splice(index, 1);
        this.setState({ packages: newPackages });
    };

    addNewFAQItem = () => {
        const { faqs } = this.state;
        this.setState(
            (prevState) => ({
                faqs: [
                    ...prevState?.faqs,
                    {
                        question: '',
                        questionError: '',
                        answer: '',
                        answerError: '',
                    },
                ],
            }),
            () => console.log(faqs)
        );
    };

    removeFAQItem = (index) => {
        const { faqs } = this.state;
        let newFaqs = faqs;
        newFaqs.splice(index, 1);
        this.setState({ faqs: newFaqs });
    };

    handleMainNameChange = (event) => {
        if (event?.target?.value !== '') {
            this.setState({
                name: event?.target?.value,
                nameError: '',
                service: '',
                showService: false,
            });
        } else {
            this.setState({
                showService: true,
            });
        }
    };

    render() {
        const {
            name,
            nameError,
            description,
            descriptionError,
            price,
            priceError,
            loaderButton,
            packages,
            faqs,
            addressError,
            categoryError,
            subCategoryError,
            serviceError,
            showService,
            category,
            subCategory,
            service,
            categoryData,
            subCategoryData,
            serviceData,
            showAddOwnSection,
        } = this.state;
        const { classes, intl } = this.props;
        const { formatMessage } = intl;
        return (
            <Card className="col-md-12">
                <form
                    className="row"
                    noValidate
                    autoComplete="off"
                    id="service-create"
                >
                    {showAddOwnSection && (
                        <div className="col-md-6 col-xs-12">
                            <WSTextField
                                className="text-box-spacing"
                                error={nameError ? true : false}
                                helperText={nameError}
                                name="name"
                                label="Name"
                                id="name"
                                placeholder="Add Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(event) => {
                                    this.handleMainNameChange(event);
                                }}
                                defaultValue={name}
                                disabled={loaderButton ? true : false}
                            />
                        </div>
                    )}
                    <div
                        className={
                            isMobile
                                ? 'col-md-4 col-xs-12 p-0'
                                : 'col-md-4 col-xs-12'
                        }
                    >
                        <FormControl
                            className={classes?.formControl}
                            error={categoryError ? true : false}
                            style={{
                                marginVertical: isMobile && 0,
                                marginBottom: 0,
                            }}
                        >
                            <InputLabel shrink>
                                <FormattedMessage
                                    id="category"
                                    defaultMessage="Category"
                                />
                            </InputLabel>
                            <Select
                                autoWidth={true}
                                value={category}
                                onChange={this.handleCategoryChange}
                                inputProps={{
                                    name: 'category',
                                }}
                                disabled={
                                    categoryData && categoryData?.length
                                        ? false
                                        : true
                                }
                            >
                                {categoryData &&
                                    categoryData.map((cat, index) => {
                                        return (
                                            <MenuItem value={cat?.id}>
                                                {cat?.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            <FormHelperText>{categoryError}</FormHelperText>
                        </FormControl>
                    </div>
                    <div
                        className={
                            isMobile
                                ? 'col-md-4 col-xs-12 p-0'
                                : 'col-md-4 col-xs-12'
                        }
                    >
                        <FormControl
                            className={classes?.formControl}
                            error={subCategoryError ? true : false}
                            style={{
                                marginVertical: isMobile && 0,
                                marginBottom: 0,
                            }}
                        >
                            <InputLabel shrink>
                                <FormattedMessage
                                    id="subCategory"
                                    defaultMessage="Sub Category"
                                />
                            </InputLabel>
                            <Select
                                autoWidth={true}
                                value={subCategory}
                                onChange={this.handleSubCategoryChange}
                                inputProps={{
                                    name: 'subCategory',
                                }}
                                disabled={
                                    subCategoryData && subCategoryData?.length
                                        ? false
                                        : true
                                }
                            >
                                {subCategoryData &&
                                    subCategoryData.map((cat, index) => {
                                        return (
                                            <MenuItem value={cat?.id}>
                                                {cat?.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            <FormHelperText>{subCategoryError}</FormHelperText>
                        </FormControl>
                    </div>
                    {showService && (
                        <div
                            className={
                                isMobile
                                    ? 'col-md-4 col-xs-12 p-0'
                                    : 'col-md-4 col-xs-12'
                            }
                        >
                            <FormControl
                                className={classes?.formControl}
                                error={serviceError ? true : false}
                                style={{
                                    marginVertical: isMobile && 0,
                                    marginBottom: 0,
                                }}
                            >
                                <InputLabel shrink>
                                    <FormattedMessage
                                        id="service"
                                        defaultMessage="Service"
                                    />
                                </InputLabel>
                                <Select
                                    autoWidth={true}
                                    value={service}
                                    onChange={this.handleServiceChange}
                                    inputProps={{
                                        name: 'service',
                                    }}
                                    disabled={
                                        service === 'noService' ? true : false
                                    }
                                >
                                    {serviceData &&
                                        serviceData.map((service, index) => {
                                            return (
                                                <MenuItem value={service?.key}>
                                                    {service?.heading}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                                <FormHelperText>{serviceError}</FormHelperText>
                            </FormControl>
                        </div>
                    )}
                    <div
                        className={
                            isMobile
                                ? 'col-md-6 col-xs-12 p-0'
                                : 'col-md-6 col-xs-12'
                        }
                    >
                        <WSTextField
                            className="text-box-spacing"
                            error={priceError ? true : false}
                            helperText={priceError}
                            name="price"
                            label="Price (Price in AED)"
                            id="price"
                            placeholder="Add Price"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                this.setState({
                                    price: event?.target?.value,
                                    priceError: '',
                                    error: '',
                                });
                            }}
                            value={price}
                            defaultValue={price}
                            disabled={loaderButton || (service === 'noService' || service === 'selectService') ? true : false}
                        />
                    </div>
                    <div
                        className={
                            isMobile
                                ? 'col-md-6 col-xs-12 p-0'
                                : 'col-md-6 col-xs-12'
                        }
                    >
                        <WSTextField
                            className="text-box-spacing"
                            error={descriptionError ? true : false}
                            helperText={descriptionError}
                            name="description"
                            label={formatMessage({
                                id: 'description',
                            })}
                            id="description"
                            placeholder={formatMessage({
                                id: 'description',
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                this.setState({
                                    description: event?.target?.value,
                                    descriptionError: '',
                                    error: '',
                                });
                            }}
                            defaultValue={description}
                            disabled={loaderButton || (service === 'noService' || service === 'selectService') ? true : false}
                        />
                    </div>
                    <br />
                    <div className="col-md-12">
                        <Paper className="col-md-12  card-spacing">
                            <h4>
                                <FormattedMessage
                                    id="addPackage"
                                    defaultMessage="Enter Your Package Details"
                                />
                            </h4>
                            {packages.map((packageItem, index) => {
                                return (
                                    <Fragment key={index}>
                                        {this.renderMultiPackageForm(
                                            index,
                                            packageItem,
                                            service
                                        )}
                                    </Fragment>
                                );
                            })}
                            <div
                                className={
                                    isMobile ? 'pb-1 mt-10' : 'mt-3 mb-3 pb-1'
                                }
                            >
                                <WSButton onClick={this.addNewItem}>
                                    <Add />
                                    <FormattedMessage
                                        id="add"
                                        defaultMessage="Add"
                                    />
                                </WSButton>
                            </div>
                        </Paper>
                    </div>
                    <br />
                    <div className="col-md-12">
                        &nbsp;
                        <Paper className="col-md-12  card-spacing">
                            <h4>
                                <FormattedMessage
                                    id="enterFaq"
                                    defaultMessage="Enter Your FAQ Details"
                                />
                            </h4>
                            {faqs.map((faqItem, index) => {
                                return (
                                    <Fragment key={index}>
                                        {this.renderMultiFAQForm(
                                            index,
                                            faqItem,
                                            service
                                        )}
                                    </Fragment>
                                );
                            })}
                            <div
                                className={
                                    isMobile ? 'pb-1 mt-10' : 'mt-3 mb-3 pb-1'
                                }
                            >
                                <WSButton onClick={this.addNewFAQItem}>
                                    <Add />
                                    <FormattedMessage
                                        id="add"
                                        defaultMessage="Add"
                                    />
                                </WSButton>
                            </div>
                        </Paper>
                    </div>
                    <br />
                    <div className="col-md-12">
                        <MultiAddressPanel
                            addNewAddress={this.addNewAddress}
                            setBookingAddress={(keys) => this.setAddress(keys)}
                            {...this.props}
                        />
                        {addressError && (
                            <h6 style={{ color: 'red' }}>{addressError}</h6>
                        )}
                    </div>
                    <div className="col-md-12 col-xs-12">
                        <div className={!isMobile && 'mt-3'}>
                            {this.renderButton()}
                        </div>
                    </div>
                </form>
            </Card>
        );
    }
}

const mapStateToProps = ({ auth, categories }) => {
    return {
        providerData: auth?.userData,
        subcategoryData: categories?.subcategoryData,
    };
};

const composedComponent = compose(
    withStyles(styles),
    connect(mapStateToProps, actions)
);

export default withCustomTheme(composedComponent(injectIntl(ServiceCreate)));
