import Cookies from 'universal-cookie';

export const cookies = new Cookies();

export const getCookies = () => {
    let authToken;
    authToken = cookies.get('x-auth-token', { path: '/' });

    if (authToken) {
        return authToken;
    }
    return null;
};
