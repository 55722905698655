import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Tooltip } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import * as actions from '../../actions';

import WSButton from '../../components/Button/WSButton';

import 'react-image-crop/dist/ReactCrop.css';

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.secondary.main,
    },
    title: {
        flex: '0 0 auto',
    },
});

let GalleryListToolbar = (props) => {
    const { numSelected, classes, shareImagesToProvider, isShared } = props;
    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <h4
                        style={{ fontWeight: 400 }}
                        className={isMobile && 'font-14'}
                    >
                        Image Selection for final images :
                    </h4>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 && (
                    <Tooltip title="Share with provider">
                        <WSButton
                            disabled={isShared ? true : false}
                            style={{ margin: '0 10px' }}
                            variant="raised"
                            className="jr-btn"
                            onClick={shareImagesToProvider}
                        >
                            {isShared && (
                                <FormattedMessage
                                    id="SharedButton"
                                    defaultMessage="Shared"
                                />
                            )}
                            {!isShared && (
                                <FormattedMessage
                                    id="ShareButton"
                                    defaultMessage="Share"
                                />
                            )}
                        </WSButton>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

GalleryListToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

GalleryListToolbar = withStyles(toolbarStyles)(GalleryListToolbar);

class SelectBookingImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            selected: [],
            existingImage: '',
            imageError: '',
            imgIndex: 0,
            showUploadImageForm: false,
            ErrorColor: 'black',
            bookingToken: props.bookingToken || '',
            sharedImages: props.sharedImages || [],
            isShared: false,
            selectedImages: [],
        };
    }

    componentDidMount() {
        const { sharedImages } = this.state;
        let images = sharedImages;
        let isShared = false;
        if (images && images.length > 0) {
            images = images.map((image) => {
                let isSelected = image.isSelected ? image.isSelected : false;
                if (isSelected === true) {
                    isShared = true;
                }

                return {
                    id: image.id,
                    src: image.filename,
                    thumbnail: image.filename,
                    isSelected: image.isSelected,
                };
            });
        }
        this.setState({
            images,
            isShared,
        });
    }

    selectedImages = (index, image) => {
        const { images } = this.state;
        let Images = images.slice();
        let img = Images[index];
        if (img.hasOwnProperty('isSelected')) img.isSelected = !img.isSelected;
        else img.isSelected = true;
        let selected = Images.filter((image) => {
            return image.isSelected;
        });
        this.setState({
            images: Images,
            selected,
        });
    };

    shareImagesToProvider = () => {
        const { shareSelectedBookingImagesAction } = this.props;
        const { selected, bookingToken } = this.state;
        let ids = selected.map((selectedImg) => {
            return selectedImg.id;
        });
        shareSelectedBookingImagesAction(
            {
                bookingToken: bookingToken,
                selectedIds: ids,
            },
            this.onSharedDone
        );
    };

    onSharedDone = (success, data) => {
        if (success) {
            this.setState({ isShared: true });
            NotificationManager.success(data.message, 'Success');
        } else {
            this.setState({ deleteAlert: false });
            NotificationManager.error(data.message, 'Error');
        }
    };

    render() {
        const { match } = this.props;
        const { selected, images, isShared } = this.state;
        return (
            <div class="row">
                <div class="col-md-12">
                    <GalleryListToolbar
                        numSelected={selected.length}
                        match={match}
                        shareImagesToProvider={this.shareImagesToProvider}
                        isShared={isShared}
                    />
                    <div style={{ margin: '0 20px' }}>
                        <Gallery
                            images={images}
                            showImageCount={true}
                            enableImageSelection={isShared ? false : true}
                            onSelectImage={(selectedImages) => {
                                this.selectedImages(selectedImages);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const composedComponent = compose(
    withStyles(toolbarStyles),
    connect(null, actions)
);

export default composedComponent(SelectBookingImages);
