import { notificationList } from '../apiHelpers/notification/list';

import { NOTIFICATION_LOADER, NOTIFICATION_DATA_RECEIVED } from './types';

export const notificationListAction = (params) => async (
    dispatch,
    getState
) => {
    dispatch({ type: NOTIFICATION_LOADER, payload: true });
    const response = await notificationList(params);
    if (response.status === 'OK') {
        dispatch({ type: NOTIFICATION_DATA_RECEIVED, payload: response.data });
        dispatch({ type: NOTIFICATION_LOADER, payload: false });
    }
};
