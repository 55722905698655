import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import { NotificationManager } from 'react-notifications';

import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
    Checkbox,
    FormControlLabel,
    Divider,
    FormGroup
} from '@material-ui/core';

import * as actions from '../../actions';

import {
    getCameraBrandList,
    getSkinColor,
    getHairType,
    getHairColor,
    getEthinicity,
    getEyeColor
} from '../../utils/config/constants';

import WSButton from "../../components/Button/WSButton";
import WSTextField from "../../components/TextField/WSTextField";

import Select from '../Select/Select';
import Option from '../Select/Option';

import '../../assets/css/style.css';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
});

class ProviderProfession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderButton: false,
            removedProvider: [],
            prepapredData: [],
            allSelectedCategory: [],
            selectedCategory: null,
            selectedCategoryId: null,
        };
    }

    componentDidMount() {
        const { allCategoryAction } = this.props;
        allCategoryAction();
    }

    updateProfessionDetailAPI = (values) => {
        const { prepapredData, removedProvider } = this.state;
        const { updateFullProviderProfileAction } = this.props;
        let categoryData = prepapredData && prepapredData.map(category => ({ category: category.category, subcategories: category && category.subcategories && category.subcategories.filter(item => item.checked === true) }))
        let isProfessionUpdate = [];
        categoryData = categoryData && categoryData.map(cat => {
            const subValues = cat && cat.subcategories && cat.subcategories.map(function (obj) {
                return obj.key
            })
            cat.subcategories = subValues
            if (cat.category === '5d0e20d44a53032881f3d9a5') {
                cat.form = {
                    height: values[`height${cat.category}`],
                    bustCms: values[`bustCms${cat.category}`],
                    bustInches: values[`bustInches${cat.category}`],
                    waistCms: values[`waistCms${cat.category}`],
                    waistInches: values[`waistInches${cat.category}`],
                    hipsCms: values[`hipsCms${cat.category}`],
                    hipsInches: values[`hipsInches${cat.category}`],
                    shoeSize: values[`shoeSize${cat.category}`],
                    skinColor: values[`skinColor${cat.category}`],
                    hairType: values[`hairType${cat.category}`],
                    hairColor: values[`hairColor${cat.category}`],
                    eyeColor: values[`eyeColor${cat.category}`],
                    ethinicity: values[`ethinicity${cat.category}`],
                }
            }
            if (cat.category === '5cff874866f1d1714902de7d' || cat.category === '5d08d65f737a8212cae898a4') {
                cat.form = {
                    cameraBrand1: values[`cameraBrand1${cat.category}`],
                    cameraBrand2: values[`cameraBrand2${cat.category}`],
                    cameraBrand3: values[`cameraBrand3${cat.category}`],
                    cameraModel1: values[`cameraModel1${cat.category}`],
                    cameraModel2: values[`cameraModel2${cat.category}`],
                    cameraModel3: values[`cameraModel3${cat.category}`],
                    otherGear: values[`otherGear${cat.category}`],
                }
            }
            if (subValues && subValues.length > 5) {
                NotificationManager.error('You have select upto 5 sub categories', null, 2000)
                isProfessionUpdate.push({ status: false })
            } else if (subValues && subValues.length < 1) {
                NotificationManager.error('You have select atleast 1 sub category', null, 2000)
                isProfessionUpdate.push({ status: false })
            } else {
                isProfessionUpdate.push({ status: true })
            }
            return cat
        })
        const finalCall = isProfessionUpdate.filter(item => item.status === false)

        finalCall.length === 0 && updateFullProviderProfileAction({
            removeProviderType: removedProvider,
            providerTypes: categoryData,
        }, this.onDone)
    }

    onDone = (success) => {
        this.setState({ removedProvider: [] })
        if (success) {
            NotificationManager.success('Profession Updated', null, 2000);
        } else {
            NotificationManager.error('Profession Update Failed', null, 2000);
        }
    };

    getSubcategories = (value) => {
        const { categoryData, providerData } = this.props;
        const { prepapredData } = this.state;
        const formData = providerData && providerData.providerTypes && providerData.providerTypes.filter(item => item.providerType.name === value)
        const filterData = categoryData.filter(item => item.heading === value)
        prepapredData.forEach(item => {
            if (item.type === value) {
                item.subcategories = _.unionBy(item.subcategories, filterData && filterData[0] && filterData[0].subCategories, 'key');
                item.formData = formData && formData[0] && formData[0].modelPacket
            }
        })
        this.setState({
            prepapredData
        })
    }

    componentWillReceiveProps(nextProps) {
        const { providerTypes } = nextProps.providerData;

        if (providerTypes && providerTypes.length > 0) {
            let categories = []
            let subCategories = []
            providerTypes.forEach((category, index) => {

                const categoryDetails = {
                    catName: category.providerType.name, catId: category.providerType.id
                }
                categories.push(categoryDetails)
                const prepareDetails = {
                    category: category.providerType.id,
                    subcategories: category && category.subcategories && category.subcategories.map((sub) => ({ ...sub, checked: true, key: sub.id, heading: sub.name })),
                    type: category.providerType.name
                }
                subCategories.push(prepareDetails)
                for (const property in category.modelPacket) {
                    this.setState({ [`${property}${category.providerType.id}`]: category.modelPacket[property] })
                }

            })
            this.setState({
                allSelectedCategory: categories,
                prepapredData: subCategories,
                selectedCategory: categories && categories[0] && categories[0].catName,
                selectedCategoryId: categories && categories[0] && categories[0].catId,
                isShowSubCategory: true,
            })
        }
    }

    render() {
        const { categoryData, theme } = this.props;
        let {
            removedProvider,
            isShowSubCategory,
            prepapredData,
            allSelectedCategory,
            selectedCategory,
            selectedCategoryId,
            profession,
            loaderButton,
            ...rest
        } = this.state
        return (
            <Formik
                initialValues={{ ...rest }}
                enableReinitialize={true}
                onSubmit={(values) => {
                    this.setState({ ...values })
                    this.updateProfessionDetailAPI(values)
                }}
            >
                {props => (
                    <form
                        className="dialog-form dialog-form-new"
                        autoComplete="off"
                    >
                        <span className="mb-0 mt-4 ml-3 mb-10 font-w600 font-14">Select Profession <span className="font-w400">(upto 3)</span></span>
                        <div className="row col-md-12 p-0">
                            <div className={`col-md-3 col-xs-12 ${!isMobile && 'pr-0 pl-30'}`}>
                                <Select
                                    name="profession"
                                    id="profession"
                                    className="form-control form-control-select w-100 mt-2"
                                    onChange={(e) => {
                                        if (!prepapredData.find((val) => val.category === (e.target.value).split('-')[1])) {
                                            this.setState({ profession: (e.target.value).split('-')[1] })
                                            props.setFieldValue('profession', (e.target.value).split('-')[0])
                                            allSelectedCategory.length < 3 && allSelectedCategory.push({ catName: (e.target.value).split('-')[0], catId: (e.target.value).split('-')[1] })
                                            prepapredData.length < 3 && prepapredData.push({ category: (e.target.value).split('-')[1], subcategories: [], type: (e.target.value).split('-')[0] })
                                            this.setState({
                                                selectedCategory: (e.target.value).split('-')[0],
                                                selectedCategoryId: (e.target.value).split('-')[1],
                                                isShowSubCategory: true,
                                            })
                                            this.getSubcategories((e.target.value).split('-')[0])
                                        }
                                    }}
                                    value={""}
                                >
                                    {allSelectedCategory && allSelectedCategory.length === 0 && <Option value="">Select Profession</Option>}
                                    {categoryData && categoryData.map(category => {
                                        return (<Option value={`${category.heading}-${category.key}`} key={category.key}>{category.heading}</Option>)
                                    })}
                                </Select>
                                {props.errors.profession && props.touched.profession && (
                                    <div className="error-text">{props.errors.profession}</div>
                                )}
                            </div>
                            <span className='w-100'></span>
                            {allSelectedCategory && allSelectedCategory.map((selectCategory, index) => {
                                return (
                                    <div className="col-md-3 pb-2 mt-20" key={selectCategory.catName}>
                                        <div className={`genere-btn ${selectedCategory === selectCategory.catName ? 'active' : ''}`}
                                            onClick={() => {
                                                this.setState({
                                                    selectedCategory: selectCategory.catName,
                                                    selectedCategoryId: selectCategory.catId,
                                                    isShowSubCategory: true,
                                                    profession: selectCategory.catId
                                                })
                                                this.getSubcategories(selectCategory.catName)
                                            }}
                                        >
                                            {selectCategory.catName}
                                            <div
                                                class="cross"
                                                onClick={() => {
                                                    allSelectedCategory.splice(index, 1);
                                                    prepapredData.splice(index, 1);
                                                    this.setState({
                                                        removedProvider: [...removedProvider, selectCategory.catId]
                                                    }, () => {
                                                        console.log(removedProvider)
                                                    })
                                                    setTimeout(() => {
                                                        this.setState({
                                                            loaderButton: false,
                                                            isShowSubCategory: allSelectedCategory.length === 0 ? false : true,
                                                            selectedCategory: allSelectedCategory.length !== 0 ? allSelectedCategory[index - 1].catName : '',
                                                            selectedCategoryId: allSelectedCategory.length !== 0 ? allSelectedCategory[index - 1].catId : '',
                                                            profession: allSelectedCategory.length !== 0 ? allSelectedCategory[index - 1].catId : ''
                                                        })
                                                    }, 200);
                                                }}
                                            >X</div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        {allSelectedCategory && allSelectedCategory.length ? <Divider className="mt-20" /> : <span></span>}
                        <div className="row col-md-12 p-0">
                            {prepapredData && isShowSubCategory && prepapredData.filter(item => item.category === selectedCategoryId).map(category => {
                                return category && category.subcategories && category.subcategories.length ?
                                    <span className="mb-0 mt-4 ml-3 font-w600 font-14 mb-30">Select Sub Categories <span className="font-w400">(upto 5 sub categories)</span></span> : <span></span>
                            })}
                            <span className='w-100'></span>
                            <FormGroup aria-label="position" row>
                                {prepapredData && isShowSubCategory && prepapredData.filter(item => item.category === selectedCategoryId).map(category => {
                                    return category && category.subcategories && category.subcategories.map(subCategory => {
                                        return <div className={`col-md-3 col-xs-12 mb-2 flex-align-center ${!isMobile && 'pr-0 pl-30'}`} key={subCategory.key}>
                                            <FormControlLabel
                                                checked={subCategory.checked}
                                                value={subCategory.key}
                                                onChange={(e) => {
                                                    this.setState({
                                                        loaderButton: false
                                                    })
                                                    if (e.target.checked) {
                                                        subCategory.checked = true
                                                    } else {
                                                        subCategory.checked = false
                                                    }
                                                }}
                                                control={<Checkbox color="primary" />}
                                                label={subCategory.heading}
                                                labelPlacement="right"
                                            />
                                        </div>
                                    })
                                })}
                            </FormGroup>
                            <span className='w-100'></span>
                            {selectedCategory === 'Female Models' && isShowSubCategory && <Fragment>
                                <span className='w-100 profile-header-label' style={{ color: 'black', marginTop: isMobile ? 20 : 30, paddingLeft: isMobile && 15 }}>Vital Stats (Personal Details)</span>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Height</span>
                                    <WSTextField
                                        error={props.errors.height && props.touched.height ? true : false}
                                        helperText={props.errors.height && props.touched.height ? props.errors.height : ''}
                                        className="login-form-textinput"
                                        id="height"
                                        name={`height${profession}`}
                                        placeholder={'Height'}
                                        value={props.values[`height${profession}`]}
                                        onChange={props.handleChange(`height${profession}`)}
                                        disabled={loaderButton}
                                    />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Shoe Size in Us</span>
                                    <WSTextField
                                        error={props.errors.shoeSize && props.touched.shoeSize ? true : false}
                                        helperText={props.errors.shoeSize && props.touched.shoeSize ? props.errors.shoeSize : ''}
                                        className="login-form-textinput"
                                        id="shoeSize"
                                        name={`shoeSize${profession}`}
                                        placeholder={'Shoe Size in Us'}
                                        value={props.values[`shoeSize${profession}`]}
                                        onChange={props.handleChange(`shoeSize${profession}`)}
                                        disabled={loaderButton}
                                    />
                                </div>
                                <span className='w-100'></span>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Bust in Cms</span>
                                    <WSTextField
                                        error={props.errors.bustCms && props.touched.bustCms ? true : false}
                                        helperText={props.errors.bustCms && props.touched.bustCms ? props.errors.bustCms : ''}
                                        className="login-form-textinput"
                                        id="bustCms"
                                        name={`bustCms${profession}`}
                                        placeholder={'Bust in Cms'}
                                        value={props.values[`bustCms${profession}`]}
                                        onChange={props.handleChange(`bustCms${profession}`)}
                                        disabled={loaderButton}
                                    />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Bust in Inches</span>
                                    <WSTextField
                                        error={props.errors.bustInches && props.touched.bustInches ? true : false}
                                        helperText={props.errors.bustInches && props.touched.bustInches ? props.errors.bustInches : ''}
                                        className="login-form-textinput"
                                        id="bustInches"
                                        name={`bustInches${profession}`}
                                        placeholder={'Bust in Inches'}
                                        value={props.values[`bustInches${profession}`]}
                                        onChange={props.handleChange(`bustInches${profession}`)}
                                        disabled={loaderButton}
                                    />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Waist in Cms</span>
                                    <WSTextField
                                        error={props.errors.waistCms && props.touched.waistCms ? true : false}
                                        helperText={props.errors.waistCms && props.touched.waistCms ? props.errors.waistCms : ''}
                                        className="login-form-textinput"
                                        id="waistCms"
                                        name={`waistCms${profession}`}
                                        placeholder={'Waist in Cms'}
                                        value={props.values[`waistCms${profession}`]}
                                        onChange={props.handleChange(`waistCms${profession}`)}
                                        disabled={loaderButton}
                                    />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Waist Inches</span>
                                    <WSTextField
                                        error={props.errors.waistInches && props.touched.waistInches ? true : false}
                                        helperText={props.errors.waistInches && props.touched.waistInches ? props.errors.waistInches : ''}
                                        className="login-form-textinput"
                                        id="waistInches"
                                        name={`waistInches${profession}`}
                                        placeholder={'Waist Inches'}
                                        value={props.values[`waistInches${profession}`]}
                                        onChange={props.handleChange(`waistInches${profession}`)}
                                        disabled={loaderButton}
                                    />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Hips in Cms</span>
                                    <WSTextField
                                        error={props.errors.hipsCms && props.touched.hipsCms ? true : false}
                                        helperText={props.errors.hipsCms && props.touched.hipsCms ? props.errors.hipsCms : ''}
                                        className="login-form-textinput"
                                        id="hipsCms"
                                        name={`hipsCms${profession}`}
                                        placeholder={'Hips in Cms'}
                                        value={props.values[`hipsCms${profession}`]}
                                        onChange={props.handleChange(`hipsCms${profession}`)}
                                        disabled={loaderButton}
                                    />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Hips Inches</span>
                                    <WSTextField
                                        error={props.errors.hipsInches && props.touched.hipsInches ? true : false}
                                        helperText={props.errors.hipsInches && props.touched.hipsInches ? props.errors.hipsInches : ''}
                                        className="login-form-textinput"
                                        id="hipsInches"
                                        name={`hipsInches${profession}`}
                                        placeholder={'Hips Inches'}
                                        value={props.values[`hipsInches${profession}`]}
                                        onChange={props.handleChange(`hipsInches${profession}`)}
                                        disabled={loaderButton}
                                    />
                                </div>
                                <span className='w-100'></span>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Skin Color</span>
                                    <Select
                                        name={`skinColor${profession}`}
                                        id="skinColor"
                                        className="form-control form-control-select w-100 mt-25"
                                        onChange={props.handleChange(`skinColor${profession}`)}
                                        value={props.values[`skinColor${profession}`]}
                                    >
                                        <Option value="">Select</Option>
                                        {getSkinColor && getSkinColor.map(skin => {
                                            return <Option value={skin} key={skin}>{skin}</Option>
                                        })}
                                    </Select>
                                    {props.errors.skinColor && props.touched.skinColor && (
                                        <div className="error-text">{props.errors.skinColor}</div>
                                    )}
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Current Hair Type</span>
                                    <Select
                                        name={`hairType${profession}`}
                                        id="hairType"
                                        className="form-control form-control-select w-100 mt-25"
                                        onChange={props.handleChange(`hairType${profession}`)}
                                        value={props.values[`hairType${profession}`]}
                                    >
                                        <Option value="">Select</Option>
                                        {getHairType && getHairType.map(type => {
                                            return <Option value={type} key={type}>{type}</Option>
                                        })}
                                    </Select>
                                    {props.errors.hairType && props.touched.hairType && (
                                        <div className="error-text">{props.errors.hairType}</div>
                                    )}
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Current Hair Color</span>
                                    <Select
                                        name={`hairColor${profession}`}
                                        id="hairColor"
                                        className="form-control form-control-select w-100 mt-25"
                                        onChange={props.handleChange(`hairColor${profession}`)}
                                        value={props.values[`hairColor${profession}`]}
                                    >
                                        <Option value="">Select</Option>
                                        {getHairColor && getHairColor.map(color => {
                                            return <Option value={color} key={color}>{color}</Option>
                                        })}
                                    </Select>
                                    {props.errors.hairColor && props.touched.hairColor && (
                                        <div className="error-text">{props.errors.hairColor}</div>
                                    )}
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Eye Color</span>
                                    <Select
                                        name={`eyeColor${profession}`}
                                        id="eyeColor"
                                        className="form-control form-control-select w-100 mt-25"
                                        onChange={props.handleChange(`eyeColor${profession}`)}
                                        value={props.values[`eyeColor${profession}`]}
                                    >
                                        <Option value="">Select</Option>
                                        {getEyeColor && getEyeColor.map(color => {
                                            return <Option value={color} key={color}>{color}</Option>
                                        })}
                                    </Select>
                                    {props.errors.eyeColor && props.touched.eyeColor && (
                                        <div className="error-text">{props.errors.eyeColor}</div>
                                    )}
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Ethinicity</span>
                                    <Select
                                        name={`ethinicity${profession}`}
                                        id="ethinicity"
                                        className="form-control form-control-select w-100 mt-25"
                                        onChange={props.handleChange(`ethinicity${profession}`)}
                                        value={props.values[`ethinicity${profession}`]}
                                    >
                                        <Option value="">Select</Option>
                                        {getEthinicity && getEthinicity.map(type => {
                                            return <Option value={type} key={type}>{type}</Option>
                                        })}
                                    </Select>
                                    {props.errors.ethinicity && props.touched.ethinicity && (
                                        <div className="error-text">{props.errors.ethinicity}</div>
                                    )}
                                </div>
                            </Fragment>}
                            <span className='w-100'></span>
                            {(selectedCategory === 'Photographer' || selectedCategory === 'Videographer') && isShowSubCategory && <Fragment>
                                <span className="mb-0 mt-4 ml-3 font-w600 font-14">Select your gadget and its type</span>
                                <div className="row col-md-12 p-0">
                                    <div className={`col-md-3 col-xs-12 mb-3 mt-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                        <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }}>Camera 1 Brand</span>
                                        <Select
                                            name={`cameraBrand1${profession}`}
                                            id="cameraBrand1"
                                            className={isMobile ? "form-control form-control-select w-100 mt-2 ml-15" : "form-control form-control-select w-100 mt-2"}
                                            onChange={props.handleChange(`cameraBrand1${profession}`)}
                                            value={props.values[`cameraBrand1${profession}`] || ""}
                                        >
                                            <Option value="">Select Camera 1 Brand</Option>
                                            {getCameraBrandList && (
                                                Object.keys(getCameraBrandList).map(brand => {
                                                    return <Option value={brand}>{brand}</Option>
                                                })
                                            )}
                                        </Select>
                                        {props.errors.cameraBrand1 && props.touched.cameraBrand1 && (
                                            <div className="error-text">{props.errors.cameraBrand1}</div>
                                        )}
                                    </div>
                                    <div className={`col-md-3 col-xs-12 mb-3 mt-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                        <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }}>Camera 1 Model</span>
                                        <Select
                                            name={`cameraModel1${profession}`}
                                            id="cameraModel1"
                                            className={isMobile ? "form-control form-control-select w-100 mt-2 ml-15" : "form-control form-control-select w-100 mt-2"}
                                            onChange={props.handleChange(`cameraModel1${profession}`)}
                                            value={props.values[`cameraModel1${profession}`] || ""}
                                        >
                                            <Option value="">Select Camera 1 Model</Option>
                                            {getCameraBrandList && props.values[`cameraBrand1${profession}`] && (getCameraBrandList[props.values[`cameraBrand1${profession}`]].map(model => {
                                                return <Option value={model}>{model}</Option>
                                            })
                                            )}
                                        </Select>
                                        {props.errors.cameraModel1 && props.touched.cameraModel1 && (
                                            <div className="error-text">{props.errors.cameraModel1}</div>
                                        )}
                                    </div>
                                    <span className='w-100'></span>
                                    <div className={`col-md-3 col-xs-12 mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                        <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }}>Camera 2 Brand</span>
                                        <Select
                                            name={`cameraBrand2${profession}`}
                                            id="cameraBrand2"
                                            className={isMobile ? "form-control form-control-select w-100 mt-2 ml-15" : "form-control form-control-select w-100 mt-2"}
                                            onChange={props.handleChange(`cameraBrand2${profession}`)}
                                            value={props.values[`cameraBrand2${profession}`] || ""}
                                        >
                                            <Option value="">Select Camera 2 Brand</Option>
                                            {getCameraBrandList && (
                                                Object.keys(getCameraBrandList).map(brand => {
                                                    return <Option value={brand}>{brand}</Option>
                                                })
                                            )}
                                        </Select>
                                    </div>
                                    <div className={`col-md-3 col-xs-12 mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                        <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }}>Camera 2 Model</span>
                                        <Select
                                            name={`cameraModel2${profession}`}
                                            id="cameraModel2"
                                            className={isMobile ? "form-control form-control-select w-100 mt-2 ml-15" : "form-control form-control-select w-100 mt-2"}
                                            onChange={props.handleChange(`cameraModel2${profession}`)}
                                            value={props.values[`cameraModel2${profession}`] || ""}
                                        >
                                            <Option value="">Select Camera 2 Model</Option>
                                            {getCameraBrandList && props.values[`cameraBrand2${profession}`] && (getCameraBrandList[props.values[`cameraBrand2${profession}`]].map(model => {
                                                return <Option value={model}>{model}</Option>
                                            })
                                            )}
                                        </Select>
                                    </div>
                                    <span className='w-100'></span>
                                    <div className={`col-md-3 col-xs-12 mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                        <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }}>Camera 3 Brand</span>
                                        <Select
                                            name={`cameraBrand3${profession}`}
                                            id="cameraBrand3"
                                            className={isMobile ? "form-control form-control-select w-100 mt-2 ml-15" : "form-control form-control-select w-100 mt-2"}
                                            onChange={props.handleChange(`cameraBrand3${profession}`)}
                                            value={props.values[`cameraBrand3${profession}`] || ""}
                                        >
                                            <Option value="">Select Camera 3 Brand</Option>
                                            {getCameraBrandList && (
                                                Object.keys(getCameraBrandList).map(brand => {
                                                    return <Option value={brand}>{brand}</Option>
                                                })
                                            )}
                                        </Select>
                                    </div>
                                    <div className={`col-md-3 col-xs-12 mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                        <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }}>Camera 3 Model</span>
                                        <Select
                                            name={`cameraModel3${profession}`}
                                            id="cameraModel3"
                                            className={isMobile ? "form-control form-control-select w-100 mt-2 ml-15" : "form-control form-control-select w-100 mt-2"}
                                            onChange={props.handleChange(`cameraModel3${profession}`)}
                                            value={props.values[`cameraModel3${profession}`] || ""}
                                        >
                                            <Option value="">Select Camera 3 Model</Option>
                                            {getCameraBrandList && props.values[`cameraBrand3${profession}`] && (getCameraBrandList[props.values[`cameraBrand3${profession}`]].map(model => {
                                                return <Option value={model}>{model}</Option>
                                            })
                                            )}
                                        </Select>
                                    </div>
                                    <span className='w-100'></span>
                                    <div className={`col-md-3 col-xs-12 ${!isMobile && 'pr-0 pl-30'}`}>
                                        <span className="profile-label" style={{ paddingLeft: isMobile && 30 }}>Other Gear</span>
                                        <WSTextField
                                            error={props.errors.otherGear && props.touched.otherGear ? true : false}
                                            helperText={props.errors.otherGear && props.touched.otherGear ? props.errors.otherGear : ''}
                                            className={isMobile ? "login-form-textinput ml-15" : "login-form-textinput"}
                                            id="otherGear"
                                            name={`otherGear${profession}`}
                                            placeholder={'Other Gear'}
                                            value={props.values[`otherGear${profession}`] || ""}
                                            onChange={props.handleChange(`otherGear${profession}`)}
                                            disabled={loaderButton}
                                        />
                                    </div>
                                </div>
                            </Fragment>}
                            <span className='w-100'></span>
                        </div>
                        <div className="flex-justify-spacebetween mt-2">
                            <div>
                                <WSButton
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: '#ffffff',
                                        margin: !isMobile && 20,
                                        borderRadius: 30
                                    }}
                                    onClick={props.handleSubmit}
                                >
                                    Save Updates
                                </WSButton>
                            </div>
                        </div>
                    </form>
                )
                }
            </Formik>
        );
    }
}

const mapStateToProps = ({ auth, categories }) => {
    return {
        providerData: auth.providerData,
        categoryData: categories.categoryData,
    };
};

const composedHOC = compose(
    withTheme(),
    withStyles(styles),
    connect(
        mapStateToProps,
        actions
    )
);

export default composedHOC(injectIntl(ProviderProfession));