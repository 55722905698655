import React, { Component } from 'react';
import { compose } from 'redux';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../actions';

import ProviderTypesList from '../../components/Providers/ProviderTypesList.js';
import HOCSectionLoader from '../../components/HOC/HOCSectionLoader.jsx';

import PortfolioGallery from './ProtfolioGallery';

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};
const sendPropsWithoutRouter = (props) => {
    const { match, location, ...rest } = props;
    return rest;
};

export class ManagePortfolio extends Component {
    componentDidMount() {
        //this.getData();
    }

    getData = () => {
        const { getProviderServicesAction, userData } = this.props;
        // getProviderServicesAction({ id: userData.id });
    };

    render() {
        const {
            classes,
            providerServiceData,
            providerServiceLoader,
            match,
            providerTypeData,
        } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <HOCSectionLoader loading={providerServiceLoader}>
                            <Switch>
                                <Route
                                    path={`${match.url}/gallery`}
                                    render={(props) => (
                                        <PortfolioGallery
                                            {...props}
                                            {...sendPropsWithoutRouter(
                                                this.props
                                            )}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/`}
                                    exact={true}
                                    render={(props) => (
                                        <ProviderTypesList
                                            {...props}
                                            {...sendProps(this.props)}
                                            data={providerServiceData}
                                            providerTypeData={providerTypeData}
                                        />
                                    )}
                                />
                            </Switch>
                        </HOCSectionLoader>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({}) => {
    return {};
};

const composedComponent = compose(connect(mapStateToProps, actions));

export default composedComponent(ManagePortfolio);
