import React, { Component } from 'react';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import SlotList from "../../components/Customer/SlotList.jsx";

const styles = theme => ({
    card: {
        color: theme.palette.secondary.main
    },
    mainDiv: {
        minHeight: '500px'
    }
});

const sendProps = props => {
    const { classes, ...rest } = props;
    return rest;
};

export class Slots extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={isMobile ? "row" : "row mb-4"}>
                    <div className="col-md-12">
                        <Card className={classes.card}>
                            <Toolbar>
                                <h3 className={isMobile && 'font-18'}>
                                    <FormattedMessage
                                        id="mySlots"
                                        defaultMessage="My Calender"
                                    />
                                </h3>
                            </Toolbar>
                        </Card>
                    </div>
                </div>

                <SlotList {...sendProps(this.props)} />
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles));

export default composedComponent(Slots);