import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';

import FaqList from '../../components/Providers/FaqList.js';

import FaqCreate from './FaqCreate.jsx';
import FaqUpdate from './FaqUpdate.jsx';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};

const sendPropsWithoutRouter = (props) => {
    const { match, location, ...rest } = props;
    return rest;
};

export class Faq extends Component {
    render() {
        const { match } = this.props;
        return (
            <div>
                <Route
                    exact={true}
                    path={`${match.url}`}
                    render={(props) => (
                        <FaqList {...props} {...sendProps(this.props)} />
                    )}
                />
                <Route
                    path={`${match.url}/create/:serviceId`}
                    render={(props) => (
                        <FaqCreate
                            {...props}
                            {...sendPropsWithoutRouter(this.props)}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/update/:serviceId`}
                    render={(props) => (
                        <FaqUpdate
                            {...props}
                            {...sendPropsWithoutRouter(this.props)}
                        />
                    )}
                />
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles));

export default composedComponent(Faq);
