import {
    NOTIFICATION_DATA_RECEIVED,
    NOTIFICATION_LOADER,
    LOGOUT,
} from '../actions/types';

const INITIAL_STATE = {
    notificationData: [],
    notificationLoader: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case NOTIFICATION_DATA_RECEIVED:
            return { ...state, notificationData: action.payload };
        case NOTIFICATION_LOADER:
            return { ...state, notificationLoader: action.payload };

        case LOGOUT:
            return INITIAL_STATE;
        default:
            return state;
    }
}
