import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import WbSunny from '@material-ui/icons/WbSunny';
import Brightness1 from '@material-ui/icons/Brightness1';
import Brightness3 from '@material-ui/icons/Brightness3';

import * as actions from '../../actions';

import WSButton from '../Button/WSButton';

class TimeSlotList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderButton: false,
            selectedSlot: '',
            checked: true,
            slots: this?.props?.slots,
            sessions: this?.props?.sessions,
            dateTimeValue: this?.props?.dateTimeValue,
            disableUpdateButton: this?.props?.disableUpdateButton
        };
    }

    handleError = params => {
        this.setState(params);
    };

    componentDidUpdate(prevProps) {
        const { slots, sessions, dateTimeValue, disableUpdateButton } = this.props;
        if (prevProps?.sessions !== sessions || prevProps?.slots !== slots) {
            this.setState({
                slots: slots,
                sessions: sessions,
                dateTimeValue: dateTimeValue,
                disableUpdateButton: disableUpdateButton
            });
        }
    }

    onSlotPress = (e, session) => {
        let sessions = this?.props?.sessions;
        sessions[session].isSeesionSlots = e?.target?.checked;
        this.setState({ sessions: { ...sessions } });
    };

    updateDateRangeAvailbility = () => {
        const { sessions, dateTimeValue } = this.state;
        const { onTimeSlotSelected } = this.props;
        this.setState({
            disableUpdateButton: true
        });
        onTimeSlotSelected({
            sessions: sessions,
            date: dateTimeValue
        });
    }

    render() {
        const { sessions } = this.state;
        const { isDateAvailable, disableUpdateButton } = this.props;
        return (
            <div>
                <Grid container justify="center" >
                    <Grid item md={6} xs={12}>
                        <WbSunny /> {sessions?.morning?.label}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={isDateAvailable === false ? true : false}
                                    checked={sessions?.morning?.isSeesionSlots ? true : false}
                                    onChange={(e) => this.onSlotPress(e, 'morning')}
                                    value={sessions?.morning?.isSeesionSlots}
                                    color="primary"
                                />
                            }
                            label={sessions?.morning?.isSeesionSlots ? "Available" : "Unavailable"}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Brightness1 /> {sessions?.afternoon?.label}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={isDateAvailable === false ? true : false}
                                    checked={sessions?.afternoon?.isSeesionSlots ? true : false}
                                    onChange={(e) => this.onSlotPress(e, 'afternoon')}
                                    value={sessions?.afternoon?.isSeesionSlots}
                                    color="primary"
                                />
                            }
                            label={sessions?.afternoon?.isSeesionSlots ? "Available" : "Unavailable"}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Brightness3 />  {sessions?.evening?.label}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={isDateAvailable === false ? true : false}
                                    checked={sessions?.evening?.isSeesionSlots ? true : false}
                                    onChange={(e) => this.onSlotPress(e, 'evening')}
                                    value={sessions?.evening?.isSeesionSlots}
                                    color="primary"
                                />
                            }
                            label={sessions?.evening?.isSeesionSlots ? "Available" : "Unavailable"}
                        />
                    </Grid>
                    <Grid item md={4} xs={12} textAlign="center">
                        <WSButton
                            onClick={this.updateDateRangeAvailbility}
                            disabled={
                                isDateAvailable == false ? true : disableUpdateButton
                            }
                        >
                            {this.state?.disableUpdateButton && (
                                'Loading...'
                            )}
                            {!this.state?.disableUpdateButton && (
                                'Update'
                            )}
                        </WSButton>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const composedHOC = compose(
    withTheme(),
    connect(
        null,
        actions
    )
);

export default composedHOC(TimeSlotList);