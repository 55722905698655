import { PROMOCODE_DATA_RECEIVED, LOGOUT } from '../actions/types';

const INITIAL_STATE = {
    promoData: [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case PROMOCODE_DATA_RECEIVED:
            return { ...state, promoData: action.payload };

        case LOGOUT:
            return { ...state, userData: INITIAL_STATE.promoData };
        default:
            return state;
    }
}
