import axios from 'axios';

import {
    errorResponse,
    validateApiData,
} from '../../utils/validators/validators';
import { SERVER_URL } from '../../utils/config/constants';

export const signup = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/signup`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const checkOtp = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/check-otp`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const checkProviderOtp = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/check-emailotp`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const sendOtpOnPhone = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/send-phoneotp`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const checkOtpOnPhone = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/check-phoneotp`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const forgotPassword = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/forgot-password`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const checkForgotOtp = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/check-forgot-otp`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const resetPassword = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/reset-password`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const socialLogin = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/user/social-login`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const contactUs = async (params) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/helpdesk/contact`,
            data: params,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};
