import React, { Component } from 'react';
import { compose } from 'redux';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import ServiceGalleryCreate from '../../components/Providers/ServiceGalleryCreate';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

export class Create extends Component {
    constructor(props) {
        super();
        this.state = {
            service: props.location ? props.location.service : '',
            data: '',
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const { providerServiceDetailAction, match } = this.props;
        providerServiceDetailAction(
            { proServiceId: match.params.serviceId },
            this.onDone
        );
    };

    onDone = (success, data) => {
        const { history, match } = this.props;
        if (success) {
            this.setState({ data: data.data, loader: false });
        } else {
            history.push({
                pathname: `/provider/service/gallery`,
                serviceId: match.params.serviceId,
            });
        }
    };

    render() {
        const { classes } = this.props;
        const { data, service } = this.state;
        return (
            <div>
                <div className={isMobile ? 'row mb-2' : 'row mb-4'}>
                    <div className="col-md-12">
                        <Card className={classes.card}>
                            <Toolbar>
                                <h3>Add New Gallery Image</h3>
                            </Toolbar>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {data !== '' && (
                            <ServiceGalleryCreate
                                {...this.props}
                                data={data}
                                service={service}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles));

export default composedComponent(Create);
