import React, { Component } from 'react';

import BusinessHours from './BusinessHours';

export function ProviderBusinessHours(props) {

    return (
        <div>
            <BusinessHours />
        </div>
    );
}

export default ProviderBusinessHours;