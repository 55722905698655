/*eslint-disable*/
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Redirect, withRouter } from 'react-router-dom';
import {
    isTablet,
    isBrowser,
    isMobile
} from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { CircularProgress } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { Dashboard, AccountBox, ContactPhone } from '@material-ui/icons';

import { ROLE_PROVIDER, ROLE_CUSTOMER } from '../../utils/config/constants.js';

import Button from 'components/CustomButtons/Button.jsx';

import headerLinksStyle from 'assets/jss/material-kit-react/components/headerLinksStyle.jsx';

class MainPageLinks extends Component {

    constructor(props) {
        super();
        this.state = {
            signupRedirect: false,
            dashboardRedirect: false,
            language: 'en'
        };
    }

    handleChangeLanguage = event => {
        const { changeLanguageAction } = this.props;
        this.setState({
            [event.target.name]: event.target.value
        });
        document.documentElement.lang = event.target.value;
        changeLanguageAction({ language: event.target.value });
    };

    render() {
        const { classes, userData, history, isLoading, renderLogin, currentLanguage } = this.props;
        const { dashboardRedirect, signupRedirect } = this.state;

        if (signupRedirect) {
            return (
                <Redirect
                    to={{
                        pathname: `/signup`
                    }}
                />
            );
        }

        if (dashboardRedirect) {
            if (userData.role === ROLE_PROVIDER) {
                history.push(`/user/dashboard`);
            } else if (userData.role === ROLE_CUSTOMER) {
                history.push(`/user/profile`);
            }
        }

        if (isLoading) {
            return <CircularProgress />;
        }

        if (!userData.name) {
            return (
                <List className={classes.list + ' navigation-right'}>
                    <ListItem
                        className={classes.listItem}
                        style={{ color: '#ffffff' }}
                    >
                        <Button
                            target="_blank"
                            color="transparent"
                            className={classes.navLink + ' btn-login'}
                            onClick={renderLogin}
                            onClick={() => {
                                history.push(`/login`);
                            }}
                            style={{ fontSize: isMobile ? '14px' : '16px' }}
                            size={isBrowser ? "lg" : isTablet ? "medium" : "small"}
                        >
                            <FormattedMessage
                                id="header.login"
                                defaultMessage="Login"
                            />
                        </Button>
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        style={{ color: '#ffffff' }}
                    >
                        <Button
                            target="_blank"
                            color="transparent"
                            className={classes.navLink + ' btn-login'}
                            onClick={() => {
                                history.push(`/signup`);
                            }}
                            style={{ fontSize: isMobile ? '14px' : '16px' }}
                            size={isBrowser ? "lg" : isTablet ? "medium" : "small"}
                        >
                            <FormattedMessage
                                id="SIGNUP"
                                defaultMessage="Signup"
                            />
                        </Button>
                    </ListItem>
                </List>
            );
        } else {
            return (
                <List className={classes.list + ' navigation-right'}>
                    <ListItem
                        className={classes.listItem}
                        style={{ color: '#ffffff' }}
                    >
                        <Button
                            target="_blank"
                            color="transparent"
                            className={classes.navLink + ' btn-login'}
                            onClick={() =>
                                this.setState({ dashboardRedirect: true })
                            }
                            style={{ fontSize: isMobile ? '14px' : '16px' }}
                            size={isBrowser ? "lg" : isTablet ? "medium" : "small"}
                        >
                            {userData.role === ROLE_CUSTOMER ? <Fragment>
                                <AccountBox className={classes.icons} />&nbsp;
                                <FormattedMessage
                                    id="customer.profile"
                                    defaultMessage="My Profile"
                                />
                            </Fragment>
                                : <Fragment>
                                    <Dashboard className={classes.icons} />&nbsp;
                                    <FormattedMessage
                                        id="header.dashboard"
                                        defaultMessage="Dashboard"
                                    />
                                </Fragment>
                            }
                        </Button>
                        <Button
                            target="_blank"
                            color="transparent"
                            className={classes.navLink + ' btn-login'}
                            onClick={() => history.push(`/contact-us`)}
                            style={{ fontSize: isMobile ? '14px' : '16px', marginLeft: !isMobile && 5 }}
                            size={isBrowser ? "lg" : isTablet ? "medium" : "small"}
                        >
                            <Fragment>
                                <ContactPhone className={classes.icons} />&nbsp;
                                <FormattedMessage
                                    id="contactUs"
                                    defaultMessage="Contact Us"
                                />
                            </Fragment>
                        </Button>
                    </ListItem>
                </List>
            );
        }
    }
}

const mapStateToProps = ({ auth, settings }) => {
    return {
        currentLanguage: settings.currentLanguage
    };
};

const composedComponent = compose(
    withStyles(headerLinksStyle),
    connect(
        mapStateToProps,
        null
    )
);

export default composedComponent(withRouter(MainPageLinks));