export const validateApiData = (data) => {
    if (data.status === 'OK') {
        return true;
    } else if (data.status === 'NOK') {
        return true;
    } else if (data.status === 'MAINTAINENCE') {
        return 'MAINTAINENCE';
    } else if (data.status === 'NOV') {
        return 'NOV';
    }
    return false;
};

export const errorResponse = {
    status: 'NOK',
    message: 'Unknown Error Occurred',
};
