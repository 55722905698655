import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import CssBaseline from '@material-ui/core/CssBaseline';
import MailIcon from '@material-ui/icons/Mail';
import { fade } from '@material-ui/core/styles/colorManipulator';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MenuRounded } from '@material-ui/icons';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { AccountBalanceWallet } from '@material-ui/icons';
import {
    Avatar,
    Typography,
    ListItemIcon,
    ListItemText,
    Toolbar,
    AppBar,
    IconButton,
    Badge,
    MenuItem,
    Menu,
    Drawer,
    ListItem
} from '@material-ui/core';

import * as actions from '../../actions';
import Hidden from '@material-ui/core/Hidden';

import logo from 'assets/img/logo.png';

import { ROLE_CUSTOMER, ROLE_PROVIDER } from '../../utils/config/constants';

import HOCLoader from '../../components/HOC/HOCLoader.jsx';
import withUser from '../../components/HOC/HOCwithUser.jsx';
import ChatPanel from '../../components/Customer/ChatPanel';
import Slots from '../../components/Providers/Slots';

import Dashboard from '../User/Dashboard.jsx';
import Profile from '../User/Profile.jsx';
import ProviderPortfolio from '../User/ProviderPortfolio';
import CustomerAddress from '../User/Address.jsx';
import ProviderService from '../User/ProviderService.jsx';
import { Booking } from '../User/Booking.jsx';
import ManagePortfolio from '../User/ManagePortfolio.js';
import NotificationList from '../User/Notification.jsx';
import PromocodeList from '../User/Promocode.jsx';
import ProviderDashboard from '../User/ProviderDashboard.jsx';
import ProviderBooking from '../User/ProviderBooking.jsx';

import SideNav from '../SideBar/SideNav';
import Favourite from './Favourite.jsx';
import Inbox from './Inbox.jsx';
import ChangePassword from './ChangePassword';

const drawerWidth = 240;

const sendRouterProps = props => {
    return props;
};

const styles = theme => ({
    root: {
        display: 'flex',
        color: theme.palette.primary.main
    },
    appBar: {
        marginLeft: drawerWidth,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`
        }
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#252b33'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 5,

        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto'
        }
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200
        }
    },
    sectionDesktop: {
    },
    sectionMobile: {
        display: 'flex',
        padding: 0
    }
});

const sendProps = props => {
    const { classes, ...rest } = props;
    return rest;
};

class CustomerMain extends Component {
    constructor(props) {
        super();
        this.state = {
            anchorEl: '',
            anchorE2: '',
            mobileMoreAnchorEl: '',
            redirect: false,
            redirectMain: false,
            allNotifications: false,
            language: 'en',
            mobileOpen: false
        };
    }

    handleDrawerToggle = () => {
        this.setState(prevState => ({
            mobileOpen: !prevState.mobileOpen
        }));
        this.forceUpdate();
    };

    componentDidMount() {
        const { getLoggedInUserAction, notificationListAction, removeHeaderAction, getProviderTypesAction, userData } = this.props;
        getLoggedInUserAction()
        notificationListAction();
        removeHeaderAction(true);
        getProviderTypesAction();
        // if (userData && userData.firstLogin) {
        //     window.location.href = '/createprofile';
        // }
    }

    componentWillUnmount() {
        const { removeHeaderAction } = this.props;
        removeHeaderAction(false);
    }

    // componentDidUpdate(prevProps) {
    // const { userData } = this.props; 
    //     if (prevProps.userData.name === '' && userData.name === '') {
    //         this.setState({
    //             redirectMain: true
    //         });
    //     }
    // }

    logoutCall = async () => {
        const { logoutAction } = this.props;
        logoutAction(this.onLogoutDone);
    };

    onLogoutDone = (success, data) => {
        if (success) {
            this.setState({
                redirectMain: true
            });
            window.location.reload();
        }
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleNotificationMenuOpen = event => {
        this.setState({ anchorE2: event.currentTarget });
    };

    handleNotiMenuClose = () => {
        this.setState({ anchorE2: null });
        this.handleMobileMenuClose();
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    redirectMainWebsite = () => {
        this.setState({ redirectMain: true });
    };

    getNotificationBadge = () => {
        const { notificationData } = this.props;
        let countNoti = 0;
        notificationData.filter(noti => {
            if (noti.is_read === 2) {
                countNoti = parseInt(countNoti) + 1;
            }
        });
        return countNoti;
    };

    userProfile = () => {
        const { history } = this.props;
        history.push(`/user/profile`);
    };

    changePassword = () => {
        const { history } = this.props;
        history.push(`/user/changepassword`);
    };

    clickNotification = notification => {
        const { history, notificationListAction } = this.props;
        this.handleNotiMenuClose();
        if (notification.route === 'BookingDetail') {
            history.push({
                pathname:
                    notification.webroute +
                    '/' +
                    notification.notification.payload.additionalData.params
                        .bookingDetail.key,
                bookingId:
                    notification.notification.payload.additionalData.params
                        .bookingDetail.key
            });
        } else if (notification.route === 'Offers') {
            history.push({
                pathname: notification.webroute
            });
        }
        // notificationListAction();
    };

    renderUnreadNotification = () => {
        const { notificationData } = this.props;
        return notificationData.slice(0, 10).map((noti, index) => {
            return (
                <MenuItem style={{ fontSize: 14 }} key={index}>
                    <ListItemIcon>
                        <NotificationsIcon />
                    </ListItemIcon>
                    <ListItemText
                        onClick={() => this.clickNotification(noti)}
                        primary={
                            noti.is_read === 2 ? (
                                <Typography
                                    color="primary"
                                    variant="inherit"
                                    noWrap
                                >
                                    {noti.notification.payload.body}
                                </Typography>
                            ) : (
                                <Typography variant="inherit" noWrap>
                                    {noti.notification.payload.body}
                                </Typography>
                            )
                        }
                    />
                    {/* <ListItemSecondaryAction>
                        {convertTimestampToDate(noti.created_at * 1000)}
                    </ListItemSecondaryAction> */}
                </MenuItem>
            );
        });
    };

    listAllNotification = () => {
        this.setState({ allNotifications: true });
    };

    handleWalletClick = () => {
        const { history } = this.props;
        history.push(`/user/profile`);
    };

    handleChangeLanguage = event => {
        const { changeLanguageAction } = this.props;
        this.setState({ [event.target.name]: event.target.value });
        changeLanguageAction({ language: event.target.value });
        document.documentElement.lang = event.target.value;
    };

    render() {
        const {
            anchorEl,
            mobileMoreAnchorEl,
            anchorE2,
            language,
            redirectMain,
            allNotifications,
            mobileOpen,
            redirect
        } = this.state;
        const {
            classes,
            userData,
            history,
            settings,
            currentLanguage,
            notificationData,
            authLoader
        } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const isNotiMenuOpen = Boolean(anchorE2);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
        const renderMenu = (
            <Menu
                className="modal-fixed-to"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.userProfile}>
                    <FormattedMessage id="profile" defaultMessage="Profile" />
                </MenuItem>
                <MenuItem onClick={this.changePassword}>
                    <FormattedMessage
                        id="changePassword"
                        defaultMessage="Change Password"
                    />
                </MenuItem>
                <MenuItem onClick={this.logoutCall}>
                    &nbsp;
                    <FormattedMessage id="logout" defaultMessage="Logout" />
                </MenuItem>
            </Menu>
        );

        const renderNotificationMenu = (
            <Menu
                anchorEl={anchorE2}
                className="modal-fixed-to"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isNotiMenuOpen}
                onClose={this.handleNotiMenuClose}
            >
                {this.renderUnreadNotification()}
                <ListItem
                    onClick={this.listAllNotification}
                    style={{ cursor: 'pointer' }}
                >
                    <FormattedMessage
                        id="seeAllNotifications"
                        defaultMessage="See All Notifications"
                    />
                </ListItem>
                ;
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                className="modal-fixed-to"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem>
                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <p>
                        <FormattedMessage
                            id="messages"
                            defaultMessage="Messages"
                        />
                    </p>
                </MenuItem>
                <MenuItem>
                    <IconButton color="inherit">
                        <Badge
                            badgeContent={this.getNotificationBadge()}
                            color="secondary"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <p>
                        <FormattedMessage
                            id="notifications"
                            defaultMessage="Notifications"
                        />
                    </p>
                </MenuItem>
                {/* <MenuItem className={classes.listItem}>
                    <Select
                        className="btn-login"
                        style={{ color: '#ffffff' }}
                        value={language}
                        onChange={this.handleChangeLanguage}
                        name="language"
                    >
                        <MenuItem
                            selected={
                                language === 'en' ? true : false
                            }
                            value="en"
                        >
                            English
                        </MenuItem>
                        <MenuItem
                            selected={
                                language === 'ar' ? true : false
                            }
                            value="ar"
                        >
                            Arabic
                        </MenuItem>
                    </Select>
                </MenuItem> */}
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        {userData.role === ROLE_CUSTOMER
                            ? userData.image && (
                                <Avatar
                                    alt="User Profile"
                                    src={userData.image.small}
                                    className={classes.avatar}
                                />
                            )
                            : userData.proImage && (
                                <Avatar
                                    alt="User Profile"
                                    src={userData.proImage.small}
                                    className={classes.avatar}
                                />
                            )}
                    </IconButton>
                    <p>
                        <FormattedMessage
                            id="profile"
                            defaultMessage="Profile"
                        />
                    </p>
                </MenuItem>
            </Menu>
        );

        if (redirectMain) {
            history.push(`/`);
        }
        if (allNotifications) {
            history.push(`/notification/list`);
        }

        return (
            // userData && !userData.firstLogin &&
            <div className={classes.root}>
                <CssBaseline />
                <div className={classes.root}>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            {isMobile && <MenuRounded className="mr-10" onClick={() => this.setState({ mobileOpen: true })} />}
                            {userData.role === ROLE_PROVIDER && (
                                <h6 style={{ color: '#ffffff', maxWidth: isMobile && '100%' }}>
                                    {userData.businessName} (
                                    {userData.name})
                                </h6>
                            )}
                            {userData.role === ROLE_CUSTOMER && (
                                <h6 style={{ color: '#ffffff' }}>
                                    {userData.name}
                                </h6>
                            )}
                            <div className={classes.grow} />
                            <div
                                className={
                                    classes.sectionDesktop +
                                    ' navigation-right'
                                }
                            >
                                {userData.role === ROLE_CUSTOMER && settings.constants.WALLET_ACTIVE && (
                                    <IconButton
                                        className="btn-login mr-2"
                                        aria-owns={
                                            isNotiMenuOpen
                                                ? 'material-appbar'
                                                : undefined
                                        }
                                        aria-haspopup="true"
                                        color="inherit"
                                        onClick={this.handleWalletClick}
                                    >
                                        <Badge
                                            className="badge-noti"
                                            badgeContent={
                                                userData
                                                    .wallet
                                            }
                                            color="secondary"
                                        >
                                            <AccountBalanceWallet />
                                        </Badge>
                                    </IconButton>
                                )}
                                {/* <IconButton
                                        className="btn-login mr-2"
                                        aria-owns={
                                            isNotiMenuOpen
                                                ? 'material-appbar'
                                                : undefined
                                        }
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                        <Select
                                            className="btn-lang mr-2"
                                            style={{ color: '#ffffff' }}
                                            value={currentLanguage}
                                            onChange={this.handleChangeLanguage}
                                            name="language"
                                        >
                                            <MenuItem
                                                selected={
                                                    this.props
                                                        .currentLanguage === 'en'
                                                }
                                                value="en"
                                            >
                                                English
                                            </MenuItem>
                                            <MenuItem
                                                selected={
                                                    this.props
                                                        .currentLanguage === 'ar'
                                                }
                                                value="ar"
                                            >
                                                Arabic
                                            </MenuItem>
                                        </Select>
                                    </IconButton> */}
                                <IconButton
                                    id="notificationIconBtn"
                                    className="btn-login mr-2"
                                    aria-owns={isNotiMenuOpen ? 'material-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleNotificationMenuOpen}
                                    color="inherit"
                                >
                                    <Badge
                                        className="badge-noti"
                                        badgeContent={this.getNotificationBadge()}
                                        color="secondary"
                                    >
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    id="profileIconBtn"
                                    className="btn-login btn-profile"
                                    aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    {userData.role === ROLE_CUSTOMER ? userData.image && (
                                        <Avatar
                                            alt="User Profile"
                                            src={userData.image.small}
                                            className={classes.avatar}
                                        />
                                    )
                                        : userData.proImage && (
                                            <Avatar
                                                alt="User Profile"
                                                src={userData.proImage.small}
                                                className={classes.avatar}
                                            />
                                        )}
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {renderMenu}
                    {renderMobileMenu}
                    {notificationData.length > 0 &&
                        renderNotificationMenu}
                </div>
                <nav className={classes.drawer}>
                    {isMobile && <Hidden xsUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor="left"
                            open={mobileOpen}
                            onClick={this.handleDrawerToggle}
                            onClose={this.handleDrawerToggle}
                            classes={{ paper: classes.drawerPaper + ' SideNav-arab' }}
                            ModalProps={{ keepMounted: true }}
                        >
                            <div
                                className={
                                    classes.toolbar +
                                    ' ' +
                                    'logo-user-dashboard'
                                }
                                onClick={this.redirectMainWebsite}
                                style={{ cursor: 'pointer' }}
                            >
                                <LazyLoadImage
                                    src={logo}
                                    width="120px"
                                    height="40px"
                                    alt="logo"
                                    style={{ marginTop: 10 }}
                                />
                            </div>
                            <SideNav {...sendProps(this.props)} />
                        </Drawer>
                    </Hidden>}
                    {!isMobile && <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper + ' SideNav-arab'
                            }}
                            variant="permanent"
                            anchor="left"
                            open
                        >
                            <div
                                className={
                                    classes.toolbar +
                                    ' ' +
                                    'logo-user-dashboard'
                                }
                                onClick={this.redirectMainWebsite}
                                style={{ cursor: 'pointer' }}
                            >
                                <LazyLoadImage src={logo} alt="logo" />
                            </div>
                            <SideNav {...sendProps(this.props)} />
                        </Drawer>
                    </Hidden>}
                </nav>
                <main className={classes.content + ' main-div'}>
                    <HOCLoader
                        loading={authLoader}
                    // redirect={redirect}
                    // redirectTo={'/'}
                    >
                        <div className={classes.toolbar} />
                        {userData.name !== '' && (
                            <Switch>
                                <Route
                                    path={`/user/dashboard`}
                                    render={props =>
                                        userData.role === ROLE_CUSTOMER ? (
                                            <Dashboard
                                                {...sendProps(this.props)}
                                                {...sendRouterProps(props)}
                                            />
                                        ) : (
                                            <ProviderDashboard
                                                {...sendProps(this.props)}
                                                {...sendRouterProps(props)}
                                            />
                                        )}
                                />
                                <Route
                                    path={`/user/profile`}
                                    render={props => (
                                        <Profile
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/provider/portfolio`}
                                    render={props => (
                                        <ProviderPortfolio
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/user/slots/list`}
                                    render={props => (
                                        <Slots
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/user/changepassword`}
                                    render={props => (
                                        <ChangePassword
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/user/address`}
                                    render={props => (
                                        <CustomerAddress
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/notification/list`}
                                    render={props => (
                                        <NotificationList
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/user/manage-portfolio`}
                                    render={props => (
                                        <ManagePortfolio
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/user/booking`}
                                    render={props => (
                                        <Booking
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/provider/booking`}
                                    render={props => (
                                        <ProviderBooking
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/provider/service`}
                                    render={props => (
                                        <ProviderService
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/user/favourite`}
                                    render={props => (
                                        <Favourite
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/promocode/list`}
                                    render={props => (
                                        <PromocodeList
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/user/inbox`}
                                    render={props => (
                                        <Inbox
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/user/chat`}
                                    render={props => (
                                        <ChatPanel
                                            {...sendProps(this.props)}
                                            {...sendRouterProps(props)}
                                        />
                                    )}
                                />
                            </Switch>
                        )}
                    </HOCLoader>
                </main>
            </div>
        );
    }
}

CustomerMain.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ settings, auth, notification }) => {
    return {
        colors: settings.colors,
        settings: settings,
        userData: auth.userData,
        authLoader: auth.authLoader,
        notificationData: notification.notificationData,
        currentLanguage: settings.currentLanguage,
        providerTypeData: auth.providerTypeData,
        providerTypeLoader: auth.providerTypeLoader
    };
};

const composedComponent = compose(
    withTheme(),
    withStyles(styles),
    connect(
        mapStateToProps,
        actions
    )
);

export default composedComponent(withUser(CustomerMain));