import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
    Divider,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';

import * as actions from '../../actions';

import { SERVER_URL } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

import withUser from '../../components/HOC/HOCwithUser.jsx';
import HOCLoader from '../../components/HOC/HOCLoader.jsx';
import WSButton from '../../components/Button/WSButton';
import Select from '../../components/Select/Select';
import Option from '../../components/Select/Option';

import PortfolioGallery from './ProtfolioGallery';

import '../../assets/css/style.css';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 2 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
});

export class ManageGener extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            genersData: [],
            showList: true,
            slectGener: '',
            selectedGenere: 0,
            selectedGenereName: '',
            selectedGenereObj: null,
            isEditable: false,
            portfolios: [{}],
            categoryIds: [],
        };
    }

    componentDidMount() {
        const { getFullProviderProfileAction } = this.props;
        this.getGeneresData();
        getFullProviderProfileAction()
    }

    getGeneresData = (categotyId, index) => {
        const { providerData } = this.props;
        axios({
            method: 'GET',
            data: { providerType: categotyId, createdBy: providerData && providerData.id },
            url: `${SERVER_URL}/provider/list-genere?providerType=${categotyId}&createdBy=${providerData && providerData.id}`,
            headers: { 'x-auth-token': getCookies() },
        }).then(response => {
            if (response.data && response.data.status === 'OK') {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({
                        [`genersData${index}`]: response.data.data,
                        [`selectedGenereObj${index}`]: response.data.data[0],
                        [`selectedGenere${index}`]: response.data.data[0].id,
                        [`selectedGenereName${index}`]: response.data.data[0].generName
                    });
                } else {
                    this.setState({
                        [`genersData${index}`]: [],
                        [`selectedGenereObj${index}`]: null,
                        [`selectedGenere${index}`]: 0,
                        [`selectedGenereName${index}`]: ''
                    });
                }
            }
        }, function (err) {
            console.log('axios error', err.status, err.response.status);
        })
            .catch(err => {
                console.log('axios error catch', err.status, err.response.status);
            });
    }

    addGenereAction = (value, id, index) => {
        if (!value) {
            NotificationManager.error('Select another gener to add', 'Error');
            return false;
        }
        axios({
            method: 'POST',
            data: { providerType: this.state[`selectedGenereCategoryId${index}`], portfolioGenreName: value },
            url: `${SERVER_URL}/provider/add-genere`,
            headers: { 'x-auth-token': getCookies() },
        }).then(response => {
            NotificationManager.success(
                response.data.message,
                'Success'
            );
            if (response.data && response.data.status === 'OK') {
                this.setState({
                    [`genersData${index}`]: response.data.data,
                    showList: true,
                    [`selectedGenereObj${index}`]: response.data.data[response.data.data.length - 1],
                    [[`selectedGenere${index}`]]: response.data.data[response.data.data.length - 1].id,
                    [`selectedGenereName${index}`]: response.data.data[response.data.data.length - 1].generName
                });
            } else if (response.data && response.data.status === 'NOK') {
                this.setState({
                    showList: true,
                    [`selectedGenereObj${index}`]: null,
                    [[`selectedGenere${index}`]]: id,
                    [`selectedGenereName${index}`]: value
                });
            } else {
                this.setState({
                    [`selectedGenereObj${index}`]: null,
                    [[`selectedGenere${index}`]]: 0,
                    [`selectedGenereName${index}`]: ''
                });
            }
        })
    }

    removeGenere = (id, index, generName) => {
        const { categoryIds } = this.state;
        axios({
            method: 'GET',
            data: { providerType: this.state[`selectedGenereCategoryId${index}`], genereId: id },
            url: `${SERVER_URL}/provider/delete-genere?providerType=${this.state[`selectedGenereCategoryId${index}`]}&genereId=${id}`,
            headers: { 'x-auth-token': getCookies() },
        }).then(response => {
            NotificationManager.success(
                response.data.message,
                'Success'
            );
            if (response.data && response.data.status === 'OK') {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({
                        [`genersData${index}`]: response.data.data,
                        [`selectedGenereObj${index}`]: response.data.data[response.data.data.length - 1],
                        [`selectedGenere${index}`]: response.data.data[response.data.data.length - 1].id,
                        [`selectedGenereName${index}`]: response.data.data[response.data.data.length - 1].generName,
                        [`allSelectedGenre${index}`]: this.state[`allSelectedGenre${index}`] && this.state[`allSelectedGenre${index}`].filter((grn) => grn.genreName !== generName),
                    }, () => {
                        if (!this.state[`genersData${index}`].length) {
                            this.state[`portfolios`].pop()
                            this.setState({
                                portfolios: this.state["portfolios"],
                                categoryIds: categoryIds && categoryIds.filter((cat) => cat !== this.state[`selectedGenereCategoryId${index}`])

                            })
                        }
                    });
                } else {
                    this.setState({
                        [`selectedGenereObj${index}`]: null,
                        [`selectedGenere${index}`]: 0,
                        [`selectedGenereName${index}`]: '',
                        [`allSelectedGenre${index}`]: this.state[`allSelectedGenre${index}`] && this.state[`allSelectedGenre${index}`].filter((grn) => grn.genreName !== generName)
                    }, () => {
                        if (this.state[`genersData${index}`] && !this.state[`genersData${index}`].length) {
                            this.state[`portfolios`].pop()
                            this.setState({
                                portfolios: this.state["portfolios"],
                                categoryIds: categoryIds && categoryIds.filter((cat) => cat !== this.state[`selectedGenereCategoryId${index}`])

                            })
                        }
                    });
                }
            }
        })
    }

    getSpecificGeneresData = (id, index) => {
        const { providerData } = this.props;
        axios({
            method: 'GET',
            data: { providerType: this.state[`selectedGenereCategoryId${index}`] },
            url: `${SERVER_URL}/provider/list-genere?providerType=${this.state[`selectedGenereCategoryId${index}`]}&genereId=${id}&createdBy=${providerData && providerData.id}`,
            headers: { 'x-auth-token': getCookies() },
        }).then(response => {
            if (response.data && response.data.status === 'OK') {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({
                        [`selectedGenereObj${index}`]: response.data.data[0],
                    });
                } else {
                    this.setState({
                        [`selectedGenereObj${index}`]: null,
                    });
                }
            }
        }, function (err) {
            console.log('axios error', err.status, err.response.status);
        })
            .catch(err => {
                console.log('axios error catch', err.status, err.response.status);
            });
    }

    toggleScreen = () => {
        this.setState(prevState => ({
            isEditable: !prevState.isEditable
        }))
    }

    componentWillReceiveProps(nextProps) {
        const { providerTypes } = nextProps.providerData || {};
        const { isEditable } = this.props

        // if(providerTypes && providerTypes.length !== prepapredData.length){
        if (providerTypes && providerTypes.length > 0) {

            let categoryIds = []
            let numberOfPortfolios = []
            providerTypes.forEach((category, index) => {
                if ((category.portfolioGenre && category.portfolioGenre[0]) || !index) {
                    categoryIds.push(category.providerType.id)
                    numberOfPortfolios.push({})
                    this.setState({
                        [`genersData${index}`]: category.portfolioGenre,
                        [`selectedGenereObj${index}`]: category.portfolioGenre && category.portfolioGenre[0],
                        [`selectedGenere${index}`]: category.portfolioGenre && category.portfolioGenre[0] && category.portfolioGenre[0].id,
                        [`selectedGenereName${index}`]: category.portfolioGenre && category.portfolioGenre[0] && category.portfolioGenre[0].generName,
                        [`selectedGenereCategoryId${index}`]: category.providerType.id,
                        [`genreData${category.providerType.id}`]: category.subcategories
                    })
                } else {
                    this.setState({
                        [`genersData${index}`]: null,
                    })
                }
            })

            this.setState({ portfolios: numberOfPortfolios, categoryIds: categoryIds || [] })
        }
    }

    render() {
        const {
            providerData,
            classes,
            isLoading
        } = this.props;
        const {
            isEditable,
            value,
            portfolios,
            categoryIds,
            allSelectedGenre
        } = this.state;
        return (
            <Formik
                initialValues={{
                    category: '',
                    genreData: [],
                    genre: '',
                }}
                onSubmit={(values) => {
                }}
            >
                {props => (
                    <HOCLoader loading={isLoading}>
                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <div class="row" style={{ marginLeft: 5 }}>
                                {isEditable &&
                                    <span className={isMobile ? 'w-100 profile-header-label ml-15' : 'w-100 profile-header-label ml-30'} style={{ color: 'black', padding: 0 }}>Upload your portfolio for your categories</span>
                                }
                            </div>
                            {portfolios && portfolios.map((item, index) => {
                                return (
                                    <Fragment>
                                        {isEditable ?
                                            <div class={isMobile ? "row mb-25 ml-15" : "row mb-25 ml-30"}>
                                                <div className="row col-md-12 p-0">
                                                    <div className={`col-md-3 col-xs-12 ${!isMobile && 'pr-0 ml-40'}`}>
                                                        <Select
                                                            name={`category${index}`}
                                                            id="category"
                                                            className="form-control form-control-select w-100 mt-2"
                                                            onChange={(e) => {
                                                                if (!categoryIds || (categoryIds && !categoryIds.includes(e.target.value))) {
                                                                    this.setState({ categoryIds: !categoryIds ? [e.target.value] : [...categoryIds, e.target.value] })
                                                                    props.setFieldValue(`category${index}`, e.target.value)
                                                                    this.setState({ [`selectedGenereCategoryId${index}`]: e.target.value })
                                                                    e.target.value && this.getGeneresData(e.target.value, index);
                                                                    providerData && providerData.providerTypes &&
                                                                        providerData.providerTypes.filter(item => item.providerType.id === e.target.value).map(category => {
                                                                            this.setState({ [`genreData${e.target.value}`]: category.subcategories })
                                                                        })
                                                                }
                                                            }}
                                                            value={this.state[`selectedGenereCategoryId${index}`]}
                                                        >
                                                            <Option value="">Select Category</Option>
                                                            {providerData && providerData.providerTypes && providerData.providerTypes.map(category => {
                                                                return (<Option value={`${category.providerType.id}`} key={category.providerType.name}>{category.providerType.name}</Option>)
                                                            })}
                                                        </Select>
                                                        {props.errors.category && props.touched.category && (
                                                            <div className="error-text">{props.errors.category}</div>
                                                        )}
                                                    </div>
                                                    <div className={`col-md-3 col-xs-12 ${!isMobile && 'pr-0 pl-30'}`}>
                                                        <Select
                                                            name="genre"
                                                            id="genre"
                                                            className="form-control form-control-select w-100 mt-2"
                                                            onChange={(e) => {
                                                                const { allSelectedGenre } = this.state
                                                                if (!this.state[`allSelectedGenre${index}`] || !this.state[`allSelectedGenre${index}`].length) {
                                                                    props.setFieldValue('genre', (e.target.value).split('-')[0])
                                                                    this.addGenereAction((e.target.value).split('-')[0], (e.target.value).split('-')[1], index)
                                                                    this.setState({ [`allSelectedGenre${index}`]: [{ genreName: (e.target.value).split('-')[0] }] })
                                                                } else {
                                                                    // console.log(this.state[`allSelectedGenre${index}`].filter((gen) => gen.genreName === (e.target.value).split('-')[0]))
                                                                    if (this.state[`allSelectedGenre${index}`] && !this.state[`allSelectedGenre${index}`].filter((gen) => gen.genreName === (e.target.value).split('-')[0]).length) {
                                                                        props.setFieldValue('genre', (e.target.value).split('-')[0])
                                                                        this.addGenereAction((e.target.value).split('-')[0], (e.target.value).split('-')[1], index)
                                                                        this.setState({ [`allSelectedGenre${index}`]: [...this.state[`allSelectedGenre${index}`], { genreName: (e.target.value).split('-')[0] }] })
                                                                    }
                                                                }
                                                            }}
                                                            value={props.values.genre}
                                                        >
                                                            <Option value="">Select Genre</Option>
                                                            {this.state[`genreData${this.state[`selectedGenereCategoryId${index}`]}`] && this.state[`genreData${this.state[`selectedGenereCategoryId${index}`]}`].map(genre => {
                                                                return (<Option value={`${genre.name}-${genre.id}`} key={genre.id}>{genre.name}</Option>)
                                                            })}
                                                        </Select>
                                                        {props.errors.genre && props.touched.genre && (
                                                            <div className="error-text">{props.errors.genre}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className={classes.root}>
                                                <Tabs
                                                    className="profile-tabs-dash tab-bottom-border"
                                                    value={this.state[`selectedGenereCategoryId${index}`]}
                                                    onChange={(event, value) => {
                                                        this.setState({ value });
                                                        // if (!categoryIds || (categoryIds && !categoryIds.includes(value))) {
                                                        //     this.setState({ categoryIds: !categoryIds ? [value] : [...categoryIds, value] })
                                                        props.setFieldValue(`category${index}`, value)
                                                        this.setState({ [`selectedGenereCategoryId${index}`]: value })
                                                        value && this.getGeneresData(value, index);
                                                        providerData && providerData.providerTypes &&
                                                            providerData.providerTypes.filter(item => item.providerType.id === value).map(category => {
                                                                this.setState({ [`genreData${value}`]: category.subcategories })
                                                            })
                                                        // }
                                                    }}
                                                    variant="scrollable"
                                                    scrollButtons="on"
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                // centered
                                                >
                                                    {providerData && providerData.providerTypes && providerData.providerTypes.map((category, key) => {
                                                        return (
                                                            <Tab
                                                                label={category.providerType.name}
                                                                icon={<span className={this.state[`selectedGenereCategoryId${index}`] === category.providerType.id ? "tab-count-active" : "tab-count-inactive"}>{key + 1}</span>}
                                                                value={category.providerType.id}
                                                                className={this.state[`selectedGenereCategoryId${index}`] === category.providerType.id ? 'tab-text-active' : 'tab-text-inactive'}
                                                            />
                                                        )
                                                    })}
                                                </Tabs>
                                            </div >}
                                        {!isEditable &&
                                            <h5 className={'w-100 profile-header-label ml-40 mb-0' + isMobile && 'ml-20'} style={{ color: 'black', padding: 0 }}>Select Genre</h5>
                                        }
                                        <div class={isMobile ? "row ml-10 mr-10" : "row ml-30"}>
                                            {this.state[`genersData${index}`] && this.state[`genersData${index}`].length > 0 &&
                                                this.state[`genersData${index}`].map(genere => {
                                                    return <div className={"col-md-2 pb-2 mt-20"} key={genere.id}>
                                                        <div className={`${isEditable ? 'genere-btn' : 'genere-button'} ${genere.generName === this.state[`selectedGenereName${index}`] ? 'active' : ''}`}
                                                            onClick={() => {
                                                                this.getSpecificGeneresData(genere.id, index);
                                                                this.setState({
                                                                    [`selectedGenere${index}`]: genere.id,
                                                                    [`selectedGenereName${index}`]: genere.generName,
                                                                    [`selectedGenereObj${index}`]: genere
                                                                })
                                                            }}
                                                        >{genere.generName}
                                                            {isEditable && <div
                                                                class={`${isMobile ? 'cross2-mobile' : 'cross2'} ${genere.id === this.state[`selectedGenere${index}`] ? 'active' : ''}`}
                                                                onClick={() => {
                                                                    this.removeGenere(genere.id, index, genere.generName);
                                                                    this.state[`genersData${index}`].length === 1 &&
                                                                        this.setState({
                                                                            [`genersData${index}`]: []
                                                                        })
                                                                }}
                                                            >X</div>}
                                                        </div>
                                                    </div>
                                                })}
                                        </div>
                                        <div className={isMobile ? "mb-30" : "ml-30 mb-30"}>
                                            {this.state[`selectedGenereObj${index}`] &&
                                                <PortfolioGallery
                                                    providerTypeId={this.state[`selectedGenereCategoryId${index}`]}
                                                    providerId={providerData && providerData.id}
                                                    providerTypeProfileData={this.state[`selectedGenereObj${index}`]}
                                                    selectedGenereId={this.state[`selectedGenere${index}`]}
                                                    selectedGenereName={this.state[`selectedGenereName${index}`]}
                                                    isEditable={isEditable}
                                                />
                                            }
                                        </div>
                                    </Fragment>
                                )
                            })}
                        </div>
                        {isEditable && providerData && providerData.providerTypes && (providerData.providerTypes.length > (categoryIds && categoryIds.length)) && <Fragment>
                            <Divider className="mt-5" />
                            <div className={isMobile ? "flex-direction-row flex-align-center mt-20 mb-20 ml-15" : "flex-direction-row flex-align-center mt-20 mb-20 ml-30"} onClick={() => {
                                this.setState({
                                    loaderButton: false,
                                    portfolios: [...portfolios, {}]
                                })
                            }}>
                                <span className="add-portfolio">+</span>
                                <span className="font-w600 font-14 ml-2">Add Another Portfolio</span>
                            </div>
                            <Divider />
                        </Fragment>}
                        <div className={isMobile ? 'ml-15 mt-15' : "mt-5 ml-30"}>
                            <WSButton
                                style={{
                                    backgroundColor: '#e24f36',
                                    color: '#ffffff',
                                    borderRadius: 30
                                }}
                                onClick={this.toggleScreen}
                            >
                                {isEditable ? 'Save Updates' : 'Edit Information'}
                            </WSButton>
                        </div>
                    </HOCLoader>
                )}
            </Formik>)
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        providerData: auth.providerData,
    };
};

const composedHOC = compose(
    withTheme(),
    withStyles(styles),
    connect(
        mapStateToProps,
        actions
    )
);

export default composedHOC(injectIntl(withRouter(withUser(ManageGener))));