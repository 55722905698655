import axios from 'axios';

import {
    errorResponse,
    validateApiData,
} from '../../utils/validators/validators';
import { SERVER_URL } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

export const userDetail = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/user/check`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const getLoggedInUser = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/user/check`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const getProviderType = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/user/providers/type/list`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerDetail = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/user/provider-detail`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const addAddress = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/user-address/create`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const updateAddress = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/user-address/update`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const deleteAddress = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/user-address/delete`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const updateProfile = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/profile/update-profile`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const proUpdateProfile = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider/update-profile`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const changePassword = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/user/change-password`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const markProviderFavorite = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post', //you can set what request you want to be
            url: `${SERVER_URL}/favoriteservice/create`,
            data: params,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const getWishlist = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/favorite/my`,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const addNewtoWishlist = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/favorite/create`,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const providerDashboardData = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider/dashboard`,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const providerCheck = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/profile/check-provider`,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const getBusinessHours = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/provider/timings`,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const updateTimingPacket = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/provider/update-timings`,
            data: params,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const getInboxDetails = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/inbox/detail`,
            data: params,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const refreshData = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post', //you can set what request you want to be
            url: `${SERVER_URL}/inbox/refresh`,
            data: params,
            headers: {
                'x-auth-Token': authToken,
            },
        });

        return validateApiData(data) ? data : errorResponse;
        // If data is not valid
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const sendMessage = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post', //you can set what request you want to be
            url: `${SERVER_URL}/inbox/add`,
            data: params,
            headers: {
                'x-auth-Token': authToken,
            },
        });

        return validateApiData(data) ? data : errorResponse;
        // If data is not valid
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const providerRating = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get', //you can set what request you want to be
            url: `${SERVER_URL}/rating/get`,

            headers: {
                'x-auth-Token': authToken,
            },
        });

        return validateApiData(data) ? data : errorResponse;
        // If data is not valid
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};
