import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import * as actions from '../../actions';

import DeleteAlert from '../Alert/DeleteAlert.jsx';
import WSButton from '../Button/WSButton';

import '../../assets/css/style.css';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const AddressListHead = props => {
    const createSortHandler = (property) => (event) => {
        const { onRequestSort } = props;
        onRequestSort(event, property);
    };

    const { formatMessage } = props;
    const rows = [
        {
            id: 'name',
            label: formatMessage({
                id: 'address.name',
            }),
        },
        {
            id: 'email',
            label: formatMessage({
                id: 'address.email',
            }),
        },
        {
            id: 'address_line1',
            label: formatMessage({
                id: 'address.address',
            }),
        },
        {
            id: 'city',
            label: formatMessage({
                id: 'address.city',
            }),
        },
        {
            id: 'state',
            label: formatMessage({
                id: 'address.state',
            }),
        },
        {
            id: 'country',
            label: formatMessage({
                id: 'address.country',
            }),
        },
        {
            id: 'phone',
            label: formatMessage({
                id: 'address.phone',
            }),
        },
        {
            id: 'is_default',
            label: formatMessage({
                id: 'address.is_default',
            }),
        },
        {
            id: 'action',
            label: formatMessage({
                id: 'address.action',
            }),
        },
    ];

    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
    } = props;

    return (
        <TableHead>
            <TableRow>
                {rows.map(
                    (row) => (
                        <TableCell
                            key={row?.id}
                            align={row?.numeric ? 'right' : 'left'}
                            padding={
                                row?.disablePadding ? 'none' : 'default'
                            }
                            sortDirection={
                                orderBy === row?.id ? order : false
                            }
                        >
                            <Tooltip
                                title="Sort"
                                placement={
                                    row?.numeric
                                        ? 'bottom-end'
                                        : 'bottom-start'
                                }
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={orderBy === row?.id}
                                    direction={order}
                                    onClick={createSortHandler(row?.id)}
                                >
                                    {row?.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this
                )}
            </TableRow>
        </TableHead>
    );
}

AddressListHead.propTypes = {
    numSelected: PropTypes?.number?.isRequired,
    onRequestSort: PropTypes?.func?.isRequired,
    onSelectAllClick: PropTypes?.func?.isRequired,
    order: PropTypes?.string?.isRequired,
    orderBy: PropTypes?.string?.isRequired,
    rowCount: PropTypes?.number?.isRequired,
};

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme?.spacing?.unit,
    },
    highlight:
        theme?.palette?.type === 'light'
            ? {
                color: theme?.palette?.secondary?.main,
                backgroundColor: lighten(theme?.palette?.secondary?.light, 0.85),
            }
            : {
                color: theme?.palette?.text?.primary,
                backgroundColor: theme?.palette?.secondary?.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme?.palette?.secondary?.main,
    },
    title: {
        flex: '0 0 auto',
    },
});

let AddressListToolbar = (props) => {
    const { numSelected, classes, deleteAction, match } = props;

    return (
        <Toolbar
            className={classNames(classes?.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes?.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected}&nbsp;
                        <FormattedMessage
                            id="selected"
                            defaultMessage="selected"
                        />
                    </Typography>
                ) : (
                    <h6
                        style={{
                            fontWeight: 500,
                            fontSize: isMobile ? '14px' : '16px',
                        }}
                    >
                        &nbsp;
                        <FormattedMessage
                            id="customer.myAddresses"
                            defaultMessage="My Addresses"
                        />
                    </h6>
                )}
            </div>
            <div className={classes?.spacer} />
            <div className={classes?.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton
                            aria-label="Delete"
                            onClick={deleteAddress}
                        >
                            <DeleteIcon onClick={deleteAction} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Add">
                        <WSButton
                            className="addNewButton"
                            variant="fab"
                            aria-label="Add"
                            style={{
                                padding: 0,
                                height: 40,
                            }}
                        >
                            <Link
                                style={{
                                    textDecoration: 'none',
                                    color: '#FFFFFF',
                                    fontSize: '14px',
                                }}
                                to={`/user/address/create`}
                            >
                                Add New
                            </Link>
                        </WSButton>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

AddressListToolbar.propTypes = {
    classes: PropTypes?.object?.isRequired,
    numSelected: PropTypes?.number?.isRequired,
};

AddressListToolbar = withStyles(toolbarStyles)(AddressListToolbar);

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme?.spacing?.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 600,
    },
});

const AddressList = props => {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState(props?.userData?.addresses);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [clickValue, setClickValue] = useState('');
    const [item, setItem] = useState('');
    const [itemId, setItemId] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [defaultAddressvalue, setDefaultAddressvalue] = useState('');
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [selectedDeleteKey, setSelectedDeleteKey] = useState('');
    const [menuState, setMenuState] = useState(false);
    const [loader, setLoader] = useState(false);
    const actionCallbacks = (action, res) => {
        if (action === 'update') {
            setClickValue('update')
            setItem(res)
            setMenuState(false)
            setItemId(res?.key)
        }
        if (action === 'delete') {
            setSelectedDeleteKey(res?.key)
            setMenuState(false),
                deleteRecord()
        }
    }
    useEffect(() => {
        const { userData } = props;
        setData(userData?.addresses)
    }, []);


    const handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (orderBy === property && order === 'desc') {
            order = 'asc';
        }
        setOrder(order)
        setOrderBy(orderBy)
    };

    const handleSelectAllClick = (event) => {
        if (event?.target?.checked) {
            setSelected(data.map((n) => n?.key))
            return;
        }
        setSelected([])
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected)
    };

    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event?.target?.value)
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const deleteRecord = () => {
        setShowAlert(true)
    };

    const deleteAddress = () => {
        const { deleteAddressAction } = props;
        deleteAddressAction(
            {
                id: selectedDeleteKey,
            },
            (success, data) => onDeleteDone(success, data)
        );
    };

    const onDeleteDone = (success, data) => {
        setLoader(false)
        if (success) {
            setShowAlert(false)
            NotificationManager.success(data?.message, 'Success');
        }
    };

    const renderDefaultAddress = () => {
        const defaultAddressvalue = data.filter(
            (address) => address?.is_default === 1
        )[0];
        if (defaultAddressvalue) {
            return (
                <div>
                    <h4>
                        <b>
                            &nbsp;
                            <FormattedMessage
                                id="defaultAddress"
                                defaultMessage="Default Address"
                            />
                        </b>
                    </h4>
                    <p>{defaultAddressvalue?.name},</p>
                    <p>
                        {defaultAddressvalue?.phone},<br />
                        {defaultAddressvalue?.email},<br />
                        {defaultAddressvalue?.address_line1},
                        {defaultAddressvalue?.city}, {defaultAddressvalue?.state}
                        ,&nbsp;
                        {defaultAddressvalue?.country}
                    </p>
                </div>
            );
        }
    };

    const { classes, match, intl } = props;
    const { formatMessage } = intl;
    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, data?.length - page * rowsPerPage);
    if (clickValue) {
        return (
            <Redirect
                to={{
                    pathname: `/user/address/update/${itemId}`,
                    state: {
                        item: item,
                        fromPage:
                            window?.location?.pathname === '/user/profile'
                                ? 'myProfile'
                                : 'myAddress',
                    },
                }}
            />
        );
    }
    return (
        <div>
            <Paper className={classes?.root}>
                <AddressListToolbar
                    numSelected={selected?.length}
                    deleteAction={deleteRecord}
                    match={match}
                />
                <div className="table-responsive">
                    <Table className="table" aria-labelledby="tableTitle">
                        <AddressListHead
                            numSelected={selected?.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data?.length}
                            formatMessage={formatMessage}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((n) => {
                                    const isSelect = isSelected(
                                        n?.key
                                    );
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelect}
                                            tabIndex={-1}
                                            key={n?.key}
                                            selected={isSelect}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {n?.name}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {n?.email}
                                            </TableCell>
                                            <TableCell>
                                                {n?.address_line1}
                                            </TableCell>
                                            <TableCell>{n?.city}</TableCell>
                                            <TableCell>{n?.state}</TableCell>
                                            <TableCell>
                                                {n?.country}
                                            </TableCell>
                                            <TableCell>{n?.phone}</TableCell>
                                            <TableCell>
                                                {n?.is_default ? (
                                                    <CheckCircleIcon />
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {data?.length > 1 ? (
                                                    <div>
                                                        <WSButton
                                                            id="update"
                                                            className="mr-10 mb-5"
                                                            variant="outlined"
                                                            onClick={() =>
                                                                actionCallbacks(
                                                                    'update',
                                                                    n
                                                                )
                                                            }
                                                        >
                                                            Update
                                                            </WSButton>
                                                        <WSButton
                                                            id="delete"
                                                            className="mb-5"
                                                            variant="outlined"
                                                            onClick={() =>
                                                                actionCallbacks(
                                                                    'delete',
                                                                    n
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                            </WSButton>
                                                    </div>
                                                ) : (
                                                    <WSButton
                                                        id="update"
                                                        variant="outlined"
                                                        onClick={() =>
                                                            actionCallbacks(
                                                                'update',
                                                                n
                                                            )
                                                        }
                                                    >
                                                        Update
                                                    </WSButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{ height: 49 * emptyRows }}
                                >
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    className="dashboard-table-pagination"
                    classes={{
                        selectIcon: 'tablePaginationSelectIcon',
                    }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <DeleteAlert
                open={showAlert}
                title="address"
                close={() => setShowAlert(false)}
                handleYes={deleteAddress}
                handleNo={() => setShowAlert(false)}
            />
        </div>
    );
}

AddressList.propTypes = {
    classes: PropTypes?.object?.isRequired,
};

const composedComponent = compose(withStyles(styles), connect(null, actions));

export default composedComponent(injectIntl(AddressList));
