import React, { Component, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper } from '@material-ui/core';

import * as actions from '../../actions';

import { validateCustomerForm } from '../../utils/validators/validateForm';

import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

import 'react-image-crop/dist/ReactCrop.css';

const handleDropRejected = (...args) => console.log('reject', args);
function AddServiceMedia(props) {

    const [error, setError] = useState('')
    const [loader, setLoader] = useState(false)
    const [loaderButton, setLoaderButton] = useState(false)
    const [pass, setPass] = useState(false)
    const [noResponse, setNoResponse] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [preview, setPreview] = useState(null)
    const [generalPhotos, setGeneralPhotos] = useState('')
    const [status, setStatus] = useState(1)
    const [crop, setCrop] = useState({ aspect: 1 / 1 })
    const [is_blocked, setIs_blocked] = useState(false)
    const [is_deleted, setIs_deleted] = useState(false)
    const [customerDetail, setCustomerDetail] = useState('')
    const [existingImage, setExistingImage] = useState('')
    const [files, setFiles] = useState([])
    const [showOld, setShowOld] = useState(true)
    const [images, setImages] = useState([{ index: 0, image: require('../../assets/img/uploadPlaceHolder.png') }, { index: 1, image: require('../../assets/img/uploadPlaceHolder.png') }, { index: 2, image: require('../../assets/img/uploadPlaceHolder.png') }, { index: 3, image: require('../../assets/img/uploadPlaceHolder.png') }])
    const [isPickerVisible, setIsPickerVisible] = useState(false)
    const [urls, setUrls] = useState([{ url: '', urlError: '' }, { url: '', urlError: '' }, { url: '', urlError: '' }])

    const onImageLoaded = (image, pixelCrop) => {
        imageRef = image;
    };

    const submit = () => {
        const { updateProfileAction, user } = props;
        if (validateCustomerForm({ name, email }, handleError)) {
            setLoaderButton(true)
            updateProfileAction(
                {
                    id: user?.id,
                    name,
                    email,
                    phone
                },
                onDone
            );
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Oops!'
            );
        }
    };

    const handleError = params => {
        // setState(params);
    };

    const onDone = (success, data) => {
        const { updateDone } = props;
        setLoaderButton(false)
        if (success) {
            NotificationManager.success(data?.message, 'Updated Successfully');
            updateDone();
        } else {
            NotificationManager.error(data, 'Error');
        }
    };

    const renderButton = () => {
        if (loaderButton) {
            return <CircularProgress />;
        }
        return (
            <WSButton
                variant="raised"
                className="jr-btn"
                onClick={submit}
            >
                <FormattedMessage
                    id="customer.update"
                    defaultMessage="Update"
                />
            </WSButton>
        );
    };

    const handleDrop = (files) => {
        if (files?.length <= 4) {
            setFiles(files.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            ))
            setShowOld(false)
        } else {
            NotificationManager.error(
                'You cannot add more than 4 images',
                'Error'
            );
        }
    }

    const onCropComplete = async (crop, pixelCrop) => {
        if (crop?.width && crop?.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef,
                pixelCrop,
                'newFile.jpeg'
            );
            setCroppedImageUrl(croppedImageUrl)
        }
    };

    const getCroppedImg = (image, pixelCrop, fileName) => {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop?.width;
        canvas.height = pixelCrop?.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            pixelCrop?.x,
            pixelCrop?.y,
            pixelCrop?.width,
            pixelCrop?.height,
            0,
            0,
            pixelCrop?.width,
            pixelCrop?.height
        );
        return new Promise((resolve, reject) => {
            fileUrl = canvas.toDataURL();
            resolve(fileUrl);
        });
    }

    const uploadPhoto = index => {
        setIsPickerVisible(true)
        setCurrentPhoto(index)
    };

    const onImageSelected = image => {
        const { uploadServicePhotoAction } = props;
        const images = images;
        images[currentPhoto] = {
            image: {
                uri: image?.path
            },
            index: currentPhoto
        };
        setImages(images)
        setIsPickerVisible(false)
        uploadServicePhotoAction(
            {
                image,
                index: currentPhoto,
                categoryId: categoryId,
                serviceId: serviceId
            },
            (success, error) => onImageUploaded(success, error)
        );
    };

    const onImageUploaded = (success, message) => {
        if (success) {
            return onSuccessPress(message);
        }
    };

    const renderImageUpload = () => {
        const thumbs = files.map(file => (
            <div>
                <h3>Media</h3>
                <ReactCrop
                    src={file?.preview}
                    imageStyle={{
                        width: '100%',
                        height: '100%'
                    }}
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    crop={crop}
                    onChange={crop => {
                        setCrop(crop)
                    }}
                />
            </div>
        ));
        const baseStyle = {
            width: 200,
            height: 200,
            borderWidth: 2,
            borderColor: '#666',
            borderStyle: 'dashed',
            borderRadius: 5
        };
        const activeStyle = {
            borderStyle: 'solid',
            borderColor: '#6c6',
            backgroundColor: '#eee'
        };
        const rejectStyle = {
            borderStyle: 'solid',
            borderColor: '#c66',
            backgroundColor: '#eee'
        };
        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-2">
                        <h5 className={isMobile && 'font-14'}>
                            <FormattedMessage
                                id="customer.drop"
                                defaultMessage="Drop Image here to Upload"
                            />
                        </h5>
                        <Dropzone
                            onDrop={handleDrop}
                            accept="image/jpeg,image/jpg,image/png"
                            multiple={false}
                            onDropRejected={handleDropRejected}
                        >
                            {({
                                getRootProps,
                                getInputProps,
                                isDragActive,
                                isDragAccept,
                                isDragReject,
                                acceptedFiles,
                                rejectedFiles
                            }) => {
                                let styles = { ...baseStyle };
                                styles = isDragActive
                                    ? { ...styles, ...activeStyle }
                                    : styles;
                                styles = isDragReject
                                    ? { ...styles, ...rejectStyle }
                                    : styles;

                                {
                                    return (
                                        <div {...getRootProps()} style={styles}>
                                            <input {...getInputProps()} />
                                            <p>
                                                <FormattedMessage
                                                    id="customer.dropfiles"
                                                    defaultMessage="Drop files here, or click to select files"
                                                />
                                            </p>
                                        </div>
                                    );
                                }
                            }}
                        </Dropzone>
                    </div>
                    <div className="col-md-5">{thumbs}</div>
                    <div className="col-md-5">
                        {croppedImageUrl && (
                            <div>
                                <h3>
                                    <FormattedMessage
                                        id="customer.finalimage"
                                        defaultMessage="Final Image"
                                    />
                                </h3>
                                <LazyLoadImage
                                    width={'200px'}
                                    height={'200px'}
                                    alt="Crop"
                                    src={croppedImageUrl}
                                />
                                <br />
                                <WSButton
                                    variant="outlined"
                                    onClick={uploadMediaPicture}
                                >
                                    Upload
                                </WSButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderVideoUpload = () => {
        return (
            <div style={{ justifyContent: 'center' }} className="col-md-12">
                {urls.map((url, index) => {
                    return (
                        <div key={index} className="col-md-6">
                            <WSTextField
                                error={urls[index]?.urlError}
                                helperText={urls[index]?.urlError}
                                name="name"
                                label="Youtube Link"
                                id="name"
                                placeholder="Your youtube URL"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={event => {
                                    const urls = urls;
                                    urls[index].url = url;
                                    urls[index].urlError = '';
                                    setUrls(urls)
                                }}
                                defaultValue={urls[index]?.url}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="col-md-12">
            <Paper>
                <form
                    className="row"
                    noValidate
                    autoComplete="off"
                    id="add-media"
                >
                    <div className="col-md-12">
                        {renderImageUpload()}
                    </div>
                    <div className="col-md-12">
                        {renderVideoUpload()}
                    </div>
                    <div className="col-md-12 col-xs-12">
                        <div className="mt-3 mb-3">
                            <div className="col-md-12">
                                {renderButton()}
                            </div>
                        </div>
                    </div>
                </form>
            </Paper>
        </div>
    );
}

export default connect(
    null,
    actions
)(injectIntl(AddServiceMedia));