import axios from 'axios';

import {
    errorResponse,
    validateApiData,
} from '../../utils/validators/validators';
import { SERVER_URL } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

export const favouriteList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/favorite/my`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};
