import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import WSButton from '../../components/Button/WSButton.jsx';

import MainProfile from './MainProfile.jsx';
import UpdateProfile from './UpdateProfile.jsx';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};

class Profile extends Component {
    constructor() {
        super();
        this.state = { update: false };
    }

    componentDidMount() {
        if (window.location.pathname === '/user/profile/edit') {
            this.setState({ update: true });
        }
    }

    profileUpdate = () => {
        const { history } = this.props;
        if (window.location.pathname === '/user/profile') {
            history.push({
                pathname: `/user/profile/edit`,
            });
            this.setState({ update: true });
        }
    };

    showProfile = () => {
        const { history } = this.props;
        if (window.location.pathname === '/user/profile/edit') {
            history.push({
                pathname: `/user/profile`,
            });
            this.setState({ update: false });
        }
    };

    updateDone = () => {
        const { history } = this.props;
        if (window.location.pathname === '/user/profile/edit') {
            history.push({
                pathname: `/user/profile`,
            });
        } else {
            this.setState({ update: false });
        }
    };

    render() {
        const { classes, userData, providerTypeData } = this.props;
        const { update } = this.state;
        return (
            <div>
                <div className={isMobile ? 'row mb-2' : 'row mb-4'}>
                    <div className="col-md-12">
                        <Card className={classes.card}>
                            <Toolbar>
                                {!update ? (
                                    <h3 className={isMobile && 'font-18'}>
                                        <FormattedMessage
                                            id="customer.profile"
                                            defaultMessage="My Profile"
                                        />
                                    </h3>
                                ) : (
                                    <h3 className={isMobile && 'font-18'}>
                                        <FormattedMessage
                                            id="customer.updateprofile"
                                            defaultMessage="Update Profile"
                                        />
                                    </h3>
                                )}
                                <div
                                    className="text-right"
                                    style={{ marginLeft: 'auto' }}
                                >
                                    {!update ? (
                                        <Fragment></Fragment>
                                    ) : (
                                        <WSButton
                                            onClick={this.showProfile}
                                            className="addNewButton w-180"
                                            style={{ fontSize: '14px' }}
                                        >
                                            <FormattedMessage
                                                id="customer.view_profile"
                                                defaultMessage="View Profile"
                                            />
                                        </WSButton>
                                    )}
                                </div>
                            </Toolbar>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {!update ? (
                            <MainProfile {...sendProps(this.props)} />
                        ) : (
                            <UpdateProfile
                                user={userData}
                                providerTypeData={providerTypeData}
                                updateDone={this.updateDone}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const composedProfile = compose(withStyles(styles));

export default composedProfile(withRouter(Profile));
