import React from 'react';
import UserCell from './UserCell/index';

const ChatUserList = ({
    chatUsers,
    selectedSectionId,
    onSelectUser,
    userData
}) => {
    return (
        <div className="chat-user">
            {chatUsers.map((chat, index) => (
                <UserCell
                    userData={userData}
                    style={{ cursor: 'pointer' }}
                    key={index}
                    chat={chat}
                    selectedSectionId={selectedSectionId}
                    onSelectUser={onSelectUser}
                />
            ))}
        </div>
    );
};

export default ChatUserList;
