import React, { Component } from 'react';
import FavouriteExpansion from './FavouriteExpansion';

export function CustomerFavoriteList(props) {
    return (
        <div>
            <FavouriteExpansion />
        </div>
    );
}

export default CustomerFavoriteList;
