import Payment from 'payment';
import moment from 'moment';
const weekday = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
export const APP_NAME = 'PHOTOPLAN8';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;
export const GOOGLE_MAP_KEY = 'AIzaSyB8Ea9V7TEhGrhgXnQDM2M9dr0t5P7moMk';
export const IMGGUR_KEY = '4068357ad7063c3';
export const STRIPE_SECRET_KEY = 'sk_test_3GqtSpULXX0N9FJpgJRCbv42';
export const STRIPE_PUBLIC_KEY = 'pk_test_LezvdYhziviCQAXniVe85AH0';
export const ROLE_PROVIDER = 840;
export const ROLE_CUSTOMER = 420;
export const SOCIAL_TYPE_FACEBOOK = 1;
export const SOCIAL_TYPE_GOOGLE = 2;
export const SOCIAL_TYPE_INSTAGRAM = 3;

export const PAYMENT_SUCCESS = 1;
export const PAYMENT_FAILED = 2;
export const PAYMENT_PENDING_CASH = 3;
export const PAYMENT_CANCELLED = 4;
export const PAYMENT_PENDING_CARD = 5;
export const PAYMENT_ON_HOLD = 6;
export const PAYMENT_COMPLETE = 7;
export const PAYMENT_EXPIRED = 8;
export const PAYMENT_DECLINED = 10;

export const getBookingPaymentStatus = (number) => {
    switch (number) {
        case PAYMENT_SUCCESS:
            return 'Successful';
        case PAYMENT_FAILED:
            return 'Failed';
        case PAYMENT_PENDING_CASH:
            return 'Pending';
        case PAYMENT_CANCELLED:
            return 'Cancelled';
        case PAYMENT_PENDING_CARD:
            return 'Pending';
        case PAYMENT_ON_HOLD:
            return 'Hold';
        case PAYMENT_EXPIRED:
            return 'Expired';
        case PAYMENT_DECLINED:
            return 'Declined';
        case PAYMENT_COMPLETE:
            return 'Complete';

        default:
            return 'Pending';
    }
};

export const getBookingPaymentStatusClass = (number) => {
    switch (number) {
        case PAYMENT_SUCCESS:
            return 'badge text-uppercase text-white bg-success';
        case PAYMENT_FAILED:
            return 'badge text-uppercase text-white bg-danger';
        case PAYMENT_PENDING_CASH:
            return 'badge text-uppercase text-white bg-warning';
        case PAYMENT_CANCELLED:
            return 'badge text-uppercase text-white bg-danger';
        case PAYMENT_PENDING_CARD:
            return 'badge text-uppercase text-white bg-warning';
        case PAYMENT_ON_HOLD:
            return 'badge text-uppercase text-white bg-warning';
        case PAYMENT_DECLINED:
            return 'badge text-uppercase text-white bg-danger';
        case PAYMENT_COMPLETE:
            return 'badge text-uppercase text-white bg-success';

        default:
            return 'badge text-uppercase text-white bg-warning';
    }
};

export const getLanguages = [
    'English',
    'Arabic',
    'Hindi',
    'French',
    'Spanish',
    'Russian',
    'German',
    'Italian',
    'Dutch',
    'Chinese',
    'Urdu',
    'Portugese',
    'Turkish',
    'Tagalog',
    'Japanese',
    'Korean',
];

export const getPhotographyGenre = [
    'Landscape',
    'Street',
    'Nightclub/ Parties',
    'Events',
    'Wedding',
    'Birthday',
    'Travel',
    'Tours',
    'Workshops',
    'Studio',
    'Portraits',
    'Lifestyle',
    'Fashion',
    'Food',
    'Product',
    'Interior',
    'Architecture',
    'Newborn',
    'Maternity',
    'E-commerce',
    'Instagram shots',
    'Corporate',
    'Family',
    'Wildlife',
    'Aerial/Drone',
    'Conceptual / Fine Art',
    'Photojournalism',
];

export const getPhotographerLocation = [
    'Dubai',
    'Abu Dhabi',
    'Sharjah',
    'Al Ain',
    'Ras Al Khaimah',
    'Ajman',
    'UAQ',
    'Outside UAE',
    'International',
];

export const getCameraBrandList = {
    Canon: [
        'Canon 5DMK4',
        'Canon 5DMK3',
        'Canon 1DXM2',
        'Canon 1DX',
        'Canon 1DS',
        'Canon 5DS / 5DSR',
        'Canon 6DM2',
        'Canon EOS R',
        'Canon EOS RP',
        'Canon Other',
    ],
    Nikon: [
        'Nikon D850',
        'Nikon D810',
        'Nikon D750',
        'Nikon D610',
        'Nikon D600',
        'Nikon D500',
        'Nikon D5',
        'Nikon Z7',
        'Nikon Z6',
        'Nikon Other',
    ],
    Sony: [
        'Sony A9',
        'Sony A7RM3',
        'Sony A7RM2',
        'Sony A7SM2',
        'Sony A7M2',
        'Sony A6500',
        'Sony A7R',
        'Sony A7S',
        'Sony A6400/A6300',
        'Sony Other',
    ],
    Fujifilm: [
        'Fujifilm GFX50s',
        'Fujifilm XT3',
        'Fujifilm XT2',
        'Fujifilm XT1',
        'Fujifilm Other',
    ],
    Hasselblad: ['Hasselblad	H6D-100C', 'Hasselblad	X1D-50C', 'Hasselblad	Other'],
    Olympus: [
        'Olympus OM-D E-M1X',
        'Olympus OM-D E-M5 Mark II',
        'Olympus OM-D E-M10 Mark III',
        'Olympus Other',
    ],
    Panasonic: [
        'Panasonic LUMIX S1R',
        'Panasonic LUMIX S1',
        'Panasonic LUMIX G95',
        'Panasonic LUMIX GH5s',
        'Panasonic LUMIX GH5',
        'Panasonic LUMIX G9',
        'Panasonic LUMIX GX9',
        'Panasonic Other',
    ],
};

export const getCameraModelList = [];

export const getvideographyGenreList = [
    'Advertising',
    'Corporate',
    'Nightclub/ Parties',
    'Events',
    'Wedding',
    'Birthday',
    'Travel',
    'Tours',
    'Workshops',
    'BTS',
    'Documentary',
    'Action',
    'Interview',
    'Food',
    'Product',
    'Interior',
    'Architecture',
    'Education',
    'Testimonials',
    'E-commerce',
    'Instagram videos',
    'Corporate',
    'Family',
    'Wildlife',
    'Aerial/Drone',
    'Conceptual / Fine Art',
    'Photojournalism',
    'Other',
];

export const getmodelgenre = [
    'Fashion',
    'Lifestyle',
    'Events',
    'Street',
    'E-commerce',
    'Runway',
    'Commercial Model',
    'Plus-size',
    'Petite',
    'Child',
    'Swimsuit / Lingerie',
    'Sports',
    'Glamour',
    'Fitness',
    'Promotional Model',
    'Mature',
    'Parts (Hand, Foot)',
];

export const getSkinColor = ['Dark', 'Fair', 'Brown', 'White', 'Other'];

export const getHairType = [
    'Wavy',
    'Curly',
    'Coily',
    'Straight',
    'Kinky',
    'Other',
];

export const getHairColor = ['Black', 'Brown', 'Blonde', 'Red', 'Other'];

export const getEyeColor = [
    'Black',
    'Dark Brown',
    'Light Brown',
    'Hazel',
    'Blue',
    'Green',
    'Gray',
    'Amber',
    'Other',
];

export const getEthinicity = [
    'African',
    'Arabic',
    'Europian',
    'Indian',
    'Mediterranean',
    'Oriental',
    'Other',
];

export const getModelSpecialisation = [
    'Actor',
    'Host',
    'MC',
    'Fashion Shows',
    'Influencer',
    'Television Celebrity',
];

export const BOOKING_STATUS_INCART = 1;
export const BOOKING_STATUS_INITIATED = 2;
export const BOOKING_STATUS_CANCELLED = 3;
export const BOOKING_STATUS_REJECTED = 4;
export const BOOKING_STATUS_CONFIRMED = 5;
export const BOOKING_STATUS_START = 6;
export const BOOKING_STATUS_PAUSED = 7;
export const BOOKING_STATUS_END = 8;
export const BOOKING_STATUS_COMPLETED = 9;

export const getBookingStatus = (number) => {
    switch (number) {
        case BOOKING_STATUS_INCART:
            return 'Incart';
        case BOOKING_STATUS_INITIATED:
            return 'Initiated';
        case BOOKING_STATUS_CANCELLED:
            return 'Cancelled';
        case BOOKING_STATUS_REJECTED:
            return 'Rejected';
        case BOOKING_STATUS_CONFIRMED:
            return 'Confirmed';
        case BOOKING_STATUS_START:
            return 'Started';
        case BOOKING_STATUS_PAUSED:
            return 'Paused';
        case BOOKING_STATUS_END:
            return 'Ended';
        case BOOKING_STATUS_COMPLETED:
            return 'Completed';
        default:
            return 'Cancelled';
    }
};

export const getBookingStatusClass = (number) => {
    switch (number) {
        case 'Incart':
            return 'badge text-uppercase text-white bg-warning';
        case 'Initiated':
            return 'badge text-uppercase text-white bg-info';
        case 'Cancelled':
            return 'badge text-uppercase text-white bg-danger';
        case 'Rejected':
            return 'badge text-uppercase text-white bg-danger';
        case 'Confirmed':
            return 'badge text-uppercase text-white bg-info';
        case 'Started':
            return 'badge text-uppercase text-white bg-success';
        case 'Paused':
            return 'badge text-uppercase text-white bg-warning';
        case 'Ended':
            return 'badge text-uppercase text-white bg-success';
        case 'Completed':
            return 'badge text-uppercase text-white bg-success';

        default:
            return 'badge text-uppercase text-white bg-warning';
    }
};

export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = 2;
export const getStatusClass = (number) => {
    switch (number) {
        case 1:
            return 'badge text-uppercase text-white bg-success';
        case 2:
            return 'badge text-uppercase text-white bg-warning';

        default:
            return 'badge text-uppercase text-white bg-success';
    }
};

export const getStatus = (number) => {
    switch (number) {
        case STATUS_ACTIVE:
            return 'ACTIVE';
        case STATUS_INACTIVE:
            return 'INACTIVE';

        default:
            return 'ACTIVE';
    }
};

export const TRANSACTION_INVALID_CARD = 1;
export const TRANSACTION_FAILED = 2;
export const TRANSACTION_PENDING = 3;
export const TRANSACTION_CANCELLED = 4;
export const TRANSACTION_SUCCESS = 5;

export const getTransactionStatus = (number) => {
    switch (number) {
        case TRANSACTION_INVALID_CARD:
            return 'Failed Due to Invalid Card Used';
        case TRANSACTION_FAILED:
            return 'Failed';
        case TRANSACTION_PENDING:
            return 'Pending';
        case TRANSACTION_CANCELLED:
            return 'Cancelled';
        case TRANSACTION_SUCCESS:
            return 'Sucessful';

        default:
            return 'Pending';
    }
};

export const getTransactionStatusClass = (number) => {
    switch (number) {
        case TRANSACTION_INVALID_CARD:
            return 'badge text-uppercase text-white bg-warning';
        case TRANSACTION_FAILED:
            return 'badge text-uppercase text-white bg-danger';
        case TRANSACTION_PENDING:
            return 'badge text-uppercase text-white bg-info';
        case TRANSACTION_CANCELLED:
            return 'badge text-uppercase text-white bg-danger';
        case TRANSACTION_SUCCESS:
            return 'badge text-uppercase text-white bg-success';
        default:
            return 'badge text-uppercase text-white bg-warning';
    }
};

export const ar = 'Average Rating';
export const r1 = 'Tools';
export const r2 = 'Work Quality';
export const r3 = 'Behaviour';

export const getRGBA1Value = (rgb) => {
    const r = rgb.r;
    const g = rgb.g;
    const b = rgb.b;

    return 'rgba(' + r + ',' + g + ',' + b + ',1)';
};
export const getRGBA2Value = (rgb) => {
    const r = rgb.r;
    const g = rgb.g;
    const b = rgb.b;

    return 'rgba(' + r + ',' + g + ',' + b + ',0)';
};

export const BOOKING_ACCEPTED = 'Booking accepted message';
export const BOOKING_COMPLETED_CUSTOMER =
    'Booking completed by customer message';
export const BOOKING_COMPLETED_PROVIDER =
    'Booking completed by provider message';
export const BOOKING_DELAYED_REMINDER =
    'Booking no action taken(accept/reject)';
export const BOOKING_ENDED = 'Booking ends message';
export const BOOKING_MINS_REMINDER = 'Booking reminder message to provider';
export const BOOKING_PAUSED = 'Booking paused message';
export const BOOKING_REJECTED = 'Booking rejected message';
export const BOOKING_RESTARTED = 'Booking restarted message';
export const NEW_BOOKING_REQUEST = 'New Booking request message';

export const getNotificationsMessageLabel = (value) => {
    value = value + '';
    switch (value) {
        case 'BOOKING_ACCEPTED':
            return 'Booking accepted message';
        case 'BOOKING_COMPLETED_CUSTOMER':
            return 'Booking completed by customer message';
        case 'BOOKING_DELAYED_REMINDER':
            return 'Booking no action taken(accept/reject)';
        case 'BOOKING_ENDED':
            return 'Booking ends message';
        case 'BOOKING_MINS_REMINDER':
            return 'Booking reminder message to provider';
        case 'BOOKING_PAUSED':
            return 'Booking paused message';
        case 'BOOKING_REJECTED':
            return 'Booking rejected message';
        case 'BOOKING_RESTARTED':
            return 'Booking restarted message';
        case 'NEW_BOOKING_REQUEST':
            return 'New Booking request message';
        default:
            return 'New booking Status found';
    }
};

export const BOOKING_TYPE_CASH = 1;
export const BOOKING_TYPE_CARD = 2;
export const BOOKING_TYPE_WALLET = 3;
export const getPaymentTypeClass = (number) => {
    switch (number) {
        case BOOKING_TYPE_CASH:
            return 'badge text-uppercase text-white bg-success';
        case BOOKING_TYPE_CARD:
            return 'badge text-uppercase text-white bg-warning';
        case BOOKING_TYPE_WALLET:
            return 'badge text-uppercase text-white bg-info';
        default:
            return 'badge text-uppercase text-white bg-warning';
    }
};

export const getPaymentType = (number) => {
    switch (number) {
        case BOOKING_TYPE_CASH:
            return 'Cash Payment';
        case BOOKING_TYPE_CARD:
            return 'Card Payment';
        case BOOKING_TYPE_WALLET:
            return 'Wallet Payment';

        default:
            return 'None';
    }
};

export const getYoutubeUrl = (url) => {
    let videoid = url.match(
        /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
    );
    if (videoid !== null) {
        return videoid[1];
    }
};

export const PROMO_TYPE_SINGLE = 1;
export const PROMO_TYPE_SINGLE_PER_USER = 2;
export const PROMO_TYPE_UNLIMITED = 3;

export const PROMO_OFFERTYPE_APP = 1;
export const PROMO_OFFERTYPE_PROMOTION = 2;

export const PROMO_ADMIN_TYPE = 1;
export const PROMO_PROVIDER_TYPE = 2;

export const getPromoTypeOptions = (number) => {
    switch (number) {
        case PROMO_TYPE_SINGLE:
            return 'Single Usage';
        case PROMO_TYPE_SINGLE_PER_USER:
            return 'Single Use per User';
        case PROMO_TYPE_UNLIMITED:
            return 'Unlimited Usage';

        default:
            return 'None';
    }
};

export const getPromoOfferTypeOptions = (number) => {
    switch (number) {
        case PROMO_OFFERTYPE_APP:
            return 'App Offer';
        case PROMO_OFFERTYPE_PROMOTION:
            return 'Promotion Type Offer';

        default:
            return 'None';
    }
};

export const getPromoUserTypeOptions = (number) => {
    switch (number) {
        case PROMO_ADMIN_TYPE:
            return 'Admin';
        case PROMO_PROVIDER_TYPE:
            return 'Provider';

        default:
            return 'None';
    }
};

export const countries = [
    { name: 'ANDORRA', code: '376', short: 'AD' },
    { name: 'UNITED ARAB EMIRATES', code: '971', short: 'AE' },
    { name: 'AFGHANISTAN', code: '93', short: 'AF' },
    { name: 'ANTIGUA AND BARBUDA', code: '1268', short: 'AG' },
    { name: 'ANGUILLA', code: '1264', short: 'AI' },
    { name: 'ALBANIA', code: '355', short: 'AL' },
    { name: 'ARMENIA', code: '374', short: 'AM' },
    { name: 'NETHERLANDS ANTILLES', code: '599', short: 'AN' },
    { name: 'ANGOLA', code: '244', short: 'AO' },
    { name: 'ANTARCTICA', code: '672', short: 'AQ' },
    { name: 'ARGENTINA', code: '54', short: 'AR' },
    { name: 'AMERICAN SAMOA', code: '1684', short: 'AS' },
    { name: 'AUSTRIA', code: '43', short: 'AT' },
    { name: 'AUSTRALIA', code: '61', short: 'AU' },
    { name: 'ARUBA', code: '297', short: 'AW' },
    { name: 'AZERBAIJAN', code: '994', short: 'AZ' },
    { name: 'BOSNIA AND HERZEGOVINA', code: '387', short: 'BA' },
    { name: 'BARBADOS', code: '1246', short: 'BB' },
    { name: 'BANGLADESH', code: '880', short: 'BD' },
    { name: 'BELGIUM', code: '32', short: 'BE' },
    { name: 'BURKINA FASO', code: '226', short: 'BF' },
    { name: 'BULGARIA', code: '359', short: 'BG' },
    { name: 'BAHRAIN', code: '973', short: 'BH' },
    { name: 'BURUNDI', code: '257', short: 'BI' },
    { name: 'BENIN', code: '229', short: 'BJ' },
    { name: 'SAINT BARTHELEMY', code: '590', short: 'BL' },
    { name: 'BERMUDA', code: '1441', short: 'BM' },
    { name: 'BRUNEI DARUSSALAM', code: '673', short: 'BN' },
    { name: 'BOLIVIA', code: '591', short: 'BO' },
    { name: 'BRAZIL', code: '55', short: 'BR' },
    { name: 'BAHAMAS', code: '1242', short: 'BS' },
    { name: 'BHUTAN', code: '975', short: 'BT' },
    { name: 'BOTSWANA', code: '267', short: 'BW' },
    { name: 'BELARUS', code: '375', short: 'BY' },
    { name: 'BELIZE', code: '501', short: 'BZ' },
    { name: 'CANADA', code: '1', short: 'CA' },
    { name: 'COCOS (KEELING) ISLANDS', code: '61', short: 'CC' },
    { name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE', code: '243', short: 'CD' },
    { name: 'CENTRAL AFRICAN REPUBLIC', code: '236', short: 'CF' },
    { name: 'CONGO', code: '242', short: 'CG' },
    { name: 'SWITZERLAND', code: '41', short: 'CH' },
    { name: 'COTE D IVOIRE', code: '225', short: 'CI' },
    { name: 'COOK ISLANDS', code: '682', short: 'CK' },
    { name: 'CHILE', code: '56', short: 'CL' },
    { name: 'CAMEROON', code: '237', short: 'CM' },
    { name: 'CHINA', code: '86', short: 'CN' },
    { name: 'COLOMBIA', code: '57', short: 'CO' },
    { name: 'COSTA RICA', code: '506', short: 'CR' },
    { name: 'CUBA', code: '53', short: 'CU' },
    { name: 'CAPE VERDE', code: '238', short: 'CV' },
    { name: 'CHRISTMAS ISLAND', code: '61', short: 'CX' },
    { name: 'CYPRUS', code: '357', short: 'CY' },
    { name: 'CZECH REPUBLIC', code: '420', short: 'CZ' },
    { name: 'GERMANY', code: '49', short: 'DE' },
    { name: 'DJIBOUTI', code: '253', short: 'DJ' },
    { name: 'DENMARK', code: '45', short: 'DK' },
    { name: 'DOMINICA', code: '1767', short: 'DM' },
    { name: 'DOMINICAN REPUBLIC', code: '1809', short: 'DO' },
    { name: 'ALGERIA', code: '213', short: 'DZ' },
    { name: 'ECUADOR', code: '593', short: 'EC' },
    { name: 'ESTONIA', code: '372', short: 'EE' },
    { name: 'EGYPT', code: '20', short: 'EG' },
    { name: 'ERITREA', code: '291', short: 'ER' },
    { name: 'SPAIN', code: '34', short: 'ES' },
    { name: 'ETHIOPIA', code: '251', short: 'ET' },
    { name: 'FINLAND', code: '358', short: 'FI' },
    { name: 'FIJI', code: '679', short: 'FJ' },
    { name: 'FALKLAND ISLANDS (MALVINAS)', code: '500', short: 'FK' },
    { name: 'MICRONESIA, FEDERATED STATES OF', code: '691', short: 'FM' },
    { name: 'FAROE ISLANDS', code: '298', short: 'FO' },
    { name: 'FRANCE', code: '33', short: 'FR' },
    { name: 'GABON', code: '241', short: 'GA' },
    { name: 'UNITED KINGDOM', code: '44', short: 'GB' },
    { name: 'GRENADA', code: '1473', short: 'GD' },
    { name: 'GEORGIA', code: '995', short: 'GE' },
    { name: 'GHANA', code: '233', short: 'GH' },
    { name: 'GIBRALTAR', code: '350', short: 'GI' },
    { name: 'GREENLAND', code: '299', short: 'GL' },
    { name: 'GAMBIA', code: '220', short: 'GM' },
    { name: 'GUINEA', code: '224', short: 'GN' },
    { name: 'EQUATORIAL GUINEA', code: '240', short: 'GQ' },
    { name: 'GREECE', code: '30', short: 'GR' },
    { name: 'GUATEMALA', code: '502', short: 'GT' },
    { name: 'GUAM', code: '1671', short: 'GU' },
    { name: 'GUINEA-BISSAU', code: '245', short: 'GW' },
    { name: 'GUYANA', code: '592', short: 'GY' },
    { name: 'HONG KONG', code: '852', short: 'HK' },
    { name: 'HONDURAS', code: '504', short: 'HN' },
    { name: 'CROATIA', code: '385', short: 'HR' },
    { name: 'HAITI', code: '509', short: 'HT' },
    { name: 'HUNGARY', code: '36', short: 'HU' },
    { name: 'INDONESIA', code: '62', short: 'ID' },
    { name: 'IRELAND', code: '353', short: 'IE' },
    { name: 'ISRAEL', code: '972', short: 'IL' },
    { name: 'ISLE OF MAN', code: '44', short: 'IM' },
    { name: 'INDIA', code: '91', short: 'IN' },
    { name: 'IRAQ', code: '964', short: 'IQ' },
    { name: 'IRAN, ISLAMIC REPUBLIC OF', code: '98', short: 'IR' },
    { name: 'ICELAND', code: '354', short: 'IS' },
    { name: 'ITALY', code: '39', short: 'IT' },
    { name: 'JAMAICA', code: '1876', short: 'JM' },
    { name: 'JORDAN', code: '962', short: 'JO' },
    { name: 'JAPAN', code: '81', short: 'JP' },
    { name: 'KENYA', code: '254', short: 'KE' },
    { name: 'KYRGYZSTAN', code: '996', short: 'KG' },
    { name: 'CAMBODIA', code: '855', short: 'KH' },
    { name: 'KIRIBATI', code: '686', short: 'KI' },
    { name: 'COMOROS', code: '269', short: 'KM' },
    { name: 'SAINT KITTS AND NEVIS', code: '1869', short: 'KN' },
    { name: 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF', code: '850', short: 'KP' },
    { name: 'KOREA REPUBLIC OF', code: '82', short: 'KR' },
    { name: 'KUWAIT', code: '965', short: 'KW' },
    { name: 'CAYMAN ISLANDS', code: '1345', short: 'KY' },
    { name: 'KAZAKSTAN', code: '7', short: 'KZ' },
    { name: 'LAO PEOPLES DEMOCRATIC REPUBLIC', code: '856', short: 'LA' },
    { name: 'LEBANON', code: '961', short: 'LB' },
    { name: 'SAINT LUCIA', code: '1758', short: 'LC' },
    { name: 'LIECHTENSTEIN', code: '423', short: 'LI' },
    { name: 'SRI LANKA', code: '94', short: 'LK' },
    { name: 'LIBERIA', code: '231', short: 'LR' },
    { name: 'LESOTHO', code: '266', short: 'LS' },
    { name: 'LITHUANIA', code: '370', short: 'LT' },
    { name: 'LUXEMBOURG', code: '352', short: 'LU' },
    { name: 'LATVIA', code: '371', short: 'LV' },
    { name: 'LIBYAN ARAB JAMAHIRIYA', code: '218', short: 'LY' },
    { name: 'MOROCCO', code: '212', short: 'MA' },
    { name: 'MONACO', code: '377', short: 'MC' },
    { name: 'MOLDOVA, REPUBLIC OF', code: '373', short: 'MD' },
    { name: 'MONTENEGRO', code: '382', short: 'ME' },
    { name: 'SAINT MARTIN', code: '1599', short: 'MF' },
    { name: 'MADAGASCAR', code: '261', short: 'MG' },
    { name: 'MARSHALL ISLANDS', code: '692', short: 'MH' },
    {
        name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
        code: '389',
        short: 'MK',
    },
    { name: 'MALI', code: '223', short: 'ML' },
    { name: 'MYANMAR', code: '95', short: 'MM' },
    { name: 'MONGOLIA', code: '976', short: 'MN' },
    { name: 'MACAU', code: '853', short: 'MO' },
    { name: 'NORTHERN MARIANA ISLANDS', code: '1670', short: 'MP' },
    { name: 'MAURITANIA', code: '222', short: 'MR' },
    { name: 'MONTSERRAT', code: '1664', short: 'MS' },
    { name: 'MALTA', code: '356', short: 'MT' },
    { name: 'MAURITIUS', code: '230', short: 'MU' },
    { name: 'MALDIVES', code: '960', short: 'MV' },
    { name: 'MALAWI', code: '265', short: 'MW' },
    { name: 'MEXICO', code: '52', short: 'MX' },
    { name: 'MALAYSIA', code: '60', short: 'MY' },
    { name: 'MOZAMBIQUE', code: '258', short: 'MZ' },
    { name: 'NAMIBIA', code: '264', short: 'NA' },
    { name: 'NEW CALEDONIA', code: '687', short: 'NC' },
    { name: 'NIGER', code: '227', short: 'NE' },
    { name: 'NIGERIA', code: '234', short: 'NG' },
    { name: 'NICARAGUA', code: '505', short: 'NI' },
    { name: 'NETHERLANDS', code: '31', short: 'NL' },
    { name: 'NORWAY', code: '47', short: 'NO' },
    { name: 'NEPAL', code: '977', short: 'NP' },
    { name: 'NAURU', code: '674', short: 'NR' },
    { name: 'NIUE', code: '683', short: 'NU' },
    { name: 'NEW ZEALAND', code: '64', short: 'NZ' },
    { name: 'OMAN', code: '968', short: 'OM' },
    { name: 'PANAMA', code: '507', short: 'PA' },
    { name: 'PERU', code: '51', short: 'PE' },
    { name: 'FRENCH POLYNESIA', code: '689', short: 'PF' },
    { name: 'PAPUA NEW GUINEA', code: '675', short: 'PG' },
    { name: 'PHILIPPINES', code: '63', short: 'PH' },
    { name: 'PAKISTAN', code: '92', short: 'PK' },
    { name: 'POLAND', code: '48', short: 'PL' },
    { name: 'SAINT PIERRE AND MIQUELON', code: '508', short: 'PM' },
    { name: 'PITCAIRN', code: '870', short: 'PN' },
    { name: 'PUERTO RICO', code: '1', short: 'PR' },
    { name: 'PORTUGAL', code: '351', short: 'PT' },
    { name: 'PALAU', code: '680', short: 'PW' },
    { name: 'PARAGUAY', code: '595', short: 'PY' },
    { name: 'QATAR', code: '974', short: 'QA' },
    { name: 'ROMANIA', code: '40', short: 'RO' },
    { name: 'SERBIA', code: '381', short: 'RS' },
    { name: 'RUSSIAN FEDERATION', code: '7', short: 'RU' },
    { name: 'RWANDA', code: '250', short: 'RW' },
    { name: 'SAUDI ARABIA', code: '966', short: 'SA' },
    { name: 'SOLOMON ISLANDS', code: '677', short: 'SB' },
    { name: 'SEYCHELLES', code: '248', short: 'SC' },
    { name: 'SUDAN', code: '249', short: 'SD' },
    { name: 'SWEDEN', code: '46', short: 'SE' },
    { name: 'SINGAPORE', code: '65', short: 'SG' },
    { name: 'SAINT HELENA', code: '290', short: 'SH' },
    { name: 'SLOVENIA', code: '386', short: 'SI' },
    { name: 'SLOVAKIA', code: '421', short: 'SK' },
    { name: 'SIERRA LEONE', code: '232', short: 'SL' },
    { name: 'SAN MARINO', code: '378', short: 'SM' },
    { name: 'SENEGAL', code: '221', short: 'SN' },
    { name: 'SOMALIA', code: '252', short: 'SO' },
    { name: 'SURINAME', code: '597', short: 'SR' },
    { name: 'SAO TOME AND PRINCIPE', code: '239', short: 'ST' },
    { name: 'EL SALVADOR', code: '503', short: 'SV' },
    { name: 'SYRIAN ARAB REPUBLIC', code: '963', short: 'SY' },
    { name: 'SWAZILAND', code: '268', short: 'SZ' },
    { name: 'TURKS AND CAICOS ISLANDS', code: '1649', short: 'TC' },
    { name: 'CHAD', code: '235', short: 'TD' },
    { name: 'TOGO', code: '228', short: 'TG' },
    { name: 'THAILAND', code: '66', short: 'TH' },
    { name: 'TAJIKISTAN', code: '992', short: 'TJ' },
    { name: 'TOKELAU', code: '690', short: 'TK' },
    { name: 'TIMOR-LESTE', code: '670', short: 'TL' },
    { name: 'TURKMENISTAN', code: '993', short: 'TM' },
    { name: 'TUNISIA', code: '216', short: 'TN' },
    { name: 'TONGA', code: '676', short: 'TO' },
    { name: 'TURKEY', code: '90', short: 'TR' },
    { name: 'TRINIDAD AND TOBAGO', code: '1868', short: 'TT' },
    { name: 'TUVALU', code: '688', short: 'TV' },
    { name: 'TAIWAN, PROVINCE OF CHINA', code: '886', short: 'TW' },
    { name: 'TANZANIA, UNITED REPUBLIC OF', code: '255', short: 'TZ' },
    { name: 'UKRAINE', code: '380', short: 'UA' },
    { name: 'UGANDA', code: '256', short: 'UG' },
    { name: 'UNITED STATES', code: '1', short: 'US' },
    { name: 'URUGUAY', code: '598', short: 'UY' },
    { name: 'UZBEKISTAN', code: '998', short: 'UZ' },
    { name: 'HOLY SEE (VATICAN CITY STATE)', code: '39', short: 'VA' },
    { name: 'SAINT VINCENT AND THE GRENADINES', code: '1784', short: 'VC' },
    { name: 'VENEZUELA', code: '58', short: 'VE' },
    { name: 'VIRGIN ISLANDS, BRITISH', code: '1284', short: 'VG' },
    { name: 'VIRGIN ISLANDS, U.S.', code: '1340', short: 'VI' },
    { name: 'VIET NAM', code: '84', short: 'VN' },
    { name: 'VANUATU', code: '678', short: 'VU' },
    { name: 'WALLIS AND FUTUNA', code: '681', short: 'WF' },
    { name: 'SAMOA', code: '685', short: 'WS' },
    { name: 'KOSOVO', code: '381', short: 'XK' },
    { name: 'YEMEN', code: '967', short: 'YE' },
    { name: 'MAYOTTE', code: '262', short: 'YT' },
    { name: 'SOUTH AFRICA', code: '27', short: 'ZA' },
    { name: 'ZAMBIA', code: '260', short: 'ZM' },
    { name: 'ZIMBABWE', code: '263', short: 'ZW' },
];

export const HELPDESK_STATUS_PENDING = 1;
export const HELPDESK_STATUS_INPROGRESS = 2;
export const HELPDESK_STATUS_CLOSED = 3;

export const getHelpdeskStatus = (status) => {
    switch (status) {
        case HELPDESK_STATUS_PENDING:
            return 'Pending';
        case HELPDESK_STATUS_INPROGRESS:
            return 'Inprogress';
        case HELPDESK_STATUS_CLOSED:
            return 'Closed';

        default:
            return 'Pending';
    }
};

export const getHelpdeskStatusClass = (status) => {
    switch (status) {
        case HELPDESK_STATUS_PENDING:
            return 'badge text-uppercase text-white bg-warning';
        case HELPDESK_STATUS_INPROGRESS:
            return 'badge text-uppercase text-white bg-info';
        case HELPDESK_STATUS_CLOSED:
            return 'badge text-uppercase text-white bg-primary';

        default:
            return 'badge text-uppercase text-white bg-warning';
    }
};

export const HELPDESK_TYPE_VALID = 1;
export const HELPDESK_TYPE_INVALID = 2;

export const getHelpdeskType = (status) => {
    switch (status) {
        case HELPDESK_TYPE_VALID:
            return 'Valid';
        case HELPDESK_TYPE_INVALID:
            return 'Invalid';

        default:
            return 'Valid';
    }
};

export const getHelpdeskTypeClass = (status) => {
    switch (status) {
        case HELPDESK_TYPE_VALID:
            return 'badge text-uppercase text-white bg-success';
        case HELPDESK_TYPE_INVALID:
            return 'badge text-uppercase text-white bg-danger';

        default:
            return 'badge text-uppercase text-white bg-success';
    }
};

export const secondsToHms = (d) => {
    const date = new Date(null);
    date.setSeconds(d);
    const result = date.toISOString().substr(11, 5);
    return result;
};

export const getOffSetTime = (time) => {
    let temp = secondsToHms(time);
    temp = temp.split(':');
    time = temp[0] + ':' + temp[1];
    let sign = '+';
    const offset = new Date().getTimezoneOffset();
    if (offset < 0) {
        sign = '+';
    }
    let prefix = Math.ceil(offset / 60);
    let suffix = offset % 60;
    if (prefix < 0) {
        prefix = -prefix;
        suffix = -suffix;
    }
    if (prefix < 10) {
        prefix = `0${prefix}`;
    }
    if (suffix < 10) {
        suffix = `0${suffix}`;
    }
    return `${time}:00${sign}${prefix}:${suffix}`;
};

function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`;
            break;
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === 'amex' ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    return clearValue;
}

export function formatFormData(data) {
    return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export function convertTimestampToDate(timeStamp) {
    const date = new Date(timeStamp);
    return moment(date).format('Do MMMM YYYY, dddd');
    // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function getDay(date) {
    const tempDate = new Date(date);
    return `${weekday[tempDate.getDay()]}`;
}
export function tConvert(date) {
    let minutes = date.getMinutes();
    if (date.getMinutes() < 10) {
        minutes = '0' + date.getMinutes();
    }
    let time = `${date.getHours()}:${minutes}`;
    const splittedArray = time.split(':');
    let meridian = 'AM';
    if (splittedArray[0] > 12) {
        splittedArray[0] = splittedArray[0] - 12;
        meridian = 'PM';
    }
    return splittedArray[0] + ':' + splittedArray[1] + ' ' + meridian;
}

export const BOOKING_VIEW_ALL = 0;
export const BOOKING_VIEW_NEW = 1;
export const BOOKING_VIEW_INPROGRESS = 2;
export const BOOKING_VIEW_COMPLETED = 3;
export const BOOKING_VIEW_CANCELLED = 4;
export const getBookingFilterOptions = (number) => {
    switch (number) {
        case BOOKING_VIEW_ALL:
            return 'All bookings';
        case BOOKING_VIEW_NEW:
            return 'New bookings';
        case BOOKING_VIEW_INPROGRESS:
            return 'In Progress';
        case BOOKING_VIEW_COMPLETED:
            return 'Completed bookings';
        case BOOKING_VIEW_CANCELLED:
            return 'Cancelled bookings';
        default:
            return 'All bookings';
    }
};

export const PROVIDER_FILTER_PRICE_DESENDING = 1;
export const PROVIDER_FILTER_PRICE_ACCENDING = 2;
export const PROVIDER_FILTER_RATING = 3;
export const PROVIDER_FILTER_NAME_A = 4;
export const PROVIDER_FILTER_NAME_Z = 5;
export const PROVIDER_FILTER_DISTANCE = 6;
export const PROVIDER_FILTER_POPULARITY = 7;
export const PROVIDER_FILTER_EXPERIENCE = 8;

export const PROVIDER_SORT_MENU_DATA = [
    {
        key: PROVIDER_FILTER_PRICE_ACCENDING,
        label: 'Price: Low to High',
    },
    {
        key: PROVIDER_FILTER_PRICE_DESENDING,
        label: 'Price: High to Low',
    },
    {
        key: PROVIDER_FILTER_NAME_A,
        label: 'Name: A-Z',
    },
    {
        key: PROVIDER_FILTER_NAME_Z,
        label: 'Name: Z-A',
    },
];

export const PROVIDER_FILTER_MENU_DATA = [
    {
        key: PROVIDER_FILTER_RATING,
        label: 'Rating',
    },
    {
        key: PROVIDER_FILTER_POPULARITY,
        label: 'Popularity',
    },
    // {
    //     key: PROVIDER_FILTER_DISTANCE,
    //     label: 'Nearby your Area',
    // },
    {
        key: PROVIDER_FILTER_EXPERIENCE,
        label: 'Experience',
    },
];

export const getStringFromProviderFilter = (number) => {
    switch (number) {
        case PROVIDER_FILTER_PRICE_DESENDING:
            return 'Price: High to Low';
        case PROVIDER_FILTER_PRICE_ACCENDING:
            return 'Price: Low to High';
        case PROVIDER_FILTER_RATING:
            return 'Rating';
        case PROVIDER_FILTER_NAME_A:
            return 'Name: A-Z';
        case PROVIDER_FILTER_NAME_Z:
            return 'Name: Z-A';
        case PROVIDER_FILTER_DISTANCE:
            return 'Nearby your Area';
        default:
            return 'All Providers';
    }
};

export function timeDifference(current, previous) {
    let msPerMinute = 60 * 1000;
    let msPerHour = msPerMinute * 60;
    let msPerDay = msPerHour * 24;
    let msPerMonth = msPerDay * 30;
    let msPerYear = msPerDay * 365;

    let elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
        return Math.round(elapsed / msPerYear) + ' years ago';
    }
}
export const timingData = [
    {
        key: '1',
        day: 'Monday',
        status: false,
        startTime: '10:00 AM',
        endTime: '7:00 PM'
    },
    {
        key: '2',
        day: 'Tuesday',
        status: false,
        startTime: '10:00 AM',
        endTime: '7:00 PM'
    },
    {
        key: '3',
        day: 'Wednesday',
        status: false,
        startTime: '10:00 AM',
        endTime: '7:00 PM'
    },
    {
        key: '4',
        day: 'Thursday',
        status: false,
        startTime: '10:00 AM',
        endTime: '7:00 PM'
    },
    {
        key: '5',
        day: 'Friday',
        status: false,
        startTime: '10:00 AM',
        endTime: '7:00 PM'
    },
    {
        key: '6',
        day: 'Saturday',
        status: false,
        startTime: '10:00 AM',
        endTime: '7:00 PM'
    },
    {
        key: '7',
        day: 'Sunday',
        status: false,
        startTime: '10:00 AM',
        endTime: '7:00 PM'
    }
]
