import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import * as actions from '../../actions';

import facebookLogo from '../../assets/img/facebook.png';
import googleLogo from '../../assets/img/google-icon.png';

import {
    SOCIAL_TYPE_GOOGLE,
    SOCIAL_TYPE_FACEBOOK,
    SOCIAL_TYPE_INSTAGRAM,
    ROLE_CUSTOMER
} from '../../utils/config/constants';

import SocialButton from './index';

class SocialSignin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            player_id: ''
        };
    }

    componentDidMount = async () => {
        this.setState({ playerId: window.onesignalId });
    };
    onDone = (success, data) => {
        const { isLoginScreenCall, loginDone } = this.props;
        if (success) {
            if (isLoginScreenCall) {
                loginDone();
            }
            this.setState({ redirect: true });
        }
    };

    render() {
        const { socialLoginAction } = this.props;
        const { playerId, redirect } = this.state;
        const responseGoogle = response => {
            if (response._profile) {
                socialLoginAction(
                    {
                        playerId: playerId,
                        role: ROLE_CUSTOMER,
                        type_id: SOCIAL_TYPE_GOOGLE,
                        ...response._profile
                    },
                    this.onDone
                );
            } else {
                console.log('Social Login Error');
            }
        };
        const responseFacebook = response => {
            if (response) {
                socialLoginAction(
                    {
                        playerId: playerId,
                        role: ROLE_CUSTOMER,
                        type_id: SOCIAL_TYPE_FACEBOOK,
                        ...response._profile
                    },
                    this.onDone
                );
            } else {
                console.log('Social Login Error');
            }
        };
        const responseInstagram = response => {
            if (response) {
                socialLoginAction(
                    {
                        playerId: playerId,
                        role: ROLE_CUSTOMER,
                        type_id: SOCIAL_TYPE_INSTAGRAM,
                        ...response
                    },
                    this.onDone
                );
            } else {
                console.log('INSTA Social Login Error');
            }
        };

        if (redirect) {
            return <Redirect to={'/user/profile'} />;
        }

        return (
            <div className="row">
                <SocialButton
                    provider="facebook"
                    appId="650407999116669"
                    onLoginSuccess={responseFacebook}
                    onLoginFailure={responseFacebook}
                >
                    <div style={{ width: 40, height: 40, borderRadius: 100, marginRight: 5, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: 15 }}>
                        <LazyLoadImage
                            src={facebookLogo}
                            alt="facebook"
                            style={{ width: 40, height: 40 }}
                        />
                    </div>
                </SocialButton>
                <SocialButton
                    provider="google"
                    appId="991746574978-2sp42p2i3lu0e542gvrpbvmi44i0cijl.apps.googleusercontent.com"
                    onLoginSuccess={responseGoogle}
                    onLoginFailure={responseGoogle}
                >
                    <div style={{ width: 40, height: 40, borderRadius: 100, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <LazyLoadImage
                            src={googleLogo}
                            alt="google"
                            style={{ width: 25, height: 25 }}
                        />
                    </div>
                </SocialButton>
            </div>
        );
    }
}

export default connect(null, actions)(withRouter(SocialSignin));