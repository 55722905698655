import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';

import ServiceCreate from '../../components/Providers/ServiceCreate';

export class Create extends Component {
    render() {
        const { providerData, subcategoryData } = this.props;
        return (
            <div>
                <div className={isMobile ? 'row mb-2' : 'row mb-4'}>
                    <div className="col-md-12">
                        <Card>
                            <Toolbar>
                                <h3>
                                    <FormattedMessage
                                        id="addNewItem"
                                        defaultMessage="Add New Service"
                                    />
                                </h3>
                            </Toolbar>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ServiceCreate
                            {...this.props}
                            providerData={providerData}
                            allSubcategoryData={subcategoryData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Create;
