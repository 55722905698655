import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NotificationManager } from 'react-notifications';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CircularProgress, Fade } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';

import * as actions from '../../actions';

import {
    validateOTP,
    emailValidate,
    passwordMatchValidate,
} from '../../utils/validators/validateForm';

import WSButton from '../../components/Button/WSButton';
import WSTextField from '../../components/TextField/WSTextField';
import WSPasswordError from '../../components/Password/WSPasswordError';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderButton: false,
            email: '',
            emailError: '',
            dashboardRedirect: false,
            showContent: 1,
            password: '',
            passwordError: '',
            confirmPassword: '',
            confirmPasswordError: '',
            forgotToken: '',
            otp: '',
            otpError: '',
            timer: 60,
            validUpper: false,
            validLower: false,
            validDigit: false,
            validSymbol: false,
            validMinLength: false,
            passwordStrength: 'Weak',
            showNewPassword: false,
            showConfirmPassword: false,
        };
    }

    handleClose = () => {
        const { close } = this.props;
        close();
    };
    handleClickShowNewPassword = () => {
        {
            this.state?.showNewPassword === false ?
                this.setState({ showNewPassword: true })
                :
                this.setState({ showNewPassword: false })
        }
    }
    handleClickShowConfirmPassword = () => {
        {
            this.state?.showConfirmPassword === false ?
                this.setState({ showConfirmPassword: true })
                :
                this.setState({ showConfirmPassword: false })
        }
    }
    renderButton = () => {
        const { loaderButton, showContent } = this.state;
        const { intl, theme } = this.props;
        if (loaderButton) {
            return <CircularProgress />;
        } else {
            return (
                <WSButton
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        color: '#ffffff',
                        marginBottom: 20,
                    }}
                    onClick={() => this.forgotPasswordActionCall()}
                >
                    {showContent === 1 && intl.formatMessage({ id: 'Next' })}
                    {showContent === 2 && intl.formatMessage({ id: 'Next' })}
                    {showContent === 3 && intl.formatMessage({ id: 'Done' })}
                </WSButton>
            );
        }
    };

    forgotPasswordActionCall = () => {
        const {
            forgotPasswordAction,
            checkForgotOtpAction,
            resetPasswordAction,
        } = this.props;
        const {
            showContent,
            email,
            otp,
            password,
            confirmPassword,
            forgotToken,
        } = this.state;
        if (showContent === 1) {
            if (emailValidate(email, this.handleError)) {
                this.setState({ loaderButton: true });
                forgotPasswordAction(
                    {
                        email: email,
                    },
                    this.onForgotEmailDone
                );
            }
        } else if (showContent === 2) {
            if (validateOTP(otp, this.handleError)) {
                this.setState({ loaderButton: true });
                checkForgotOtpAction(
                    {
                        email: email,
                        otp: otp,
                    },
                    this.onOtpDone
                );
            }
        } else if (showContent === 3) {
            if (
                passwordMatchValidate(
                    password,
                    confirmPassword,
                    this.handleError
                )
            ) {
                this.setState({ loaderButton: true });
                resetPasswordAction(
                    {
                        email: email,
                        token: forgotToken,
                        password: password,
                    },
                    this.onResetPassword
                );
            }
        }
    };

    resendOtp = () => {
        const { email } = this.state;
        const { forgotPasswordAction } = this.props;
        this.setState({ timer: 60 });
        this.interval = setInterval(() => {
            const { timer } = this.state;
            if (timer === 0) {
                return clearInterval(this.interval);
            }
            this.setState({ timer: timer - 1 });
        }, 1000);
        forgotPasswordAction(
            {
                email: email,
            },
            this.onResendDone
        );
    };

    handleError = (params) => {
        this.setState(params);
    };

    onConfirmPasswordChange = (event) => {
        this.setState({
            confirmPassword: event.target.value,
            confirmPasswordError: '',
        });
    };

    onChangeEmail = (event) => {
        this.setState({
            email: event.target.value,
            emailError: '',
        });
    };

    onChangeOtp = (event) => {
        this.setState({
            otp: event.target.value,
            otpError: '',
        });
    };

    onChangePassword = (event) => {
        this.setState({
            password: event.target.value,
            passwordError: '',
        });
        this.checkValidation(event.target.value);
    };

    onForgotEmailDone = (success, data) => {
        if (success) {
            this.setState(function (previousState) {
                return {
                    showContent: previousState.showContent + 1,
                    loaderButton: false,
                };
            });
            this.interval = setInterval(() => {
                const { timer } = this.state;
                if (timer === 0) {
                    return clearInterval(this.interval);
                }
                this.setState({ timer: timer - 1 });
            }, 1000);
        } else {
            this.setState({
                loaderButton: false,
                emailError: data,
            });
        }
    };

    onOtpDone = (success, data) => {
        if (success) {
            this.setState(function (previousState) {
                return {
                    showContent: previousState.showContent + 1,
                    loaderButton: false,
                    forgotToken: data.token,
                };
            });
        } else {
            this.setState({
                loaderButton: false,
                otpError: data,
            });
        }
    };

    onResetPassword = (success, data) => {
        const { close } = this.props;
        if (success) {
            this.setState({
                loaderButton: false,
            });
            close();
            NotificationManager.success(
                <FormattedMessage
                    id="passwordResetSuccess"
                    defaultMessage="Password Reset Successfully, you can login now!"
                />,
                'Success'
            );
        } else {
            this.setState({
                loaderButton: false,
                passwordError: data,
            });
            NotificationManager.error('Error', data.message);
        }
    };

    onResendDone = (success, data) => {
        if (success) {
            this.setState(function (previousState) {
                return {
                    showContent: 2,
                    loaderButton: false,
                };
            });
        }
    };

    checkValidation(password) {
        this.passwordStrength(password);
        const upperCasePatten = /(?=.*[A-Z])/;
        const lowerCasePatten = /(?=.*[a-z])/;
        const digitCasePatten = /(?=.*[0-9])/;
        const symbolCasePatten = /(?=.*\W)/;
        const spaceCasePatten = /(?=.*\s)/;
        const minLengthPatten = /(?=.{8,})/;

        if (upperCasePatten.test(password)) {
            this.setState({ validUpper: true });
        } else {
            this.setState({ validUpper: false });
        }
        if (lowerCasePatten.test(password)) {
            this.setState({ validLower: true });
        } else {
            this.setState({ validLower: false });
        }
        if (digitCasePatten.test(password)) {
            this.setState({ validDigit: true });
        } else {
            this.setState({ validDigit: false });
        }
        if (symbolCasePatten.test(password) &&
            !spaceCasePatten.test(password)
        ) {
            this.setState({ validSymbol: true });
        } else {
            this.setState({ validSymbol: false });
        }
        if (minLengthPatten.test(password)) {
            this.setState({ validMinLength: true });
        } else {
            this.setState({ validMinLength: false });
        }
    }

    passwordStrength = (password) => {
        let score = 0;
        let passwordStrength;
        let regexPositive = ['[A-Z]', '[a-z]', '[0-9]', '\\W'];
        regexPositive.forEach((regex, index) => {
            if (new RegExp(regex).test(password)) {
                score += 1;
            }
        });
        switch (score) {
            case 1:
                passwordStrength = 'Poor';
                break;
            case 2:
                passwordStrength = 'Poor';
                break;
            case 3:
                passwordStrength = 'Average';
                break;
            case 4:
                passwordStrength = 'Strong';
                break;
            default:
                passwordStrength = 'Weak';
        }
        this.setState({
            passwordStrength,
        });
        return passwordStrength;
    };
    handleKeyPress = (event) => {
        if (event?.key === 'Enter') {
            this.forgotPasswordActionCall()
        }
    }
    render() {
        const {
            emailError,
            showContent,
            otpError,
            passwordError,
            confirmPasswordError,
            loaderButton,
            validUpper,
            validLower,
            validDigit,
            validSymbol,
            validMinLength,
            passwordStrength,
            dashboardRedirect,
            email,
            otp,
            timer,
            password,
            confirmPassword,
            showNewPassword,
            showConfirmPassword,
        } = this.state;
        const { intl, open, theme } = this.props;
        const { formatMessage } = intl;
        if (dashboardRedirect) {
            return (
                <Redirect
                    to={{
                        pathname: `/user/dashboard`,
                    }}
                />
            );
        }
        return (
            <div>
                <Dialog
                    fullWidth={true}
                    TransitionComponent={Fade}
                    className="text-left"
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <form
                        className="dialog-form dialog-form-new margin-unset"
                        autocomplete="off"
                    >
                        <div
                            className="dialog-header"
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingLeft: 5,
                            }}
                        >
                            {showContent === 1 && (
                                <h3
                                    className={
                                        isMobile
                                            ? 'dialog-heading font-16'
                                            : 'dialog-heading'
                                    }
                                >
                                    <FormattedMessage
                                        id="forgotPassword"
                                        defaultMessage="Forgot Password?"
                                    />
                                </h3>
                            )}
                            {showContent === 2 && (
                                <h3
                                    className={
                                        isMobile
                                            ? 'dialog-heading font-16'
                                            : 'dialog-heading'
                                    }
                                >
                                    <FormattedMessage
                                        id="verifyOtp"
                                        defaultMessage="Verify OTP"
                                    />
                                </h3>
                            )}
                            {showContent === 3 && (
                                <h3
                                    className={
                                        isMobile
                                            ? 'dialog-heading font-16'
                                            : 'dialog-heading'
                                    }
                                >
                                    <FormattedMessage
                                        id="resetPassword"
                                        defaultMessage="Reset Password"
                                    />
                                </h3>
                            )}
                            <IconButton
                                color="inherit"
                                onClick={this.handleClose}
                                aria-label="Close"
                            >
                                <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                        </div>
                        <div className="dialog-body">
                            <DialogContent className="pt-0">
                                {showContent === 1 && (
                                    <WSTextField
                                        error={emailError ? true : false}
                                        helperText={emailError}
                                        placeholder={formatMessage({
                                            id: 'enterEmail',
                                        })}
                                        className="login-form-textinput"
                                        id="email"
                                        fullWidth={true}
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={this.onChangeEmail}
                                        disabled={loaderButton}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                )}
                                {showContent === 2 && (
                                    <div>
                                        <WSTextField
                                            error={otpError ? true : false}
                                            helperText={otpError}
                                            placeholder={formatMessage({
                                                id: 'enterOTP',
                                            })}
                                            className="login-form-textinput"
                                            id="otp"
                                            name="otp"
                                            value={otp}
                                            onChange={this.onChangeOtp}
                                            disabled={loaderButton}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                        <div>
                                            {timer === 0 && (
                                                <a
                                                    style={{
                                                        cursor: 'pointer',
                                                        color:
                                                            theme.palette
                                                                .primary.main,
                                                    }}
                                                    onClick={this.resendOtp}
                                                    disabled={timer > 0}
                                                >
                                                    <FormattedMessage
                                                        id="resendOtp"
                                                        defaultMessage="Resend OTP"
                                                    />&nbsp;
                                                </a>
                                            )}
                                        </div>
                                        {timer > 0 && (
                                            <div>
                                                &nbsp;
                                                <FormattedMessage
                                                    id="youCanResendOtp"
                                                    defaultMessage="You can resend OTP in"
                                                />&nbsp;
                                                <b>
                                                    {timer !== 0 ? timer : ''}
                                                </b>&nbsp;
                                                <FormattedMessage
                                                    id="secs"
                                                    defaultMessage="secs"
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                                {showContent === 3 && (
                                    <div>
                                        <WSTextField
                                            error={passwordError ? true : false}
                                            helperText={passwordError}
                                            placeholder={formatMessage({
                                                id: 'enterNewPassword',
                                            })}
                                            className="login-form-textinput"
                                            id="password"
                                            name="password"
                                            type={showNewPassword ? "text" : "password"}
                                            value={password}
                                            onChange={this.onChangePassword}
                                            disabled={loaderButton}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton
                                                            className="passwordIcon"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowNewPassword}
                                                        >
                                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {password && (
                                            <Fragment>
                                                <WSPasswordError
                                                    isValid={
                                                        passwordStrength ===
                                                            'Weak' ||
                                                            passwordStrength ===
                                                            'Poor'
                                                            ? false
                                                            : true
                                                    }
                                                    label={`Password Strength: ${passwordStrength}`}
                                                />
                                                <WSPasswordError
                                                    isValid={validMinLength}
                                                    label="The password should be at least 8 characters"
                                                />
                                                <WSPasswordError
                                                    isValid={validSymbol}
                                                    label="The password should contains at least 1 special symbol without space"
                                                />
                                                <WSPasswordError
                                                    isValid={validDigit}
                                                    label="The password should contains at least 1 digit"
                                                />
                                                <WSPasswordError
                                                    isValid={validUpper}
                                                    label="The password should contains at least 1 upper character"
                                                />
                                                <WSPasswordError
                                                    isValid={validLower}
                                                    label="The password should contains at least 1 lower character"
                                                />
                                            </Fragment>
                                        )}
                                        <WSTextField
                                            error={
                                                confirmPasswordError
                                                    ? true
                                                    : false
                                            }
                                            helperText={confirmPasswordError}
                                            placeholder={formatMessage({
                                                id: 'confirmPassword',
                                            })}
                                            className="login-form-textinput"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            type={showConfirmPassword ? "text" : "password"}
                                            value={confirmPassword}
                                            onChange={
                                                this.onConfirmPasswordChange
                                            }
                                            disabled={loaderButton}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton
                                                            className="passwordIcon"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowConfirmPassword}
                                                        >
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                    </div>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <div className="col-md-12 log-btn">
                                    {this.renderButton()}
                                </div>
                            </DialogActions>
                        </div>
                    </form>
                </Dialog>
            </div>
        );
    }
}

const composedHOC = compose(withTheme(), connect(null, actions));

export default composedHOC(injectIntl(ForgotPassword));
