import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { Toolbar } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';

import * as actions from '../../actions';

import { validateChangePasswordForm } from '../../utils/validators/validateForm';

import HOCSectionLoader from '../../components/HOC/HOCSectionLoader.jsx';
import WSButton from '../../components/Button/WSButton';
import WSTextField from '../../components/TextField/WSTextField';
import WSPasswordError from '../../components/Password/WSPasswordError';

class ChangePassword extends Component {
    constructor(props) {
        super();
        this.state = {
            error: '',
            passwordError: '',
            newPasswordError: '',
            confirmPasswordError: '',
            password: '',
            newPassword: '',
            confirmPassword: '',
            loader: false,
            loaderButton: false,
            noResponse: false,
            errorMessage: '',
            validUpper: false,
            validLower: false,
            validDigit: false,
            validSymbol: false,
            validMinLength: false,
            passwordStrength: 'Weak',
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
        };
    }

    submit = (params) => {
        const { password, newPassword, confirmPassword,validUpper, validLower, validDigit, validSymbol, validMinLength, } = this.state;
        const { changePasswordAction } = this.props;
        if(validUpper  && validLower && validDigit && validSymbol && validMinLength )
        {
        if (
            validateChangePasswordForm(
                { password, newPassword, confirmPassword },
                this.handleError
            )
        ) {
            this.setState({ loaderButton: true });
            changePasswordAction(
                {
                    password,
                    newPassword,
                    confirmPassword,
                },
                this.onDone
            );
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Oops!'
            );
        }
    }else {
        NotificationManager.error(
            <FormattedMessage
                id="Validation Error"
                defaultMessage="Validation Error"
            />,
            'Oops!'
        );
    }
    };

    handleError = (params) => {
        this.setState(params);
    };

    onDone = (success, data) => {
        const { history } = this.props;
        if (success) {
            this.setState({ loaderButton: false });
            NotificationManager.success(
                <FormattedMessage
                    id="passwordChangedSuccess"
                    defaultMessage="Password Changed Successfully"
                />,
                'Success'
            );
            history.push(`/user/profile`);
        } else {
            this.setState({ loaderButton: false });
            NotificationManager.error(data, 'Error');
        }
    };

    renderButton = () => {
        const { loaderButton } = this.state;
        if (loaderButton) {
            return <CircularProgress />;
        }
        return (
            <WSButton variant="raised" className="jr-btn" onClick={this.submit}>
                <FormattedMessage
                    id="changePassword"
                    defaultMessage="Change Password"
                />
            </WSButton>
        );
    };

    checkValidation(password) {
        this.passwordStrength(password);
        const upperCasePatten = /(?=.*[A-Z])/;
        const lowerCasePatten = /(?=.*[a-z])/;
        const digitCasePatten = /(?=.*[0-9])/;
        const symbolCasePatten = /(?=.*\W)/;
        const spaceCasePatten = /(?=.*\s)/;
        const minLengthPatten = /(?=.{8,})/;

        if (upperCasePatten.test(password)) {
            this.setState({ validUpper: true });
        } else {
            this.setState({ validUpper: false });
        }

        if (lowerCasePatten.test(password)) {
            this.setState({ validLower: true });
        } else {
            this.setState({ validLower: false });
        }

        if (digitCasePatten.test(password)) {
            this.setState({ validDigit: true });
        } else {
            this.setState({ validDigit: false });
        }

        if (symbolCasePatten.test(password) &&
            !spaceCasePatten.test(password)
        ) {
            this.setState({ validSymbol: true });
        } else {
            this.setState({ validSymbol: false });
        }

        if (minLengthPatten.test(password)) {
            this.setState({ validMinLength: true });
        } else {
            this.setState({ validMinLength: false });
        }
    }

    passwordStrength = (password) => {
        let score = 0;
        let passwordStrength;
        let regexPositive = ['[A-Z]', '[a-z]', '[0-9]', '\\W'];
        regexPositive.forEach((regex, index) => {
            if (new RegExp(regex).test(password)) {
                score += 1;
            }
        });
        switch (score) {
            case 1:
                passwordStrength = 'Poor';
                break;
            case 2:
                passwordStrength = 'Poor';
                break;
            case 3:
                passwordStrength = 'Average';
                break;
            case 4:
                passwordStrength = 'Strong';
                break;
            default:
                passwordStrength = 'Weak';
        }
        this.setState({
            passwordStrength,
        });
        return passwordStrength;
    };
    handleClickShowNewPassword = () => {
        {
            this.state?.showNewPassword === false ?
                this.setState({ showNewPassword: true })
                :
                this.setState({ showNewPassword: false })
        }
    }
    handleClickShowOldPassword = () => {
        {
            this.state?.showOldPassword === false ?
                this.setState({ showOldPassword: true })
                :
                this.setState({ showOldPassword: false })
        }
    }
    handleClickShowConfirmPassword = () => {
        {
            this.state?.showConfirmPassword === false ?
                this.setState({ showConfirmPassword: true })
                :
                this.setState({ showConfirmPassword: false })
        }
    }
    render() {
        const {
            error,
            passwordError,
            newPasswordError,
            confirmPasswordError,
            loaderButton,
            validUpper,
            validLower,
            validDigit,
            validSymbol,
            validMinLength,
            passwordStrength,
            newPassword,
            loader,
            noResponse,
            redirect,
            showOldPassword,
            showNewPassword,
            showConfirmPassword,
        } = this.state;
        const { intl } = this.props;
        const { formatMessage } = intl;
        return (
            <HOCSectionLoader
                loading={loader}
                noResponse={noResponse}
                redirect={redirect}
                errorShow={error}
            >
                <div className="sign-up-form sign-up-form-2">
                    <div class="dialog-form dialog-form-new">
                        <div className={isMobile ? 'row' : 'row mb-4'}>
                            <div className="col-md-12">
                                <Toolbar>
                                    <h3
                                        className={
                                            isMobile
                                                ? 'margin-auto font-18'
                                                : 'margin-auto'
                                        }
                                    >
                                        <FormattedMessage
                                            id="changePassword"
                                            defaultMessage="Change Password"
                                        />
                                    </h3>
                                </Toolbar>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <form
                                    className="row"
                                    noValidate
                                    autoComplete="off"
                                    id="customer-create"
                                >
                                    <div className="col-md-12 col-xs-12">
                                        <WSTextField
                                            error={passwordError ? true : false}
                                            className={
                                                isMobile
                                                    ? 'login-form-textinput mt-0'
                                                    : 'login-form-textinput mt-0'
                                            }
                                            helperText={passwordError}
                                            type={showOldPassword ? 'text' : 'password'}
                                            name="password"
                                            id="password"
                                            placeholder={formatMessage({
                                                id: 'oldPassword',
                                            })}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(event) => {
                                                this.setState({
                                                    password:
                                                        event.target.value,
                                                    passwordError: '',
                                                    error: '',
                                                });
                                            }}
                                            disabled={
                                                loaderButton ? true : false
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton
                                                            className="passwordIcon"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowOldPassword}
                                                        >
                                                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-12 col-xs-12">
                                        <WSTextField
                                            error={
                                                newPasswordError ? true : false
                                            }
                                            helperText={newPasswordError}
                                            className={
                                                isMobile
                                                    ? 'login-form-textinput mt-0'
                                                    : 'login-form-textinput mt-0'
                                            }
                                            type={showNewPassword ? 'text' : 'password'}
                                            name="newPassword"
                                            id="newPassword"
                                            placeholder={formatMessage({
                                                id: 'newPassword',
                                            })}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(event) => {
                                                this.setState({
                                                    newPassword:
                                                        event.target.value,
                                                    newPasswordError: '',
                                                    error: '',
                                                });
                                                this.checkValidation(
                                                    event.target.value
                                                );
                                            }}
                                            disabled={
                                                loaderButton ? true : false
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton
                                                            className="passwordIcon"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowNewPassword}
                                                        >
                                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {newPassword && (
                                            <Fragment>
                                                <WSPasswordError
                                                    isValid={
                                                        passwordStrength ===
                                                            'Weak' ||
                                                            passwordStrength ===
                                                            'Poor'
                                                            ? false
                                                            : true
                                                    }
                                                    label={`Password Strength: ${passwordStrength}`}
                                                />
                                                <WSPasswordError
                                                    isValid={validMinLength}
                                                    label="The password should be at least 8 characters"
                                                />
                                                <WSPasswordError
                                                    isValid={validSymbol}
                                                    label="The password should contains at least 1 special symbol"
                                                />
                                                <WSPasswordError
                                                    isValid={validDigit}
                                                    label="The password should contains at least 1 digit"
                                                />
                                                <WSPasswordError
                                                    isValid={validUpper}
                                                    label="The password should contains at least 1 upper character"
                                                />
                                                <WSPasswordError
                                                    isValid={validLower}
                                                    label="The password should contains at least 1 lower character"
                                                />
                                            </Fragment>
                                        )}
                                    </div>
                                    <div className="col-md-12 col-xs-12">
                                        <WSTextField
                                            error={
                                                confirmPasswordError
                                                    ? true
                                                    : false
                                            }
                                            helperText={confirmPasswordError}
                                            className={
                                                isMobile
                                                    ? 'login-form-textinput mt-0'
                                                    : 'login-form-textinput mt-0'
                                            }
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name="confirmPassword"
                                            placeholder="confirmPassword"
                                            id="confirmPassword"
                                            placeholder={formatMessage({
                                                id: 'confirmPassword',
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => {
                                                this.setState({
                                                    confirmPassword:
                                                        event.target.value,
                                                    confirmPasswordError: '',
                                                    error: '',
                                                });
                                            }}
                                            disabled={
                                                loaderButton ? true : false
                                            }
                                            FormHelperTextProps={{
                                                color: 'black',
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton
                                                            className="passwordIcon"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowConfirmPassword}
                                                        >
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-12 col-xs-12">
                                        <div
                                            className={
                                                isMobile
                                                    ? 'mt-10 mb-2 log-btn'
                                                    : 'mt-4 mb-2 log-btn'
                                            }
                                        >
                                            {this.renderButton()}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </HOCSectionLoader>
        );
    }
}

export default connect(null, actions)(injectIntl(ChangePassword));
