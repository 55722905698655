import {
    categoryList,
    topCategoryList,
    mainSearch,
    allServices,
    providerServiceList,
    getProviderServices,
    serviceChangeStatus,
    getCategoryService,
    addService,
    providerServiceDetail,
    updateProviderService,
    addServiceFaq,
    serviceFaqDetail,
    updateServiceFaq,
    deleteServiceFaq,
    addServicePackage,
    updateServicePackage,
    servicePackageDetail,
    deleteServicePackage,
    addServiceGalleryImage,
    addPortfolioGalleryImage,
    deletePortfolioGallery,
    shareBookingImage,
    deleteSharedBookingImages,
    sharedBookingImages,
    shareSelectedBookingImages,
    providerServiceVideoList,
    addVideoUrl,
    deleteServiceVideoUrl,
    updateVideoUrl,
    providerServiceVideoDetail,
    providerCategoryList,
    providerSubCategoryList,
    serviceDetail,
} from '../apiHelpers/category/list';
import { serviceProviderList } from '../apiHelpers/provider/list';

import {
    CATEGORY_DATA_RECEIVED,
    TOP_CATEGORY_DATA_RECEIVED,
    CATEGORY_LOADER,
    SERVICE_DATA_RECEIVED,
    PROVIDER_DATA_RECEIVED,
    PROVIDER_SERVICE_LOADER,
    PROVIDER_SERVICE_DATA_RECEIVED,
    SUBCATEGORY_LOADER,
    SUBCATEGORY_DATA_RECEIVED,
} from './types';

export const allCategoryAction = () => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_LOADER, payload: true });
        const response = await categoryList();
        if (response.status === 'OK') {
            dispatch({ type: CATEGORY_DATA_RECEIVED, payload: response.data });
            dispatch({ type: CATEGORY_LOADER, payload: false });
        }
    } catch (e) {
        console.log({ e });
    }
};

export const topCategoryAction = () => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_LOADER, payload: true });
        const response = await topCategoryList();
        if (response.status === 'OK') {
            dispatch({
                type: TOP_CATEGORY_DATA_RECEIVED,
                payload: response.data,
            });
            dispatch({ type: CATEGORY_LOADER, payload: false });
        }
    } catch (e) {
        console.log({ e });
    }
};

export const allProviderSubCategoryAction = () => async (dispatch) => {
    try {
        dispatch({ type: SUBCATEGORY_LOADER, payload: true });
        const response = await providerSubCategoryList();
        if (response.status === 'OK') {
            dispatch({
                type: SUBCATEGORY_DATA_RECEIVED,
                payload: response.data,
            });
            dispatch({ type: SUBCATEGORY_LOADER, payload: false });
        }
    } catch (e) {
        console.log({ e });
    }
};

export const allProviderCategoryAction = () => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_LOADER, payload: true });
        const response = await providerCategoryList();
        if (response.status === 'OK') {
            dispatch({ type: CATEGORY_DATA_RECEIVED, payload: response.data });
            dispatch({ type: CATEGORY_LOADER, payload: false });
        }
    } catch (e) {
        console.log({ e });
    }
};

export const mainSearchAction = (params, done) => async (dispatch) => {
    try {
        const response = await mainSearch(params);
        if (response.status === 'OK') {
            dispatch({ type: PROVIDER_DATA_RECEIVED, payload: response.data });

            done(true, response);
        } else {
            done(true, response);
        }
    } catch (e) {
        done(false, 'Something error');
    }
};

export const allServicesAction = () => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_LOADER, payload: true });
        const response = await allServices();
        if (response.status === 'OK') {
            dispatch({ type: SERVICE_DATA_RECEIVED, payload: response.data });
            dispatch({ type: CATEGORY_LOADER, payload: false });
        }
    } catch (e) {
        console.log({ e });
    }
};

export const providerServiceListAction = (params, done) => async (dispatch) => {
    try {
        const response = await providerServiceList(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(true, response);
        }
    } catch (e) {
        done(false, 'Something error');
    }
};

export const serviceProviderListAction = (params, done) => async (dispatch) => {
    try {
        const response = await serviceProviderList(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(true, response);
        }
    } catch (e) {
        done(false, 'Something error');
    }
};

export const getProviderServicesAction = (params, done) => async (dispatch) => {
    try {
        dispatch({ type: PROVIDER_SERVICE_LOADER, payload: true });
        const response = await getProviderServices(params);
        if (response.status === 'OK') {
            dispatch({
                type: PROVIDER_SERVICE_DATA_RECEIVED,
                payload: response.data,
            });
            dispatch({ type: PROVIDER_SERVICE_LOADER, payload: false });
            return true;
        } else {
            dispatch({ type: PROVIDER_SERVICE_LOADER, payload: false });
            return false;
        }
    } catch (e) {
        done(false, 'Something error');
    }
};

export const serviceChangeStatusAction = (params, done) => async (dispatch) => {
    try {
        const response = await serviceChangeStatus(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Something error');
    }
};

export const getCategoryServiceAction = (params, done) => async (dispatch) => {
    try {
        const response = await getCategoryService(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Something error');
    }
};

export const addServiceAction = (params, done) => async (dispatch) => {
    try {
        const response = await addService(params);
        if (response.status === 'OK') {
            dispatch(getProviderServicesAction());
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Something error');
    }
};

export const updateProviderServiceAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await updateProviderService(params);
        if (response.status === 'OK') {
            dispatch(getProviderServicesAction());
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

// export const uploadServicePhotoAction = (params, done) => async dispatch => {
//     try {
//         const response = await uploadServicePhoto(params);
//         if (response.status === 'OK') {
//             return done(true, response.message);
//         }
//         return done(false, response.message);
//     } catch (e) {
//         done(false, 'Something error');
//     }
// };

export const providerServiceDetailAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await providerServiceDetail(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const serviceDetailAction = (params, done) => async (dispatch) => {
    try {
        const response = await serviceDetail(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const addServiceFaqAction = (params, done) => async (dispatch) => {
    try {
        const response = await addServiceFaq(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const addServicePackageAction = (params, done) => async (dispatch) => {
    try {
        const response = await addServicePackage(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const updateServiceFaqAction = (params, done) => async (dispatch) => {
    try {
        const response = await updateServiceFaq(params);
        if (response.status === 'OK') {
            dispatch(getProviderServicesAction());
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const updateServicePackageAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await updateServicePackage(params);
        if (response.status === 'OK') {
            dispatch(getProviderServicesAction());
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const serviceFaqDetailAction = (params, done) => async (dispatch) => {
    try {
        const response = await serviceFaqDetail(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const servicePackageDetailAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await servicePackageDetail(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const deleteServiceFaqAction = (params, done) => async (dispatch) => {
    try {
        const response = await deleteServiceFaq(params);
        if (response.status === 'OK') {
            dispatch(getProviderServicesAction());
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const deleteServicePackageAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await deleteServicePackage(params);
        if (response.status === 'OK') {
            dispatch(getProviderServicesAction());
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const addServiceGalleryImageAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await addServiceGalleryImage(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const addPortfolioGalleryImageAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await addPortfolioGalleryImage(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const shareBookingImageAction = (params, done) => async (dispatch) => {
    try {
        const response = await shareBookingImage(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const deleteSharedBookingImagesAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await deleteSharedBookingImages(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const sharedBookingImagesAction = (params, done) => async (dispatch) => {
    try {
        const response = await sharedBookingImages(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const shareSelectedBookingImagesAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await shareSelectedBookingImages(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const deletePortfolioGalleryAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await deletePortfolioGallery(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const providerServiceVideoListAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await providerServiceVideoList(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const providerServiceVideoDetailAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await providerServiceVideoDetail(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const addVideoUrlAction = (params, done) => async (dispatch) => {
    try {
        const response = await addVideoUrl(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};

export const updateVideoUrlAction = (params, done) => async (dispatch) => {
    try {
        const response = await updateVideoUrl(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        console.log(e);
        done(false, 'Unknown Error Occurred');
    }
};

export const deleteServiceVideoUrlAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await deleteServiceVideoUrl(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        done(false, 'Unknown Error Occurred');
    }
};
