import React, { Component } from 'react';
import { compose } from 'redux';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../actions';

import ProviderServiceList from '../../components/Providers/ProviderServiceList.js';
import HOCSectionLoader from '../../components/HOC/HOCSectionLoader.jsx';

import { Create } from '../ProviderService/Create.jsx';
import { AddMedia } from '../ProviderService/AddMedia.jsx';
import { Update } from '../ProviderService/Update.jsx';
import { Faq } from '../ProviderService/Faq.jsx';
import { Package } from '../ProviderService/Package.jsx';
import { Gallery } from '../ProviderService/Gallery.jsx';
import MediaUrl from '../ProviderService/MediaUrl.jsx';

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};

const sendPropsWithoutRouter = (props) => {
    const { match, location, ...rest } = props;
    return rest;
};

export class ProviderService extends Component {
    componentDidMount() {
        const { getFullProviderProfileAction } = this.props;
        this.getData();
        getFullProviderProfileAction();
    }

    getData = () => {
        const {
            userData,
            getProviderServicesAction,
            allProviderCategoryAction,
            allProviderSubCategoryAction,
        } = this.props;
        getProviderServicesAction({ id: userData.id });
        allProviderCategoryAction();
        allProviderSubCategoryAction();
    };

    render() {
        const {
            providerServiceData,
            providerServiceLoader,
            match,
            providerData,
            subcategoryData,
        } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <HOCSectionLoader loading={providerServiceLoader}>
                            <Switch>
                                <Route
                                    path={`${match.url}/create`}
                                    render={(props) => (
                                        <Create
                                            {...props}
                                            {...sendProps(this.props)}
                                            providerData={providerData}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/faq`}
                                    render={(props) => (
                                        <Faq
                                            {...props}
                                            {...sendPropsWithoutRouter(
                                                this.props
                                            )}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/package`}
                                    render={(props) => (
                                        <Package
                                            {...props}
                                            {...sendPropsWithoutRouter(
                                                this.props
                                            )}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/gallery`}
                                    render={(props) => (
                                        <Gallery
                                            {...props}
                                            {...sendPropsWithoutRouter(
                                                this.props
                                            )}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/url`}
                                    render={(props) => (
                                        <MediaUrl
                                            {...props}
                                            {...sendPropsWithoutRouter(
                                                this.props
                                            )}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/update/:serviceId`}
                                    render={(props) => (
                                        <Update
                                            {...props}
                                            {...sendPropsWithoutRouter(
                                                this.props
                                            )}
                                            subcategoryData={subcategoryData}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/addmedia`}
                                    render={(props) => (
                                        <AddMedia {...props} {...this.props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/`}
                                    exact={true}
                                    render={(props) => (
                                        <ProviderServiceList
                                            {...props}
                                            {...sendProps(this.props)}
                                            data={providerServiceData}
                                            getData={this.getData}
                                        />
                                    )}
                                />
                            </Switch>
                        </HOCSectionLoader>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ categories, auth }) => {
    return {
        providerServiceLoader: categories.providerServiceLoader,
        providerServiceData: categories.providerServiceData,
        providerData: auth.providerData,
        categoryData: categories.categoryData,
        categoryLoader: categories.categoryLoader,
        subcategoryData: categories.subcategoryData,
        subcategoryLoader: categories.subcategoryLoader,
    };
};

const composedComponent = compose(connect(mapStateToProps, actions));

export default composedComponent(ProviderService);
