import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { Card } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Person, Menu, Favorite, Money } from '@material-ui/icons';
import { withTheme, withStyles } from '@material-ui/core/styles';

import * as actions from '../../actions';

import DashboardLineChart from '../../components/Chart/DashboardLineChart';
import HOCSectionLoader from '../../components/HOC/HOCSectionLoader.jsx';

import 'bootstrap/dist/css/bootstrap.css';

const styles = (theme) => ({
    heading: {
        color: '#ffffff',
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
    },
    card: {
        color: theme.palette.secondary.main,
    },
});

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            menuState: false,
            loader: true,
            open: false,
        };
    }

    onOptionMenuSelect = (event) => {
        this.setState({ menuState: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => {
        this.setState({ menuState: false });
    };

    render() {
        const { classes } = this.props;
        const { loader } = this.state;
        return (
            <div>
                <HOCSectionLoader loading={loader}>
                    <div className="row">
                        <Card className={classes.card + ' ' + 'col-md-12'}>
                            <h3>
                                <FormattedMessage
                                    id="dashboard"
                                    defaultMessage="Dashboard"
                                />
                            </h3>
                        </Card>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-12">
                            <Paper
                                elevation={1}
                                className="dashboard-small-box bg-yellow"
                            >
                                <h3 style={{ color: '#fff' }}>
                                    <Person color="primary" />
                                    123
                                </h3>
                                <h4 className={classes.heading}>
                                    &nbsp;
                                    <FormattedMessage
                                        id="totalUsers"
                                        defaultMessage="Total Users"
                                    />
                                </h4>
                            </Paper>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <Paper
                                elevation={1}
                                className="dashboard-small-box bg-green"
                            >
                                <h3>
                                    <Menu />
                                    123
                                </h3>
                                <h4 className={classes.heading}>
                                    <FormattedMessage
                                        id="totalBookings"
                                        defaultMessage="Total Bookings"
                                    />
                                </h4>
                            </Paper>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <Paper
                                elevation={1}
                                className={
                                    classes.paper + ' ' + 'dashboard-small-box'
                                }
                            >
                                <h3>
                                    <Favorite />
                                    123
                                </h3>
                                <h4 className={classes.heading}>
                                    <FormattedMessage
                                        id="totalFavourites"
                                        defaultMessage="Total Favorites"
                                    />
                                </h4>
                            </Paper>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <Paper
                                elevation={1}
                                className="dashboard-small-box bg-cyan"
                            >
                                <h3>
                                    <Money />
                                    123
                                </h3>
                                <h4 className={classes.heading}>
                                    <FormattedMessage
                                        id="totalTransactions"
                                        defaultMessage="Total Transactions"
                                    />
                                </h4>
                            </Paper>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h3>
                                <FormattedMessage
                                    id="weeklyBookings"
                                    defaultMessage="Weekly Bookings"
                                />
                            </h3>
                        </div>
                        <DashboardLineChart />
                    </div>
                </HOCSectionLoader>
            </div>
        );
    }
}

const composedComponent = compose(
    withStyles(styles),
    withTheme(),
    connect(null, actions)
);

export default composedComponent(Dashboard);
