import axios from 'axios';

import {
    errorResponse,
    validateApiData,
} from '../../utils/validators/validators';
import { SERVER_URL } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

export const getThemeSettings = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/settings/fetch`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const changeLanguage = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/user/change-language`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const getHeaderLinks = async () => {
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/cms/list`,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const getCmsDetail = async (params) => {
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/cms/view`,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const bannerList = async (params) => {
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/settings/bannerlist`,
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};
