import React, { Component } from 'react';
import {
    CartesianGrid,
    Line,
    LineChart,
    XAxis,
    YAxis
} from 'recharts';
export const data = [
    { name: 'Let A', uv: 0 },
    { name: 'Let B', uv: 30 },
    { name: 'Let C', uv: 20 },
    { name: 'Let D', uv: 27 },
    { name: 'Let E', uv: 18 },
    { name: 'Let F', uv: 23 },
    { name: 'Let G', uv: 0 }
];

const styles = theme => ({
    LineChart: {
        width: '100%'
    }
});

export class DashboardLineChart extends Component {
    constructor(props) {
        super();
    }
    render() {
        const { data, theme } = this.props;
        return (
            <div className="chart-responsive">
                <LineChart
                    preserveAspectRatio="none"
                    width={1200}
                    height={400}
                    data={data}
                >
                    <XAxis preserveAspectRatio="none" dataKey="x" />
                    <YAxis preserveAspectRatio="none" />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                    <Line
                        preserveAspectRatio="none"
                        type="monotone"
                        dataKey="y"
                        stroke={theme.palette.primary.main}
                    />
                </LineChart>
            </div>
        );
    }
}

export default DashboardLineChart;
