import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';

const UserWrapper = Component =>
    class WithUser extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isLoading: true
            };
        }

        // Set up HOC
        async componentDidMount() {
            const { userData, getLoggedInUserAction } = this.props;
            if (userData.name) {
                this.setState({ isLoading: false });
            } else {
                const response = await getLoggedInUserAction();
                if (response) {
                    this.setState({ isLoading: false });
                } else {
                    this.setState({
                        isLoading: false,
                        redirect: true,
                        error: {
                            error: true,
                            message: 'Can not find user'
                        }
                    });
                }
            }
        }

        render() {
            return <Component {...this.props} {...this.state} />;
        }
    };
const mapStateToProps = ({ auth }) => {
    return {
        userData: auth.userData
    };
};
const composedWithUser = compose(
    connect(
        mapStateToProps,
        actions
    ),
    UserWrapper
);
export default composedWithUser;
