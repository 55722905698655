import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import * as actions from '../../actions';

import HOCSectionLoader from '../../components/HOC/HOCSectionLoader.jsx';
import ServiceFaqUpdate from '../../components/Providers/ServiceFaqUpdate.js';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

export class Update extends Component {
    constructor(props) {
        super();
        this.state = {
            data: [],
            loader: true,
        };
    }

    componentDidMount() {
        const { serviceFaqDetailAction, match } = this.props;
        serviceFaqDetailAction({ faqId: match.params.serviceId }, this.onDone);
    }

    onDone = (success, data) => {
        const { history } = this.props;
        if (success) {
            this.setState({ data: data.data, loader: false });
        } else {
            history.push(`provider/service/faq`);
        }
    };

    render() {
        const { classes, match } = this.props;
        const { loader, data } = this.state;
        return (
            <div>
                <div className={isMobile ? 'row mb-2' : 'row mb-4'}>
                    <div className="col-md-12">
                        <Card className={classes.card}>
                            <Toolbar>
                                <h3 className={isMobile && 'font-18'}>
                                    &nbsp;
                                    Update Faq
                                </h3>
                            </Toolbar>
                        </Card>
                    </div>
                </div>
                <HOCSectionLoader loading={loader}>
                    <div className="row">
                        <div className="col-md-12">
                            <ServiceFaqUpdate
                                {...this.props}
                                data={data}
                                serviceId={match.params.serviceId}
                            />
                        </div>
                    </div>
                </HOCSectionLoader>
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles), connect(null, actions));

export default composedComponent(Update);
