import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isBrowser, isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withTheme } from '@material-ui/core/styles';

import * as actions from '../../actions';

import { ROLE_PROVIDER } from '../../utils/config/constants';

import Detail from '../../components/Customer/BookingDetail.jsx';
import withCustomTheme from '../../components/HOC/HOCwithCustomTheme.jsx';
import WSButton from '../../components/Button/WSButton.jsx';

export class BookingDetail extends Component {
    componentDidMount() {
        const { getProviderServicesAction, userId } = this.props;
        getProviderServicesAction({ id: userId });
    }

    render() {
        const { userData, theme, history, match } = this.props;
        return (
            <div className="mb-20">
                {!userData && (
                    <div
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            height: '80px',
                            width: '100%',
                        }}
                    />
                )}
                <div className={isBrowser ? 'container mb-4 mt-20' : 'mb-2'}>
                    <div className="col-md-12 col-sm-12 p-0">
                        <Card>
                            <Toolbar
                                style={{ justifyContent: 'space-between' }}
                            >
                                <h3 className={isMobile && 'font-18'}>
                                    <FormattedMessage
                                        id="bookingDetail"
                                        defaultMessage="Booking Detail"
                                    />
                                </h3>
                                <WSButton
                                    style={{ left: 0 }}
                                    onClick={() =>
                                        userData &&
                                        userData.role === ROLE_PROVIDER
                                            ? history.push('/provider/booking')
                                            : history.push('/user/booking')
                                    }
                                >
                                    <FormattedMessage
                                        id="myBookings"
                                        defaultMessage="My Bookings"
                                    />
                                </WSButton>
                            </Toolbar>
                        </Card>
                    </div>
                </div>
                <div
                    className={isBrowser && 'container'}
                    style={{ minHeight: 300 }}
                >
                    <div className="col-md-12 col-sm-12 p-0">
                        <Detail
                            key={match.params.bookingId}
                            bookingId={match.params.bookingId}
                            {...this.props}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const composedComponent = compose(withTheme(), connect(null, actions));

export default composedComponent(withCustomTheme(BookingDetail));
