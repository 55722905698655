import React, { Fragment, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import * as actions from '../../actions';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    CircularProgress,
} from '@material-ui/core';
import ProgressiveImage from 'react-progressive-image';
import ContentLoader from 'react-content-loader';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    getBookingStatusClass,
    tConvert,
    convertTimestampToDate,
} from '../../utils/config/constants.js';
import {
    KeyboardArrowDown,
    FilterList,
    Store,
    LocationOn,
    Phone,
    Email,
    Person,
    Camera,
} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import MenuButton from '../Menu/MenuButton.js';
import { Card } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import {
    BOOKING_VIEW_ALL,
    BOOKING_VIEW_INPROGRESS,
    BOOKING_VIEW_NEW,
    BOOKING_VIEW_COMPLETED,
    BOOKING_VIEW_CANCELLED,
} from '../../utils/config/constants';
import WSButton from '../Button/WSButton';
import { isMobile } from 'react-device-detect';
import avatarImage from '../../assets/img/avatar.png';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    inline: {
        display: 'inline',
    },
    margin: {
        margin: theme?.spacing?.unit,
    },
    extendedIcon: {
        marginRight: theme?.spacing?.unit,
    },
    paper: {
        backgroundColor: theme?.palette?.background?.paper,
        marginBottom: '10px',
    },
});
function BookingList(props) {

    const [page, setPage] = useState(1);
    const [scrollClicked, setScrollClicked] = useState(false);
    const [detailRender, setDetailRender] = useState(false);
    const [bookingSelected, setBookingSelected] = useState('');
    const [showScroll, setShowScroll] = useState(true);

    useEffect(() => {
        const { bookingListAction } = props;
        bookingListAction({ page: page, append: false });
    }, []);

    const bookingData = useSelector(state => state?.bookings?.bookingData?.bookingList);

    const renderPromoImage = () => {
        return (
            <div style={{ width: 200, height: 200 }}>
                <ContentLoader
                    height={200}
                    width={200}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="2" ry="2" width="200" height="200" />
                </ContentLoader>
            </div>
        );
    };

    const handlePageChange = () => {
        const { bookingListAction } = props;
        setScrollClicked(false)
        const pages = page + 1
        bookingListAction({
            page: pages,
            append: true,
        });
        setPage(pages)
    };

    const renderScrollIcon = () => {
        if (scrollClicked) {
            return <CircularProgress />;
        } else {
            return (
                <WSButton
                    variant="fab"
                    style={{
                        padding: 0,
                        height: 40,
                    }}
                >
                    <KeyboardArrowDown onClick={handlePageChange} />
                </WSButton>
            );
        }
    };

    const renderDetail = (token) => {
        setDetailRender(true)
        setBookingSelected(token)
    };

    const actionCallbacks = (action) => {
        const { bookingListAction } = props;
        if (action === BOOKING_VIEW_ALL) {
            setShowScroll(true)
        } else {
            setShowScroll(false)
        }
        bookingListAction({ type: action, append: false });
    };

    const { classes, intl, history } = props;
    const { formatMessage } = intl;
    if (detailRender) {
        history.push({
            pathname: `/user/booking/detail/${bookingSelected}`,
            bookingId: bookingSelected,
        });
    }
    return (
        <div>
            <div className={isMobile ? 'row' : 'row mb-4'}>
                <div className="col-md-12">
                    <Card className={classes?.card}>
                        <Toolbar className="flex-justify-spacebetween">
                            <h3 className={isMobile && 'font-18'}>
                                <FormattedMessage
                                    id="myBookings"
                                    defaultMessage="My Bookings"
                                />
                            </h3>
                            <MenuButton
                                items={[
                                    {
                                        action: BOOKING_VIEW_ALL,
                                        label: formatMessage({
                                            id: 'booking.allbookings',
                                        }),
                                    },
                                    {
                                        action: BOOKING_VIEW_NEW,
                                        label: formatMessage({
                                            id: 'booking.newbookings',
                                        }),
                                    },
                                    {
                                        action: BOOKING_VIEW_INPROGRESS,
                                        label: formatMessage({
                                            id: 'booking.inprogress',
                                        }),
                                    },
                                    {
                                        action: BOOKING_VIEW_COMPLETED,
                                        label: formatMessage({
                                            id: 'booking.completedbookings',
                                        }),
                                    },
                                    {
                                        action: BOOKING_VIEW_CANCELLED,
                                        label: formatMessage({
                                            id: 'booking.cancelledbookings',
                                        }),
                                    },
                                ]}
                                menuId={'bookingMenu'}
                                menuCallback={(action) =>
                                    actionCallbacks(action)
                                }
                                iconType={FilterList}
                            />
                        </Toolbar>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {bookingData?.length > 0 ? (
                        <List
                            className={
                                classes?.root + ' dashboard-booking-tabs'
                            }
                        >
                            {bookingData.map((booking) => {
                                return (
                                    <ListItem
                                        key={booking?.key}
                                        className={classes?.paper}
                                    >
                                        <ListItemAvatar>
                                            <ProgressiveImage
                                                delay={2000}
                                                src={
                                                    booking &&
                                                        booking?.bookingImage &&
                                                        booking?.bookingImage
                                                            ?.medium
                                                        ? booking
                                                            ?.bookingImage
                                                            ?.medium
                                                        : avatarImage
                                                }
                                                placeholder="provider-image"
                                            >
                                                {(src, loading) =>
                                                    loading ? (
                                                        renderPromoImage()
                                                    ) : (
                                                        <LazyLoadImage
                                                            width="200px"
                                                            height="200px"
                                                            src={src}
                                                            alt="provider image"
                                                        />
                                                    )
                                                }
                                            </ProgressiveImage>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography={true}
                                            primary={
                                                <Fragment>
                                                    {isMobile ? (
                                                        <Fragment>
                                                            <h3
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                Booking ID:{' '}
                                                                {
                                                                    booking?.token
                                                                }
                                                            </h3>
                                                            <h3
                                                                style={{
                                                                    marginTop: 0,
                                                                }}
                                                            >
                                                                {convertTimestampToDate(
                                                                    booking?.datetime
                                                                )}
                                                                &nbsp;at&nbsp;
                                                                {tConvert(
                                                                    new Date(
                                                                        booking?.datetime
                                                                    )
                                                                )}
                                                            </h3>
                                                        </Fragment>
                                                    ) : (
                                                        <h3>
                                                            Booking ID:{' '}
                                                            {booking?.token}{' '}
                                                            - (
                                                            {convertTimestampToDate(
                                                                booking?.datetime
                                                            )}
                                                            &nbsp;at&nbsp;
                                                            {tConvert(
                                                                new Date(
                                                                    booking?.datetime
                                                                )
                                                            )}
                                                            )
                                                        </h3>
                                                    )}
                                                </Fragment>
                                            }
                                            secondary={
                                                <Fragment>
                                                    <p>
                                                        <Camera
                                                            fontSize={
                                                                isMobile
                                                                    ? 'small'
                                                                    : 'default'
                                                            }
                                                        />
                                                        &nbsp;
                                                        {booking &&
                                                            booking?.services &&
                                                            booking
                                                                ?.services[0] &&
                                                            booking
                                                                ?.services[0]
                                                                ?.service}
                                                    </p>
                                                    <p>
                                                        <Store
                                                            fontSize={
                                                                isMobile
                                                                    ? 'small'
                                                                    : 'default'
                                                            }
                                                        />
                                                        &nbsp;
                                                        {
                                                            booking?.businessName
                                                        }
                                                    </p>
                                                    <p>
                                                        <Person
                                                            fontSize={
                                                                isMobile
                                                                    ? 'small'
                                                                    : 'default'
                                                            }
                                                        />
                                                        &nbsp;
                                                        {booking?.provider}
                                                    </p>
                                                    <p>
                                                        <LocationOn
                                                            fontSize={
                                                                isMobile
                                                                    ? 'small'
                                                                    : 'default'
                                                            }
                                                        />
                                                        &nbsp;
                                                        {
                                                            booking
                                                                ?.providerAddress
                                                                ?.address_line1
                                                        }
                                                    </p>
                                                    <p>
                                                        <Phone
                                                            fontSize={
                                                                isMobile
                                                                    ? 'small'
                                                                    : 'default'
                                                            }
                                                        />
                                                        &nbsp;
                                                        {
                                                            booking
                                                                ?.providerAddress
                                                                ?.phone
                                                        }
                                                    </p>
                                                    <p>
                                                        <Email
                                                            fontSize={
                                                                isMobile
                                                                    ? 'small'
                                                                    : 'default'
                                                            }
                                                        />
                                                        &nbsp;
                                                        {
                                                            booking
                                                                ?.providerAddress
                                                                ?.email
                                                        }
                                                    </p>
                                                    <div
                                                        className={getBookingStatusClass(
                                                            booking?.status
                                                        )}
                                                    >
                                                        {`BOOKING STATUS: ${booking?.isPaymentPending
                                                            ? `${booking?.status} - Your payment in progess, it's reflect in few seconds`
                                                            : booking?.status
                                                            }`}
                                                    </div>
                                                </Fragment>
                                            }
                                        />
                                        <ListItemSecondaryAction className="booking-detail-arrows">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <WSButton
                                                    className={
                                                        classes?.margin
                                                    }
                                                    onClick={() =>
                                                        renderDetail(
                                                            booking?.token
                                                        )
                                                    }
                                                >
                                                    <FormattedMessage
                                                        id="viewDetail"
                                                        defaultMessage="View Detail"
                                                    />
                                                </WSButton>
                                            </div>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    ) : (
                        <div>
                            <p style={{ color: '#000' }}>
                                <FormattedMessage
                                    id="noBookingFound"
                                    defaultMessage="No Booking found"
                                />
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="text-center">
                {showScroll && renderScrollIcon()}
            </div>
        </div>
    );
}

const composedComponent = compose(
    withStyles(styles),
    connect(null, actions)
);
export default composedComponent(injectIntl(withRouter(BookingList)));
