import React, { Component, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, injectIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CircularProgress, Fade } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from '@material-ui/core/styles';

import * as actions from '../../actions';
import { validateOTP } from '../../utils/validators/validateForm';

import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

function CustomerVerification(props) {

    const [loaderButton, setLoaderButton] = useState(false)
    const [otp, setOtp] = useState('')
    const [otpError, setOtpError] = useState('')
    const [dashboardRedirect, setDashboardRedirect] = useState(false)
    const [playerId, setPlayerId] = useState('')

    useEffect(() => {
        try {
            setPlayerId(window?.onesignalId)
        } catch (e) {
            NotificationManager.error(e?.getMessage, 'Error');
        }
    }, []);


    const handleClose = () => {
        const { closePopup } = props;
        closePopup();
    };

    const renderButton = () => {
        const { theme } = props;
        if (loaderButton) {
            return <CircularProgress />;
        } else {
            return (
                <WSButton
                    style={{
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#ffffff',
                        marginRight: 10,
                        borderRadius: 50,
                        marginBottom: 20,
                    }}
                    onClick={verifyEmailActionCall}
                >
                    <FormattedMessage id="verify" defaultMessage="Verify" />
                </WSButton>
            );
        }
    };

    const verifyEmailActionCall = () => {
        const { checkOtpAction, email } = props;
        if (validateOTP(otp, handleError)) {
            setLoaderButton(true)
            checkOtpAction(
                {
                    email: email,
                    otp: otp,
                    is_email_verify: 1,
                    playerId: window?.onesignalId,
                },
                onDone
            );
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Oops!'
            );
        }
    };

    const handleError = (params) => {
        setOtpError(params?.otpError)
    };

    const onDone = (success, data) => {
        const { close } = props;
        if (success) {
            let cookies = new Cookies();
            setLoaderButton(false)
            setDashboardRedirect(true)
            handleClose();
            NotificationManager.success(
                <FormattedMessage
                    id="Welcome"
                    defaultMessage="Email Verified! Please Login"
                />,
                'Success'
            );
        } else {
            setLoaderButton(false)
            setOtpError(data)
        }
    };

    const { history, open, intl, theme } = props;
    return (
        <div>
            <Dialog
                fullWidth={true}
                TransitionComponent={Fade}
                disableBackdropClick={true}
                className="text-left"
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <form className="dialog-form dialog-form-new margin-unset">
                    <div
                        className="dialog-header"
                        style={{
                            backgroundColor: theme?.palette?.primary?.main,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <h3
                            className={
                                isMobile
                                    ? 'dialog-heading font-16'
                                    : 'dialog-heading'
                            }
                            style={{ color: 'white' }}
                        >
                            <FormattedMessage
                                id="verify.email"
                                defaultMessage="Verify Email"
                            />
                        </h3>
                        <IconButton
                            color="inherit"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                    <div className="dialog-body">
                        <DialogContent className="pt-0">
                            <WSTextField
                                error={otpError ? true : false}
                                helperText={otpError}
                                placeholder={intl.formatMessage({
                                    id: 'OTP',
                                })}
                                className="login-form-textinput"
                                id="otp"
                                name="otp"
                                value={otp}
                                onChange={(event) => {
                                    setOtp(event?.target?.value)
                                    setOtpError('')
                                }}
                            />
                            Note
                                <span style={{color: "red"}}>- Please check your email inbox/spam for OTP</span>
                        </DialogContent>

                        <DialogActions>
                            <div className="col-md-12">
                                {renderButton()}
                            </div>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
        </div>
    );
}

const composedHOC = compose(
    withTheme(),

    connect(null, actions)
);
export default composedHOC(withRouter(injectIntl(CustomerVerification)));
