import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';

import MediaUrlList from '../../components/Providers/MediaUrlList.js';

import MediaUrlCreate from './MediaUrlCreate.jsx';
import MediaUrlUpdate from './MediaUrlUpdate.jsx';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};

const sendPropsWithoutRouter = (props) => {
    const { match, location, ...rest } = props;
    return rest;
};

export class MediaUrl extends Component {
    render() {
        const { match } = this.props;
        return (
            <div>
                <Route
                    exact={true}
                    path={`${match.url}`}
                    render={(props) => (
                        <MediaUrlList {...props} {...sendProps(this.props)} />
                    )}
                />
                <Route
                    path={`${match.url}/create/:serviceId`}
                    render={(props) => (
                        <MediaUrlCreate
                            {...props}
                            {...sendPropsWithoutRouter(this.props)}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/update/:serviceId`}
                    render={(props) => (
                        <MediaUrlUpdate
                            {...props}
                            {...sendPropsWithoutRouter(this.props)}
                        />
                    )}
                />
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles));

export default composedComponent(MediaUrl);
