import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import { Card, CircularProgress } from '@material-ui/core';

import * as actions from '../../actions';

import { validateFAQCreateForm } from '../../utils/validators/validateForm';

import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';

import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

const styles = {
    width: '100%',
    height: '100%',
    formControl: {
        minWidth: '90%',
        margin: '15px'
    }
};

class ServiceFaqCreate extends Component {

    constructor(props) {
        super();
        this.state = {
            question: '',
            questionError: '',
            answer: '',
            answerError: '',
            loaderButton: false,
            pass: false,
            noResponse: false,
            errorMessage: '',
            validate: true,
            serviceId: props.serviceId
        };
    }

    submit = async () => {
        const { question, answer } = this.state;
        const result = await validateFAQCreateForm(
            {
                question,
                answer
            },
            this.handleError
        );
        if (result) {
            this.addFaq();
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Error'
            );
        }
    };

    addFaq = () => {
        this.setState({ loader: true });
        const {
            question,
            answer,
            serviceId
        } = this.state;
        const { addServiceFaqAction } = this.props;
        addServiceFaqAction(
            {
                question,
                answer,
                provider_service_id: serviceId
            },
            this.onAddDone
        );
    };

    handleError = params => {
        this.setState(params);
    };

    onAddDone = (success, data) => {
        const { history } = this.props;
        const { serviceId } = this.state;
        this.setState({ loader: false });
        if (success) {
            NotificationManager.success(data.message, 'Success');
            history.push({
                pathname: `/provider/service/faq`,
                serviceId: serviceId
            });
        }
    };

    renderButton = () => {
        const { loader } = this.state;
        if (loader) {
            return <CircularProgress />;
        }
        return (
            <WSButton
                variant="raised"
                className="jr-btn"
                onClick={this.submit}
            >
                <FormattedMessage id="add" defaultMessage="Add" />
            </WSButton>
        );
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render() {
        const {
            question,
            questionError,
            answer,
            answerError
        } = this.state;
        const { intl } = this.props;
        return (
            <Card className="col-md-12">
                <form
                    className="row"
                    noValidate
                    autoComplete="off"
                    id="faq-create"
                >
                    <div className="col-md-6 col-xs-12">
                        <WSTextField
                            className={isMobile && 'mt-15 mb-0'}
                            error={questionError ? true : false}
                            helperText={questionError}
                            name="question"
                            label={intl.formatMessage({
                                id: 'question'
                            })}
                            id="question"
                            placeholder={intl.formatMessage({
                                id: 'question'
                            })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            defaultValue={question}
                            onChange={this.handleChange('question')}
                        />
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <WSTextField
                            className={isMobile && 'mt-15 mb-15'}
                            error={answerError ? true : false}
                            helperText={answerError}
                            name="answer"
                            label={intl.formatMessage({
                                id: 'answer'
                            })}
                            id="answer"
                            placeholder={intl.formatMessage({
                                id: 'answer'
                            })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            defaultValue={answer}
                            onChange={this.handleChange('answer')}
                        />
                    </div>
                    <div className="col-md-12 col-xs-12">
                        {this.renderButton()}
                    </div>
                </form>
            </Card>
        );
    }
}

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default withCustomTheme(composedComponent(injectIntl(ServiceFaqCreate)));