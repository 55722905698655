import React, { lazy } from 'react';

import SlotCreate from 'views/User/index.jsx';

const Components = lazy(() => import('views/Components/Components.jsx'));
const CustomerDashboard = lazy(() => import('views/User/index.jsx'));
const CustomerProfile = lazy(() => import('views/User/index.jsx'));
const ProviderPortfolio = lazy(() => import('views/User/index.jsx'));
const CustomerAddress = lazy(() => import('views/User/index.jsx'));
const AddressCreate = lazy(() => import('views/User/index.jsx'));
const BookingList = lazy(() => import('views/User/index.jsx'));
const BookingDetail = lazy(() => import('../views/User/BookingDetail.jsx'));
const BookingReceipt = lazy(() => import('../views/User/BookingReceipt.jsx'));
const FavouriteExpansion = lazy(() => import('views/User/index.jsx'));
const ChangePassword = lazy(() => import('views/User/index.jsx'));
const Inbox = lazy(() => import('views/User/index.jsx'));
const AddressUpdate = lazy(() => import('views/User/index.jsx'));
const ServiceList = lazy(() => import('views/User/index.jsx'));
const ProviderList = lazy(() => import('../views/User/ProviderListing.jsx'));
const ProviderDetail = lazy(() => import('../views/User/ProviderDetail.jsx'));
const CategoryService = lazy(() =>
    import('../views/Category/ServiceListing.jsx')
);
const MainSearch = lazy(() => import('../views/Category/MainSearch.jsx'));
const Signup = lazy(() => import('../views/Signup/SignupStepper.jsx'));
const CreateProfile = lazy(() =>
    import('../views/CreateProfile/CreateProfileForm.jsx')
);
const LoginPage = lazy(() => import('../views/LoginPage/LoginPage'));
const BookingCreate = lazy(() => import('../views/Booking/Create.jsx'));
const BookingPayment = lazy(() => import('../views/Booking/Payment.jsx'));
const BookingPaypalPayment = lazy(() =>
    import('../views/Booking/PaypalPayment.jsx')
);
const Paypalpayment = lazy(() => import('../views/Paypal/Paypalpayment.js'));
const NotificationList = lazy(() => import('views/User/index.jsx'));
const ManagePortfolio = lazy(() => import('views/User/index.jsx'));
const PromocodeList = lazy(() => import('views/User/index.jsx'));
const ChatPanel = lazy(() => import('views/User/index.jsx'));
const NotAllowed = lazy(() => import('../views/NotAllowed.jsx'));
const Maintainence = lazy(() => import('../views/Maintainence.jsx'));
const Page = lazy(() => import('../views/Page/Detail.jsx'));
const Contact = lazy(() => import('../views/Page/Contact.jsx'));
const Paypal = lazy(() => import('../views/Booking/Paypal.jsx'));
const ServiceDetail = lazy(() =>
    import('../components/Category/ServiceDetail')
);

export let appRoutes = [
    {
        path: '/user/dashboard',
        name: 'CustomerDashboard',
        component: CustomerDashboard,
    },
    {
        path: '/user/bookingdetail/:bookingId',
        name: 'BookingDetail',
        component: BookingDetail,
    },
    {
        path: '/user/bookingreceipt',
        name: 'BookingReceipt',
        component: BookingReceipt,
    },
    {
        path: '/user/profile',
        name: 'CustomerProfile',
        component: CustomerProfile,
    },
    {
        path: '/user/profile/edit',
        name: 'CustomerProfileEdit',
        component: CustomerProfile,
    },
    {
        path: '/provider/portfolio',
        name: 'ProviderPortfolio',
        component: ProviderPortfolio,
    },
    {
        path: '/user/address',
        name: 'CustomerAddress',
        component: CustomerAddress,
    },
    {
        path: '/address/create',
        name: 'AddressCreate',
        component: AddressCreate,
    },
    {
        path: '/user/booking',
        name: 'BookingList',
        component: BookingList,
    },
    {
        path: '/provider/service',
        name: 'ServiceList',
        component: ServiceList,
    },
    {
        path: '/user/slots/list',
        name: 'SlotCreate',
        component: SlotCreate,
    },
    {
        path: '/provider/booking',
        name: 'BookingList',
        component: BookingList,
    },
    {
        path: '/user/favourite',
        name: 'FavouriteList',
        component: FavouriteExpansion,
    },
    {
        path: '/provider/list/:keyword/:type',
        name: 'ProviderList',
        component: ProviderList,
    },
    {
        path: '/provider/list/:serviceId',
        name: 'ProviderList',
        component: ProviderList,
    },
    {
        path: '/provider/list',
        name: 'ProviderList',
        component: ProviderList,
    },
    {
        path: '/provider/detail/:providerId/:serviceId',
        name: 'ProviderDetail',
        component: ProviderDetail,
    },
    {
        path: '/provider/detail/:providerId',
        name: 'ProviderDetail',
        component: ProviderDetail,
    },
    {
        path: '/category/services',
        name: 'CategoryServices',
        component: CategoryService,
    },
    {
        path: '/service/detail/:serviceId',
        name: 'ServiceDetail',
        component: ServiceDetail,
    },
    {
        path: '/mainsearch',
        name: 'MainSearch',
        component: MainSearch,
    },
    // {
    //     path: '/signup',
    //     name: 'Signup',
    //     component: Signup,
    // },
    {
        path: '/createprofile',
        name: 'CreateProfile',
        component: CreateProfile,
    },
    // {
    //     path: '/login',
    //     name: 'LoginPage',
    //     component: LoginPage,
    // },
    {
        path: '/book/service/:providerId/:serviceId/:itemId',
        name: 'BookingCreate',
        component: BookingCreate,
    },
    {
        path: '/book/service/:providerId/:serviceId',
        name: 'BookingCreate',
        component: BookingCreate,
    },
    {
        path: '/booking/payment/:bookingId',
        name: 'BookingPayment',
        component: BookingPayment,
    },
    {
        path: '/booking/paypalpayment/:bookingId',
        name: 'BookingPaypalPayment',
        component: BookingPaypalPayment,
    },
    {
        path: '/wallet/paypalpayment/:walletAmount',
        name: 'Paypalpayment',
        component: Paypalpayment,
    },
    {
        path: '/paypal',
        name: 'Paypal',
        component: Paypal,
    },
    {
        path: '/address/update/:addressId',
        name: 'AddressUpdate',
        component: AddressUpdate,
    },
    {
        path: '/user/chat',
        name: 'ChatPanel',
        component: ChatPanel,
    },
    // {
    //     path: '/booking/detail/:bookingId',
    //     name: 'BookingDetail',
    //     component: BookingDetail
    // },
    {
        path: '/notification/list',
        name: 'NotificationList',
        component: NotificationList,
    },
    {
        path: '/user/manage-portfolio',
        name: 'managePortfolio',
        component: ManagePortfolio,
    },
    {
        path: '/promocode/list',
        name: 'PromocodeList',
        component: PromocodeList,
    },
    {
        path: '/user/changepassword',
        name: 'ChangePassword',
        component: ChangePassword,
    },
    {
        path: '/user/inbox',
        name: 'Inbox',
        component: Inbox,
    },
    {
        path: '/page/:slug',
        name: 'Page',
        component: Page,
    },
    {
        path: '/contact-us',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/403',
        name: 'NotAllowed',
        component: NotAllowed,
    },
    {
        path: '/maintainence',
        name: 'Maintainence',
        component: Maintainence,
    },
    { path: '/', name: 'Components', component: Components, exact: true },
];

export let authRoutes = [
    {
        path: '/category/services',
        name: 'CategoryServices',
        component: CategoryService,
    },
    {
        path: '/service/detail/:serviceId',
        name: 'ServiceDetail',
        component: ServiceDetail,
    },
    {
        path: '/mainsearch',
        name: 'MainSearch',
        component: MainSearch,
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/provider/list/:keyword/:type',
        name: 'ProviderList',
        component: ProviderList,
    },
    {
        path: '/provider/list/:serviceId',
        name: 'ProviderList',
        component: ProviderList,
    },
    {
        path: '/provider/list',
        name: 'ProviderList',
        component: ProviderList,
    },
    {
        path: '/page/:slug',
        name: 'Page',
        component: Page,
    },
    {
        path: '/contact-us',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/403',
        name: 'NotAllowed',
        component: NotAllowed,
    },
    {
        path: '/maintainence',
        name: 'Maintainence',
        component: Maintainence,
    },
    { path: '/', name: 'Components', component: Components, exact: true },
];
