import React, { Fragment, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { withStyles } from '@material-ui/core/styles';
import ContentLoader from 'react-content-loader';
import { NotificationManager } from 'react-notifications';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
    CircularProgress,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Chip,
} from '@material-ui/core';
import {
    Check,
    Person,
    LocationOn,
    Store,
    Phone,
    Email,
} from '@material-ui/icons';
import HOCSectionLoader from '../HOC/HOCSectionLoader.jsx';
import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';
import {
    getBookingStatusClass,
    convertTimestampToDate,
    BOOKING_STATUS_INCART,
    getBookingPaymentStatus,
    getBookingPaymentStatusClass,
    getPaymentType,
    getPaymentTypeClass,
    ROLE_CUSTOMER,
    ROLE_PROVIDER,
    PAYMENT_PENDING_CARD,
} from '../../utils/config/constants.js';
import { tConvert } from '../../utils/config/constants';
import { formatPrice } from '../../utils/helpers/functions/validators';
import {
    BOOKING_STATUS_INITIATED,
    BOOKING_STATUS_CONFIRMED,
    BOOKING_STATUS_CANCELLED,
    BOOKING_STATUS_REJECTED,
    BOOKING_STATUS_START,
    BOOKING_STATUS_PAUSED,
    BOOKING_STATUS_END,
    BOOKING_STATUS_COMPLETED,
} from '../../utils/config/constants';
import SelectedBookingImages from '../../views/User/SelectedBookingImages';
import SelectBookingImages from '../../views/User/SelectBookingImages';
import RatingPopup from './RatingPopup.jsx';
import { isMobile } from 'react-device-detect';
import WSButton from '../Button/WSButton.jsx';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    inline: {
        display: 'inline',
    },
    margin: {
        margin: theme?.spacing?.unit,
    },
    extendedIcon: {
        marginRight: theme?.spacing?.unit,
    },
    paper: {
        backgroundColor: theme?.palette?.background?.paper,
        marginBottom: '10px',
    },
});

let isMounted = false;

function BookingDetail(props, componentRef) {

    const [data, setData] = useState({});
    const [loader, setLoader] = useState(true);
    const [showPaymentOption, setShowPaymentOption] = useState(false);
    const [cardPayment, setCardPayment] = useState(false);
    const [ratePopup, setRatePopup] = useState(false);
    const [actionLoader, setActionLoader] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [ratingDone, setRatingDone] = useState(false);
    const [isBookingCancelled, setIsBookingCancelled] = useState(false);
    const [isRebooking, setIsRebooking] = useState(false);
    const [showPayButton, setShowPayButton] = useState(true);

    useEffect(() => {
        isMounted = true;
        getData();
    }, []);

    useEffect((prevProps) => {
        const { location } = props;
        if (prevProps?.location?.bookingId !== location?.bookingId) {
            if (isMounted) {
                getData();
            }
        }
    }, []);

    const getData = () => {
        const {
            bookingDetailAction,
            checkBookingStatusAction,
            history,
            bookingId,
            getThemeSettingsAction
        } = props;
        getThemeSettingsAction();
        checkBookingStatusAction(
            {
                BOOKING_TOKEN:
                    history?.location?.search &&
                    history?.location?.search.split('=') &&
                    history?.location?.search.split('=')[1],
            },
            (success, data) => {
                bookingDetailAction(
                    {
                        bookingId: bookingId,
                    },
                    onDone
                );
            }
        );
    };

    const onDone = (success, data) => {
        if (success) {
            setData(data?.data)
            setLoader(false)
        } else {
            setLoader(false)
            NotificationManager.success(data?.message, 'Error');
        }
    };

    const renderService = (data) => {
        if (!data?.services) {
            return <CircularProgress />;
        } else {
            return (
                <div>
                    {data?.item_type === 1 && `${data?.services[0] && data?.services[0]?.service} - `}
                    {data?.services !== 'undefined' && data?.item_type === 1
                        ? data?.services[0] && data?.services[0]?.packageName
                        : data?.services[0] && data?.services[0]?.service}
                </div>
            );
        }
    };

    const renderServicePrice = (data) => {
        const { settings } = props;
        if (!data?.services) {
            return <CircularProgress />;
        } else {
            return (
                <div style={{ marginTop: 10 }}>
                    {settings?.constants?.CURRENCY_SYMBOL}{' '}
                    {data?.services !== 'undefined' && data?.item_type === 1
                        ? formatPrice(
                            data?.services[0] &&
                            data?.services[0]?.present_charge,
                            '0,0'
                        )
                        : data?.services[0] &&
                        formatPrice(data?.services[0]?.servicePrice, '0,0') +
                        '/hr'}
                </div>
            );
        }
    };

    const cancelBooking = (data) => {
        const { cancelBookingAction } = props;
        cancelBookingAction(
            {
                bookingId: data?.key,
            },
            onCancelDone
        );
    };

    const onCancelDone = (success, data) => {
        if (success) {
            setIsBookingCancelled(true)
            NotificationManager.success(
                <FormattedMessage
                    id="bookingCancelledSuccess"
                    defaultMessage="Booking Cancelled Successfully"
                />,
                'Cancelled'
            );
        } else {
            NotificationManager.error(data, 'Error');
        }
    };

    const renderCancelOption = (data) => {
        const { settings } = props;
        let cancellationMinutes =
            parseInt(settings?.constants?.CANCELLATION_MINUTES) * 60 * 1000;
        let bookingDateTime = parseInt(data?.datetime);
        let matchUpTime = bookingDateTime - cancellationMinutes;
        if (
            (matchUpTime > new Date().getTime() &&
                data?.status === BOOKING_STATUS_INITIATED) ||
            (matchUpTime > new Date().getTime() &&
                data?.status === BOOKING_STATUS_CONFIRMED)
        ) {
            return (
                <WSButton
                    variant="outlined"
                    onClick={() => cancelBooking(data)}
                >
                    <FormattedMessage
                        id="cancelBooking"
                        defaultMessage="Cancel Booking"
                    />
                </WSButton>
            );
        }
    };

    const renderRebookOption = (data) => {
        if (data?.reschedule) {
            if (isRebooking) {
                return <CircularProgress />;
            }
            return (
                <div className="text-center">
                    <WSButton
                        className="ml-2"
                        variant="outlined"
                        onClick={() => onRebook(data)}
                    >
                        <FormattedMessage id="rebook" defaultMessage="Rebook" />
                    </WSButton>
                </div>
            );
        }
    };

    const onRebook = (value) => {
        const { reBookingAction, history } = props;
        setIsRebooking(true)
        reBookingAction(
            {
                bookingId: data?.key,
            },
            (success, data) => {
                setIsRebooking(false)
                let selectedService = value?.item_type === 2 ? value?.services[0] : null
                let selectedPackage = value?.item_type === 1 ? value?.services[0] : null
                if (success) {
                    history.push({
                        pathname: `/book/service/${data?.providerDetail?.id}/${data?.webServiceId}`,
                        state: {
                            selectedService,
                            selectedPackage,
                            bookStatus: 'rebook',
                        },
                        search: `?selectedService=${btoa(
                            JSON.stringify(selectedService)
                        )}&selectedPackage=${btoa(JSON.stringify(selectedPackage))}`,
                    });
                } else {
                    NotificationManager.error(data, 'Error');
                }
            }
        );
    };

    const printOption = () => {
        return (
            <div className="mt-1">
                {

                    <Fragment>
                        <ReactToPrint
                            trigger={() => {
                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                // to the root node of the returned component as it will be overwritten.
                                return (
                                    <WSButton
                                        style={{ left: 0, marginRight: 20 }}
                                    // onClick={() => window.print()}
                                    >
                                        <FormattedMessage
                                            id="print"
                                            defaultMessage="Print"
                                        />
                                    </WSButton>
                                );
                            }}
                            content={() => componentRef}
                        />
                    </Fragment>

                    // )
                }
            </div>
        );
    };

    const renderActions = (data) => {
        if (data?.statusId === BOOKING_STATUS_INCART && !data?.isPaymentPending) {
            return (
                <div className="text-center mt-1 mb-1">
                    {renderPayButton(data)}
                    {printOption()}
                </div>
            );
        } else if (data?.statusId === BOOKING_STATUS_INITIATED) {
            return (
                <div className="text-center mt-1 mb-2">
                    <p>
                        <b>
                            &nbsp;
                            <FormattedMessage
                                id="waitingForProviderText"
                                defaultMessage="Waiting for provider to accept."
                            />
                        </b>
                    </p>
                    {printOption()}
                </div>
            );
        } else if (
            data?.statusId === BOOKING_STATUS_END ||
            data?.statusId === BOOKING_STATUS_COMPLETED
        ) {
            return (
                <div className="text-center mt-1 mb-1">
                    {renderPayButton(data)}
                    {renderReviewButton(data)}
                    {printOption()}
                </div>
            );
        } else if (
            data?.statusId === BOOKING_STATUS_CONFIRMED ||
            data?.statusId === BOOKING_STATUS_START ||
            data?.statusId === BOOKING_STATUS_PAUSED ||
            data?.statusId === BOOKING_STATUS_CANCELLED ||
            data?.statusId === BOOKING_STATUS_REJECTED
        ) {
            return (
                <div className="text-center mt-1 mb-1">
                    {printOption()}
                </div>
            );
        }
    };

    const renderReviewButton = (data) => {
        if (!data?.customer_reviewed && !ratingDone) {
            return (
                <WSButton onClick={showRatePopup}>
                    <FormattedMessage id="rate" defaultMessage="Rate" />
                </WSButton>
            );
        }
        return null;
    };

    const showRatePopup = () => {
        setRatePopup(true)
    };

    const renderPayButton = (data) => {
        if (!data?.customer_approved && showPayButton) {
            return (
                <WSButton className="mr-2" onClick={showPaymentOptions}>
                    <FormattedMessage id="pay" defaultMessage="Pay" />
                </WSButton>
            );
        }
        return null;
    };

    const showPaymentOptions = () => {
        setShowPaymentOption(true)
    };

    const renderAllPaymentOptions = (data) => {
        const { settings } = props;
        let { paymentOptions } = settings?.constants;
        return (
            <div className="text-center mt-1 mb-1">
                {paymentOptions &&
                    paymentOptions.map((option, index) => {
                        if (option?.value === data?.payment_type) {
                            return (
                                <WSButton
                                    style={{ marginRight: 10 }}
                                    key={index}
                                    variant="outlined"
                                    value={option?.value}
                                    name="paymentType"
                                    onClick={() =>
                                        proceedPayment(option?.value)
                                    }
                                >
                                    <FormattedMessage
                                        id="payBy"
                                        defaultMessage="Pay by"
                                    />
                                    &nbsp;{option?.label}
                                </WSButton>
                            );
                        }
                    })}
            </div>
        );
    };

    const proceedPayment = (value) => {
        const { walletPayAction } = props;
        if (value === 2) {
            setCardPayment(true)
        } else if (value === 3) {
            walletPayAction({ bookingToken: data?.token }, onPaymentDone);
        }
    };

    const onPaymentDone = (success, data) => {
        const { history } = props;
        if (success) {
            setShowPayButton(false)
            history.push('/user/booking');
            NotificationManager.success(data?.message, 'Success');
        } else {
            NotificationManager.error(data?.message, 'Error');
        }
    };

    const bookingAction = (type) => {
        const {
            acceptBookingAction,
            rejectBookingAction,
            approvePaymentBookingAction,
        } = props;
        setActionLoader(true)
        if (type === 1) {
            acceptBookingAction(
                { bookingToken: data?.token },
                onActionDone
            );
        } else if (type === 2) {
            rejectBookingAction(
                { bookingToken: data?.token },
                onActionDone
            );
        } else if (type === 3) {
            approvePaymentBookingAction(
                { bookingToken: data?.token },
                onActionDone
            );
        }
    };

    const onActionDone = (success, data) => {
        if (success) {
            NotificationManager.success(
                <FormattedMessage
                    id="actionCompleted"
                    defaultMessage="Action Completed"
                />,
                'Success'
            );
            setRedirect(true)
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="someErrorOccurred"
                    defaultMessage="Some Error Occured"
                />,
                'Error'
            );
        }
    };

    const renderProviderOptions = (data) => {
        if (actionLoader) {
            return (
                <div className="text-center">
                    <CircularProgress />
                </div>
            );
        } else {
            if (data?.statusId === BOOKING_STATUS_INITIATED) {
                return (
                    <div className="text-center mt-1 mb-1">
                        <WSButton
                            style={{ marginRight: 10 }}
                            key="accept"
                            variant="outlined"
                            value="accept"
                            name="paymentType"
                            onClick={() => bookingAction(1)}
                        >
                            <FormattedMessage
                                id="accept"
                                defaultMessage="Accept"
                            />
                        </WSButton>
                        <WSButton
                            style={{ marginRight: 10 }}
                            key="reject"
                            variant="outlined"
                            value="reject"
                            name="paymentType"
                            onClick={() => bookingAction(2)}
                        >
                            <FormattedMessage
                                id="reject"
                                defaultMessage="Reject"
                            />
                        </WSButton>
                        {printOption()}
                    </div>
                );
            } else if (
                (data?.statusId === BOOKING_STATUS_END ||
                    data?.statusId === BOOKING_STATUS_CONFIRMED) &&
                data?.payment_status === PAYMENT_PENDING_CARD
            ) {
                return (
                    <div className="text-center mt-1 mb-1">
                        <WSButton
                            style={{ marginRight: 10 }}
                            key="accept"
                            value="complete"
                            name="paymentType"
                            onClick={() => bookingAction(3)}
                        >
                            <Check />
                            &nbsp;
                            <FormattedMessage
                                id="approvePayment"
                                defaultMessage="Approve Payment"
                            />
                        </WSButton>
                        {printOption()}
                    </div>
                );
            } else if (
                data?.statusId === BOOKING_STATUS_END ||
                data?.statusId === BOOKING_STATUS_CONFIRMED
            ) {
                return (
                    <div className="text-center mt-1 mb-1">
                        <WSButton
                            style={{ marginRight: 10 }}
                            key="accept"
                            value="complete"
                            name="paymentType"
                            onClick={() => bookingAction(3)}
                        >
                            <Check />
                            &nbsp;
                            <FormattedMessage
                                id="Complete"
                                defaultMessage="Complete"
                            />
                        </WSButton>
                        {printOption()}
                    </div>
                );
            } else if (
                data?.statusId === BOOKING_STATUS_START ||
                data?.statusId === BOOKING_STATUS_PAUSED ||
                data?.statusId === BOOKING_STATUS_CANCELLED ||
                data?.statusId === BOOKING_STATUS_REJECTED
            ) {
                return (
                    <div className="text-center mt-1 mb-1">
                        {printOption()}
                    </div>
                );
            }
        }
    };

    const handleRatingClose = () => {
        setRatePopup(false)
    };

    const ratingDoneCheck = () => {
        setRatingDone(true)
    };

    const { intl, history, match, settings } = props;
    let userData = props?.userData
        ? props?.userData
        : { role: ROLE_CUSTOMER };
    const { formatMessage } = intl;
    const CustomTag = isMobile ? 'h5' : 'h4';

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: `/provider/booking`,
                }}
            />
        );
    }
    if (cardPayment) {
        setTimeout(function () {
            window.location = data?.payment_url;
        }, 3000);
    }
    return (
        <div ref={(el) => (componentRef = el)}>
            <HOCSectionLoader loading={loader}>
                <Paper>
                    <div className="tableWrapper-table">
                        <Table className="table dashboard-tables">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="bookingId"
                                                defaultMessage="Booking ID"
                                            />
                                        </CustomTag>
                                    </TableCell>
                                    <TableCell>
                                        <CustomTag
                                            style={{
                                                marginBottom: isMobile && 7,
                                            }}
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {data?.token}
                                        </CustomTag>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="bookingStatus"
                                                defaultMessage="Booking Status"
                                            />
                                        </CustomTag>
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            className={getBookingStatusClass(
                                                !isBookingCancelled
                                                    ? data?.status
                                                    : 'Cancelled'
                                            )}
                                            label={
                                                !isBookingCancelled
                                                    ? data?.isPaymentPending
                                                        ? `${data?.status} - Your payment in progess, it's reflect in few seconds`
                                                        : data?.status
                                                    : 'Cancelled'
                                            }
                                            style={{ marginBottom: 7 }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userData?.role === ROLE_CUSTOMER && (
                                    <TableRow>
                                        <TableCell colSpan="2">
                                            {!showPaymentOption
                                                ? renderActions(data)
                                                : renderAllPaymentOptions(
                                                    data
                                                )}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {userData?.role === ROLE_PROVIDER && (
                                    <TableRow>
                                        <TableCell
                                            colSpan="2"
                                            style={{
                                                marginTop: 3,
                                                marginBottom: 3,
                                            }}
                                        >
                                            {renderProviderOptions(
                                                data
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}

                                <TableRow>
                                    <TableCell colSpan="2">
                                        {data?.status !== 'Cancelled' &&
                                            !isBookingCancelled &&
                                            renderCancelOption(data)}

                                        {userData?.role === ROLE_CUSTOMER &&
                                            renderRebookOption(data)}
                                    </TableCell>
                                </TableRow>

                                {isMobile ? (
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            {data?.bookingImage && (
                                                <LazyLoadImage
                                                    width="200px"
                                                    className="mobile-img-table"
                                                    src={
                                                        userData?.role ===
                                                            ROLE_CUSTOMER
                                                            ? data
                                                                ?.bookingImage
                                                                ?.medium
                                                            : data?.image
                                                                ?.medium
                                                    }
                                                />
                                            )}
                                            {data?.customerAddress && (
                                                <Fragment>
                                                    <div>
                                                        <div>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '600',
                                                                    }}
                                                                >
                                                                    {userData?.role ===
                                                                        ROLE_CUSTOMER
                                                                        ? 'Provider Details:'
                                                                        : 'Customer Details:'}
                                                                </span>
                                                            </CustomTag>
                                                            {userData?.role ===
                                                                ROLE_CUSTOMER && (
                                                                    <CustomTag
                                                                        className={
                                                                            isMobile &&
                                                                            'font-14'
                                                                        }
                                                                        style={{
                                                                            marginTop: 0,
                                                                            marginBottom: 0,
                                                                        }}
                                                                    >
                                                                        <Store
                                                                            style={{
                                                                                color:
                                                                                    'grey',
                                                                            }}
                                                                            fontSize="small"
                                                                        />
                                                                        &nbsp;
                                                                        {
                                                                            data?.businessName
                                                                        }
                                                                    </CustomTag>
                                                                )}
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Person
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                    fontSize="small"
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data?.provider
                                                                    : data?.customerName}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                    fontSize="small"
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.providerAddress
                                                                        ?.address_line1
                                                                    : data
                                                                        ?.customerAddress
                                                                        ?.address_line1}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Phone
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                    fontSize="small"
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.providerAddress
                                                                        ?.phone
                                                                    : data
                                                                        ?.customerAddress
                                                                        ?.phone}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Email
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                    fontSize="small"
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.providerAddress
                                                                        ?.email
                                                                    : data
                                                                        ?.customerAddress
                                                                        ?.email}
                                                            </CustomTag>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '600',
                                                                    }}
                                                                >
                                                                    Your
                                                                    Details:
                                                                </span>
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Person
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data?.customerName
                                                                    : data?.provider}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                    fontSize="small"
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.customerAddress
                                                                        ?.address_line1
                                                                    : data
                                                                        ?.providerAddress
                                                                        ?.address_line1}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Phone
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                    fontSize="small"
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.customerAddress
                                                                        ?.phone
                                                                    : data
                                                                        ?.providerAddress
                                                                        ?.phone}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Email
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                    fontSize="small"
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.customerAddress
                                                                        ?.email
                                                                    : data
                                                                        ?.providerAddress
                                                                        ?.email}
                                                            </CustomTag>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <TableRow>
                                        <TableCell>
                                            {data?.bookingImage && (
                                                <LazyLoadImage
                                                    width="200px"
                                                    className="mobile-img-table"
                                                    src={
                                                        userData?.role ===
                                                            ROLE_CUSTOMER
                                                            ? data
                                                                ?.bookingImage
                                                                ?.medium
                                                            : data
                                                                ?.customerImage
                                                                ?.medium
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className="text-left">
                                            {data?.customerAddress && (
                                                <Fragment>
                                                    <div>
                                                        <div>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '600',
                                                                    }}
                                                                >
                                                                    {userData?.role ===
                                                                        ROLE_CUSTOMER
                                                                        ? 'Provider Details:'
                                                                        : 'Customer Details:'}
                                                                </span>
                                                            </CustomTag>
                                                            {userData?.role ===
                                                                ROLE_CUSTOMER && (
                                                                    <CustomTag
                                                                        className={
                                                                            isMobile &&
                                                                            'font-14'
                                                                        }
                                                                        style={{
                                                                            marginTop: 0,
                                                                            marginBottom: 0,
                                                                        }}
                                                                    >
                                                                        <Store
                                                                            style={{
                                                                                color:
                                                                                    'grey',
                                                                            }}
                                                                        />
                                                                        &nbsp;
                                                                        {
                                                                            data?.businessName
                                                                        }
                                                                    </CustomTag>
                                                                )}
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Person
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data?.provider
                                                                    : data?.customerName}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.providerAddress
                                                                        ?.address_line1
                                                                    : data
                                                                        ?.customerAddress
                                                                        ?.address_line1}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Phone
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.providerAddress
                                                                        ?.phone
                                                                    : data
                                                                        ?.customerAddress
                                                                        ?.phone}
                                                                &nbsp;
                                                                <Email
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.providerAddress
                                                                        ?.email
                                                                    : data
                                                                        ?.customerAddress
                                                                        ?.email}
                                                            </CustomTag>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '600',
                                                                    }}
                                                                >
                                                                    Your
                                                                    Details:
                                                                </span>
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Person
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data?.customerName
                                                                    : data?.provider}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.customerAddress
                                                                        ?.address_line1
                                                                    : data
                                                                        ?.providerAddress
                                                                        ?.address_line1}
                                                            </CustomTag>
                                                            <CustomTag
                                                                className={
                                                                    isMobile &&
                                                                    'font-14'
                                                                }
                                                                style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                <Phone
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.customerAddress
                                                                        ?.phone
                                                                    : data
                                                                        ?.providerAddress
                                                                        ?.phone}
                                                                &nbsp;
                                                                <Email
                                                                    style={{
                                                                        color:
                                                                            'grey',
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {userData?.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? data
                                                                        ?.customerAddress
                                                                        ?.email
                                                                    : data
                                                                        ?.providerAddress
                                                                        ?.email}
                                                            </CustomTag>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell
                                        style={{
                                            width: 250,
                                            marginTop: isMobile && 22,
                                        }}
                                    >
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="bookingType"
                                                defaultMessage="Booking Type"
                                            />
                                        </CustomTag>
                                    </TableCell>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {data?.item_type === 1
                                                ? 'Package'
                                                : 'Hourly Based'}
                                        </CustomTag>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: 250 }}>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {data?.item_type === 1 ? (
                                                <FormattedMessage
                                                    id="bookingServicePackage"
                                                    defaultMessage="Booking Service & Package"
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    id="bookingService"
                                                    defaultMessage="Booking Service"
                                                />
                                            )}
                                        </CustomTag>
                                    </TableCell>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {renderService(data)}
                                        </CustomTag>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: 250 }}>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >

                                            <FormattedMessage
                                                id="bookingLocation"
                                                defaultMessage="Booking Location"
                                            />
                                        </CustomTag>
                                    </TableCell>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {data?.shootType}
                                        </CustomTag>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="schedule"
                                                defaultMessage="Schedule"
                                            />
                                        </CustomTag>
                                    </TableCell>

                                    <TableCell>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: isMobile
                                                    ? 'flex-end'
                                                    : 'center',
                                                marginBottom: isMobile && 5,
                                            }}
                                        >
                                            <CustomTag
                                                className={
                                                    isMobile && 'font-14'
                                                }
                                            >
                                                {convertTimestampToDate(
                                                    data?.datetime
                                                )}
                                                &nbsp; at&nbsp;
                                            </CustomTag>
                                            <Chip
                                                color="primary"
                                                label={tConvert(
                                                    new Date(data?.datetime)
                                                )}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="subAmount"
                                                defaultMessage="Sub Amount"
                                            />
                                        </CustomTag>
                                    </TableCell>

                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {renderServicePrice(data)}
                                        </CustomTag>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="promoCodeApplied"
                                                defaultMessage="Promo Code Applied"
                                            />
                                        </CustomTag>
                                    </TableCell>

                                    <TableCell>
                                        <CustomTag
                                            style={{
                                                marginTop: isMobile && 20,
                                            }}
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {data?.promo_code
                                                ? data?.promo_code?.code
                                                : '-'}
                                        </CustomTag>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="totalAmount"
                                                defaultMessage="Total Amount"
                                            />
                                        </CustomTag>
                                    </TableCell>

                                    <TableCell>
                                        <CustomTag
                                            style={{ marginTop: 10 }}
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {
                                                settings?.constants
                                                    ?.CURRENCY_SYMBOL
                                            }{' '}
                                            {data?.total_amount
                                                ? formatPrice(
                                                    data?.total_amount,
                                                    '0,0'
                                                )
                                                : '0'}
                                        </CustomTag>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="paymentStatus"
                                                defaultMessage="Payment Status"
                                            />
                                        </CustomTag>
                                    </TableCell>

                                    <TableCell>
                                        <Chip
                                            className={getBookingPaymentStatusClass(
                                                data?.payment_status
                                            )}
                                            label={getBookingPaymentStatus(
                                                data?.payment_status
                                            )}
                                            style={{ marginTop: 5 }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="paymentType"
                                                defaultMessage="Payment Type"
                                            />
                                        </CustomTag>
                                    </TableCell>

                                    <TableCell>
                                        <Chip
                                            className={getPaymentTypeClass(
                                                data?.payment_type
                                            )}
                                            label={getPaymentType(
                                                data?.payment_type
                                            )}
                                            style={{ marginTop: 5 }}
                                        />
                                    </TableCell>
                                </TableRow>
                                {data?.questionoireData &&
                                    data?.questionoireData?.length > 0 && (
                                        <tr>
                                            <td>
                                                <CustomTag
                                                    className={
                                                        isMobile &&
                                                        'font-14'
                                                    }
                                                >
                                                    Questionnaire Detail :
                                                </CustomTag>
                                            </td>
                                            <td>
                                                {data?.questionoireData && (
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <b>
                                                                        Question
                                                                    </b>
                                                                </td>
                                                                <td>
                                                                    <b>
                                                                        Answer
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            {data?.questionoireData.map(
                                                                (
                                                                    quetiore
                                                                ) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                <CustomTag
                                                                                    className={
                                                                                        isMobile &&
                                                                                        'font-14'
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        quetiore?.question
                                                                                    }
                                                                                    &nbsp;
                                                                                </CustomTag>
                                                                            </td>
                                                                            <td>
                                                                                <CustomTag
                                                                                    className={
                                                                                        isMobile &&
                                                                                        'font-14'
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        quetiore?.answer
                                                                                    }
                                                                                    &nbsp;
                                                                                </CustomTag>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="additionalNote"
                                                defaultMessage="Additional Note"
                                            />
                                        </CustomTag>
                                    </TableCell>
                                    <TableCell>
                                        <CustomTag
                                            style={{
                                                marginTop: isMobile && 10,
                                            }}
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {data?.note !== '' && data?.note}
                                            {data?.note === '' && '-'}
                                        </CustomTag>
                                    </TableCell>
                                </TableRow>

                                {data?.rating && (
                                    <Fragment>
                                        <TableRow>
                                            <TableCell>
                                                <CustomTag
                                                    className={
                                                        isMobile &&
                                                        'font-14'
                                                    }
                                                >
                                                    <FormattedMessage
                                                        id="rating"
                                                        defaultMessage="Rating"
                                                    />
                                                </CustomTag>
                                            </TableCell>
                                            <TableCell>
                                                <CustomTag
                                                    className={
                                                        isMobile &&
                                                        'font-14'
                                                    }
                                                >
                                                    {data?.rating?.ar
                                                        ? data?.rating?.ar +
                                                        '/5'
                                                        : '-'}
                                                </CustomTag>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <CustomTag
                                                    className={
                                                        isMobile &&
                                                        'font-14'
                                                    }
                                                >
                                                    <FormattedMessage
                                                        id="review"
                                                        defaultMessage="Review"
                                                    />
                                                </CustomTag>
                                            </TableCell>
                                            <TableCell>
                                                <CustomTag
                                                    className={
                                                        isMobile &&
                                                        'font-14'
                                                    }
                                                >
                                                    {data?.rating?.review
                                                        ? data?.rating?.review
                                                        : '-'}
                                                </CustomTag>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                )}
                                <TableRow>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            <FormattedMessage
                                                id="dob"
                                                defaultMessage="Date of Booking"
                                            />
                                        </CustomTag>
                                    </TableCell>
                                    <TableCell>
                                        <CustomTag
                                            className={
                                                isMobile && 'font-14'
                                            }
                                        >
                                            {convertTimestampToDate(
                                                data?.created_at
                                            )}
                                        </CustomTag>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    {userData?.role === ROLE_PROVIDER &&
                        data &&
                        data?.provider_id !== null &&
                        data?.statusId >= BOOKING_STATUS_CONFIRMED && (
                            <SelectedBookingImages
                                bookingToken={data?.token}
                                sharedImages={data?.sharedImages}
                                isShared={data?.isShared}
                            />
                        )}
                    {userData?.role === ROLE_CUSTOMER &&
                        data &&
                        data?.sharedImages &&
                        data?.sharedImages?.length > 0 &&
                        data?.isShared && (
                            <SelectBookingImages
                                bookingToken={data?.token}
                                sharedImages={data?.sharedImages}
                            />
                        )}
                </Paper>

                {ratePopup && (
                    <RatingPopup
                        open={ratePopup}
                        close={handleRatingClose}
                        r1Value={settings?.constants?.R1}
                        r2Value={settings?.constants?.R2}
                        r3Value={settings?.constants?.R3}
                        arValue={settings?.constants?.AR}
                        starRatedColor={settings?.colors?.RATING?.HEX}
                        bookingToken={data?.token}
                        reviewDone={ratingDoneCheck}
                    />
                )}
            </HOCSectionLoader>
        </div>
    );
}

export default withStyles(styles)(withCustomTheme(injectIntl(BookingDetail)));
