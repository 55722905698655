import { serviceFaq } from '../apiHelpers/faq/list';

export const serviceFaqAction = (params, done) => async (dispatch) => {
    const response = await serviceFaq(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};
