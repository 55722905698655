import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
    Tooltip,
    IconButton,
    TableRow,
    TableSortLabel,
    Toolbar,
    TablePagination,
    TableHead,
    TableCell,
    TableBody,
    Typography,
    Table,
    Paper
} from '@material-ui/core';

import * as actions from '../../actions';

import HOCSectionLoader from '../HOC/HOCSectionLoader.jsx';

import MenuButton from '../Menu/MenuButton';
import ChangeStatusAlert from '../Alert/ChangeStatusAlert.jsx';
import DeleteAlert from '../Alert/DeleteAlert.jsx';
import WSButton from '../Button/WSButton.jsx';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    {
        id: 'question',
        label: 'Question'
    },
    {
        id: 'answer',
        label: 'Answer'
    },

    {
        id: 'action',
        label: 'Action'
    }
];

class FaqListHead extends Component {

    createSortHandler = property => event => {
        const { onRequestSort } = this.props;
        onRequestSort(event, property);
    };

    render() {
        const { order, orderBy } = this.props;
        return (
            <TableHead>
                <TableRow>
                    {rows.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

FaqListHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: '1 1 100%'
    },
    actions: {
        color: theme.palette.secondary.main
    },
    title: {
        flex: '0 0 auto'
    }
});

let FaqListToolbar = props => {
    const { numSelected, classes, deleteAction, serviceId } = props;
    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                        <h6>FAQ List</h6>
                    )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton
                            aria-label="Delete"
                            onClick={this.deleteAddress}
                        >
                            <DeleteIcon onClick={deleteAction} />
                        </IconButton>
                    </Tooltip>
                ) : (
                        <Tooltip title="Add">
                            <WSButton
                                variant="fab"
                                aria-label="Add"
                                style={{
                                    padding: 0,
                                    width: isMobile && 40,
                                    height: isMobile && 40,
                                }}
                            >
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: '#FFFFFF',
                                    }}
                                    to={`/provider/service/faq/create/${serviceId}`}
                                >
                                    <AddIcon />
                                </Link>
                            </WSButton>
                        </Tooltip>
                    )}
            </div>
        </Toolbar>
    );
};

FaqListToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired
};

FaqListToolbar = withStyles(toolbarStyles)(FaqListToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3
    },
    table: {
        minWidth: 1020
    },
    tableWrapper: {
        overflowX: 'auto'
    }
});

class FaqList extends Component {
    constructor(props) {
        super();
        this.state = {
            order: 'desc',
            orderBy: 'question',
            selected: [],
            data: [],
            service: '',
            page: 0,
            rowsPerPage: 5,
            clickValue: '',
            item: '',
            itemId: props.location.service ? props.location.service.key : '',
            showAlert: false,
            selectedKey: '',
            loader: true,
            deleteAlert: false,
            deleteSelected: ''
        };
    }

    componentDidMount() {
        const { location, providerServiceDetailAction, history } = this.props;
        if (location.service) {
            providerServiceDetailAction(
                {
                    proServiceId: location.service.key
                },
                this.onDone
            );
        } else if (location.serviceId) {
            providerServiceDetailAction(
                {
                    proServiceId: location.serviceId
                },
                this.onDone
            );
        } else {
            history.push(`/provider/service`);
        }
    }

    onDone = (success, data) => {
        if (success) {
            this.setState({
                loader: false,
                data: data.data.providerServiceFaq,
                service: data.data
            });
        } else {
            this.setState({ loader: false });
            NotificationManager.error(data.message, 'Error');
        }
    };

    actionCallbacks(action, res) {
        if (action === 'update') {
            this.setState({
                clickValue: 'update',
                item: res,
                menuState: false,
                itemId: res.key
            });
        } else if (action === 'changestatus') {
            this.changeRecord(res.key);
        } else if (action === 'delete') {
            this.deleteRecord(res.key);
        }
    }

    deleteRecord = id => {
        this.setState({ deleteAlert: true, deleteSelected: id });
    };

    deleteFaq = () => {
        const { deleteSelected } = this.state;
        const { deleteServiceFaqAction } = this.props;
        deleteServiceFaqAction(
            {
                faqId: deleteSelected
            },
            this.onDeleteDone
        );
    };

    onDeleteDone = (success, data) => {
        if (success) {
            this.setState({ deleteAlert: false });
            NotificationManager.success(data.message, 'Success');
        } else {
            this.setState({ deleteAlert: false });
            NotificationManager.error(data.message, 'Error');
        }
    };

    handleCloseDelete = () => {
        this.setState({ deleteAlert: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n.key) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    changeRecord = key => {
        this.setState({ showAlert: true, selectedKey: key });
    };

    changeItemStatus = () => {
        const { serviceChangeStatusAction } = this.props;
        const { selectedKey } = this.state;
        this.setState({ loader: true });
        serviceChangeStatusAction(
            {
                proServiceId: selectedKey
            },
            (success, data) => this.onChangeDone(success, data)
        );
    };

    onChangeDone = (success, data) => {
        const { getData } = this.props;
        this.setState({ loader: false });
        if (success) {
            this.setState({ showAlert: false });
            getData();
        }
    };

    render() {
        const {
            classes,
            match,
            history
        } = this.props;
        const {
            data,
            service,
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            clickValue,
            item,
            itemId,
            loader,
            showAlert,
            deleteAlert
        } = this.state;
        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, data.length - page * rowsPerPage);
        if (clickValue) {
            history.push({
                pathname: `${match.url}/update/${itemId}`,
                service: item
            });
        }
        return (
            <div>
                <HOCSectionLoader loading={loader}>
                    <div className={isMobile ? "row" : "row mb-4"}>
                        <div className="col-md-12">
                            <Card className={classes.card}>
                                <Toolbar>
                                    <h3 className={isMobile && 'font-18'}>Service FAQs: {service.name}</h3>
                                </Toolbar>
                            </Card>
                        </div>
                    </div>
                    <Paper className={classes.root + isMobile && "mt-10"}>
                        <FaqListToolbar
                            numSelected={selected.length}
                            deleteAction={this.deleteRecord}
                            match={match}
                            serviceId={service.key}
                        />
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                            >
                                <FaqListHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                />
                                <TableBody>
                                    {stableSort(
                                        data,
                                        getSorting(order, orderBy)
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map(n => {
                                            const isSelected = this.isSelected(
                                                n.key
                                            );
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.key}
                                                    selected={isSelected}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {n.question}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {n.answer}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <MenuButton
                                                            rowData={n}
                                                            items={[
                                                                {
                                                                    action: 'update',
                                                                    label: 'Update'
                                                                },
                                                                {
                                                                    action: 'delete',
                                                                    label: 'Delete'
                                                                }
                                                            ]}
                                                            menuId={'userMenu'}
                                                            menuCallback={(
                                                                action,
                                                                rowData
                                                            ) =>
                                                                this.actionCallbacks(
                                                                    action,
                                                                    rowData
                                                                )
                                                            }
                                                            iconType={
                                                                MoreVertIcon}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 49 * emptyRows }}
                                        >
                                            <TableCell colSpan={8} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page'
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </HOCSectionLoader>
                <ChangeStatusAlert
                    open={showAlert}
                    title="service"
                    close={() => this.setState({ showAlert: false })}
                    handleYes={this.changeItemStatus}
                    handleNo={() => this.setState({ showAlert: false })}
                />
                <DeleteAlert
                    open={deleteAlert}
                    close={deleteAlert}
                    handleNo={this.handleCloseDelete}
                    handleYes={this.deleteFaq}
                />
            </div>
        );
    }
}

FaqList.propTypes = {
    classes: PropTypes.object.isRequired
};

const composedComponent = compose(
    connect(
        null,
        actions
    )
);

export default composedComponent(withStyles(styles)(FaqList));