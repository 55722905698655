import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';

import { withTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
    CircularProgress,
    Fade,
    DialogContent,
    DialogActions,
    Dialog,
} from '@material-ui/core';

import * as actions from '../../actions';

import { validateOTP } from '../../utils/validators/validateForm';

import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

class Verification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderButton: false,
            loaderMain: false,
            value: 0,
            phone: '',
            emailOtp: '',
            phoneOtp: '',
            showContent: 1,
            dashboardRedirect: false
        };
    }

    componentDidMount = async () => {
        this.setState({
            playerId: window.onesignalId
        });
    };

    handleClose = () => {
        const { close } = this.props;
        close();
    };

    verifyActionCall = () => {
        const { checkProviderOtpAction, email } = this.props;
        const { emailOtp, playerId } = this.state;
        if (validateOTP(emailOtp, this.handleError)) {
            this.setState({ loaderButton: true });
            checkProviderOtpAction(
                {
                    email: email,
                    otp: emailOtp,
                    playerId: playerId
                },
                this.onEmailVerifyDone
            );
        }
        else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Oops!'
            );
        }
        // else if (showContent === 2) {
        //     if (phoneValidate(phone, this.handleError)) {
        //         this.setState({ loaderButton: true });
        //         sendOtpOnPhoneAction(
        //             {
        //                 email: email,
        //                 phone: phone,
        //                 playerId: playerId
        //             },
        //             this.onPhoneDone
        //         );
        //     }
        // } else if (showContent === 3) {
        //     if (validateOTP(phoneOtp, this.handleError)) {
        //         this.setState({ loaderButton: true });
        //         checkOtpOnPhoneAction(
        //             {
        //                 email: email,
        //                 otp: phoneOtp,
        //                 playerId: playerId
        //             },
        //             this.onPhoneVerifyDone
        //         );
        //     }
        // }
    };

    handleError = params => {
        this.setState(params);
    };

    onPhoneVerifyDone = (success, data) => {
        if (success) {
            this.setState(function (previousState) {
                return {
                    showContent: previousState.showContent + 1,
                    loaderButton: false
                };
            });
        } else {
            this.setState({
                loaderButton: false,
                verifyError: true,
                emailOtpError: data
            });
        }
    };

    onPhoneDone = (success, data) => {
        if (success) {
            this.setState(function (previousState) {
                return {
                    showContent: previousState.showContent + 1,
                    loaderButton: false
                };
            });
        } else {
            this.setState({
                loaderButton: false,
                verifyError: true,
                phoneError: data
            });
        }
    };

    onEmailVerifyDone = (success, data) => {
        if (success) {
            let cookies = new Cookies();
            if (!cookies.get('x-auth-token', { path: '/' })) {
                cookies.set('x-auth-token', data.token, { path: '/' });
            }
            this.setState({
                loaderButton: false,
                dashboardRedirect: true
            });
        } else {
            this.setState({
                loaderButton: false,
                verifyError: true,
                phoneOtpError: data
            });
        }
    };

    renderButton = () => {
        const { loaderButton, verifyError } = this.state;
        const { theme } = this.props;
        if (loaderButton) {
            return <CircularProgress />;
        } else {
            return (
                <div>
                    <WSButton
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                            marginRight: 10,
                            borderRadius: 50,
                            marginBottom: 20
                        }}
                        onClick={this.verifyActionCall}
                    >
                        <FormattedMessage
                            id="verify.title"
                            defaultMessage="Verify"
                        />
                    </WSButton>
                    {verifyError && (
                        <WSButton
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                color: '#ffffff',
                                borderRadius: 50
                            }}
                            onClick={this.handleClose}
                        >
                            <FormattedMessage
                                id="verify.close"
                                defaultMessage="Close"
                            />
                        </WSButton>
                    )}
                </div>
            );
        }
    };

    render() {
        const {
            intl,
            history,
            open,
            theme
        } = this.props;
        const { dashboardRedirect, emailOtp } = this.state;
        if (dashboardRedirect) {
            NotificationManager.success(
                <FormattedMessage id="Welcome" defaultMessage="Registration Successful! Please Login" />,
                'Success'
            );
            history.push(`/login`);
        }
        const { emailOtpError, loaderButton } = this.state;
        const { formatMessage } = intl;
        return (
            <div>
                <Dialog
                    fullWidth={true}
                    TransitionComponent={Fade}
                    disableBackdropClick={true}
                    className="text-center"
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <form className="dialog-form dialog-form-new margin-unset">
                        <div
                            className="dialog-header"
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingLeft: 15
                            }}
                        >
                            {/* {showContent === 1 && ( */}
                            <h3 className={isMobile ? "dialog-heading font-16" : "dialog-heading"}>
                                <FormattedMessage
                                    id="verify.email"
                                    defaultMessage="Verify Email"
                                />
                            </h3>
                            <IconButton
                                color="inherit"
                                onClick={this.handleClose}
                                aria-label="Close"
                            >
                                <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                            {/* )} */}
                            {/* {showContent === 2 && (
                                <h3 className={isMobile ? "dialog-heading font-16" : "dialog-heading"}>
                                    <FormattedMessage
                                        id="verify.enterphone"
                                        defaultMessage="Enter Phone Number"
                                    />
                                </h3>
                            )}
                            {showContent === 3 && (
                                <h3 className={isMobile ? "dialog-heading font-16" : "dialog-heading"}>
                                    <FormattedMessage
                                        id="verify.phone"
                                        defaultMessage="Verify Phone"
                                    />
                                </h3>
                            )} */}
                        </div>
                        <div className="dialog-body">
                            <DialogContent className="pt-0">
                                {/* {showContent === 1 && ( */}
                                <WSTextField
                                    error={emailOtpError ? true : false}
                                    helperText={emailOtpError}
                                    placeholder={formatMessage({
                                        id: 'verify.otp'
                                    })}
                                    className="login-form-textinput"
                                    id="emailOtp"
                                    name="emailOtp"
                                    value={emailOtp}
                                    onChange={event => {
                                        this.setState({
                                            emailOtp: event.target.value,
                                            emailOtpError: ''
                                        });
                                    }}
                                    disabled={loaderButton}
                                />
                                Note
                                <span style={{color: "red"}}>- Please check your email inbox/spam for OTP</span>
                                {/* )} */}
                                {/* {showContent === 2 && (
                                    <WSTextField
                                        error={phoneError ? true : false}
                                        helperText={phoneError}
                                        label={formatMessage({
                                            id: 'verify.phone'
                                        })}
                                        id="phone"
                                        name="phone"
                                        value={phone}
                                        onChange={event => {
                                            this.setState({
                                                phone: event.target.value,
                                                phoneError: ''
                                            });
                                        }}
                                        disabled={loaderButton}
                                    />
                                )}
                                {showContent === 3 && (
                                    <WSTextField
                                        error={phoneOtpError ? true : false}
                                        helperText={phoneOtpError}
                                        label={formatMessage({
                                            id: 'verify.otp'
                                        })}
                                        id="phoneOtp"
                                        name="phoneOtp"
                                        value={phoneOtp}
                                        onChange={event => {
                                            this.setState({
                                                phoneOtp: event.target.value,
                                                phoneOtpError: ''
                                            });
                                        }}
                                        disabled={loaderButton}
                                    />
                                )} */}
                            </DialogContent>
                            <DialogActions>
                                <div className="col-md-12">
                                    {this.renderButton()}
                                </div>
                            </DialogActions>
                        </div>
                    </form>
                </Dialog>
            </div>
        );
    }
}

const composedHOC = compose(
    withTheme(),

    connect(
        null,
        actions
    )
);

export default composedHOC(injectIntl(withRouter(Verification)));