import React, { Component } from 'react';
import { ChatList } from 'react-chat-elements';

import Paper from '@material-ui/core/Paper';

import * as actions from '../../actions';

function MessageInbox(props) {

    return (
        <div>
            <div className="col-md-4">
                <Paper>
                    <ChatList
                        className="chat-list"
                        dataSource={[
                            {
                                avatar:
                                    'https://api.jerryondemand.com:1340/uploads/profile//small/KvWZrmwWMJEd7fr6J97gThHSIMvutaO9.png',
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 0
                            },
                            {
                                avatar:
                                    'https://api.jerryondemand.com:1340/uploads/profile//small/KvWZrmwWMJEd7fr6J97gThHSIMvutaO9.png',
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 0
                            },
                            {
                                avatar:
                                    'https://api.jerryondemand.com:1340/uploads/profile//small/KvWZrmwWMJEd7fr6J97gThHSIMvutaO9.png',
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 0
                            }
                        ]}
                    />
                </Paper>
            </div>
            <div className="col-md-8">
                <Paper>
                    <ChatList
                        className="chat-list"
                        dataSource={[
                            {
                                avatar:
                                    'https://api.jerryondemand.com:1340/uploads/profile//small/KvWZrmwWMJEd7fr6J97gThHSIMvutaO9.png',
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 0
                            },
                            {
                                avatar:
                                    'https://api.jerryondemand.com:1340/uploads/profile//small/KvWZrmwWMJEd7fr6J97gThHSIMvutaO9.png',
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 0
                            },
                            {
                                avatar:
                                    'https://api.jerryondemand.com:1340/uploads/profile//small/KvWZrmwWMJEd7fr6J97gThHSIMvutaO9.png',
                                alt: 'Reactjs',
                                title: 'Facebook',
                                subtitle: 'What are you doing?',
                                date: new Date(),
                                unread: 0
                            }
                        ]}
                    />
                </Paper>
            </div>
        </div>
    );
}

export default MessageInbox;
