import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import { NotificationManager } from 'react-notifications';
import PhoneInput from 'react-phone-input-2';

import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import WSButton from "../../components/Button/WSButton";
import WSTextField from "../../components/TextField/WSTextField";
import Select from "../../components/Select/Select";
import Option from "../../components/Select/Option";

import '../../assets/css/style.css';
import 'react-phone-input-2/lib/style.css';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme?.palette?.background?.paper
    },
});

const ProviderBusinessInformation = props => {
   
    const [loaderButton, setLoaderButton] = useState(false);
    const [contactNoActive, setContactNoActive] = useState(false);
    const userData = useSelector(state => state?.auth?.userData);
    const updateBusinessDetailAPI = (values) => {
        const { updateFullProviderProfileAction } = props;
        const {
            businessName,
            licenseType,
            licenseNo,
            licenseValid,
            baseLocation,
            contactNo,
            estDate
        } = values
        updateFullProviderProfileAction({
            businessName,
            licenseType,
            licenseNo,
            licenseValidtill: licenseValid,
            baseLocation,
            establishedOn: estDate,
            contactNo_Office: contactNo
        }, onDone)
    }

    const onDone = (success) => {
        if (success) {
            NotificationManager.success('Business Details Updated', null, 2000);
        } else {
            NotificationManager.error('Business Details Update Failed', null, 2000);
        }
    };

        const { theme } = props;
        return (
            <Formik
                initialValues={{
                    businessName: userData && userData?.businessName || '',
                    status: userData && userData?.status || '',
                    licenseType: userData && userData?.licenseType || '',
                    licenseNo: userData && userData?.licenseNo || '',
                    licenseValid: userData && userData?.licenseValidtill || '',
                    baseLocation: userData && userData?.baseLocation || '',
                    contactNo: String(userData && userData?.contactNo_Office) || '971',
                    estDate: userData && userData?.establishedOn || '',
                }}
                enableReinitialize
                onSubmit={(values) => updateBusinessDetailAPI(values)}
            >
                {props => (
                    <form
                        className="dialog-form dialog-form-new"
                        autoComplete="off"
                    >
                        <div className="row col-md-12 p-0">
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                                    Business Name
                                </span>
                                <WSTextField
                                    error={props?.errors?.businessName && props?.touched?.businessName ? true : false}
                                    helperText={props?.errors?.businessName && props?.touched?.businessName ? props?.errors?.businessName : ''}
                                    className="login-form-textinput"
                                    id="businessName"
                                    name="businessName"
                                    placeholder={'Business Name '}
                                    value={props?.values?.businessName}
                                    onChange={props.handleChange('businessName')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>License Type</span>
                                <Select
                                    name="licenseType"
                                    id="licenseType"
                                    className="form-control form-control-select w-100 mt-25"
                                    onChange={props.handleChange('licenseType')}
                                    value={props?.values?.licenseType}
                                >
                                    <Option value="">Select License Type</Option>
                                    <Option value="Trade">Trade</Option>
                                    <Option value="Commercial">Commercial</Option>
                                    <Option value="Free Zone">Free Zone</Option>
                                    <Option value="Freelancer">Freelancer</Option>
                                </Select>
                                {props?.errors?.licenseType && props?.touched?.licenseType && (
                                    <div className="error-text">{props?.errors?.licenseType}</div>
                                )}
                            </div>
                            <span className='w-100'></span>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>License No</span>
                                <WSTextField
                                    error={props?.errors?.licenseNo && props?.touched?.licenseNo ? true : false}
                                    helperText={props?.errors?.licenseNo && props?.touched?.licenseNo ? props?.errors?.licenseNo : ''}
                                    className="login-form-textinput"
                                    id="licenseNo"
                                    name="licenseNo"
                                    placeholder={'License No'}
                                    value={props?.values?.licenseNo}
                                    onChange={props.handleChange('licenseNo')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>License Valid Till</span>
                                <WSTextField
                                    error={props?.errors?.licenseValid && props?.touched?.licenseValid ? true : false}
                                    helperText={props?.errors?.licenseValid && props?.touched?.licenseValid ? props?.errors?.licenseValid : ''}
                                    className="login-form-textinput"
                                    id="licenseValid"
                                    name="licenseValid"
                                    type="date"
                                    placeholder={'License Valid Till'}
                                    value={props?.values?.licenseValid}
                                    onChange={props.handleChange('licenseValid')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <span className='w-100'></span>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Base Location</span>
                                <Select
                                    name="baseLocation"
                                    id="baseLocation"
                                    className="form-control form-control-select w-100 mt-25"
                                    onChange={props.handleChange('baseLocation')}
                                    value={props?.values?.baseLocation}
                                >
                                    <Option value="">Select Base Location</Option>
                                    <Option value="Dubai">Dubai</Option>
                                    <Option value="Abu Dhabi">Abu Dhabi</Option>
                                    <Option value="Sharjah">Sharjah</Option>
                                    <Option value="Other Emirates">Other Emirates</Option>
                                </Select>
                                {props?.errors?.baseLocation && props?.touched?.baseLocation && (
                                    <div className="error-text">{props?.errors?.baseLocation}</div>
                                )}
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Contact No Office</span>
                                <PhoneInput
                                    country={'ae'}
                                    enableSearch
                                    autoFormat
                                    countryCodeEditable={false}
                                    disableSearchIcon
                                    placeholder="Contact No Office"
                                    value={props?.values?.contactNo}
                                    containerStyle={{ marginTop: 25 }}
                                    inputStyle={{ backgroundColor: '#f6f9ff', width: '100%', height: 50, borderRadius: 0, borderColor: contactNoActive && '#e24f36' }}
                                    inputClass="border-shadow-none"
                                    buttonStyle={{ backgroundColor: '#f6f9ff', borderRadius: 0, borderColor: contactNoActive && '#e24f36' }}
                                    onChange={phone => props.setFieldValue('contactNo', phone)}
                                    onFocus={() => setContactNoActive(true)}
                                    onBlur={() => setContactNoActive(false)}
                                />
                                {props?.errors?.contactNo && props?.touched?.contactNo && (
                                    <div className="error-text">{props?.errors?.contactNo}</div>
                                )}
                            </div>
                            <span className='w-100'></span>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Established Date</span>
                                <WSTextField
                                    error={props?.errors?.estDate && props?.touched?.estDate ? true : false}
                                    helperText={props?.errors?.estDate && props?.touched?.estDate ? props?.errors?.estDate : ''}
                                    className="login-form-textinput"
                                    id="estDate"
                                    name="estDate"
                                    type="date"
                                    placeholder={'Established Date'}
                                    value={props?.values?.estDate}
                                    onChange={props.handleChange('estDate')}
                                    disabled={loaderButton}
                                />
                            </div>
                        </div>
                        <div className="flex-justify-spacebetween mt-2">
                            <div>
                                <WSButton
                                    style={{
                                        backgroundColor: theme?.palette?.primary?.main,
                                        color: '#ffffff',
                                        margin: !isMobile && 20,
                                        borderRadius: 30
                                    }}
                                    onClick={props.handleSubmit}
                                >
                                    Save Updates
                                </WSButton>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        );
    }

export default withStyles(styles)(withTheme()(ProviderBusinessInformation));