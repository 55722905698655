import axios from 'axios';

import {
    errorResponse,
    validateApiData,
} from '../../utils/validators/validators';
import { SERVER_URL } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

export const bookingList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/booking/my`,
            params: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerBookingList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/provider/bookings`,
            params: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const bookingDetail = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/booking/detail`,
            params: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const bookingTimeSlot = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/provider/checkslots`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const promoCodeList = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/promocodes/fetch`,
            // data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const checkPromoCode = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/promocodes/check`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const cardList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/cards/list`,

            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};
