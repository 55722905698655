import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';

import componentsStyle from 'assets/jss/material-kit-react/views/components.jsx';

import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';

import AnimatedLoader from '../Loader/loader';

import Retry from './Retry.js';

class HOCLoader extends Component {
    render() {
        const {
            containerClass,
            //loadingContainerClass,
            retryContainerClass,
            errorShow,
            retryLink,
            loading,
            noResponse,
            redirect,
            redirectTo,
            children
        } = this.props;

        if (loading) {
            return (
                <div>
                    <div className={'loader-view loader-center'}>
                        <AnimatedLoader />
                    </div>
                </div>
            );
        }
        if (noResponse) {
            return (
                <div className={retryContainerClass}>
                    <Retry errorMessage={errorShow} retryTo={retryLink} />
                </div>
            );
        }
        if (redirect) {
            return (
                <Redirect
                    to={{
                        pathname: redirectTo
                    }}
                />
            );
        }

        return <div className={containerClass}>{children}</div>;
    }
}
const composedComponent = compose(
    withTheme(),
    withStyles(componentsStyle)
);
export default composedComponent(HOCLoader);
