import React, { Fragment, Component, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import ProgressiveImage from 'react-progressive-image';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import ContentLoader from 'react-content-loader';

import { withStyles } from '@material-ui/core/styles';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from '@material-ui/core';

import * as actions from '../../actions';

import WSButton from '../Button/WSButton';

const styles = theme => ({
    root: {
        width: '100%'
    },
    inline: {
        display: 'inline'
    },
    margin: {
        margin: theme?.spacing?.unit
    },
    extendedIcon: {
        marginRight: theme?.spacing?.unit
    },
    paper: {
        backgroundColor: theme?.palette?.background?.paper,
        marginBottom: '10px'
    }
});

function PromocodeList(props) {

    useEffect(() => {
        const { promoCodeListAction } = props;
        promoCodeListAction();
    }, []);

    const promoData = useSelector(state => state?.promos?.promoData);

    const renderPromoImage = index => {
        return (
            <div style={{ width: 200, height: 210 }} key={index}>
                <ContentLoader
                    height={220}
                    width={200}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="2" ry="2" width="200" height="210" />
                </ContentLoader>
            </div>
        );
    };
    const { classes } = props;
    return (
        <List className={classes?.root}>
            {promoData?.length !== 0 ?
                promoData.map((promo, index) => {
                    return (
                        <ListItem key={promo?.key} className={classes?.paper}>
                            <ListItemAvatar>
                                <ProgressiveImage
                                    delay={2000}
                                    src={promo?.image?.medium}
                                    placeholder="promo-image"
                                >
                                    {(src, loading) =>
                                        loading ? (
                                            renderPromoImage(index)
                                        ) : (
                                            <LazyLoadImage
                                                width="200px"
                                                height="220px"
                                                src={src}
                                                alt="promo image"
                                            />
                                        )
                                    }
                                </ProgressiveImage>
                            </ListItemAvatar>

                            <ListItemText
                                primary={
                                    <h3 id="hello" value={promo?.code}>
                                        {promo?.code}
                                    </h3>
                                }
                                secondary={
                                    <Fragment>
                                        <h6>{promo?.heading}</h6>
                                        <br />
                                        <b>
                                            &nbsp;
                                            Valid on your&nbsp;
                                                {promo?.booking_count} bookings
                                            </b>
                                    </Fragment>
                                }
                            />
                            <ListItemSecondaryAction>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <WSButton
                                        className={classes?.margin}
                                    >
                                        Expire On:&nbsp;
                                            {moment(promo?.end_date).format(
                                        'Do MMMM YYYY'
                                    )}
                                    </WSButton>
                                </div>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                }) : <span
                    style={{
                        display: 'flex',
                        fontSize: 20,
                        color: 'black',
                        textAlign: 'center',
                        alignSelf: 'center'
                    }}
                >No promo code found</span>}
        </List>
    );
}

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default composedComponent(PromocodeList);
