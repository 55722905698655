import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { tConvert } from '../../../../utils/config/constants';

const SentMessageCell = ({ conversation, proImage }) => {
    return (
        <div className="d-flex flex-nowrap chat-item flex-row-reverse">
            <LazyLoadImage
                width="50px"
                height="50px"
                className="rounded-circle avatar size-40 align-self-end"
                src={conversation.user.avatar ? conversation.user.avatar.small : proImage}
                alt={conversation.user.name}
            />

            <div className="bubble jambo-card">
                <div className="message">{conversation.text}</div>
                <div className="time text-muted text-right mt-2">
                    {tConvert(new Date(conversation.created_at))}
                </div>
            </div>
        </div>
    );
};

export default SentMessageCell;
