import React, { Component } from 'react';
import { Star } from '@material-ui/icons';

class RatingStar extends Component {
    render() {
        const {
            color,
            size,
            value
        } = this.props;
        return (
            <div className="row">
                <Star color={color} />
                <p style={{ fontSize: size }}>{value}</p>
            </div>
        );
    }
}

export default RatingStar;