import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
    Card,
    Avatar,
    CardHeader,
    CardContent,
} from '@material-ui/core';

import * as actions from '../../actions';

import default1 from '../../assets/img/no-image-available-icon.jpg';

import HOCLoader from '../HOC/HOCLoader.jsx';

import GridItem from '../Grid/GridItem.jsx';
import RatingStar from '../Rating/RatingStar.js';

class ServiceReview extends Component {

    state = {
        ratingList: {
            subRatings: []
        },
        loader: true
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const { serviceRatingAction, data } = this.props;
        serviceRatingAction(
            {
                proServiceId: data.proServiceId,
                userId: data.key,
                limit: 10
            },
            this.onDone
        );
    };

    onDone = (success, data) => {
        if (success) {
            this.setState({ ratingList: data.data, loader: false });
        }
    };

    imageError = (key) => {
        this.setState({
            [key]: true
        })
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true
        };
        const { ratingList, loader } = this.state;
        if (ratingList.subRatings.length <= 0) {
            return (
                <p>
                    <FormattedMessage
                        id="rating.notfound"
                        defaultMessage="No review found"
                    />
                </p>
            );
        }
        return (
            <HOCLoader laoding={loader}>
                {ratingList.subRatings.map(rating => {
                    return (
                        <GridItem
                            xl={12}
                            lg={12}
                            md={12}
                            xs={12}
                            sm={12}
                            key={rating.key}
                        >
                            <Card style={{ marginBottom: 15 }}>
                                <CardHeader
                                    style={{ textAlign: 'left' }}
                                    avatar={
                                        <Avatar
                                            style={{ marginRight: 20 }}
                                            onError={() => this.imageError(rating.key)}
                                            aria-label="Recipe"
                                            src={this.state[rating.key] ? default1 : rating.proImage.small}
                                        />
                                    }
                                    title={rating.name}
                                    subheader={
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                            <RatingStar
                                                size="15"
                                                value={rating.ar}
                                                color="primary"
                                            />
                                        </div>
                                    }
                                />
                                <CardContent
                                    style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}
                                >
                                    <p style={{ color: '#000' }}>
                                        {rating.review}
                                    </p>
                                </CardContent>
                            </Card>
                        </GridItem>
                    );
                })}
            </HOCLoader>
        );
    }
}

export default connect(
    null,
    actions
)(ServiceReview);