import numeral from 'numeral';

export const validateName = (name) => {
    const regex = /^[a-zA-Z ]{2,30}$/;
    if (regex.test(name)) {
        return true;
    }
    return false;
};

export const validateEmail = (email) => {
    if (email.includes('@') && email.includes('.')) {
        return true;
    }
    return false;
};

export const validatePassword = (password) => {
    return password.length > 6;
};

export const validateSignInData = (email, password) => {
    try {
        if (validateEmail(email) && password) {
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
};

export const validateSignUpData = (name, email, password) => {
    try {
        if (
            validateEmail(email) &&
            validateName(name) &&
            validatePassword(password)
        ) {
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
};

export const formatPrice = (price, format) => {
    const formatedPrice = numeral(price).format(format);
    return formatedPrice;
};
