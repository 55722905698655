import React, { Component } from 'react';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import CustomerAddressUpdate from '../../components/Customer/CustomerAddressUpdate.jsx';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

export class AddressUpdate extends Component {
    render() {
        const { classes, data } = this.props;
        return (
            <div>
                <div className={isMobile ? 'row mb-2' : 'row mb-4'}>
                    <div className="col-md-12">
                        <Card className={classes.card}>
                            <Toolbar>
                                <h3 className={isMobile && 'font-18'}>
                                    <FormattedMessage
                                        id="updateAddress"
                                        defaultMessage="Update Address"
                                    />
                                </h3>
                            </Toolbar>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <CustomerAddressUpdate {...this.props} address={data} />
                    </div>
                </div>
            </div>
        );
    }
}

const composedAddress = compose(withStyles(styles));

export default composedAddress(AddressUpdate);
