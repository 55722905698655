import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { addLocaleData, IntlProvider } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_fr from 'react-intl/locale-data/fr';

import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Fade } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

import { store } from '../store/index';

import * as actions from '../actions';

import messages_fr from '../utils/translations/fr.json';
import messages_en from '../utils/translations/en.json';

import { appRoutes, authRoutes } from '../routes/index.jsx';

import MainPageLinks from '../components/Header/MainPageLinks';
import MainLeftLinks from '../components/Header/MainLeftLinks';
import Header from '../components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import withCustomTheme from '../components/HOC/HOCwithCustomTheme.jsx';
import withUser from '../components/HOC/HOCwithUser.jsx';
import Verification from '../components/Providers/Verification.jsx';
import CustomerVerification from '../components/Customer/CustomerVerification.jsx';
import ScrollToTop from '../components/HOC/ScrollToTop.jsx';
import HOCLoader from '../components/HOC/HOCLoader.jsx';
import WSButton from '../components/Button/WSButton.jsx';

import componentsStyle from 'assets/jss/material-kit-react/views/components.jsx';
import logo from 'assets/img/logo.png';

import { getCookies } from '../utils/getToken';

import LoginForm from './LoginPage/LoginForm.jsx';
import ForgotPassword from './LoginPage/ForgotPassword.jsx';
import NotFound from './NotFound.jsx';

import '../assets/css/style.css';
import 'react-notifications/lib/notifications.css';
import 'assets/scss/material-kit-react.css?v=1.4.0';
import { Helmet } from 'react-helmet';

const messages = {
    fr: messages_fr,
    en: messages_en,
};

addLocaleData([...locale_en, ...locale_fr]);

const theme = (settings) => {
    return createMuiTheme({
        palette: {
            primary: { main: settings.colors.SECONDARY.HEX },
            secondary: { main: settings.colors.PRIMARY.HEX, text: '#ffffff' },
        },
        typography: { useNextVariants: true },
    });
};

let hist = createBrowserHistory();
let isMounted = true;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoginPopupVisible: false,
            isSignupPopupVisible: false,
            renderDashPage: false,
            signupRedirect: false,
            showProviderVerification: false,
            showCustomerVerification: false,
            showForgotPopup: false,
            usedEmail: '',
            language: props.currentLanguage ? props.currentLanguage : 'en',
            redirectPage: false,
            slug: '',
            open: true,
            pathname: hist.location.pathname,
        };
    }

    async componentDidMount() {
        const {
            checkMaintainence,
            getHeaderLinksAction,
            bannerListAction,
            getNationalityAction,
            getLanguagesAction,
        } = this.props;
        await checkMaintainence();
        await getHeaderLinksAction();
        await bannerListAction();
        await getNationalityAction();
        await getLanguagesAction();
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentLanguage } = this.props;
        const { pathname } = this.state;
        if (prevProps.currentLanguage !== currentLanguage) {
            if (isMounted) {
                this.setState({ language: currentLanguage });
            }
        }
        if (pathname !== hist.location.pathname) {
            this.handleClickOpen();
            this.setState({ pathname: hist.location.pathname });
        }
    }

    componentWillUnmount() {
        isMounted = false;
    }

    renderLogin = () => {
        const { showLoginPopup } = this.props;
        showLoginPopup();
        this.forceUpdate();
    };

    renderProviderVerification = (email) => {
        this.setState({ showProviderVerification: true, usedEmail: email });
    };

    renderCustomerVerification = (email) => {
        this.setState({ showCustomerVerification: true, usedEmail: email });
    };

    renderForgotPassword = (email) => {
        this.setState({ showForgotPopup: true, usedEmail: email });
    };

    closeLoginPopup = () => {
        const { hideLoginPopup } = this.props;
        hideLoginPopup();
        this.forceUpdate();
    };

    handleVerifyClose = () => {
        this.setState({ showProviderVerification: false });
    };

    handleCustomClose = () => {
        this.setState({ showCustomerVerification: false });
    };

    handleForgotClose = () => {
        this.setState({ showForgotPopup: false });
    };

    handleLanguageChange = (lang) => {
        const { changeLanguageAction } = this.props;
        document.documentElement.lang = lang;
        changeLanguageAction({ language: lang });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    goToProfile = () => {
        const { userData } = this.props;
        if (userData.firstLogin) {
            window.location.href = '/createprofile';
        } else {
            window.location.href = '/user/profile';
        }
    };

    renderheader = () => {
        const { cmsLinks, userData } = this.props;
        return (
            <Header
                brandImg={logo}
                rightLinks={
                    <MainPageLinks
                        renderLogin={this.renderLogin}
                        renderSignup={this.renderSignup}
                        renderDashboard={this.renderDashboard}
                        changeLanguage={this.handleLanguageChange}
                        userData={userData}
                    />
                }
                leftLinks={cmsLinks && <MainLeftLinks cmsLinks={cmsLinks} />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 0,
                    color: 'primary',
                }}
            />
        );
    };

    renderFooter = () => {
        return (
            <div>
                <Footer />
            </div>
        );
    };

    render() {
        const {
            language,
            maintainence,
            usedEmail,
            showCustomerVerification,
            showProviderVerification,
            showForgotPopup,
            open,
        } = this.state;
        const {
            settingsLoader,
            settings,
            classes,
            history,
            removeHeader,
            providerprofileCompletion,
        } = this.props;
        if (maintainence) {
            history.push(`/maintainence`);
        }

        const authToken = getCookies();
        const routes = authToken ? appRoutes : authRoutes;
        return (
            <HOCLoader loading={settingsLoader}>
                <Helmet>
                    <meta name="description" content="Book Photographers Online | UAE's 1st On Demand Photography Booking Platform" />
                </Helmet>
                <div>
                    <IntlProvider
                        locale={language}
                        messages={messages[language]}
                    >
                        <Router history={hist}>
                            <ScrollToTop>
                                <MuiThemeProvider
                                    theme={createMuiTheme({
                                        palette: {
                                            primary: {
                                                main:
                                                    settings.colors.SECONDARY
                                                        .HEX,
                                            },
                                            secondary: {
                                                main:
                                                    settings.colors.PRIMARY.HEX,
                                                text: '#ffffff',
                                            },
                                        },
                                        typography: {
                                            useNextVariants: true,
                                            fontFamily:
                                                "'Work Sans', sans-serif !important'",
                                        },
                                    })}
                                >
                                    {!removeHeader && this.renderheader()}
                                    <Switch>
                                        {routes.map((prop, key) => {
                                            return (
                                                <Route
                                                    exact={
                                                        prop.exact
                                                            ? prop.exact
                                                            : false
                                                    }
                                                    path={prop.path}
                                                    key={key}
                                                    component={prop.component}
                                                />
                                            );
                                        })}
                                        <Redirect
                                            to={authToken ? '/' : '/login'}
                                            exact
                                        />
                                        <Route
                                            component={NotFound}
                                            path="*"
                                            exact
                                        />
                                    </Switch>
                                    <NotificationContainer />
                                    {!removeHeader && this.renderFooter()}
                                    {store.getState().auth.isLoginVisible && (
                                        <LoginForm
                                            renderSamePage={false}
                                            isVisible={
                                                store.getState().auth
                                                    .isLoginVisible
                                            }
                                            closeLoginPopup={
                                                this.closeLoginPopup
                                            }
                                            renderProviderVerification={
                                                this.renderProviderVerification
                                            }
                                            renderCustomerVerification={
                                                this.renderCustomerVerification
                                            }
                                            renderForgotPassword={
                                                this.renderForgotPassword
                                            }
                                        />
                                    )}
                                    <CustomerVerification
                                        email={usedEmail}
                                        open={showCustomerVerification}
                                        closePopup={this.handleCustomClose}
                                    />
                                    <Verification
                                        email={usedEmail}
                                        open={showProviderVerification}
                                        close={this.handleVerifyClose}
                                    />
                                    <ForgotPassword
                                        email={usedEmail}
                                        open={showForgotPopup}
                                        close={this.handleForgotClose}
                                    />
                                    {providerprofileCompletion &&
                                        providerprofileCompletion.profileCompleted ==
                                        false &&
                                        window.location.pathname !==
                                        '/user/profile/edit' &&
                                        window.location.pathname !==
                                        '/user/profile' &&
                                        window.location.pathname !== '/' &&
                                        window.location.pathname !==
                                        '/provider/list/' &&
                                        window.location.pathname !==
                                        '/category/services/' &&
                                        window.location.pathname !==
                                        '/createprofile' && (
                                            <div className="dialogBox">
                                                <Dialog
                                                    fullWidth={true}
                                                    open={open}
                                                    onClose={this.handleClose}
                                                    TransitionComponent={Fade}
                                                    aria-labelledby="responsive-dialog-title"
                                                >
                                                    <DialogTitle id="responsive-dialog-title">
                                                        {
                                                            'Complete Your Profile'
                                                        }
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText className="custom_dialog">
                                                            {providerprofileCompletion
                                                                .profileCompletedList
                                                                .length > 0 &&
                                                                providerprofileCompletion.profileCompletedList.map(
                                                                    (list) => {
                                                                        if (
                                                                            list.completed
                                                                        ) {
                                                                            return (
                                                                                list.text !==
                                                                                'Address update step is completed.' &&
                                                                                list.text !==
                                                                                'Address is pending to update.' && (
                                                                                    <div className="popup">
                                                                                        <Icon
                                                                                            className={
                                                                                                classes.checkIcon
                                                                                            }
                                                                                        >
                                                                                            check_circle&nbsp;
                                                                                        </Icon>
                                                                                        &nbsp;
                                                                                        <span
                                                                                            className={
                                                                                                classes.cust_icon
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                list.text
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                list.text !==
                                                                                'Address update step is completed.' &&
                                                                                list.text !==
                                                                                'Address is pending to update.' && (
                                                                                    <div className="popup">
                                                                                        <Icon
                                                                                            className={
                                                                                                classes.cancelIcon
                                                                                            }
                                                                                        >
                                                                                            cancel&nbsp;
                                                                                        </Icon>
                                                                                        &nbsp;
                                                                                        <span
                                                                                            className={
                                                                                                classes.cust_icon
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                list.text
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <WSButton
                                                            variant="outlined"
                                                            onClick={
                                                                this.handleClose
                                                            }
                                                        >
                                                            Close
                                                        </WSButton>
                                                        <WSButton
                                                            onClick={() => {
                                                                this.handleClose();
                                                                this.goToProfile();
                                                            }}
                                                        >
                                                            Complete Profile
                                                        </WSButton>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        )}
                                </MuiThemeProvider>
                            </ScrollToTop>
                        </Router>
                    </IntlProvider>
                </div>
            </HOCLoader>
        );
    }
}

const mapStateToProps = ({ auth, settings }) => {
    return {
        userData: auth.userData,
        providerprofileCompletion: auth.providerprofileCompletion,
        isLoginVisible: auth.isLoginVisible,
        removeHeader: auth.removeHeader,
        currentLanguage: settings.currentLanguage,
        cmsLinks: settings.cmsLinks,
        maintainence: settings.maintainence,
        settingsLoader: settings.settingsLoader,
    };
};

const composedWithUser = compose(connect(mapStateToProps, actions));

export default composedWithUser(
    withUser(withCustomTheme(withStyles(componentsStyle)(App)))
);
