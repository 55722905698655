import React, { Component } from 'react';
import { compose } from 'redux';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import ServiceMediaUrlCreate from '../../components/Providers/ServiceMediaUrlCreate';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

export class Create extends Component {
    render() {
        const { classes, match } = this.props;
        return (
            <div>
                <div className={isMobile ? 'row mb-2' : 'row mb-4'}>
                    <div className="col-md-12">
                        <Card className={classes.card}>
                            <Toolbar>
                                <h3> Add New Youtube Url</h3>
                            </Toolbar>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ServiceMediaUrlCreate
                            {...this.props}
                            serviceId={match.params.serviceId}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles));

export default composedComponent(Create);
