import React, { useEffect } from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { compose } from 'redux';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import withUser from '../HOC/HOCwithUser.jsx';
import HOCLoader from '../HOC/HOCLoader.jsx';
import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';
import AddWalletMoneyForm from '../Customer/AddWalletMoneyForm.jsx';
import WSButton from '../Button/WSButton.jsx';

import * as actions from '../../actions';
import { formatPrice } from '../../utils/helpers/functions/validators';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function WalletListHead(props) {




    const createSortHandler = (property) => (event) => {
        const { onRequestSort } = props;
        onRequestSort(event, property);
    };

    const { formatMessage } = props;
    const rows = [
        {
            id: 'amount',
            label: formatMessage({
                id: 'wallet.amount',
            }),
        },
        {
            id: 'closing_balance',
            label: formatMessage({
                id: 'wallet.closingbalance',
            }),
        },
        {
            id: 'heading',
            label: formatMessage({
                id: 'wallet.transactionheading',
            }),
        },
        {
            id: 'created_at',
            label: formatMessage({
                id: 'wallet.created_at',
            }),
        },
    ];
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
    } = props;

    return (
        <TableHead>
            <TableRow>
                {rows.map(
                    (row) => (
                        <TableCell
                            key={row?.id}
                            align={row?.numeric ? 'right' : 'left'}
                            padding={
                                row?.disablePadding ? 'none' : 'default'
                            }
                            sortDirection={
                                orderBy === row?.id ? order : false
                            }
                        >
                            <Tooltip
                                title="Sort"
                                placement={
                                    row?.numeric
                                        ? 'bottom-end'
                                        : 'bottom-start'
                                }
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={orderBy === row?.id}
                                    direction={order}
                                    onClick={createSortHandler(row?.id)}
                                >
                                    {row?.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this
                )}
            </TableRow>
        </TableHead>
    );
}

WalletListHead.propTypes = {
    numSelected: PropTypes?.number?.isRequired,
    onRequestSort: PropTypes?.func?.isRequired,
    onSelectAllClick: PropTypes?.func?.isRequired,
    order: PropTypes?.string?.isRequired,
    orderBy: PropTypes?.string?.isRequired,
    rowCount: PropTypes?.number?.isRequired,
};

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme?.spacing?.unit,
    },
    highlight:
        theme?.palette?.type === 'light'
            ? {
                color: theme?.palette?.secondary?.main,
                backgroundColor: lighten(theme?.palette?.secondary?.light, 0.85),
            }
            : {
                color: theme?.palette?.text?.primary,
                backgroundColor: theme?.palette?.secondary?.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme?.palette?.secondary?.main,
    },
    title: {
        flex: '0 0 auto',
    },
});

let WalletListToolbar = (props) => {
    const { numSelected, classes, onAddClick } = props;

    return (
        <Toolbar
            className={isMobile ? 'walletTransaction' : classNames(classes?.root, {
                [classes?.highlight]: numSelected > 0,
            })}
        >
            <div className={classes?.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected}&nbsp;
                        <FormattedMessage
                            id="wallet.selected"
                            defaultMessage="selected"
                        />
                    </Typography>
                ) : (
                    <h6 style={{ fontWeight: 500, fontSize: '16px' }}>
                        <FormattedMessage
                            id="wallet.heading"
                            defaultMessage="Wallet Transactions"
                        />
                    </h6>
                )}
            </div>
            <div className={classes?.spacer} />
            <div className={isMobile ? "addWalletBtn" : classes?.actions}>
                <Tooltip title="Add">
                    <WSButton
                        className="addNewButton"
                        variant="fab"
                        ariaLabel="Add"
                        onClick={onAddClick}
                        style={{
                            padding: 0,
                            height: 40,
                        }}
                    >
                        Add New
                    </WSButton>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

WalletListToolbar.propTypes = {
    classes: PropTypes?.object?.isRequired,
    numSelected: PropTypes?.number?.isRequired,
    mainbalance: PropTypes?.number,
};

WalletListToolbar = withStyles(toolbarStyles)(WalletListToolbar);

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme?.spacing?.unit * 3,
    },
    table: {
        minWidth: 600,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

function WalletList(props) {

    const walletData = useSelector(state => state?.wallet?.walletData);
    const transactions = useSelector(state => state?.wallet?.walletData?.transactions);
    const walletLoader = useSelector(state => state?.wallet?.walletLoader);

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState(transactions ? transactions : []);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [clickValue, setClickValue] = useState('');
    const [itemId, setItemId] = useState('');
    const [showCreateForm, setShowCreateForm] = useState(false);


    const actionCallbacks = (action, res) => {
        if (action === 'view') {
            this.setState({ clickValue: 'view', itemId: res?.key });
        } else if (action === 'update') {
            setClickValue('update')
            setItemId(res?.key)
            setMenuState(false)
        }
    }

    useEffect(() => {
        const { walletListAction } = props;
        walletListAction();
    }, []);

    useEffect((prevProps, prevState) => {
        const { transactions } = props;
        if (prevProps?.transactions !== transactions) {
            setData(transactions)
        }
    }, []);

    const handleRequestSort = (event, property) => {
        let orders = 'desc';

        if (orderBy === property && order === 'desc') {
            orders = 'asc';
        }

        setOrder(orders)
        setOrderBy(property)
    };

    const handleSelectAllClick = (event) => {
        if (event?.target?.checked) {
            setSelected(state?.data.map((n) => n?.key))
            return;
        }
        setSelected([])
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected)
    };

    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event?.target?.value)
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const onAddClick = () => {
        setShowCreateForm(true)
    };
    const backToList = () => {
        setShowCreateForm(false)
    };
    const { classes } = props;
    const { intl, settings } = props;
    const { formatMessage } = intl;
    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, data?.length - page * rowsPerPage);

    if (clickValue) {
        return (
            <Redirect
                to={{
                    pathname: `/address/${clickValue}`,
                    state: { itemId: itemId },
                }}
            />
        );
    }
    if (showCreateForm) {
        return (
            <div>
                <AddWalletMoneyForm backToList={backToList} />
            </div>
        );
    }

    return (
        <HOCLoader loading={walletLoader}>
            <Paper>
                <WalletListToolbar
                    numSelected={selected?.length}
                    balance={walletData?.balance}
                    settings={settings}
                    onAddClick={onAddClick}
                />
                <div className="tableWrapper-table">
                    <Table
                        className="table dashboard-tables"
                        aria-labelledby="tableTitle"
                    >
                        <WalletListHead
                            numSelected={selected?.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data?.length}
                            formatMessage={intl?.formatMessage}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((n) => {
                                    const isSelecteds = isSelected(
                                        n?.key
                                    );
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelecteds}
                                            tabIndex={-1}
                                            key={n?.key}
                                            selected={isSelecteds}
                                        >
                                            <TableCell className="status-cell">
                                                <div className="badge badge-success">
                                                    {
                                                        settings?.constants
                                                            ?.CURRENCY_SYMBOL
                                                    }{' '}
                                                    {formatPrice(n?.amount, '0,0')}
                                                </div>
                                            </TableCell>
                                            <TableCell scope="row">
                                                {
                                                    settings?.constants
                                                        ?.CURRENCY_SYMBOL
                                                }{' '}
                                                {formatPrice(n?.closing_balance, '0,0')}
                                            </TableCell>
                                            <TableCell>
                                                {n?.heading}
                                            </TableCell>
                                            <TableCell>
                                                {moment(
                                                    n?.created_at
                                                ).format(
                                                    'Do MMMM YYYY H:mm a'
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{ height: 49 * emptyRows }}
                                >
                                    <TableCell colSpan={5} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div className="walletTable">
                    <TablePagination
                        className="dashboard-table-pagination"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': formatMessage({
                                id: 'pagination.previous',
                            }),
                        }}
                        nextIconButtonProps={{
                            'aria-label': formatMessage({
                                id: 'pagination.next',
                            }),
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>
            </Paper>
        </HOCLoader>
    );
}

WalletList.propTypes = {
    classes: PropTypes?.object?.isRequired,
};

const composedComponent = compose(
    withStyles(styles),
    connect(null, actions)
);

export default composedComponent(
    withCustomTheme(withUser(injectIntl(WalletList)))
);
