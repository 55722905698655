import _ from 'lodash';
import Moment from 'moment';

import { loginApi } from '../apiHelpers/user/login';
import { logoutApi } from '../apiHelpers/user/logout';
import {
    getLoggedInUser,
    getProviderType,
    providerDetail,
    addAddress,
    updateProfile,
    updateAddress,
    deleteAddress,
    changePassword,
    proUpdateProfile,
    markProviderFavorite,
    getWishlist,
    addNewtoWishlist,
    providerDashboardData,
    providerCheck,
    getBusinessHours,
    updateTimingPacket,
    getInboxDetails,
    refreshData,
    sendMessage,
    providerRating,
} from '../apiHelpers/user/detail';
import { favouriteList } from '../apiHelpers/favorite/list';
import {
    searchProviderListing,
    featuredList,
    newProviderList,
    favouriteProviderList,
    recentList,
    serviceProvider,
    allProviderListing,
    providerFilterListing,
    getInboxList,
    providerTimeSlot,
    providerSetTimeSlot,
    getProviderTypeProfile,
    updateProviderTypeProfile,
    updateFullProviderProfile,
    updateProfileImage,
    getFullProviderProfile,
    getNationality,
    getLanguages,
} from '../apiHelpers/provider/list';
import {
    signup,
    checkOtp,
    sendOtpOnPhone,
    checkProviderOtp,
    checkOtpOnPhone,
    forgotPassword,
    resetPassword,
    checkForgotOtp,
    socialLogin,
    contactUs,
} from '../apiHelpers/user/signup';
import { walletList } from '../apiHelpers/wallet/list';

import { cookies } from '../utils/getToken';

import {
    USER_DATA_RECEIVED,
    PROVIDER_PROFILE_UPDATE,
    GET_NATIONALITY_DATA,
    GET_LANGUAGES_DATA,
    FAVOURITE_DATA_RECEIVED,
    LOGOUT,
    FEATURED_LIST_RECEIVED,
    NEW_PROVIDER_LIST_RECEIVED,
    FAVOURITE_PROVIDER_LIST_RECEIVED,
    RECENT_LIST_RECEIVED,
    RECENT_PROVIDER_LOADER,
    FEATURED_PROVIDER_LOADER,
    NEW_PROVIDER_LOADER,
    FAVOURITE_HOME_PROVIDER_LOADER,
    REMOVE_HEADER_FOOTER,
    FAVOURITE_LOADER,
    WALLET_DATA_RECEIVED,
    WALLET_LOADER,
    AUTH_LOADER,
    PROVIDER_TYPE_DATA_RECEIVED,
    PROVIDER_TYPE_LOADER,
    LOGIN_TRIGGERED,
    WISHLIST_DATA_RECEIVED,
    WISHLIST_LOADER,
    CHANGE_LANGUAGE,
    INBOX_LIST,
    INBOX_LIST_LOADING,
    CLEAR_INBOX_DETAILS,
    INBOX_DETAILS_RECEIVED,
    INBOX_DETAIL_LOADER,
    INBOX_LIST_REMOVE,
    SELECTED_PROVIDER_ID,
} from './types';

export const removeHeaderAction = (param) => async (dispatch) => {
    dispatch({ type: REMOVE_HEADER_FOOTER, payload: param });
};

export const loginAction = (params, done) => async (dispatch) => {
    const response = await loginApi(params);
    if (response.status === 'OK') {
        cookies.set('x-auth-token', response.data.token, { path: '/' });
        done(true, response.data);
    } else {
        done(response.status, response);
    }
};

export const getLoggedInUserAction = () => async (dispatch) => {
    dispatch({ type: AUTH_LOADER, payload: true });
    const response = await getLoggedInUser();
    if (response.status === 'OK') {
        dispatch({
            type: CHANGE_LANGUAGE,
            payload: response.data.detail.language.language,
        });
        dispatch({
            type: USER_DATA_RECEIVED,
            payload: response.data.detail,
            providerprofileCompletion: response.data.profileCompletion,
        });
        dispatch({ type: AUTH_LOADER, payload: false });
        return true;
    } else {
        dispatch({ type: AUTH_LOADER, payload: false });
        return false;
    }
};

export const getUserDataAction = () => async (dispatch) => {
    const response = await getLoggedInUser();
    if (response.status === 'OK') {
        dispatch({
            type: USER_DATA_RECEIVED,
            payload: response.data.detail,
            providerprofileCompletion: response.data.profileCompletion,
        });
        dispatch({ type: AUTH_LOADER, payload: false });
        return true;
    } else {
        dispatch({ type: AUTH_LOADER, payload: false });
        return false;
    }
};

export const getProviderTypesAction = () => async (dispatch) => {
    dispatch({ type: PROVIDER_TYPE_LOADER, payload: true });
    const response = await getProviderType();
    if (response.status === 'OK') {
        dispatch({
            type: PROVIDER_TYPE_DATA_RECEIVED,
            payload: response.data,
        });
        dispatch({ type: PROVIDER_TYPE_LOADER, payload: false });
        return true;
    } else {
        dispatch({ type: PROVIDER_TYPE_LOADER, payload: false });
        return false;
    }
};

export const logoutAction = (done) => async (dispatch) => {
    const response = await logoutApi();
    if (response.status === 'OK') {
        dispatch({
            type: CHANGE_LANGUAGE,
            payload: 'en',
        });
        cookies.remove('x-auth-token', { path: '/' });

        dispatch({ type: LOGOUT });
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const favouriteListAction = (params) => async (dispatch, getState) => {
    if (getState().providers.favProviderList === []) {
        dispatch({ type: FAVOURITE_LOADER, payload: true });
    }
    const response = await favouriteList(params);
    if (response.status === 'OK') {
        dispatch({ type: FAVOURITE_DATA_RECEIVED, payload: response.data });
        dispatch({ type: FAVOURITE_LOADER, payload: false });
    }
};

export const featuredListAction = (params) => async (dispatch) => {
    dispatch({ type: FEATURED_PROVIDER_LOADER, payload: true });
    const response = await featuredList(params);
    if (response.status === 'OK') {
        dispatch({
            type: FEATURED_LIST_RECEIVED,
            payload: response.data.providerList,
        });
        dispatch({ type: FEATURED_PROVIDER_LOADER, payload: false });
    }
};

export const newProviderListAction = (params) => async (dispatch) => {
    dispatch({ type: NEW_PROVIDER_LOADER, payload: true });
    const response = await newProviderList(params);
    if (response.status === 'OK') {
        dispatch({
            type: NEW_PROVIDER_LIST_RECEIVED,
            payload: response.data.providerList,
        });
        dispatch({ type: NEW_PROVIDER_LOADER, payload: false });
    }
};

export const favouriteProviderListAction = (params) => async (dispatch) => {
    dispatch({ type: FAVOURITE_HOME_PROVIDER_LOADER, payload: true });
    const response = await favouriteProviderList(params);
    if (response.status === 'OK') {
        dispatch({
            type: FAVOURITE_PROVIDER_LIST_RECEIVED,
            payload: response.data.providerList,
        });
        dispatch({ type: FAVOURITE_HOME_PROVIDER_LOADER, payload: false });
    }
};

export const recentListAction = (params) => async (dispatch) => {
    dispatch({ type: RECENT_PROVIDER_LOADER, payload: true });
    const response = await recentList(params);
    if (response.status === 'OK') {
        dispatch({
            type: RECENT_LIST_RECEIVED,
            payload: response.data.providerList,
        });
        dispatch({ type: RECENT_PROVIDER_LOADER, payload: false });
    }
};

export const providerDetailAction = (params, done) => async (dispatch) => {
    const response = await providerDetail(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const allProviderListingAction = (params, done) => async (dispatch) => {
    const response = await allProviderListing(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const providerFilterListingAction = (params, done) => async (
    dispatch
) => {
    const response = await providerFilterListing(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const serviceProviderAction = (params, done) => async (dispatch) => {
    const response = await serviceProvider(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const searchProviderListingAction = (params, done) => async (
    dispatch
) => {
    const response = await searchProviderListing(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response);
    }
};

export const signupAction = (params, done) => async (dispatch) => {
    const response = await signup(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const updateProfileAction = (params, done) => async (dispatch) => {
    const response = await updateProfile(params);
    if (response.status === 'OK') {
        getLoggedInUserAction();
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const proUpdateProfileAction = (params, done) => async (dispatch) => {
    const response = await proUpdateProfile(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const checkOtpAction = (params, done) => async (dispatch) => {
    const response = await checkOtp(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const checkProviderOtpAction = (params, done) => async (dispatch) => {
    const response = await checkProviderOtp(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const sendOtpOnPhoneAction = (params, done) => async (dispatch) => {
    const response = await sendOtpOnPhone(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const checkOtpOnPhoneAction = (params, done) => async (dispatch) => {
    const response = await checkOtpOnPhone(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const forgotPasswordAction = (params, done) => async (dispatch) => {
    const response = await forgotPassword(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const checkForgotOtpAction = (params, done) => async (dispatch) => {
    const response = await checkForgotOtp(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const resetPasswordAction = (params, done) => async (dispatch) => {
    const response = await resetPassword(params);
    if (response.status === 'OK') {
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const socialLoginAction = (params, done) => async (dispatch) => {
    const response = await socialLogin(params);
    if (response.status == 'OK') {
        cookies.set('x-auth-token', response.data.token, { path: '/' });
        dispatch(getLoggedInUserAction());

        done(true, response.data);
    } else {
        done(false, response);
    }
};

export const addAddressAction = (params, done) => async (dispatch) => {
    try {
        const response = await addAddress(params);
        if (response.status === 'OK') {
            dispatch(getLoggedInUserAction());
            done(true, response);
        } else {
            done(false, response.message);
        }
    } catch (e) {
        console.error(e);
    }
};

export const updateAddressAction = (params, done) => async (dispatch) => {
    const response = await updateAddress(params);
    if (response.status === 'OK') {
        dispatch(getLoggedInUserAction());
        done(true, response);
    } else {
        done(false, response.message);
    }
};

export const deleteAddressAction = (params, done) => async (dispatch) => {
    const response = await deleteAddress(params);
    if (response.status === 'OK') {
        dispatch(getLoggedInUserAction());
        done(true, response.data);
    } else {
        done(false, response.message);
    }
};

export const walletListAction = (params) => async (dispatch, getState) => {
    if (getState().wallet.walletData === []) {
        dispatch({ type: WALLET_LOADER, payload: true });
    }
    const response = await walletList(params);
    if (response.status === 'OK') {
        if (response.data) {
            dispatch({
                type: WALLET_DATA_RECEIVED,
                payload: response.data,
            });
        }
        dispatch({ type: WALLET_LOADER, payload: false });
    }
};

export const changePasswordAction = (params, done) => async (dispatch) => {
    const list = await changePassword(params);
    if (list.status === 'OK') {
        done(true, list.data);
    } else {
        done(false, list.message);
    }
};

export const showLoginPopup = (params) => async (dispatch) => {
    dispatch({
        type: LOGIN_TRIGGERED,
        payload: { visibile: true, path: '/user' },
    });
};

export const hideLoginPopup = (params) => async (dispatch) => {
    dispatch({
        type: LOGIN_TRIGGERED,
        payload: { visibile: false, path: '/client' },
    });
};

export const markProviderFavoriteAction = (params, done) => async (
    dispatch
) => {
    const response = await markProviderFavorite(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const getWishlistAction = (done) => async (dispatch) => {
    dispatch({ type: WISHLIST_LOADER, payload: true });
    const response = await getWishlist();
    if (response.status === 'OK') {
        dispatch({ type: WISHLIST_DATA_RECEIVED, payload: response.data });
        dispatch({ type: WISHLIST_LOADER, payload: false });
    }
};

export const addNewtoWishlistAction = (params, done) => async (dispatch) => {
    const response = await addNewtoWishlist(params);
    if (response.status === 'OK') {
        dispatch(getWishlistAction());
        done(true, response);
    } else {
        done(false, response);
    }
};

export const contactUsAction = (params, done) => async (dispatch) => {
    const response = await contactUs(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const providerDashboardDataAction = (params, done) => async (
    dispatch
) => {
    const response = await providerDashboardData(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const providerCheckAction = (done) => async (dispatch) => {
    const response = await providerCheck();
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const getBusinessHoursAction = (done) => async (dispatch) => {
    const response = await getBusinessHours();
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const updateTimingPacketAction = (params, done) => async (dispatch) => {
    const response = await updateTimingPacket(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const getInboxListAction = () => async (dispatch) => {
    const response = await getInboxList();
    if (response.status === 'OK') {
        dispatch({ type: INBOX_LIST, payload: response.data });
        dispatch({ type: INBOX_LIST_LOADING, payload: false });
    } else {
        dispatch({ type: INBOX_LIST_LOADING, payload: false });
    }
};

export const getInboxDetailsAction = (params) => async (dispatch, getState) => {
    dispatch({
        type: INBOX_DETAIL_LOADER,
        payload: true,
    });
    try {
        const response = await getInboxDetails(params);
        if (response.status === 'OK') {
            const newData = response.data.chats.map((chat, index) => {
                if (index === response.data.chats.length - 1) {
                    response.data.topTime = chat.created_at;
                }
                return {
                    ...chat,
                    createdAt: new Date(chat.created_at),
                };
            });
            const inboxDetails = {
                ...response.data,
                chats: [...getState().inbox.inboxDetails.chats, ...newData],
            };
            if (inboxDetails.chats.length > 0) {
                dispatch({
                    type: INBOX_DETAILS_RECEIVED,
                    payload: {
                        ...inboxDetails,
                        bottomTime: inboxDetails.chats[0].created_at,
                    },
                });
                dispatch({
                    type: INBOX_DETAIL_LOADER,
                    payload: false,
                });
            } else {
                dispatch({
                    type: INBOX_DETAILS_RECEIVED,
                    payload: {
                        ...inboxDetails,
                        bottomTime: Moment(new Date()).valueOf(),
                    },
                });
                dispatch({
                    type: INBOX_DETAIL_LOADER,
                    payload: false,
                });
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const sendMessageAction = (params) => async (dispatch, getState) => {
    try {
        const response = await sendMessage(params);
        if (response.status === 'OK') {
            dispatch(refreshChatsAction({ messageroom: params.messageroom }));
        }
    } catch (e) {
        console.log(e);
    }
};

export const refreshChatsAction = (params) => async (dispatch, getState) => {
    try {
        const response = await refreshData({
            ...params,
            bottomTime: getState().inbox.inboxDetails.bottomTime,
        });
        if (response.status === 'OK') {
            if (response.data.chats.length > 0) {
                const newData = response.data.chats.map((chat, index) => {
                    return {
                        ...chat,
                        createdAt: new Date(chat.created_at),
                    };
                });
                const chats = _.uniqBy(
                    [...getState().inbox.inboxDetails.chats, ...newData],
                    '_id'
                );
                const inboxDetails = {
                    ...getState().inbox.inboxDetails,
                    ...response.data,
                    bottomTime:
                        response.data.chats[response.data.chats.length - 1]
                            .created_at,
                    chats,
                };
                dispatch({
                    type: INBOX_DETAILS_RECEIVED,
                    payload: inboxDetails,
                });
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const clearInboxDetails = () => {
    return {
        type: CLEAR_INBOX_DETAILS,
    };
};

export const removeInboxList = () => async (dispatch) => {
    dispatch({ type: INBOX_LIST_REMOVE });
};

export const providerRatingAction = (done) => async (dispatch) => {
    try {
        const response = await providerRating();
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        console.log(e);
    }
};

export const providerTimeSlotAction = (params, done) => async (dispatch) => {
    const response = await providerTimeSlot(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const providerSetTimeSlotAction = (params, done) => async (dispatch) => {
    try {
        const response = await providerSetTimeSlot(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        console.log(e);
    }
};

export const getProviderTypeProfileAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await getProviderTypeProfile(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        console.log(e);
    }
};

export const updateProviderTypeProfileAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await updateProviderTypeProfile(params);
        if (response.status === 'OK') {
            done(true, response);
        } else {
            done(false, response);
        }
    } catch (e) {
        console.log(e);
    }
};

export const updateFullProviderProfileAction = (params, done) => async (
    dispatch
) => {
    const response = await updateFullProviderProfile(params);
    if (response && response[0]) {
        done(true);
        dispatch({ type: PROVIDER_PROFILE_UPDATE, payload: response[0] });
    } else {
        done(false);
    }
};

export const updateProfileImageAction = (params, done) => async (dispatch) => {
    const response = await updateProfileImage(params);
    if (response.message === 'Image is successfully uploaded ') {
        done(true);
    } else {
        done(false);
    }
};

export const getFullProviderProfileAction = () => async (dispatch) => {
    const response = await getFullProviderProfile();
    if (response) {
        dispatch({
            type: PROVIDER_PROFILE_UPDATE,
            payload: response && response.data && response.data.detail,
        });
    }
};

export const getNationalityAction = () => async (dispatch) => {
    const response = await getNationality();
    if (response) {
        dispatch({
            type: GET_NATIONALITY_DATA,
            payload: response && response.data,
        });
    }
};

export const getLanguagesAction = () => async (dispatch) => {
    const response = await getLanguages();
    if (response) {
        const newData =
            response &&
            response.data &&
            response.data.sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
        dispatch({ type: GET_LANGUAGES_DATA, payload: newData });
    }
};
export const selectedProviderId = (param) => (dispatch) => {
    dispatch({
        type: SELECTED_PROVIDER_ID,
        payload: param,
    });
}