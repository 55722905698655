import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import { Card, CircularProgress } from '@material-ui/core';

import * as actions from '../../actions';

import { validateYoutubeURL } from '../../utils/validators/validateForm';

import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';

import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

const styles = {
    width: '100%',
    height: '100%',
    formControl: {
        minWidth: '90%',
        margin: '15px'
    }
};

class ServiceMediaUrlUpdate extends Component {

    constructor(props) {
        super();
        this.state = {
            url: props.url ? props.url : '',
            urlError: '',
            loader: false,
            pass: false,
            noResponse: false,
            errorMessage: '',
            validate: true,
            serviceId: props.service ? props.service.key : '',
            indexValue: props.indexValue ? props.indexValue : 0
        };
    }

    submit = async () => {
        const { url } = this.state;
        const result = await validateYoutubeURL(url, this.handleError);
        if (result) {
            this.updateUrl();
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Error'
            );
        }
    };

    updateUrl = () => {
        const { url, indexValue, serviceId } = this.state;
        const { updateVideoUrlAction } = this.props;
        this.setState({ loader: true });
        updateVideoUrlAction(
            {
                url: url,
                index: indexValue,
                id: serviceId
            },
            this.onAddDone
        );
    };

    handleError = params => {
        this.setState(params);
    };

    onAddDone = (success, data) => {
        const { serviceId } = this.state;
        const { history } = this.props;
        this.setState({ loader: false });
        if (success) {
            NotificationManager.success(data.message, 'Success');
            history.push({
                pathname: `/provider/service/url`,
                serviceId: serviceId
            });
        }
    };

    renderButton = () => {
        const { loader } = this.state;
        if (loader) {
            return <CircularProgress />;
        }
        return (
            <WSButton
                variant="raised"
                className="jr-btn"
                onClick={this.submit}
            >
                <FormattedMessage id="update" defaultMessage="update" />
            </WSButton>
        );
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render() {
        const { url, urlError, loader } = this.state;
        const { formatMessage } = this.props;
        return (
            <Card className="col-md-12">
                <form
                    className="row"
                    noValidate
                    autoComplete="off"
                    id="media-url-update"
                >
                    <div className="col-md-6 col-xs-12">
                        <WSTextField
                            error={urlError ? true : false}
                            helperText={urlError}
                            name="url"
                            label={formatMessage({
                                id: 'url'
                            })}
                            id="url"
                            placeholder={formatMessage({
                                id: 'url'
                            })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            defaultValue={url}
                            onChange={this.handleChange('url')}
                            disabled={loader}
                        />
                    </div>

                    <div className="col-md-12 col-xs-12">
                        {this.renderButton()}
                    </div>
                </form>
            </Card>
        );
    }
}

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default withCustomTheme(
    composedComponent(injectIntl(ServiceMediaUrlUpdate))
);