import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { ROLE_PROVIDER } from '../../utils/config/constants.js';

import AddressList from '../Customer/AddressList.jsx';
import ProviderBusinessHours from '../Providers/ProviderBusinessHours.jsx';
import ProviderBasicInformation from '../Providers/ProviderBasicInformation.jsx';
import ProviderBusinessInformation from '../Providers/ProviderBusinessInformation.jsx';
import ProviderBankDetails from '../Providers/ProviderBankDetails.jsx';
import ProviderProfession from '../Providers/ProviderProfession.jsx';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 2 }} className={isMobile && props.mobileStyle}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: '10px',
        width: '100%',
        backgroundColor: theme.palette.background.paper
    }
});

class CustomerTab extends Component {
    state = {
        value: 'basicDetailTab'
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    componentDidMount() {
        const { getFullProviderProfileAction } = this.props;
        getFullProviderProfileAction()
    }

    render() {
        const {
            classes,
            userData,
            intl,
            getUserDataAction,
            getFullProviderProfileAction
        } = this.props;
        const { formatMessage } = intl;
        const { value } = this.state;
        return (
            <div className={classes.root}>
                <Tabs
                    className="profile-tabs-dash tab-bottom-border"
                    value={value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                // centered
                >
                    <Tab
                        label={formatMessage({
                            id: 'customer.basicinfromation'
                        })}
                        icon={<span className={value === 'basicDetailTab' ? "tab-count-active" : "tab-count-inactive"}>1.</span>}
                        value="basicDetailTab"
                        className={value === 'basicDetailTab' ? 'tab-text-active' : 'tab-text-inactive'}
                        onClick={() => getUserDataAction()}
                    />
                    <Tab
                        label={formatMessage({
                            id: 'customer.yourProfession'
                        })}
                        value="professiontab"
                        icon={<span className={value === 'professiontab' ? "tab-count-active" : "tab-count-inactive"}>2.</span>}
                        className={value === 'professiontab' ? 'tab-text-active' : 'tab-text-inactive'}
                        onClick={() => getFullProviderProfileAction()}
                    />
                    <Tab
                        label={formatMessage({
                            id: 'customer.businessInformation'
                        })}
                        value="businessInformationTab"
                        icon={<span className={value === 'businessInformationTab' ? "tab-count-active" : "tab-count-inactive"}>3.</span>}
                        className={value === 'businessInformationTab' ? 'tab-text-active' : 'tab-text-inactive'}
                        onClick={() => getUserDataAction()}
                    />
                    <Tab
                        label={formatMessage({
                            id: 'customer.businessHour'
                        })}
                        value="hoursTab"
                        icon={<span className={value === 'hoursTab' ? "tab-count-active" : "tab-count-inactive"}>4.</span>}
                        className={value === 'hoursTab' ? 'tab-text-active' : 'tab-text-inactive'}
                        onClick={() => getFullProviderProfileAction()}
                    />
                    <Tab
                        label={formatMessage({
                            id: 'customer.addresses'
                        })}
                        value="addressTab"
                        icon={<span className={value === 'addressTab' ? "tab-count-active" : "tab-count-inactive"}>5.</span>}
                        className={value === 'addressTab' ? 'tab-text-active' : 'tab-text-inactive'}
                        onClick={() => getFullProviderProfileAction()}
                    />
                    <Tab
                        label={formatMessage({
                            id: 'customer.bankDetail'
                        })}
                        value="bankDetailtab"
                        icon={<span className={value === 'bankDetailtab' ? "tab-count-active" : "tab-count-inactive"}>6.</span>}
                        className={value === 'bankDetailtab' ? 'tab-text-active' : 'tab-text-inactive'}
                        onClick={() => getFullProviderProfileAction()}
                    />
                </Tabs>
                {
                    value === 'basicDetailTab' && (
                        <TabContainer mobileStyle='pr-0 pl-30'>
                            <ProviderBasicInformation {...this.props} />
                        </TabContainer>
                    )
                }
                {
                    value === 'professiontab' && (
                        <TabContainer mobileStyle='pr-0 pl-30'>
                            <ProviderProfession {...this.props} />
                        </TabContainer>
                    )
                }
                {
                    value === 'businessInformationTab' && (
                        <TabContainer mobileStyle='pr-0 pl-30'>
                            <ProviderBusinessInformation {...this.props} />
                        </TabContainer>
                    )
                }
                {
                    value === 'addressTab' && (
                        <TabContainer>
                            <AddressList {...this.props} />
                        </TabContainer>
                    )
                }
                {
                    value === 'hoursTab' && userData.role === ROLE_PROVIDER && (
                        <TabContainer>
                            <ProviderBusinessHours {...this.props} />
                        </TabContainer>
                    )
                }
                {
                    value === 'bankDetailtab' && userData.role === ROLE_PROVIDER && (
                        <TabContainer mobileStyle='pr-0 pl-30'>
                            <ProviderBankDetails {...this.props} />
                        </TabContainer>
                    )
                }
            </div >
        );
    }
}

CustomerTab.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectIntl(CustomerTab));