import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import * as actions from '../../actions';

import ServiceGalleryUpdate from '../../components/Providers/ServiceGalleryUpdate.js';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

export class Update extends Component {
    constructor(props) {
        super();
        this.state = {
            data: [],
            imageData: props.location ? props.location.imageData : '',
            service: props.location ? props.location.service : '',
        };
    }

    // componentDidMount() {
    // const { match, serviceGalleryDetailAction } = this.props;
    //     serviceGalleryDetailAction(
    //         { faqId: match.params.serviceId },
    //         this.onDone
    //     );
    // }

    // onDone = (success, data) => {
    // const { history } = this.props;
    //     if (success) {
    //         this.setState({ data: data.data, loader: false });
    //     } else {
    //         history.push(`provider/service/gallery`);
    //     }
    // };
    render() {
        const { classes, match } = this.props;
        const { imageData, service } = this.state;
        return (
            <div>
                <div className={isMobile ? 'row mb-2' : 'row mb-4'}>
                    <div className="col-md-12">
                        <Card className={classes.card}>
                            <Toolbar>
                                <h3>Update Faq</h3>
                            </Toolbar>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ServiceGalleryUpdate
                            {...this.props}
                            imageData={imageData}
                            service={service}
                            serviceId={match.params.serviceId}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles), connect(null, actions));

export default composedComponent(Update);
