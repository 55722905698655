import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import createHistory from 'history/createHashHistory';
import logger from 'redux-logger';

import reducers from '../reducers';

let store;
const history = createHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
    store = createStore(
        reducers,
        initialState,
        composeEnhancers(applyMiddleware(thunk, logger))
    );
    return store;
}

export { history, store };
