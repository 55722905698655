import React, { Component, Fragment } from 'react';
import Gallery from 'react-grid-gallery';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NotificationManager } from 'react-notifications';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Formik } from 'formik';
import ReactPlayer from 'react-player';

import { withStyles } from '@material-ui/core/styles';
import {
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { AddToQueue } from '@material-ui/icons';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { CloudUpload } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

import * as actions from '../../actions';

import { SERVER_URL } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

import DeleteAlert from '../../components/Alert/DeleteAlert.jsx';
import WSTextField from '../../components/TextField/WSTextField';
import WSButton from '../../components/Button/WSButton';

import 'react-image-crop/dist/ReactCrop.css';
const handleDropRejected = (...args) =>
    NotificationManager.error(
        <FormattedMessage
            id="errorUploading25mb"
            defaultMessage="Error uploading Image, try another image with maximum size 25MB"
        />,
        'Error'
    );

const toolbarStyles = (theme) => ({
    root: {},
    highlight:
        theme?.palette?.type === 'light'
            ? {
                color: theme?.palette?.secondary?.main,
                backgroundColor: lighten(theme?.palette?.secondary?.light, 0.85),
            }
            : {
                color: theme?.palette?.text?.primary,
                backgroundColor: theme?.palette?.secondary?.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme?.palette?.secondary?.main,
    },
    title: {
        flex: '0 0 auto',
    },
});

let GalleryListToolbar = (props) => {
    const {
        numSelected,
        classes,
        deleteAction,
        markDefault,
    } = props;
    return (
        <div>
            <Toolbar
                className={classNames(classes?.root, {
                    [classes?.highlight]: numSelected > 0,
                })}
                style={{ padding: 0 }}
            >
                <div className={classes?.title}>
                    {numSelected > 0 && (
                        <Typography variant="subtitle1">
                            {numSelected} selected
                        </Typography>
                    )}
                </div>
                <div className={classes?.spacer} />
                <div className={classes?.actions}>
                    {numSelected === 1 ? (
                        <Tooltip title="Make Default">
                            <IconButton aria-label="Make Default">
                                <AddToQueue onClick={markDefault} />
                            </IconButton>
                        </Tooltip>
                    )
                        : (
                            <div></div>
                        )}
                    {numSelected > 0 ? (
                        <Tooltip title="Delete">
                            <IconButton aria-label="Delete">
                                <DeleteIcon onClick={deleteAction} />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <div></div>
                    )}
                </div>
            </Toolbar>
        </div>
    );
};

GalleryListToolbar.propTypes = {
    classes: PropTypes?.object?.isRequired,
    numSelected: PropTypes?.number?.isRequired,
};

GalleryListToolbar = withStyles(toolbarStyles)(GalleryListToolbar);

class PortfolioGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            selected: [],
            deleteAlert: false,
            deleteSelected: '',
            existingImage: '',
            files: [],
            showOld: true,
            crop: {
                x: 40,
                y: 40,
                width: 100,
                height: 100,
            },
            croppedImageUrl: '',
            cropUrl: '',
            imageError: '',
            imgIndex: 0,
            showUploadImageForm: false,
            showUploadVideoForm:
                props?.selectedGenereId &&
                props?.providerTypeProfileData?.videos?.length,
            providerTypeProfileData: props?.providerTypeProfileData,
            selectedGenereId: props?.selectedGenereId,
            selectedGenereName: props?.selectedGenereName,
            providerTypeId: props?.providerTypeId,
            providerId: props?.providerId,
            ErrorColor: 'black',
            totalUploadedPhotos: 0,
            totalPhotos: 0,
            totalUploadedPercentage: 0,
            videoLinks: [Math.random()],
            videoLinkUrls: [],
            imageIndx: 0,
            shoeVideoLink: false,
        };
    }

    componentDidMount() {
        let providerTypeProfileData = [];
        let images = [];
        providerTypeProfileData = providerTypeProfileData;
        if (
            providerTypeProfileData?.images?.length
        ) {
            images = providerTypeProfileData?.images.map((image, index) => {
                return {
                    id: image?.id,
                    src: image?.filename,
                    thumbnail: image?.filename,
                    isSelected: image?.is_default,
                };
            });
        }
        this.setState({
            images,
        });
    }

    componentDidUpdate(prevProps) {
        const {
            selectedGenereId,
            providerTypeProfileData,
            providerTypeId,
            selectedGenereName,
        } = this.props;
        if (
            prevProps?.selectedGenereId !== selectedGenereId ||
            prevProps?.providerTypeProfileData !== providerTypeProfileData
        ) {
            let images = [];
            if (
                providerTypeProfileData?.images?.length
            ) {
                images = providerTypeProfileData?.images.map((image, index) => {
                    return {
                        id: image?.id,
                        src: image?.filename,
                        thumbnail: image?.filename,
                    };
                });
            }
            this.setState({
                images: images,
                videoLinkUrls: providerTypeProfileData?.videos?.length
                    ? Array.isArray(providerTypeProfileData?.videos) ? providerTypeProfileData?.videos : []
                    : '',
                videoLinks: Array(providerTypeProfileData?.videos?.length).fill(
                    Math.random()
                ),
                selectedGenereId: selectedGenereId,
                providerTypeId: providerTypeId,
                selectedGenereName: selectedGenereName,
            });
        }
    }

    selectedImages = (index, image) => {
        const { images } = this.state;
        let Images = images.slice();
        let img = Images[index];
        if (img.hasOwnProperty('isSelected')) img.isSelected = !img.isSelected;
        else img.isSelected = true;
        let selected = Images.filter((image) => {
            return image?.isSelected;
        });
        this.setState({
            images: Images,
            selected,
            imageIndx: index,
        });
    };

    deleteRecord = (id) => {
        const { selected } = this.state;
        let ids = selected.map((selectedImg) => {
            return selectedImg?.id;
        });
        this.setState({ deleteAlert: true, deleteSelected: ids });
    };
    markDefault = async () => {
        let success = false;
        let response = await this.markDefaultImage();
        const { images, imageIndx } = this.state;
        let Images = images.slice();
        let img = Images[imageIndx];
        if (img.hasOwnProperty('isSelected')) img.isSelected = !img.isSelected;
        this.setState({
            selected: [],
            imageIndx: 0,
        });
        if (response?.status === 'OK') {
            success = true;
        }
        if (success) {
            NotificationManager.success(
                `Default Image Selected Successfully.`,
                'Success'
            );
        }
    }
    markDefaultImage = () => {
        const { providerTypeId, selectedGenereId, selected } = this.state;
        return new Promise((resolve, reject) => {
            let providerType = '';
            let imageId = '';
            let genereId = '';
            let is_default = false;
            let formData = {};
            let authToken = getCookies();
            providerType = providerTypeId;
            imageId = selected.map(item => item?.id);
            genereId = selectedGenereId;
            is_default = true;
            formData = new FormData();
            formData.append('providerType', providerType);
            formData.append('imageId', imageId);
            formData.append('genereId', genereId);
            formData.append('is_default', is_default);
            return axios({
                method: 'post',
                data: formData,
                url: `${SERVER_URL}/provider/set-default-image`,
                headers: { 'x-auth-token': authToken },
            })
                .then(
                    (response) => {
                        resolve(response?.data);
                    },
                    function (err) {
                        resolve({ status: 'NOK' });
                    }
                )
                .catch((err) => {
                    resolve({ status: 'NOK' });
                });
        });
    };
    addImage = () => {
        this.setState({ showUploadImageForm: true, });
    }
    deleteGallery = () => {
        const {
            deleteSelected,
            providerTypeId,
            providerId,
            selectedGenereId,
        } = this.state;
        const { deletePortfolioGalleryAction } = this.props;
        deletePortfolioGalleryAction(
            {
                id: deleteSelected,
                providerType: providerTypeId,
                providerId: providerId,
                genereId: selectedGenereId,
            },
            this.onDeleteDone
        );
    };

    onDeleteDone = (success, data) => {
        let { deleteSelected, images } = this.state;
        if (success) {
            images = images.filter((image) => {
                return deleteSelected.indexOf(image?.id) > -1;
            });
            this.setState({
                deleteAlert: false,
                images,
                selected: [],
                showUploadImageForm: images?.length > 0,
            });
            NotificationManager.success(data?.message, 'Success');
        } else {
            this.setState({
                deleteAlert: false,
                showUploadImageForm: images?.length > 0,
            });
            NotificationManager.error(data?.message, 'Error');
        }
    };

    handleCloseDelete = () => {
        this.setState({ deleteAlert: false });
    };

    showImageUploadForm = () => {
        this.setState({
            showUploadImageForm: true,
        });
    };

    showVideoLinkForm = () => {
        this.setState({
            showUploadVideoForm: true,
        });
    };
    hideVideoLinkForm = () => {
        this.setState({
            showUploadVideoForm: false,
            shoeVideoLink: false
        });
    };

    handleDrop = (files) => {
        this.setState({
            files: files.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
            showOld: false,
        });
    };
    addDropedImage = async () => {
        const { images, totalUploadedPhotos, croppedImageUrl, files } = this.state;
        this.setState({ loader: true });
        let success = false;
        let uploadedImages = [...images];
        let uploadedCount = 0;
        if (files?.length > 0) {
            for (let file of files) {
                this.setState({
                    totalPhotos: files?.length,
                    totalUploadedPercentage: 0,
                });
                let response = await this.addGalleryImage(croppedImageUrl);
                if (response?.status === 'OK') {
                    ++uploadedCount;
                    success = true;
                    if (
                        response?.data?.images?.length
                    ) {
                        let streamImages = response?.data?.images.map(
                            (image, index) => {
                                return {
                                    id: image?.id,
                                    src: image?.filename,
                                    thumbnail: image?.filename,
                                };
                            }
                        );
                        uploadedImages = streamImages;
                    }
                    this.setState({
                        totalUploadedPhotos: totalUploadedPhotos + 1,
                    });
                } else {
                    this.setState({
                        totalUploadedPercentage: 0,
                    });
                }
            }
        }

        if (success) {
            NotificationManager.success(
                `${uploadedCount} Images uploaded Successfully.`,
                'Success'
            );
            this.setState({
                loader: false,
                showUploadImageForm: false,
                images: uploadedImages,
                totalUploadedPhotos: 0,
                totalPhotos: 0,
                totalUploadedPercentage: 0,
            });
        } else {
            this.setState({
                loader: false,
                totalUploadedPhotos: 0,
                totalPhotos: 0,
                totalUploadedPercentage: 0,
            });
        }
    }
    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    };

    onCropComplete = async (crop) => {
        if (this.imageRef && crop?.width && crop?.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    };

    getCroppedImg = (image, pixelCrop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image?.naturalWidth / image?.width;
        const scaleY = image?.naturalHeight / image?.height;
        canvas.width = pixelCrop?.width;
        canvas.height = pixelCrop?.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            pixelCrop?.x * scaleX,
            pixelCrop?.y * scaleY,
            pixelCrop?.width * scaleX,
            pixelCrop?.height * scaleY,
            0,
            0,
            pixelCrop?.width,
            pixelCrop?.height
        );
        return new Promise((resolve, reject) => {
            const fileUrl = canvas.toDataURL();
            this.setState({ cropUrl: fileUrl });
            canvas.toBlob(file => {
                file.name = fileName;
                resolve(file);
            }, 'image/jpeg');
        });
    };

    addGalleryImage = (file) => {
        const { providerTypeId, selectedGenereId, imgIndex } = this.state;
        return new Promise((resolve, reject) => {
            let providerType = '';
            let providerId = '';
            let genereId = '';
            let formData = {};
            providerType = providerTypeId;
            providerId = this.state?.providerId;
            genereId = selectedGenereId;
            formData = new FormData();
            formData.append('index', imgIndex);
            formData.append('providerType', providerType);
            formData.append('providerId', providerId);
            formData.append('genereId', genereId);
            formData.append('file', file, 'hello.jpg');
            return axios({
                method: 'post',
                data: formData,
                url: `${SERVER_URL}/provider/add-portfolio`,
                headers: { 'x-auth-token': getCookies() },
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent?.lengthComputable
                        ? progressEvent?.total
                        : progressEvent?.target.getResponseHeader(
                            'content-length'
                        ) ||
                        progressEvent?.target.getResponseHeader(
                            'x-decompressed-content-length'
                        );
                    if (totalLength !== null) {
                        let progress = Math.round(
                            (progressEvent?.loaded * 100) / totalLength
                        );
                        this.setState({
                            totalUploadedPercentage: progress,
                        });
                    }
                },
            })
                .then(
                    (response) => {
                        resolve(response?.data);
                    },
                    function (err) {
                        resolve({ status: 'NOK' });
                    }
                )
                .catch((err) => {
                    resolve({ status: 'NOK' });
                });
        });
    };

    addVideoLinks = () => {
        const {
            providerTypeId,
            selectedGenereId,
            imgIndex,
            videoLinkUrls,
        } = this.state;
        const videoLink = videoLinkUrls?.length ? videoLinkUrls : [""]
        return new Promise((resolve, reject) => {
            const finalData = {
                // index: imgIndex,
                providerType: providerTypeId,
                providerId: this.state?.providerId,
                genereId: selectedGenereId,
                videos: videoLinkUrls?.length ? videoLinkUrls : [""],
            };
            return axios({
                method: 'post',
                data: finalData,
                url: `${SERVER_URL}/provider/add-portfolio`,
                headers: { 'x-auth-token': getCookies() },
            })
                .then(
                    (response) => {
                        if (response?.data?.status === 'OK') {
                            NotificationManager.success(
                                'Video Links Saved/Updated',
                                null,
                                2000
                            );
                        }
                        resolve(response?.data);
                    },
                    function (err) {
                        resolve({ status: 'NOK' });
                    }
                )
                .catch((err) => {
                    resolve({ status: 'NOK' });
                });
        });
    };

    addField = () => {
        const { videoLinks } = this.state;
        videoLinks.push(Math.random());
        this.setState({
            videoLinks,
        });
    };

    removeField = (item, index) => {
        const { videoLinks, videoLinkUrls } = this.state;
        videoLinks.splice(videoLinks.indexOf(item), 1);
        videoLinkUrls.splice(index, 1);
        this.setState({
            videoLinks,
            videoLinkUrls,
        });
    };

    renderButton = () => {
        const {
            loader,
            totalUploadedPercentage,
            totalUploadedPhotos,
            totalPhotos,
        } = this.state;
        if (loader) {
            return (
                <div
                    style={{
                        color: 'green',
                        fontSize: '14px',
                        fontWeight: 600,
                    }}
                >
                    <div>
                        {totalUploadedPhotos} of {totalPhotos} Photos
                    </div>
                    <CircularProgress style={{ margin: '15px' }} />
                    <div>{totalUploadedPercentage} % Progress</div>
                </div>
            );
        }
    };
    render() {
        const { match, isEditable } = this.props;
        const {
            selected,
            images,
            croppedImageUrl,
            showOld,
            imgIndex,
            showUploadImageForm,
            showUploadVideoForm,
            files,
            ErrorColor,
            crop,
            providerTypeId,
            selectedGenereName,
            showImageUploadForm,
            showVideoLinkForm,
            deleteAlert,
            cropUrl,
            videoLinkUrls,
            shoeVideoLink,
        } = this.state;
        const baseStyle = {
            width: 200,
            height: 200,
            borderWidth: 2,
            borderColor: '#666',
            borderStyle: 'dashed',
            borderRadius: 5,
        };
        const activeStyle = {
            borderStyle: 'solid',
            borderColor: '#6c6',
            backgroundColor: '#eee',
        };
        const rejectStyle = {
            borderStyle: 'solid',
            borderColor: '#c66',
            backgroundColor: '#eee',
        };
        const thumbs = files.map((file, index) => (
            <div key={index}>
                <h6 style={{ textTransform: 'capitalize' }}>
                    <FormattedMessage
                        id="customer.originalimage"
                        defaultMessage="Original Image"
                    />
                </h6>
                <ReactCrop
                    src={file?.preview}
                    imageStyle={{
                        width: '100%',
                        height: '100%',
                        maxHeight: 'none',
                    }}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    crop={crop}
                    onChange={(crop) => {
                        this.setState({ crop });
                    }}
                />
            </div>
        ));
        return (
            <div>
                <div>

                    {(showUploadImageForm) ? (
                        <div>
                            <div className="row">
                                <div className="col-md-12 col-xs-12 mt-3">
                                    {this.renderButton()}
                                </div>
                            </div>
                            {!thumbs?.length && <div className="row col-md-12">
                                <div className="col-md-6 m-auto">
                                    <h5 className={isMobile && 'font-14'}>
                                        <FormattedMessage
                                            id="customer.drop"
                                            defaultMessage="Drop Multiple Image here to Upload"
                                        />
                                    </h5>
                                    <Dropzone
                                        onDrop={this.handleDrop}
                                        accept="image/jpeg,image/jpg,image/png"
                                        multiple={false}
                                        maxSize={25000000}
                                        onDropRejected={handleDropRejected}
                                    >
                                        {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive,
                                            isDragAccept,
                                            isDragReject,
                                            acceptedFiles,
                                            rejectedFiles,
                                        }) => {
                                            let styles = { ...baseStyle };
                                            styles = isDragActive
                                                ? { ...styles, ...activeStyle }
                                                : styles;
                                            styles = isDragReject
                                                ? { ...styles, ...rejectStyle }
                                                : styles;
                                            {
                                                return (
                                                    <div
                                                        {...getRootProps()}
                                                        style={styles}
                                                        className="dropbox"
                                                    >
                                                        <div className="inner">
                                                            <input
                                                                {...getInputProps()}
                                                            />
                                                            <CloudUpload />
                                                            <p>
                                                                <FormattedMessage
                                                                    id="customer.dropfile"
                                                                    defaultMessage="Drop multiple files here, or click to select files"
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }}
                                    </Dropzone>
                                    <WSButton
                                        onClick={() => { this.setState({ files: [], croppedImageUrl: '', showUploadImageForm: false }) }
                                        }
                                        style={{
                                            marginTop: 70,
                                            marginLeft: 270,
                                            width: 200,
                                        }}
                                    >
                                        Cancel
                                    </WSButton>
                                </div>
                            </div>}
                            <div className="d-flex mt-30">
                                {thumbs?.length > 0 && (
                                    <div className="col-md-4 ml-15 mb-20">
                                        {thumbs}
                                    </div>
                                )}
                                <div className="col-md-5 mb-20">
                                    {croppedImageUrl && (
                                        <div>
                                            <Fragment>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={4}
                                                    lg={4}
                                                >
                                                    <h6 className="text-capitalize">
                                                        <FormattedMessage
                                                            id="customer.finalimage"
                                                            defaultMessage="Final Image"
                                                        />
                                                    </h6>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <LazyLoadImage
                                                            width={'200px'}
                                                            height={'170px'}
                                                            alt="Crop"
                                                            src={cropUrl}
                                                        />

                                                    </div>
                                                </Grid>
                                            </Fragment>
                                            <div className="updateCancelImage">
                                                <WSButton
                                                    onClick={
                                                        this.addDropedImage
                                                    }
                                                    style={{
                                                        marginLeft: 100,
                                                    }}
                                                >
                                                    Update
                                                </WSButton>
                                                <WSButton
                                                    onClick={() => { this.setState({ files: [], croppedImageUrl: '', showUploadImageForm: false }) }
                                                    }
                                                    style={{
                                                        marginLeft: 100,
                                                    }}
                                                >
                                                    Cancel
                                                </WSButton>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <WSButton
                            variant="outlined"
                            onClick={this.addImage}
                            style={{ marginLeft: 15, marginTop: 10 }}
                        >
                            + Upload Images
                        </WSButton>
                    )}
                    {(shoeVideoLink) ?
                        (<div>
                            <form
                                className="dialog-form dialog-form-new"
                                autoComplete="off"
                            >
                                <div className="col-md-4 col-xs-12">
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginTop: 20,
                                            marginBottom: 20,
                                        }}
                                    >
                                        <span>Video Links</span>

                                    </div>
                                    {this.state?.videoLinks.map(
                                        (item, index) => {
                                            return (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection:
                                                            'row',
                                                        alignItems:
                                                            'center',
                                                    }}
                                                // key={`textField_${item}`}
                                                >
                                                    <WSTextField
                                                        className="login-form-textinput mt-0"
                                                        id="videoLinks"
                                                        name="videoLinks"
                                                        placeholder={
                                                            'Video Link'
                                                        }
                                                        onChange={(
                                                            event
                                                        ) => {
                                                            let tempLinks =
                                                                this.state
                                                                    ?.videoLinkUrls ||
                                                                [];
                                                            tempLinks[
                                                                index
                                                            ] =
                                                                event?.target?.value;
                                                            this.setState({
                                                                videoLinkUrls: tempLinks,
                                                            });
                                                        }}
                                                        value={
                                                            this.state
                                                                ?.videoLinkUrls &&
                                                            this.state
                                                                ?.videoLinkUrls[
                                                            index
                                                            ]
                                                        }
                                                    />
                                                    <WSButton
                                                        style={{
                                                            color:
                                                                '#ffffff',
                                                            width: 170,
                                                            height: 40,
                                                            marginLeft: 10,
                                                            padding: 0,
                                                        }}
                                                        onClick={() =>
                                                            this.removeField(
                                                                item,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        Remove Link
                                                    </WSButton>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                                <div className="flex-justify-spacebetween mt-2 ml-3">
                                    <div>
                                        <WSButton
                                            style={{
                                                color: '#ffffff',
                                                width: 130,
                                                height: 40,
                                                padding: 0,
                                            }}
                                            onClick={this.addField}
                                        >
                                            Add New Link
                                        </WSButton>
                                        <WSButton
                                            style={{
                                                color: '#ffffff',
                                                margin: !isMobile && 20,
                                                marginLeft: !isMobile ? 140 : 50,
                                            }}
                                            onClick={this.addVideoLinks}
                                        >
                                            Save and Update
                                        </WSButton>
                                        <WSButton
                                            style={{
                                                color: '#ffffff',
                                                margin: !isMobile && 20,
                                                marginLeft: 20,
                                            }}
                                            onClick={this.hideVideoLinkForm}
                                        >
                                            Cancel
                                        </WSButton>
                                    </div>
                                </div>
                            </form>
                        </div>) : (
                            <WSButton
                                variant="outlined"
                                onClick={() => { this.setState({ shoeVideoLink: true }) }}
                                style={{ marginLeft: 15, marginTop: 10 }}
                            >
                                + Upload/Remove Video
                            </WSButton>)
                    }
                    <GalleryListToolbar
                        isEditable={isEditable}
                        numSelected={selected?.length}
                        deleteAction={this.deleteRecord}
                        markDefault={this.markDefault}
                        match={match}
                        serviceId={1}
                        creategallery={this.showImageUploadForm}
                        createVideoGallery={this.showVideoLinkForm}
                        showUploadImageForm={showUploadImageForm}
                        showUploadVideoForm={showUploadVideoForm}
                        totalCount={images?.length}
                        providerTypeId={providerTypeId}
                        selectedGenereName={selectedGenereName}
                    />
                    <div className="col-md-12 col-xs-12 mt-3">
                        <Gallery
                            images={images}
                            showImageCount={true}
                            enableImageSelection={true}
                            onSelectImage={(selectedImages, image) => {
                                this.selectedImages(selectedImages);
                            }}
                        />
                    </div>
                    <DeleteAlert
                        open={deleteAlert}
                        close={deleteAlert}
                        handleNo={this.handleCloseDelete}
                        handleYes={this.deleteGallery}
                    />
                </div>
                <div>
                    <div className="col-md-12 col-xs-12 mt-3">
                        <div className="portfolioVideo">
                            {videoLinkUrls?.length ? (
                                videoLinkUrls.map((link) => {
                                    return (
                                        <ReactPlayer
                                            className="mr-2"
                                            controls
                                            width="300px"
                                            height="200px"
                                            url={link}
                                        />
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const composedComponent = compose(
    withStyles(toolbarStyles),
    connect(null, actions)
);

export default composedComponent(PortfolioGallery);
