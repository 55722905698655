import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import HOCLoader from '../HOC/HOCLoader.jsx';
import WSButton from '../Button/WSButton.jsx';

import * as actions from '../../actions';
import { convertTimestampToDate } from '../../utils/config/constants';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function NotificationListHead(props) {

    const createSortHandler = property => event => {
        const { onRequestSort } = props;
        onRequestSort(event, property);
    };

    const { formatMessage } = props;
    const rows = [
        {
            id: 'title',
            label: formatMessage({
                id: 'notification.title'
            })
        },
        {
            id: 'body',
            label: formatMessage({
                id: 'notification.body'
            })
        },
        {
            id: 'created_at',
            label: formatMessage({
                id: 'notification.created_at'
            })
        },
        {
            id: 'action',
            label: formatMessage({
                id: 'booking.action'
            })
        }
    ];
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount
    } = props;

    return (
        <TableHead>
            <TableRow>
                {rows.map(
                    row => (
                        <TableCell
                            key={row?.id}
                            align={row?.numeric ? 'right' : 'left'}
                            padding={
                                row?.disablePadding ? 'none' : 'default'
                            }
                            sortDirection={
                                orderBy === row?.id ? order : false
                            }
                        >
                            <Tooltip
                                title="Sort"
                                placement={
                                    row?.numeric
                                        ? 'bottom-end'
                                        : 'bottom-start'
                                }
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={orderBy === row?.id}
                                    direction={order}
                                    onClick={createSortHandler(row?.id)}
                                >
                                    {row?.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this
                )}
            </TableRow>
        </TableHead>
    );
}

NotificationListHead.propTypes = {
    numSelected: PropTypes?.number?.isRequired,
    onRequestSort: PropTypes?.func?.isRequired,
    onSelectAllClick: PropTypes?.func?.isRequired,
    order: PropTypes?.string?.isRequired,
    orderBy: PropTypes?.string?.isRequired,
    rowCount: PropTypes?.number?.isRequired
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme?.spacing?.unit
    },
    highlight:
        theme?.palette?.type === 'light'
            ? {
                color: theme?.palette?.secondary?.main,
                backgroundColor: lighten(theme?.palette?.secondary?.light, 0.85)
            }
            : {
                color: theme?.palette?.text?.primary,
                backgroundColor: theme?.palette?.secondary?.dark
            },
    spacer: {
        flex: '1 1 100%'
    },
    actions: {
        color: theme?.palette?.secondary?.main
    },
    title: {
        flex: '0 0 auto'
    }
});

let NotificationListToolbar = props => {
    const { numSelected, classes } = props;

    return (
        <Toolbar
            className={classNames(classes?.root, {
                [classes?.highlight]: numSelected > 0
            })}
        >
            <div className={classes?.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected}&nbsp;
                        <FormattedMessage
                            id="wallet.selected"
                            defaultMessage="selected"
                        />
                    </Typography>
                ) : (
                    <h6>
                        <FormattedMessage
                            id="notification.heading"
                            defaultMessage="Notifications"
                        />
                    </h6>
                )}
            </div>
            <div className={classes?.spacer} />
        </Toolbar>
    );
};

NotificationListToolbar.propTypes = {
    classes: PropTypes?.object?.isRequired,
    numSelected: PropTypes?.number?.isRequired
};

NotificationListToolbar = withStyles(toolbarStyles)(NotificationListToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme?.spacing?.unit * 3
    },
    table: {
        minWidth: 600
    },
    tableWrapper: {
        overflowX: 'auto'
    }
});

function NotificationList(props) {

    const [order, setOrder] = useState('desc')
    const [orderBy, setOrderBy] = useState('created_at')
    const [selected, setSelected] = useState([])
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [clickValue, setClickValue] = useState('')
    const [item, setItem] = useState({})

    const actionCallbacks = (action, res) => {
        if (action === 'view') {
            setClickValue('view')
            setItem(res)
        }
    }

    useEffect(() => {
        const { notificationListAction } = props;
        notificationListAction();
    }, []);

    const notificationData = useSelector(state => state?.notification?.notificationData);
    const notificationLoader = useSelector(state => state?.notification?.notificationLoader);

    useEffect((prevProps, prevState) => {
        if (prevProps?.notificationData !== notificationData) {
            setData(notificationData)
        }
    }, []);


    const handleRequestSort = (event, property) => {
        let orders = 'desc';
        if (orderBy === property && order === 'desc') {
            orders = 'asc';
        }
        setOrder(orders)
        setOrderBy(property)
    };

    const handleSelectAllClick = event => {
        if (event?.target?.checked) {
            setSelected(state?.data.map(n => n?.key))
            return;
        }
        setSelected([])
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected)
    };

    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event?.target?.value)
    };

    const isSelected = id => selected.indexOf(id) !== -1;

    const { classes } = props;
    const { intl, history, bookingLoader } = props;
    const { formatMessage } = intl;
    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, data?.length - page * rowsPerPage);

    //View Redirection
    if (clickValue) {
        if (item?.route === 'BookingDetail') {
            history.push({
                pathname:
                    item?.webroute +
                    '/' +
                    item?.notification?.payload?.additionalData
                        ?.params?.bookingDetail?.key,
                bookingId: item?.notification?.payload
                    ?.additionalData?.params?.bookingDetail?.key
            });
        } else if (item?.route === 'Offers') {
            history.push({
                pathname: item?.webroute
            });
        }
    }
    //View Redirection
    return (
        <HOCLoader loading={bookingLoader}>
            <Paper className={classes?.root + isMobile && 'mt-10'}>
                <NotificationListToolbar numSelected={selected?.length} />
                <div className={classes?.tableWrapper}>
                    <Table
                        className="dashboard-tables"
                        className={classes?.table}
                        aria-labelledby="tableTitle"
                    >
                        <NotificationListHead
                            numSelected={selected?.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data?.length}
                            formatMessage={intl?.formatMessage}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map(n => {
                                    const isSelected = isSelected(
                                        n?.key
                                    );
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n?.key}
                                            selected={isSelected}
                                            style={{
                                                backgroundColor:
                                                    n?.is_read === 2
                                                        ? '#cccccc'
                                                        : 'default',
                                            }}
                                        >
                                            <TableCell scope="row">
                                                {
                                                    n?.notification?.payload
                                                        ?.title
                                                }
                                            </TableCell>
                                            <TableCell scope="row">
                                                {
                                                    n?.notification?.payload
                                                        ?.body
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {convertTimestampToDate(
                                                    n?.created_at * 1000
                                                )}
                                            </TableCell>

                                            <TableCell>
                                                <WSButton
                                                    id="bookingMenu"
                                                    variant="outlined"
                                                    onClick={() => actionCallbacks('view', n)}
                                                >
                                                    View
                                                    </WSButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{ height: 49 * emptyRows }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>

                <TablePagination
                    className="dashboard-table-pagination"
                    classes={{
                        selectIcon: 'tablePaginationSelectIcon'
                    }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': formatMessage({
                            id: 'pagination.previous'
                        })
                    }}
                    nextIconButtonProps={{
                        'aria-label': formatMessage({
                            id: 'pagination.next'
                        })
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </HOCLoader>
    );
}

NotificationList.propTypes = {
    classes: PropTypes?.object?.isRequired
};

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default composedComponent(injectIntl(NotificationList));
