import React, { Component } from 'react'
import SocialLogin from 'react-social-login'

class SocialButton extends Component {
    render() {
        const {
            triggerLogin,
            classNames,
            children
        } = this.props;
        return (
            <div onClick={triggerLogin} className={classNames} {...this.props}>
                {children}
            </div>
        );
    }
}

export default SocialLogin(SocialButton);