import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Card, Toolbar } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';
import FavouriteList from './FavouriteList.jsx';
import HOCSectionLoader from '../HOC/HOCSectionLoader.jsx';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

const styles = theme => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme?.typography.pxToRem(15),
        flexBasis: '70%',
        paddingRight: '0',
        textAlign: 'left',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme?.typography.pxToRem(15),
        color: theme?.palette?.text?.secondary
    }
});

function FavouriteExpansion(props) {

    const [expanded, setExpanded] = useState(null)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const { favouriteListAction } = props;
        favouriteListAction();
    };

    const favouriteData = useSelector(state => state?.providers?.favProviderList);
    const favProviderLoader = useSelector(state => state?.providers?.favProviderLoader);

    useEffect((prevProps, prevState) => {
        if (prevProps?.favouriteData !== favouriteData) {
            setData(favouriteData)
        }
    }, []);

    const handleChange = panel => (event, expanded) => {
        setExpanded(expanded ? panel : false)
    };

    const { classes, favouriteLoader } = props;
    return (
        <HOCSectionLoader loading={favouriteLoader}>
            <div className={isMobile ? "row mb-2" : "row mb-4"}>
                <div className="col-md-12">
                    <Card className={classes?.card}>
                        <Toolbar>
                            <h3 className={isMobile && 'font-18'}>
                                <FormattedMessage
                                    id="myFavouriteList"
                                    defaultMessage="My Favourite List"
                                />
                            </h3>
                        </Toolbar>
                    </Card>
                </div>
            </div>
            <div className={classes?.root}>
                {data.map(n => {
                    return (

                        <div className="col-md-12 pl-0 pr-0">
                            <FavouriteList data={n?.providers} />
                        </div>

                    );
                })}
            </div>
        </HOCSectionLoader>
    );
}

FavouriteExpansion.propTypes = {
    classes: PropTypes?.object?.isRequired
};

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default composedComponent(FavouriteExpansion);
