import axios from 'axios';

import {
    errorResponse,
    validateApiData,
} from '../../utils/validators/validators';
import { SERVER_URL } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

export const categoryList = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/category/list`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const serviceDetail = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/service/view`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const topCategoryList = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: { limit: 4 },
            url: `${SERVER_URL}/category/top`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerCategoryList = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/profile/my-categories`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerSubCategoryList = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/profile/my-subcategories`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const mainSearch = async (params) => {
    let authToken = getCookies();
    let obj = {
        type: params.type,
    };
    if (params.keyword) {
        obj['text'] = params.keyword;
    }
    let str = '';
    for (let key in obj) {
        if (str != '') {
            str += '&';
        }
        str += key + '=' + obj[key];
    }
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/user/search-records?${str}`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const allServices = async () => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            url: `${SERVER_URL}/category/allservices`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerServiceList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/provider-service/get`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const getProviderServices = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/provider-service/getmine`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const serviceChangeStatus = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-service/status`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const getCategoryService = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/category/services`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const addService = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-service/create`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const updateProviderService = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-service/update`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerServiceDetail = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'get',
            params: params,
            url: `${SERVER_URL}/provider-service/detail`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const addServiceFaq = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/faq/create`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const addServicePackage = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/package/create`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const updateServiceFaq = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/faq/update`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const updateServicePackage = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/package/update`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const serviceFaqDetail = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/faq/detail`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const servicePackageDetail = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/package/detail`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const deleteServiceFaq = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/faq/delete`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const deleteServicePackage = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/package/delete`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const addServiceGalleryImage = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-service/create-media`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const addPortfolioGalleryImage = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider/add-portfolio`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const deletePortfolioGallery = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider/delete-portfolio`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const shareBookingImage = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/booking/share-booking-images`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const deleteSharedBookingImages = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/booking/delete-shared-image`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const sharedBookingImages = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/booking/share-booking-images-to-customer`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const shareSelectedBookingImages = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/booking/select-booking-images`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerServiceVideoList = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-url/list`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const addVideoUrl = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-url/create`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const updateVideoUrl = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-url/update`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const deleteServiceVideoUrl = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-url/delete`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const providerServiceVideoDetail = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            data: params,
            url: `${SERVER_URL}/provider-url/detail`,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};
