import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import ReactCrop from 'react-image-crop';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Paper, Grid } from '@material-ui/core';

import { ROLE_CUSTOMER, ROLE_PROVIDER } from '../../utils/config/constants';

import CustomerTabs from '../../components/Tabs/CustomerTabs';
import withUser from '../../components/HOC/HOCwithUser.jsx';
import HOCLoader from '../../components/HOC/HOCLoader.jsx';
import ProviderTabs from '../../components/Tabs/ProviderTabs';
import WSButton from '../../components/Button/WSButton';

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};

export class MainProfile extends Component {
    state = {
        showOld: true,
        files: [],
        croppedImageUrl: '',
        cropUrl: '',
        crop: {
            x: 40,
            y: 40,
            width: 100,
            height: 100,
        },
    };

    handleDropRejected = (...args) => {
        this.setState({ showOld: true });
        NotificationManager.error(
            <FormattedMessage
                id="errorUploading10mb"
                defaultMessage="Error uploading Image, try another image with maximum size 10MB"
            />,
            'Error'
        );
    };

    handleDrop = (files) => {
        this.setState({
            files: files.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
            showOld: false,
        });
    };

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    };

    onCropComplete = async (crop) => {
        if (crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    };

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image?.naturalWidth / image?.width;
        const scaleY = image?.naturalHeight / image?.height;
        canvas.width = pixelCrop?.width;
        canvas.height = pixelCrop?.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            pixelCrop?.x * scaleX,
            pixelCrop?.y * scaleY,
            pixelCrop?.width * scaleX,
            pixelCrop?.height * scaleY,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );
        return new Promise((resolve, reject) => {
            const fileUrl = canvas.toDataURL();
            this.setState({ cropUrl: fileUrl });
            canvas.toBlob(file => {
                file.name = fileName;
                resolve(file);
            }, 'image/jpeg');
        });
    }

    updateBasicDetailAPI = () => {
        const { updateProfileImageAction } = this.props;
        const { croppedImageUrl } = this.state;
        let formData = new FormData();
        formData.append('file', croppedImageUrl);
        updateProfileImageAction(formData, this.onDone);
    };

    onDone = (success) => {
        const { getUserDataAction } = this.props;
        if (success) {
            NotificationManager.success('Profile Image Updated', null, 2000);
            this.setState(
                {
                    showOld: true,
                    croppedImageUrl: '',
                    files: [],
                },
                () => {
                    getUserDataAction();
                }
            );
        } else {
            NotificationManager.error(
                'Profile Image Update Failed',
                null,
                2000
            );
        }
    };

    render() {
        const { userData, isLoading, theme } = this.props;
        const { showOld, croppedImageUrl, files, crop, cropUrl } = this.state;
        const thumbs = files.map((file, index) => (
            <div key={index}>
                <h6 style={{ textTransform: 'capitalize' }}>
                    <FormattedMessage
                        id="customer.originalimage"
                        defaultMessage="Original Image"
                    />
                </h6>
                <span className="mt-1 mr-4">Note: Please Crop Original Image</span>
                <ReactCrop
                    src={file.preview}
                    imageStyle={{
                        width: '100%',
                        height: '100%',
                        maxHeight: 'none',
                    }}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    crop={crop}
                    onChange={(crop) => {
                        this.setState({
                            crop,
                            file,
                        });
                    }}
                />
            </div>
        ));
        return (
            <HOCLoader loading={isLoading}>
                <div>
                    {userData && (
                        <Paper position="static" color="white">
                            <div>
                                <Grid
                                    container
                                    style={{ textAlign: isMobile && 'center' }}
                                    className="mb-20"
                                >
                                    {showOld && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                        >
                                            <Dropzone
                                                onDrop={this.handleDrop}
                                                accept="image/jpeg,image/jpg,image/png"
                                                multiple={false}
                                                maxSize={10000000}
                                                onDropRejected={
                                                    this.handleDropRejected
                                                }
                                            >
                                                {({
                                                    getRootProps,
                                                    getInputProps,
                                                }) => (
                                                    <div
                                                        {...getRootProps()}
                                                        className="flex-direction-column flex-align-center"
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />
                                                        <LazyLoadImage
                                                            alt="user-profile"
                                                            src={
                                                                userData.role ===
                                                                    ROLE_CUSTOMER
                                                                    ? userData
                                                                        .image
                                                                        .medium
                                                                    : userData
                                                                        .proImage
                                                                        .medium
                                                            }
                                                            className="profile-pic mb-0"
                                                            width="200px"
                                                            height="200px"
                                                        />
                                                        <span className="font-w500 font-14 pointerCursor">
                                                            + Change Profile Image
                                                        </span>
                                                        <span className="font-w500 font-14 ml-20 text-center">
                                                            (Note: The maximum limit to upload image is 10MB)
                                                        </span>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </Grid>
                                    )}
                                    {!showOld && thumbs.length > 0 && (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                lg={4}
                                                className="ml-20"
                                            >
                                                {thumbs}
                                            </Grid>
                                        </>
                                    )}
                                    {!showOld && croppedImageUrl && (
                                        <Fragment>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                lg={4}
                                            >
                                                <h6 className="text-capitalize">
                                                    <FormattedMessage
                                                        id="customer.finalimage"
                                                        defaultMessage="Final Image"
                                                    />
                                                </h6>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <LazyLoadImage
                                                        width={'200px'}
                                                        height={'200px'}
                                                        alt="Crop"
                                                        src={cropUrl}
                                                    />
                                                    <WSButton
                                                        style={{
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                            color: '#ffffff',
                                                            borderRadius: 30,
                                                            marginLeft: 30,
                                                            height: 50,
                                                        }}
                                                        onClick={
                                                            this
                                                                .updateBasicDetailAPI
                                                        }
                                                    >
                                                        Update
                                                    </WSButton>
                                                    <WSButton
                                                        style={{
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                            color: '#ffffff',
                                                            borderRadius: 30,
                                                            marginLeft: 30,
                                                            height: 50,
                                                        }}
                                                        onClick={() => { this.setState({ showOld: true }) }}
                                                    //     this
                                                    //         .updateBasicDetailAPI
                                                    // }
                                                    >
                                                        Cancel
                                                    </WSButton>
                                                </div>
                                            </Grid>
                                        </Fragment>
                                    )}
                                    {showOld && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={8}
                                            lg={8}
                                        >
                                            <h3
                                                style={{
                                                    marginTop: isMobile
                                                        ? '10px'
                                                        : '100px',
                                                    marginLeft: '10px'
                                                }}
                                            >
                                                <b>{userData.name}</b>
                                            </h3>
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                            {userData.role === ROLE_CUSTOMER && (
                                <CustomerTabs {...sendProps(this.props)} />
                            )}
                            {userData.role === ROLE_PROVIDER && (
                                <ProviderTabs {...sendProps(this.props)} />
                            )}
                        </Paper>
                    )}
                </div>
            </HOCLoader>
        );
    }
}

export default withUser(MainProfile);
