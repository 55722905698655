import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { Card } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {
    Star,
    Assignment,
    AssignmentLate,
    AssignmentTurnedIn,
} from '@material-ui/icons';
import { withTheme, withStyles } from '@material-ui/core/styles';

import * as actions from '../../actions';

import { formatPrice } from '../../utils/helpers/functions/validators';

import DashboardLineChart from '../../components/Chart/DashboardLineChart';
import HOCSectionLoader from '../../components/HOC/HOCSectionLoader.jsx';
import ServiceReview from '../../components/Providers/ServiceReview';

import 'bootstrap/dist/css/bootstrap.css';

const styles = (theme) => ({
    heading: {
        color: '#ffffff',
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
    },
    card: {
        color: theme.palette.secondary.main,
    },
    checkIcon: {
        color: 'green',
        position: 'relative',
    },
    cancelIcon: {
        color: 'red',
        position: 'relative',
    },
    cust_icon: {
        position: 'absolute',
        padding: '0px 8px',
    },
});

class ProviderDashboard extends Component {
    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            menuState: false,
            providerData: '',
            weeklyData: [],
            monthlyData: [],
            yearlyData: [],
            loader: true,
            ratingData: [],
            avgRating: '0.0',
        };
    }

    componentDidMount() {
        const { getFullProviderProfileAction } = this.props;
        this.getDashboardData();
        getFullProviderProfileAction();
        // this.handleClickOpen();
    }

    getDashboardData = () => {
        const {
            providerDashboardDataAction,
            providerCheckAction,
            providerRatingAction,
        } = this.props;
        providerDashboardDataAction(
            { type: 1, datetime: new Date() },
            this.onWeeklyDone
        );
        providerCheckAction(this.onCheckDone);
        providerRatingAction(this.onRatingDone);
    };

    onCheckDone = (success, data) => {
        if (success) {
            this.setState({ providerData: data.data.detail });
        }
    };

    onRatingDone = (success, data) => {
        if (success) {
            this.setState({
                ratingData: data.data.subRatings,
                avgRating: data.data.ar,
            });
        }
    };

    onWeeklyDone = (success, data) => {
        const { providerDashboardDataAction } = this.props;
        if (success) {
            this.setState(
                { weeklyData: data.data[0].data, countData: data.counts },
                () => {
                    providerDashboardDataAction(
                        { type: 2, datetime: new Date() },
                        this.onMonthlyDone
                    );
                }
            );
        }
    };

    onMonthlyDone = (success, data) => {
        const { providerDashboardDataAction } = this.props;
        if (success) {
            this.setState({ monthlyData: data.data[0].data }, () => {
                providerDashboardDataAction(
                    { type: 3, datetime: new Date() },
                    this.onYearlyDone
                );
            });
        }
    };

    onYearlyDone = (success, data) => {
        if (success) {
            this.setState({ yearlyData: data.data[0].data, loader: false });
        }
    };

    onOptionMenuSelect = (event) => {
        this.setState({ menuState: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => {
        this.setState({ menuState: false });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes, userData } = this.props;
        const {
            weeklyData,
            monthlyData,
            yearlyData,
            avgRating,
            ratingData,
            loader,
            countData,
        } = this.state;
        return (
            <div>
                <div className="row">
                    <Card className={classes.card + ' ' + 'col-md-12'}>
                        <h3 className={isMobile && 'font-18'}>
                            <FormattedMessage
                                id="providerDashboard"
                                defaultMessage="Your Dashboard"
                            />
                        </h3>
                        {/* {userData.stripe_completed === 0 && (
                            <p>
                                Add your stripe payment details in profile
                                section to receive payments.
                            </p>
                        )} */}
                    </Card>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                        <Paper
                            elevation={1}
                            className="dashboard-small-box bg-yellow"
                        >
                            <h3 style={{ color: '#fff' }}>
                                <AssignmentLate style={{ marginRight: 5 }} />
                                {countData && countData.initiatedBookingCount}
                            </h3>
                            <h4
                                className={classes.heading}
                                style={{ fontSize: isMobile && 16 }}
                            >
                                <FormattedMessage
                                    id="initiatedBookings"
                                    defaultMessage="Initiated Bookings"
                                />
                            </h4>
                        </Paper>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <Paper
                            elevation={1}
                            className="dashboard-small-box bg-green"
                        >
                            <h3 style={{ color: '#fff' }}>
                                <Assignment style={{ marginRight: 5 }} />
                                {countData && countData.inProgressBookingCount}
                            </h3>
                            <h4
                                className={classes.heading}
                                style={{ fontSize: isMobile && 16 }}
                            >
                                <FormattedMessage
                                    id="booking.inprogress"
                                    defaultMessage="InProgress Bookings"
                                />
                            </h4>
                        </Paper>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <Paper
                            elevation={1}
                            className="dashboard-small-box bg-pink"
                        >
                            <h3 style={{ color: '#fff' }}>
                                <AssignmentTurnedIn
                                    style={{ marginRight: 5 }}
                                />
                                {countData && countData.completedBookingCount}
                            </h3>
                            <h4
                                className={classes.heading}
                                style={{ fontSize: isMobile && 16 }}
                            >
                                <FormattedMessage
                                    id="booking.completedbookings"
                                    defaultMessage="Completed Bookings"
                                />
                            </h4>
                        </Paper>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <Paper
                            elevation={1}
                            className={'dashboard-small-box bg-blue'}
                        >
                            <h3 style={{ color: '#fff' }}>
                                <Star style={{ marginRight: 5 }} />
                                {avgRating && avgRating}
                            </h3>
                            <h4
                                className={classes.heading}
                                style={{ fontSize: isMobile && 16 }}
                            >
                                <FormattedMessage
                                    id="averageRating"
                                    defaultMessage="Average Rating"
                                />
                            </h4>
                        </Paper>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                        <Paper
                            elevation={1}
                            className="dashboard-small-box bg-sky"
                        >
                            <h3 style={{ color: '#fff' }}>
                                AED
                                {' '}
                                {formatPrice(countData && countData.pendingBookingRevenue, '0,0.00')}
                            </h3>
                            <h4
                                className={classes.heading}
                                style={{ fontSize: isMobile && 16 }}
                            >
                                <FormattedMessage
                                    id="pending_booking_revenue"
                                    defaultMessage="Pending Revenue"
                                />
                            </h4>
                        </Paper>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <Paper
                            elevation={1}
                            className="dashboard-small-box bg-lightgreen"
                        >
                            <h3 style={{ color: '#fff' }}>
                                AED{' '}
                                {formatPrice(countData && countData.actualRevenue, '0,0.00')}
                            </h3>
                            <h4
                                className={classes.heading}
                                style={{ fontSize: isMobile && 16 }}
                            >
                                <FormattedMessage
                                    id="booking.actualRevenue"
                                    defaultMessage="Actual Revenue"
                                />
                            </h4>
                        </Paper>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <Paper
                            elevation={1}
                            className="dashboard-small-box bg-orange"
                        >
                            <h3 style={{ color: '#fff' }}>
                                AED{' '}
                                {formatPrice(countData && countData.completedBookingRevenue, '0,0.00')}
                            </h3>
                            <h4
                                className={classes.heading}
                                style={{ fontSize: isMobile && 16 }}
                            >
                                <FormattedMessage
                                    id="booking.completed_booking_revenue"
                                    defaultMessage="Total Revenue"
                                />
                            </h4>
                        </Paper>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3 className={isMobile && 'font-18'}>
                            <FormattedMessage
                                id="booking.reviews"
                                defaultMessage="Reviews"
                            />
                        </h3>
                    </div>
                    <div className="col-md-12 text-center">
                        <ServiceReview data={ratingData} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3 className={isMobile && 'font-18'}>
                            <FormattedMessage
                                id="booking.heading"
                                defaultMessage="Bookings"
                            />
                        </h3>
                    </div>
                    <HOCSectionLoader loading={loader}>
                        <div className="col-md-12 text-center">
                            <h4 className={isMobile && 'font-16'}>
                                <FormattedMessage
                                    id="weeklyBookings"
                                    defaultMessage="Weekly Bookings"
                                />
                            </h4>
                            <DashboardLineChart
                                data={weeklyData}
                                {...this.props}
                            />
                        </div>
                        <div className="col-md-12 text-center">
                            <h4 className={isMobile && 'font-16'}>
                                <FormattedMessage
                                    id="monthlyBookings"
                                    defaultMessage="Monthly Bookings"
                                />
                            </h4>
                            <DashboardLineChart
                                data={monthlyData}
                                {...this.props}
                            />
                        </div>
                        <div className="col-md-12 text-center">
                            <h4 className={isMobile && 'font-16'}>
                                <FormattedMessage
                                    id="yearlyBookings"
                                    defaultMessage="Yearly Bookings"
                                />
                            </h4>
                            <DashboardLineChart
                                data={yearlyData}
                                {...this.props}
                            />
                        </div>
                    </HOCSectionLoader>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, categories }) => {
    return {
        providerData: auth.providerData,
    };
};

const composedComponent = compose(
    withStyles(styles),
    withTheme(),
    connect(null, actions)
);

export default composedComponent(ProviderDashboard);
