import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class MenuButton extends Component {
    state = {
        anchorEl: null
    };

    handleChange = (event, checked) => {
        this.setState({ auth: checked });
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuCallback = action => {
        const { menuCallback, rowData } = this.props;
        menuCallback(action, rowData);
        this.setState({ anchorEl: null });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const _this = this;
        const { menuId, iconType, items } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const Wrapper = iconType;
        const listItems = items.map((button, index) => (
            <MenuItem
                key={menuId + '-' + index}
                onClick={() => _this.handleMenuCallback(button.action)}
            >
                {button.label}
            </MenuItem>
        ));

        return (
            <div>
                <IconButton
                    aria-owns={open ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                >
                    {<Wrapper />}
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    {listItems}
                </Menu>
            </div>
        );
    }
}

export default MenuButton;
