import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Fade } from '@material-ui/core';

import WSButton from '../Button/WSButton';

const DeleteAlert = props => {

        const {
            open,
            close,
            handleNo,
            handleYes
        } = props;
        return (
            <div>
                <Dialog
                    fullWidth={true}
                    open={open}
                    onClose={close}
                    TransitionComponent={Fade}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure want to delete this?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <WSButton
                            onClick={handleNo}
                        >
                            No
                        </WSButton>
                        <WSButton
                            onClick={handleYes}
                            autoFocus
                        >
                            Yes
                        </WSButton>
                    </DialogActions>
                </Dialog>
            </div>
        );
}

export default DeleteAlert;
