import React, { Component } from 'react';
import BookingList from './BookingList';

export function CustomerBookingList(props) {
    return (
        <div>
            <BookingList />
        </div>
    );
}

export default CustomerBookingList;
