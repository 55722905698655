import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import jerry from '../../assets/img/jerry.png';
const Retry = props => (
    <div
        className="text-center"
        style={{ minHeight: '470px', marginTop: '12%' }}
    >
        {/* <h1 className="error-code mb-2 animated zoomInDown">404</h1> */}
        <h5 className="bounceIn animated">{props.errorMessage}</h5>
        <LazyLoadImage
            className="bounceIn animated"
            style={{ width: '200px', height: '200px' }}
            src={jerry}
        />
    </div>
);

export default Retry;
