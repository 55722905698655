import React, { Component } from 'react';
import { compose } from 'redux';

import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import BookingList from '../../components/Providers/BookingList.jsx';

import BookingDetail from './BookingDetail.jsx';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};

class Booking extends Component {
    render() {
        const { match } = this.props;
        return (
            <div>
                <Switch>
                    <Route
                        path={`${match.url}/detail/:bookingId`}
                        render={(props) => (
                            <BookingDetail
                                {...sendProps(this.props)}
                                {...props}
                            />
                        )}
                    />
                    <Route
                        path={`${match.url}/`}
                        exact={true}
                        render={(props) => (
                            <BookingList
                                {...sendProps(this.props)}
                                {...props}
                            />
                        )}
                    />
                </Switch>
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles));

export default composedComponent(Booking);
