import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import Typography from '@material-ui/core/Typography';
import { Toolbar, Card } from '@material-ui/core';

import { ROLE_PROVIDER } from '../../utils/config/constants.js';

import withUser from '../../components/HOC/HOCwithUser.jsx';
import HOCLoader from '../../components/HOC/HOCLoader.jsx';

import ManageGener from './ManageGener';

import '../../assets/css/style.css';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 2 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export class ProviderPortfolio extends Component {
    state = {
        value: 1,
    };

    providerTypesTab = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { userData, isLoading } = this.props;
        return (
            <HOCLoader loading={isLoading}>
                {userData.role === ROLE_PROVIDER &&
                    userData.providerTypes &&
                    userData.providerTypes.length > 0 && (
                        <div>
                            <Card>
                                <Toolbar>
                                    <h3 className={isMobile && 'font-18'}>
                                        My Portfolio
                                    </h3>
                                </Toolbar>
                            </Card>
                            <Card
                                className={
                                    isMobile ? 'mt-2 pb-20' : 'mt-2 pb-50'
                                }
                            >
                                <ManageGener />
                            </Card>
                        </div>
                    )}
            </HOCLoader>
        );
    }
}

export default withUser(ProviderPortfolio);
