import {
    BOOKING_DATA_RECEIVED,
    LOGOUT,
    BOOKING_LOADER,
    CARD_LOADER,
    CARD_LIST,
} from '../actions/types';

const INITIAL_STATE = {
    bookingData: {
        bookingList: [],
    },
    cardList: {
        sources: { data: [] },
    },
    finalData: {
        bookingList: [],
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case BOOKING_DATA_RECEIVED:
            return { ...state, bookingData: action.payload };
        case BOOKING_LOADER:
            return { ...state, bookingLoader: action.payload };
        case CARD_LIST:
            return { ...state, cardList: action.payload };
        case CARD_LOADER:
            return { ...state, cardLoader: action.payload };
        case LOGOUT:
            return INITIAL_STATE;
        default:
            return state;
    }
}
