import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Geosuggest from 'react-geosuggest';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Radio, Checkbox } from '@material-ui/core';

import * as actions from '../../actions';
import { validateCustomerAddressForm } from '../../utils/validators/validateForm';
import { GOOGLE_MAP_KEY, ROLE_CUSTOMER } from '../../utils/config/constants';

import withUser from '../HOC/HOCwithUser.jsx';
import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

const style = {
    width: '100%',
    height: '100%'
};
function CustomerAddressCreate(props) {

    const [address_line1, setAddress_line1] = useState('')
    const [address_line1Error, setAddress_line1Error] = useState('')
    const [city, setCity] = useState('')
    const [cityError, setCityError] = useState('')
    const [state, setState] = useState('')
    const [stateError, setStateError] = useState('')
    const [country, setCountry] = useState('')
    const [countryError, setCountryError] = useState('')
    const [pincode, setPincode] = useState('')
    const [phone, setPhone] = useState(props?.userData?.phone ? props?.userData?.phone : '')
    const [phoneError, setPhoneError] = useState('')
    const [name, setName] = useState(props?.userData?.name ? props?.userData?.name : '')
    const [nameError, setNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [email, setEmail] = useState(props?.userData?.email ? props?.userData?.email : '')
    const [loader, setLoader] = useState(false)
    const [latitude, setLatitude] = useState('25.0923603')
    const [longitude, setLongitude] = useState('55.1658744')
    const [type_id, setType_id] = useState(1)
    const [centerLat, setCenterLat] = useState(25.0923603)
    const [centerLng, setCenterLng] = useState(55.1658744)
    const [is_default, setIs_default] = useState(false)

    useEffect(() => {
        getPlace(centerLat, centerLng);
        if (navigator?.geolocation) {
            navigator?.geolocation.getCurrentPosition(position => {
                setLatitude(position?.coords?.latitude)
                setLongitude(position?.coords?.longitude)
                getPlace(
                    position?.coords?.latitude,
                    position?.coords?.longitude
                )
            });
        } else {
            error => console.log(error);
        }
    }, []);


    const submit = params => {
        const { addAddressAction } = props;
        if (
            validateCustomerAddressForm(
                {
                    address_line1,
                    city,
                    state,
                    country,
                    pincode,
                    name,
                    email,
                    phone
                },
                handleError
            )
        ) {
            setLoader(true)
            addAddressAction(
                {
                    address_line1,
                    city,
                    state,
                    country,
                    zipcode: pincode,
                    name,
                    email,
                    phone,
                    latitude: latitude
                        ? latitude
                        : centerLat,
                    longitude: longitude
                        ? longitude
                        : centerLng,
                    type_id,
                    is_default: is_default ? 1 : 0
                },
                onDone
            );
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Oops!'
            );
        }
    };

    const handleError = params => {
        if (params?.emailError) {
            setEmailError(params?.emailError)
        }
        else if (params?.address_line1Error) {
            setAddress_line1Error(params?.address_line1Error)
        }
        else if (params?.cityError) {
            setCityError(params?.cityError)
        }
        else if (params?.stateError) {
            setStateError(params?.stateError)
        }
        else if (params?.countryError) {
            setCountryError(params?.countryError)
        }
        else if (params?.phoneError) {
            setPhoneError(params?.phoneError)
        }
        else if (params?.nameError) {
            setNameError(params?.nameError)
        }
    };

    const onDone = (success, data) => {
        const { history } = props;
        setLoader(false)
        if (success) {
            history.push(`/user/address`);
            NotificationManager.success(data?.message, 'Success');
        }
    };

    const handleRadioChange = event => {
        setType_id(event?.target?.value)
    };

    const renderButton = () => {
        if (loader) {
            return <CircularProgress />;
        }
        return (
            <WSButton
                variant="raised"
                className="jr-btn"
                onClick={submit}
            >
                <FormattedMessage id="add" defaultMessage="Add" />
            </WSButton>
        );
    };

    const onMarkerDragEnd = (coord, index) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        setLatitude(lat)
        setLongitude(lng),
            getPlace(lat, lng);
    };
    const getPlace = async (lat, lng) => {
        const { settings } = props;
        let latLng = parseFloat(lat) + ',' + parseFloat(lng);

        const { data } = await axios({
            url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=${GOOGLE_MAP_KEY
                }`
        });

        if (data?.status === 'OK') {
            if (data?.results[0]?.formatted_address) {
                setAddress_line1(data?.results[0]?.formatted_address)
            }
            data?.results[0]?.address_components.map(address => {
                if (address?.types.includes('country')) {
                    setCountry(address?.long_name)
                } else if (address?.types.includes('postal_code')) {
                    // pincode = address.long_name;
                    setPincode(address?.long_name)
                } else if (
                    address?.types.includes('administrative_area_level_1')
                ) {
                    setState(address?.long_name)
                } else if (
                    address?.types.includes('administrative_area_level_2') ||
                    address?.types.includes('locality')
                ) {
                    setCity(address?.long_name)
                }
            });
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="googleApiNotWorking"
                    defaultMessage="Google Api not working, Please add address manually"
                />,
                'Error'
            );
        }
    };
    const handleChange = name => event => {
        setIs_default(event.target.checked)
    };
    const handleSelectPlace = event => {
        if (event) {
            setLatitude(event?.location?.lat)
            setLongitude(event?.location?.lng)
            setCenterLat(event?.location?.lat)
            setCenterLng(event?.location?.lng)
        }
        getPlace(event?.location?.lat, event?.location?.lng);
    }


    const {
        userData,
        intl,
        google
    } = props;
    const { formatMessage } = intl;
    return (
        <Card className="col-md-12">
            <div>
                <h4>
                    <b>
                        <FormattedMessage
                            id="markerNote"
                            defaultMessage="Note: Drag marker on map to fetch location"
                        />
                    </b>
                </h4>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ height: 300 }}>
                        <Map
                            centerAroundCurrentLocation={true}
                            style={style}
                            google={google}
                            zoom={7}
                            center={{
                                lat: latitude,
                                lng: longitude
                            }}
                            streetViewControl={false}
                        >
                            <Marker
                                position={{
                                    lat: latitude,
                                    lng: longitude
                                }}
                                draggable={true}
                                onDragend={(t, map, coord) =>
                                    onMarkerDragEnd(coord)
                                }
                            />
                        </Map>
                    </div>
                </div>
            </div>
            <div className={isMobile && 'width-300 overflow-hidden search-location'}>
                <Geosuggest
                    suggestItemClassName="autosuggest-places"
                    onSuggestSelect={(event) => handleSelectPlace(event)}
                    placeholder="Search Address or Drag Marker in Map"
                />
            </div>
            <form
                className="row"
                noValidate
                autoComplete="off"
                id="address-create"
            >
                <div className="col-md-4 col-xs-12">
                    <WSTextField
                        error={nameError ? true : false}
                        helperText={nameError}
                        name="name"
                        label={formatMessage({
                            id: 'name'
                        })}
                        id="name"
                        placeholder={formatMessage({
                            id: 'name'
                        })}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={event => {
                            setName(event?.target?.value)
                            setNameError('')
                        }}
                        value={name}
                        defaultValue={name}
                        disabled={false}
                        className={isMobile && "mt-0"}
                    />
                </div>
                <div className="col-md-4 col-xs-12">
                    <WSTextField
                        error={emailError ? true : false}
                        helperText={emailError}
                        type="email"
                        name="email"
                        label={formatMessage({
                            id: 'email'
                        })}
                        id="email"
                        placeholder={formatMessage({
                            id: 'email'
                        })}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={event => {
                            setEmail(event?.target?.value)
                            setEmailError('')
                        }}
                        defaultValue={email}
                        value={email}
                        disabled={false}
                        className={isMobile && "mt-0"}
                    />
                </div>
                <div className="col-md-4 col-xs-12">
                    <WSTextField
                        error={phoneError ? true : false}
                        helperText={phoneError}
                        name="phone"
                        label={formatMessage({
                            id: 'phone'
                        })}
                        id="phone"
                        placeholder={formatMessage({
                            id: 'phone'
                        })}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={event => {
                            setPhone(event?.target?.value)
                            setPhoneError('')
                        }}
                        value={phone}
                        defaultValue={phone}
                        disabled={false}
                        className={isMobile && "mt-0"}
                    />
                </div>
                <div className="col-md-4 col-xs-12">
                    <WSTextField
                        error={address_line1Error ? true : false}
                        helperText={address_line1Error}
                        name="address_line1"
                        label={formatMessage({
                            id: 'address'
                        })}
                        id="address_line1"
                        placeholder={formatMessage({
                            id: 'address'
                        })}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={event => {
                            setAddress_line1(event?.target?.value)
                            setAddress_line1Error('')
                        }}
                        value={address_line1}
                        defaultValue={address_line1}
                        disabled={loader ? true : false}
                        className={isMobile && "mt-0"}
                    />
                </div>
                <div className="col-md-4 col-xs-12">
                    <WSTextField
                        error={cityError ? true : false}
                        helperText={cityError}
                        name="city"
                        label={formatMessage({
                            id: 'city'
                        })}
                        id="city"
                        placeholder={formatMessage({
                            id: 'city'
                        })}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={event => {
                            setCity(event?.target?.value)
                            setCityError('')
                        }}
                        value={city}
                        defaultValue={city}
                        disabled={loader ? true : false}
                        className={isMobile && "mt-0"}
                    />
                </div>
                <div className="col-md-4 col-xs-12">
                    <WSTextField
                        error={stateError ? true : false}
                        helperText={stateError}
                        name="state"
                        label={formatMessage({
                            id: 'state'
                        })}
                        id="state"
                        placeholder={formatMessage({
                            id: 'state'
                        })}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={event => {
                            setState(event?.target?.value)
                            setStateError('')
                        }}
                        value={state}
                        defaultValue={state}
                        disabled={loader ? true : false}
                        className={isMobile && "mt-0"}
                    />
                </div>
                <div className="col-md-4 col-xs-12">
                    <WSTextField
                        error={countryError ? true : false}
                        helperText={countryError}
                        name="country"
                        label={formatMessage({
                            id: 'country'
                        })}
                        id="country"
                        placeholder={formatMessage({
                            id: 'country'
                        })}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={event => {
                            setCountry(event?.target?.value)
                            setCountryError('')
                        }}
                        value={country}
                        defaultValue={country}
                        disabled={loader ? true : false}
                        className={isMobile && "mt-0"}
                    />
                </div>
                {userData?.role === ROLE_CUSTOMER && (
                    <div className="col-md-4 row" style={{ marginTop: !isMobile && 20 }}>
                        <div className="col-md-4">
                            <div className="row">
                                <Radio
                                    checked={type_id == 1}
                                    color="primary"
                                    onChange={event =>
                                        handleRadioChange(event)
                                    }
                                    value={1}
                                />
                                <p className={isMobile ? "mt-15" : "mt-10"}>
                                    <FormattedMessage
                                        id="home"
                                        defaultMessage="Home"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <Radio
                                    checked={type_id == 2}
                                    color="primary"
                                    onChange={event =>
                                        handleRadioChange(event)
                                    }
                                    value={2}
                                />
                                <br></br>
                                <p className={isMobile ? "mt-15" : "mt-10"}>
                                    <FormattedMessage
                                        id="work"
                                        defaultMessage="Work"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <Radio
                                    checked={type_id == 3}
                                    color="primary"
                                    onChange={event =>
                                        handleRadioChange(event)
                                    }
                                    value={3}
                                />
                                <p className={isMobile ? "mt-15" : "mt-10"}>
                                    <FormattedMessage
                                        id="other"
                                        defaultMessage="Other"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="col-md-4" style={{ marginTop: !isMobile && 20 }}>
                    <div className="row align-items-center">
                        <Checkbox
                            checked={is_default}
                            color="primary"
                            onChange={handleChange('is_default')}
                            value="is_default"
                        />
                        <p className="mt-1">
                            <FormattedMessage
                                id="setAsOffice"
                                defaultMessage="Set as Default"
                            />
                        </p>
                    </div>
                </div>
                <div className="col-md-12 col-xs-12">
                    <div className={!isMobile && "mt-3"} />
                    {renderButton()}
                </div>
            </form>
        </Card>
    );
}

const composedComponent = compose(
    GoogleApiWrapper(props => ({
        apiKey: GOOGLE_MAP_KEY
    })),

    connect(
        null,
        actions
    )
);
export default composedComponent(withUser(injectIntl(CustomerAddressCreate)));
