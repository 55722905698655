import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NotificationManager } from 'react-notifications';
import { Redirect, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CircularProgress, Fade } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';

import * as actions from '../../actions';

import { validateSignInData } from '../../utils/validators/validateForm';
import { ROLE_CUSTOMER, ROLE_PROVIDER } from '../../utils/config/constants';
import { cookies } from '../../utils/getToken';

import SocialSignin from '../../components/Social/SocialSignin';
import WSButton from '../../components/Button/WSButton';
import WSTextField from '../../components/TextField/WSTextField';

import '../../assets/css/style.css';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderButton: false,
            email: '',
            password: '',
            showCustomerVerification: false,
            showProviderVerification: false,
            closeVerification: false,
            playerId: '',
            showForgotPopup: false,
            samePage: false,
            role: '',
            customerRedirect: false,
            providerRedirect: false,
            showPassword: false,
        };
        this.props = {
            renderSamePage: props.renderSamePage ? props.renderSamePage : false,
            renderUrl: props.renderUrl,
        };
    }

    handleClose = () => {
        const { closeLoginPopup } = this.props;
        closeLoginPopup();
    };

    renderLoginButton = () => {
        const { loaderButton } = this.state;
        const { theme } = this.props;
        if (loaderButton) {
            return <CircularProgress />;
        } else {
            return (
                <WSButton
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        color: '#ffffff',
                    }}
                    onClick={() => this.loginActionCall()}
                >
                    <FormattedMessage id="login" defaultMessage="Login" />
                </WSButton>
            );
        }
    };

    loginActionCall = async () => {
        const { email, password } = this.state;
        const { loginAction } = this.props;
        if (
            validateSignInData(
                { email: email, password: password },
                this.handleError
            )
        ) {
            this.setState({ loaderButton: true });
            loginAction(
                {
                    email: email,
                    password: password,
                    playerId: window.onesignalId,
                },
                this.onDone
            );
        } else {
            NotificationManager.error('Validation Error', 'Oops!');
        }
    };

    handleError = (params) => {
        this.setState(params);
    };

    onDone = (success, data) => {
        const { email } = this.state;
        const {
            renderCustomerVerification,
            renderProviderVerification,
            renderSamePage,
        } = this.props;
        this.setState({
            role: data.role,
            isFirstLogin: data.firstLogin,
        });
        if (success === 'NOV') {
            if (data.data.role === ROLE_CUSTOMER) {
                this.setState(
                    {
                        loaderButton: false,
                    },
                    () => {
                        renderCustomerVerification(email);
                        this.handleClose();
                    }
                );
            } else if (data.data.role === ROLE_PROVIDER) {
                this.setState(
                    {
                        loaderButton: false,
                        showProviderVerification: true,
                    },
                    () => {
                        renderProviderVerification(email);
                        this.handleClose();
                    }
                );
            }
        } else if (success === true) {
            if (!cookies.get('x-auth-token', { path: '/' })) {
                cookies.set('x-auth-token', data.token, { path: '/' });
            }
            if (!renderSamePage) {
                if (data.role === ROLE_CUSTOMER) {
                    this.setState({
                        loaderButton: false,
                        customerRedirect: true,
                    });
                } else {
                    this.setState({
                        loaderButton: false,
                        providerRedirect: true,
                    });
                }
            } else {
                this.setState({
                    loaderButton: false,
                    samePage: true,
                });
            }
        } else {
            this.setState({
                loaderButton: false,
                emailError: data.message,
            });
        }
    };
    handleClickShowPassword = () => {
        {
            this.state?.showPassword === false ?
                this.setState({ showPassword: true })
                :
                this.setState({ showPassword: false })
        }
    }
    render() {
        const {
            passwordError,
            emailError,
            isFirstLogin,
            customerRedirect,
            providerRedirect,
            samePage,
            email,
            loaderButton,
            password,
            showPassword,
        } = this.state;
        const {
            intl,
            history,
            renderUrl,
            isVisible,
            theme,
            renderForgotPassword,
        } = this.props;
        const { formatMessage } = intl;
        if (customerRedirect) {
            this.handleClose();
            return (
                <Redirect
                    to={{
                        pathname: '/provider/list/',
                    }}
                />
            );
        }
        if (providerRedirect) {
            this.handleClose();
            if (isFirstLogin) {
                history.push('/createprofile');
            } else {
                history.push('/user/dashboard');
            }
        }
        if (samePage) {
            this.handleClose();
            return (
                <Redirect
                    to={{
                        pathname: renderUrl,
                    }}
                />
            );
        }
        return (
            <div>
                <Dialog
                    fullWidth={true}
                    TransitionComponent={Fade}
                    style={{ width: '100%' }}
                    open={isVisible}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <form
                        className="dialog-form dialog-form-new"
                        autoComplete="off"
                        style={{ margin: 0 }}
                    >
                        <div
                            className="dialog-header"
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingLeft: 5,
                            }}
                        >
                            <h3
                                className={
                                    isMobile
                                        ? 'dialog-heading font-16'
                                        : 'dialog-heading'
                                }
                            >
                                <FormattedMessage
                                    id="login"
                                    defaultMessage="Login"
                                />
                            </h3>
                            <IconButton
                                color="inherit"
                                onClick={this.handleClose}
                                aria-label="Close"
                            >
                                <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                        </div>
                        <div className="dialog-body">
                            <DialogContent>
                                <WSTextField
                                    error={emailError ? true : false}
                                    helperText={emailError}
                                    className="login-form-textinput"
                                    id="email"
                                    name="email"
                                    placeholder={formatMessage({
                                        id: 'email',
                                    })}
                                    type="email"
                                    value={email}
                                    onChange={(event) => {
                                        this.setState({
                                            email: event.target.value,
                                            emailError: '',
                                        });
                                    }}
                                    disabled={loaderButton}
                                />
                                <WSTextField
                                    error={passwordError ? true : false}
                                    helperText={passwordError}
                                    className="login-form-textinput"
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder={formatMessage({
                                        id: 'password',
                                    })}
                                    value={password}
                                    onChange={(event) => {
                                        this.setState({
                                            password: event.target.value,
                                            passwordError: '',
                                        });
                                    }}
                                    disabled={loaderButton}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton
                                                    className="passwordIcon"
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <p
                                    style={{
                                        textAlign: 'right',
                                        fontWeight: 600,
                                    }}
                                >
                                    &nbsp;
                                    <a
                                        style={{
                                            cursor: 'pointer',
                                            color: theme.palette.primary.main,
                                        }}
                                        onClick={() =>
                                            renderForgotPassword(email)
                                        }
                                    >
                                        <FormattedMessage
                                            id="forgotPassword"
                                            defaultMessage="Forgot Password ?"
                                        />
                                    </a>
                                </p>
                            </DialogContent>
                            <DialogActions className="logintxt">
                                <div className="col-md-5 log-btn">
                                    {this.renderLoginButton()}
                                </div>
                            </DialogActions>
                            {/* <div className="footer-main-menu">
                                 <a className="fontlink">
                                    <FormattedMessage
                                        id="emailUs"
                                        defaultMessage="Not having an account ?"
                                    />
                                </a> 
                                <br />
                                <div className="row">

                                    <a href="instagram:social@badiondemand.com">
                                        <div className="col-md-2">
                                            <i className="fab fa-instagram" />
                                        </div>
                                    </a>
                                    <a href="google:social@badiondemand.com">
                                        <div className="col-md-2">
                                            <i className="fab fa-google-plus-g" />
                                        </div>
                                    </a>

                                  <a href="skype:social@badiondemand.com">
                                        <div class="col-md-2">
                                            <i class="fab fa-skype"></i>
                                        </div></a>
                                </div>
                            </div> */}
                            <div
                                className="col-md-12"
                                style={{
                                    textAlign: 'justify',
                                    marginTop: '15px',
                                }}
                            >
                                <p>
                                    Not Having an Account?&nbsp;
                                    <Link
                                        to="/signup"
                                        style={{
                                            color: 'red',
                                            fontWeight: '500',
                                        }}
                                    >
                                        SignUp
                                    </Link>
                                </p>
                            </div>
                            <div
                                className="col-md-12"
                                style={{
                                    textAlign: 'justify',
                                    marginTop: '15px',
                                }}
                            >
                                <SocialSignin
                                    playerId={window.onesignalId}
                                    isLoginScreenCall={false}
                                    class="dark"
                                    style={{
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        border: `1px solid ${theme.palette.primary.main}`,
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        userData: auth.userData,
    };
};

const composedHOC = compose(withTheme(), connect(mapStateToProps, actions));

export default composedHOC(withRouter(injectIntl(LoginForm)));
