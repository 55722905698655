import React, { Component } from 'react';
import ReceivedMessageCell from './ReceivedMessageCell/index';
import SentMessageCell from './SentMessageCell/index';
import * as actions from '../../../actions';
import { connect } from 'react-redux';
import { isBrowser, isTablet, isMobile } from 'react-device-detect';
import { Button } from '@material-ui/core';
import WSButton from '../../Button/WSButton';
class Conversation extends Component {
    constructor(props) {
        super();
        this.props = {
            conversationData: props.conversationData,
            selectedUser: props.selectedUser
        };
    }
    componentDidMount() {
        const { refreshChatsAction, messageroom } = this.props;
        this.refreshing = setInterval(() => {
            refreshChatsAction({
                messageroom: messageroom,
                type: true
            });
        }, 5000);
    }

    componentWillUnmount() {
        const { clearInboxDetails } = this.props;
        clearInboxDetails();
        clearInterval(this.refreshing);
    }

    render() {
        const { selectedUser, conversationData, getMoreMessages, userData } = this.props;
        return (
            <div className="chat-main-content">
                {/* <WSButton 
                    onClick={getMoreMessages()}
                    >
                    Get Old Messages
                </WSButton> */}
                {conversationData.map((conversation, index) =>
                    conversation.user._id === userData.id ? (
                        <SentMessageCell
                            key={index}
                            conversation={conversation}
                            proImage={userData.proImage.small}
                        />
                    ) : (
                            <ReceivedMessageCell
                                key={index}
                                conversation={conversation}
                                user={selectedUser}
                            />
                        )
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ inbox }) => {
    return { inboxDetails: inbox.inboxDetails };
};
export default connect(
    mapStateToProps,
    actions
)(Conversation);
