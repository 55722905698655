import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AddressList from '../../components/Customer/AddressList.jsx';

import AddressUpdate from '../Address/Update.jsx';
import AddressCreate from '../Address/Create.jsx';

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};

export class Address extends Component {
    render() {
        const { match } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <Switch>
                            <Route
                                path={`${match.url}`}
                                exact={true}
                                render={(props) => (
                                    <AddressList
                                        {...props}
                                        {...sendProps(this.props)}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}/create`}
                                exact={true}
                                render={(props) => (
                                    <AddressCreate
                                        {...props}
                                        {...sendProps(this.props)}
                                    />
                                )}
                            />
                            <Route
                                path={`${match.url}/update/:addressId`}
                                exact={true}
                                render={(props) => (
                                    <AddressUpdate
                                        {...props}
                                        {...sendProps(this.props)}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default Address;
