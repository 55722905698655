import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
    Tooltip,
    IconButton,
    // Checkbox,
    TableRow,
    TableSortLabel,
    Toolbar,
    TablePagination,
    TableHead,
    TableCell,
    TableBody,
    Typography,
    Table,
    Paper,
} from '@material-ui/core';

import HOCSectionLoader from '../HOC/HOCSectionLoader.jsx';

import MenuButton from '../Menu/MenuButton';

import * as actions from '../../actions';

import { getStatus, getStatusClass } from '../../utils/config/constants.js';
import { formatPrice } from '../../utils/helpers/functions/validators';

import ChangeStatusAlert from '../Alert/ChangeStatusAlert.jsx';
import WSButton from '../Button/WSButton.jsx';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

class ServiceListHead extends Component {
    createSortHandler = (property) => (event) => {
        const { onRequestSort } = this.props;
        onRequestSort(event, property);
    };

    render() {
        const rows = [
            {
                id: 'heading',
                label: 'Name',
            },
            {
                id: 'price',
                label: 'Price',
            },
            {
                id: 'categoryName',
                label: 'Category',
            },
            {
                id: 'subCategoryName',
                label: 'Sub Category',
            },

            {
                id: 'status',
                label: 'Status',
            },
            {
                id: 'action',
                label: 'Action',
            },
        ];

        const {
            // onSelectAllClick,
            order,
            orderBy,
            // numSelected,
            // rowCount,
            // match
        } = this.props;
        return (
            <TableHead>
                <TableRow>
                    {/* <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={
                                numSelected > 0 && numSelected < rowCount
                            }
                            color="primary"
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell> */}
                    {rows.map(
                        (row) => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={
                                    row.disablePadding ? 'none' : 'default'
                                }
                                sortDirection={
                                    orderBy === row.id ? order : false
                                }
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={
                                        row.numeric
                                            ? 'bottom-end'
                                            : 'bottom-start'
                                    }
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.id !== 'heading' ? (
                                            <FormattedMessage
                                                id={row.id}
                                                defaultMessage={row.label}
                                            />
                                        ) : (
                                            'Service Name'
                                        )}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

ServiceListHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.secondary.main,
    },
    title: {
        flex: '0 0 auto',
    },
});

let ServiceListToolbar = (props) => {
    const { numSelected, classes, deleteAction, match } = props;
    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected}&nbsp;
                        <FormattedMessage
                            id="selected"
                            defaultMessage="selected"
                        />
                    </Typography>
                ) : (
                    <h6>
                        {/* <FormattedMessage
                            id="services"
                            defaultMessage="Services"
                        /> */}
                    </h6>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton
                            aria-label="Delete"
                            onClick={this.deleteAddress}
                        >
                            <DeleteIcon onClick={deleteAction} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Add">
                        <WSButton
                            variant="fab"
                            ariaLabel="Add"
                            style={{
                                padding: 0,
                                width: isMobile && 40,
                                height: isMobile && 40,
                            }}
                        >
                            <Link
                                style={{
                                    textDecoration: 'none',
                                    color: '#FFFFFF',
                                }}
                                to={`${match.url}/create`}
                            >
                                <AddIcon />
                            </Link>
                        </WSButton>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

ServiceListToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

ServiceListToolbar = withStyles(toolbarStyles)(ServiceListToolbar);

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 600,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class ServiceList extends Component {
    constructor(props) {
        super();
        this.state = {
            order: 'desc',
            orderBy: 'created_at',
            selected: [],
            data: props.data,
            page: 0,
            rowsPerPage: 5,
            clickValue: '',
            item: '',
            itemId: '',
            showAlert: false,
            selectedKey: '',
            loader: false,
        };
    }

    actionCallbacks(action, res) {
        if (action === 'update') {
            this.setState({
                clickValue: 'update',
                item: res,
                menuState: false,
                itemId: res.key,
            });
        } else if (action === 'changestatus') {
            this.changeRecord(res.key);
        } else if (action === 'faq') {
            this.setState({
                clickValue: 'faq',
                item: res,
                menuState: false,
                itemId: res.key,
            });
        } else if (action === 'package') {
            this.setState({
                clickValue: 'package',
                item: res,
                menuState: false,
                itemId: res.key,
            });
        } else if (action === 'gallery') {
            this.setState({
                clickValue: 'gallery',
                item: res,
                menuState: false,
                itemId: res.key,
            });
        } else if (action === 'url') {
            this.setState({
                clickValue: 'url',
                item: res,
                menuState: false,
                itemId: res.key,
            });
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            this.setState((state) => ({
                selected: state.data.map((n) => n.key),
            }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    changeRecord = (key) => {
        this.setState({ showAlert: true, selectedKey: key });
    };

    changeItemStatus = () => {
        const { selectedKey } = this.state;
        const { serviceChangeStatusAction } = this.props;
        this.setState({ loader: true });
        serviceChangeStatusAction(
            {
                proServiceId: selectedKey,
            },
            (success, data) => this.onChangeDone(success, data)
        );
    };

    onChangeDone = (success, data) => {
        const { getData } = this.props;
        this.setState({ loader: false });
        if (success) {
            this.setState({ showAlert: false });
            getData();
        }
    };

    render() {
        const { classes, match, history } = this.props;
        const {
            data,
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            clickValue,
            item,
            itemId,
            loader,
            showAlert,
        } = this.state;
        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, data.length - page * rowsPerPage);
        if (clickValue) {
            if (clickValue === 'update') {
                history.push({
                    pathname: `${match.url}/update/${itemId}`,
                    service: item,
                });
            } else if (clickValue === 'faq') {
                history.push({
                    pathname: `${match.url}/faq`,
                    service: item,
                });
            } else if (clickValue === 'package') {
                history.push({
                    pathname: `${match.url}/package`,
                    service: item,
                });
            } else if (clickValue === 'gallery') {
                history.push({
                    pathname: `${match.url}/gallery`,
                    service: item,
                });
            } else if (clickValue === 'url') {
                history.push({
                    pathname: `${match.url}/url`,
                    service: item,
                });
            }
        }
        return (
            <div>
                <HOCSectionLoader loading={loader}>
                    <div className={isMobile ? 'row' : 'row mb-4'}>
                        <div className="col-md-12">
                            <Card className={classes.card}>
                                <Toolbar className="flex-justify-spacebetween">
                                    <h3 className={isMobile && 'font-18'}>
                                        <FormattedMessage
                                            id="myServices"
                                            defaultMessage="My Services"
                                        />
                                    </h3>
                                    <ServiceListToolbar
                                        numSelected={selected.length}
                                        deleteAction={this.deleteRecord}
                                        match={match}
                                    />
                                </Toolbar>
                            </Card>
                        </div>
                    </div>
                    <Paper className={classes.root + isMobile && 'mt-10'}>
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table + ' dashboard-tables'}
                                aria-labelledby="tableTitle"
                            >
                                <ServiceListHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                />
                                <TableBody>
                                    {stableSort(
                                        data,
                                        getSorting(order, orderBy)
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((n) => {
                                            const isSelected = this.isSelected(
                                                n.key
                                            );
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.key}
                                                    selected={isSelected}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {n.name}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {formatPrice(
                                                            n.price,
                                                            '0,0'
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {n.subcategory &&
                                                            n.subcategory
                                                                .categoryName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {n.subCategoryName}
                                                    </TableCell>
                                                    <TableCell className="status-cell">
                                                        <div
                                                            className={getStatusClass(
                                                                n.status
                                                            )}
                                                        >
                                                            {getStatus(
                                                                n.status
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MenuButton
                                                            rowData={n}
                                                            items={
                                                                n.status === 1
                                                                    ? [
                                                                          {
                                                                              action:
                                                                                  'update',
                                                                              label:
                                                                                  'Update',
                                                                          },
                                                                          {
                                                                              action:
                                                                                  'package',
                                                                              label:
                                                                                  'Manage Packages',
                                                                          },
                                                                          {
                                                                              action:
                                                                                  'faq',
                                                                              label:
                                                                                  'Manage FAQs',
                                                                          },
                                                                          //   {
                                                                          //       action:
                                                                          //           'gallery',
                                                                          //       label:
                                                                          //           'Manage Gallery'
                                                                          //   },
                                                                          //   {
                                                                          //       action:
                                                                          //           'url',
                                                                          //       label:
                                                                          //           'Manage Media Url'
                                                                          //   },
                                                                          {
                                                                              action:
                                                                                  'changestatus',
                                                                              label:
                                                                                  'Mark Inactive',
                                                                          },
                                                                      ]
                                                                    : [
                                                                          {
                                                                              action:
                                                                                  'update',
                                                                              label:
                                                                                  'Update',
                                                                          },
                                                                          {
                                                                              action:
                                                                                  'package',
                                                                              label:
                                                                                  'Manage Packages',
                                                                          },
                                                                          {
                                                                              action:
                                                                                  'faq',
                                                                              label:
                                                                                  'Manage FAQs',
                                                                          },
                                                                          //   {
                                                                          //       action:
                                                                          //           'gallery',
                                                                          //       label:
                                                                          //           'Manage Gallery'
                                                                          //   },
                                                                          //   {
                                                                          //       action:
                                                                          //           'url',
                                                                          //       label:
                                                                          //           'Manage Media Url'
                                                                          //   },
                                                                          {
                                                                              action:
                                                                                  'changestatus',
                                                                              label:
                                                                                  'Mark Active',
                                                                          },
                                                                      ]
                                                            }
                                                            menuId={'userMenu'}
                                                            menuCallback={(
                                                                action,
                                                                rowData
                                                            ) =>
                                                                this.actionCallbacks(
                                                                    action,
                                                                    rowData
                                                                )
                                                            }
                                                            iconType={
                                                                MoreVertIcon
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 49 * emptyRows }}
                                        >
                                            <TableCell colSpan={8} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            className="dashboard-table-pagination"
                            classes={{
                                selectIcon: 'tablePaginationSelectIcon',
                            }}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </HOCSectionLoader>
                <ChangeStatusAlert
                    open={showAlert}
                    title="service"
                    close={() => this.setState({ showAlert: false })}
                    handleYes={this.changeItemStatus}
                    handleNo={() => this.setState({ showAlert: false })}
                />
            </div>
        );
    }
}

ServiceList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const composedComponent = compose(withStyles(styles), connect(null, actions));

export default composedComponent(injectIntl(ServiceList));
