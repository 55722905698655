import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { withTheme, withStyles } from '@material-ui/core/styles';

import {
    Notifications,
    Home,
    Dashboard,
    Favorite,
    LocalOffer,
    ListAlt,
    AddLocation,
    Email,
    SettingsPower,
    AccountBox,
    Lock,
} from '@material-ui/icons';

import { ROLE_CUSTOMER, ROLE_PROVIDER } from '../../utils/config/constants';

const styles = (theme) => ({
    listItem: {
        color: theme.palette.secondary.text,
    },
    icon: {
        color: theme.palette.secondary.text,
    },
});

const navbarItems = [
    {
        key: 'home',
        label: 'Home',
        renderContent: (classes) => <Home className={classes.icon} />,
        link: '/',
    },
    {
        key: 'inbox',
        label: 'Inbox',
        renderContent: (classes) => <Email className={classes.icon} />,
        link: '/user/chat',
    },
    {
        key: 'notifications',
        label: 'Notifications',
        renderContent: (classes) => <Notifications className={classes.icon} />,
        link: '/notification/list',
    },
    {
        key: 'addresses',
        label: 'Addresses',
        renderContent: (classes) => <AddLocation className={classes.icon} />,
        link: '/user/address',
    },
    {
        key: 'favourite',
        label: 'Favourites',
        renderContent: (classes) => <Favorite className={classes.icon} />,
        link: '/user/favourite',
    },
    {
        key: 'promo',
        label: 'Promo Codes',
        renderContent: (classes) => <LocalOffer className={classes.icon} />,
        link: '/promocode/list',
    },
    {
        key: 'manageBookings',
        label: 'Manage Bookings',
        renderContent: (classes) => <ListAlt className={classes.icon} />,
        link: '/user/booking',
    },
    {
        key: 'My Profile',
        label: 'My Profile',
        renderContent: (classes) => <AccountBox className={classes.icon} />,
        link: '/user/profile',
    },
    {
        key: 'Change Password',
        label: 'Change Password',
        renderContent: (classes) => <Lock className={classes.icon} />,
        link: '/user/changepassword',
    },
    {
        key: 'Logout',
        label: 'Logout',
        renderContent: (classes) => <SettingsPower className={classes.icon} />,
        link: '/',
    },
];

const navbarProviderItems = [
    {
        key: 'home',
        label: 'Home',
        renderContent: (classes) => <Home className={classes.icon} />,
        link: '/',
    },
    {
        key: 'dashboard',
        label: 'Dashboard',
        renderContent: (classes) => <Dashboard className={classes.icon} />,
        link: '/user/dashboard',
    },
    {
        key: 'inbox',
        label: 'Inbox',
        renderContent: (classes) => <Email className={classes.icon} />,
        link: '/user/chat',
    },

    {
        key: 'notification',
        label: 'Notifications',
        renderContent: (classes) => <Notifications className={classes.icon} />,
        link: '/notification/list',
    },
    {
        key: 'address',
        label: 'Addresses',
        renderContent: (classes) => <AddLocation className={classes.icon} />,
        link: '/user/address',
    },
    {
        key: 'managePortfolio',
        label: 'Manage Portfolio',
        renderContent: (classes) => <ListAlt className={classes.icon} />,
        link: '/provider/portfolio',
    },
    {
        key: 'manageBookings',
        label: 'Manage Bookings',
        renderContent: (classes) => <ListAlt className={classes.icon} />,
        link: '/provider/booking',
    },
    {
        key: 'manageService',
        label: 'Manage Services',
        renderContent: (classes) => <ListAlt className={classes.icon} />,
        link: '/provider/service',
    },
    {
        key: 'slots',
        label: 'Manage Calender',
        renderContent: (classes) => <ListAlt className={classes.icon} />,
        link: '/user/slots/list',
    },
    {
        key: 'My Profile',
        label: 'My Profile',
        renderContent: (classes) => <AccountBox className={classes.icon} />,
        link: '/user/profile',
    },
    {
        key: 'Change Password',
        label: 'Change Password',
        renderContent: (classes) => <Lock className={classes.icon} />,
        link: '/user/changepassword',
    },
    {
        key: 'Logout',
        label: 'Logout',
        renderContent: (classes) => <SettingsPower className={classes.icon} />,
        link: '/',
    },
];

export class SideNav extends Component {
    renderCustomerNavbar = (formatMessage) => {
        const { classes } = this.props;
        {
            return navbarItems.map((n) => {
                return (
                    <div key={n.key}>
                        <Link
                            to={n.link}
                            onClick={() => n.key === 'Logout' && this.logout()}
                        >
                            <ListItem button key={n.key}>
                                <ListItemIcon>
                                    {n.renderContent(classes)}
                                </ListItemIcon>
                                <ListItemText
                                    className={
                                        classes.listItem + ' side-nav-link'
                                    }
                                    primary={formatMessage({
                                        id: n.key,
                                    })}
                                    primaryTypographyProps={{
                                        className: classes.listItem,
                                    }}
                                />
                            </ListItem>
                        </Link>
                        <Divider />
                    </div>
                );
            });
        }
    };

    renderProviderNavbar = (formatMessage) => {
        const { classes } = this.props;
        {
            return navbarProviderItems.map((n) => {
                return (
                    <div key={n.key}>
                        <Link
                            to={n.link}
                            onClick={() => n.key === 'Logout' && this.logout()}
                        >
                            <ListItem button key={n.key}>
                                <ListItemIcon>
                                    {n.renderContent(classes)}
                                </ListItemIcon>
                                <ListItemText
                                    className={
                                        classes.listItem + ' side-nav-link'
                                    }
                                    primary={formatMessage({
                                        id: n.key,
                                    })}
                                    primaryTypographyProps={{
                                        className: classes.listItem,
                                    }}
                                />
                            </ListItem>
                        </Link>
                        <Divider />
                    </div>
                );
            });
        }
    };

    logout = () => {
        const { logoutAction } = this.props;
        logoutAction(this.onLogoutDone);
    };

    onLogoutDone = (success, data) => {
        if (success) {
            this.setState({
                redirectMain: true,
            });
            window.location.reload();
        }
    };

    render() {
        const { intl, userData } = this.props;
        const { formatMessage } = intl;
        return (
            <List>
                {userData.role === ROLE_CUSTOMER &&
                    this.renderCustomerNavbar(formatMessage)}

                {userData.role === ROLE_PROVIDER &&
                    this.renderProviderNavbar(formatMessage)}
            </List>
        );
    }
}

const composedComponent = compose(withTheme(), withStyles(styles));

export default composedComponent(injectIntl(SideNav));
