import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import BookingList from '../../components/Customer/BookingList.jsx';

import BookingDetail from './BookingDetail.jsx';

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};

const sendPropsWithoutRouter = (props) => {
    const { location, match, ...rest } = props;
    return rest;
};

export class Booking extends Component {
    render() {
        const { match } = this.props;
        return (
            <div>
                <Route
                    exact={true}
                    path={`${match.url}/`}
                    render={(props) => (
                        <BookingList {...sendProps(this.props)} />
                    )}
                />
                <Route
                    path={`${match.url}/detail/:bookingId`}
                    render={(props) => (
                        <BookingDetail
                            {...sendPropsWithoutRouter(this.props)}
                            {...props}
                        />
                    )}
                />
            </div>
        );
    }
}

export default Booking;
