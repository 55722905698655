import {
    USER_DATA_RECEIVED,
    PROVIDER_PROFILE_UPDATE,
    GET_NATIONALITY_DATA,
    GET_LANGUAGES_DATA,
    LOGOUT,
    REMOVE_HEADER_FOOTER,
    AUTH_LOADER,
    LOGIN_TRIGGERED,
    WISHLIST_DATA_RECEIVED,
    WISHLIST_LOADER,
    PROVIDER_TYPE_LOADER,
    PROVIDER_TYPE_DATA_RECEIVED,
} from '../actions/types';

const INITIAL_STATE = {
    userData: {
        name: '',
        addresses: [],
        authLoader: false,
    },
    removeHeader: false,
    isLoginVisible: false,
    wishlistData: [],
    wishlistLoader: true,
    providerTypeData: [],
    providerTypeLoader: false,
    providerData: {},
    providerDataLoader: false,
    nationalityData: [],
    languagesData: [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_DATA_RECEIVED:
            return {
                ...state,
                userData: action.payload,
                providerprofileCompletion: action.providerprofileCompletion,
            };
        case PROVIDER_PROFILE_UPDATE:
            return {
                ...state,
                providerData: action.payload,
                providerDataLoader: action.payload ? false : true,
            };
        case GET_NATIONALITY_DATA:
            return { ...state, nationalityData: action.payload };
        case GET_LANGUAGES_DATA:
            return { ...state, languagesData: action.payload };
        case AUTH_LOADER:
            return { ...state, authLoader: action.payload };
        case PROVIDER_TYPE_DATA_RECEIVED:
            return { ...state, providerTypeData: action.payload };
        case PROVIDER_TYPE_LOADER:
            return { ...state, providerTypeLoader: action.payload };
        case REMOVE_HEADER_FOOTER:
            return { ...state, removeHeader: action.payload };
        case LOGIN_TRIGGERED:
            return {
                ...state,
                isLoginVisible: action.payload.visibile,
                path: action.payload.path,
            };
        case LOGOUT:
            return {
                ...state,
                userData: INITIAL_STATE.userData,
                providerData: INITIAL_STATE.providerData,
            };
        case WISHLIST_DATA_RECEIVED:
            return { ...state, wishlistData: action.payload };
        case WISHLIST_LOADER:
            return { ...state, wishlistLoader: action.payload };
        default:
            return state;
    }
}
