import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NotificationManager } from 'react-notifications';
import { Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import StarRatings from 'react-star-ratings';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, Fade } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import * as actions from '../../actions';

import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

function RatingPopup(props) {

    const [loaderButton, setLoaderButton] = useState(false)
    const [otp, setOtp] = useState('')
    const [otpError, setOtpError] = useState('')
    const [dashboardRedirect, setDashboardRedirect] = useState(false)
    const [playerId, setPlayerId] = useState('')
    const [r1, setR1] = useState(1)
    const [r2, setR2] = useState(1)
    const [r3, setR3] = useState(1)
    const [ar, setAr] = useState(1)
    const [review, setReview] = useState('')
    const [reviewError, setReviewError] = useState('')

    const handleClose = () => {
        const { close } = props;
        close();
    };

    const renderButton = () => {
        const { theme } = props;
        if (loaderButton) {
            return <CircularProgress />;
        } else {
            return (
                <WSButton
                    style={{
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#ffffff',
                    }}
                    onClick={() => addRatingActionCall()}
                >
                    Done
                </WSButton>
            );
        }
    };

    const addRatingActionCall = () => {
        const { rateBookingAction, bookingToken } = props;
        setLoaderButton(true)
        rateBookingAction(
            {
                r1: r1,
                r2: r2,
                r3: r3,
                review: review,
                bookingToken: bookingToken
            },
            onDone
        );
    };

    const onDone = (success, data) => {
        const { reviewDone } = props;
        if (success) {
            setLoaderButton(false)
            NotificationManager.success('Rating Added Successfully', 'Success');
            handleClose();
            reviewDone();
        } else {
            setLoaderButton(false)
            NotificationManager.error('Some error found', 'Error');
        }
    };

    const changeRating = (newRating, name) => {
        if (name === 'r1') {
            setR1(newRating)
        }
        else if (name === 'r2') {
            setR2(newRating)
        }
        else if (name === 'r3') {
            setR3(newRating)
        }
        () => {
            calculateAverageRating();
        }
    };

    const calculateAverageRating = () => {
        const avg = (r1 + r2 + r3) / 3;
        setAr(avg)
    };

    const { open, theme, arValue, starRatedColor, r1Value, r2Value, r3Value } = props;
    if (dashboardRedirect) {
        return (
            <Redirect
                to={{
                    pathname: `/user/dashboard`
                }}
            />
        );
    }
    return (
        <div>
            <Dialog
                fullWidth={true}
                TransitionComponent={Fade}
                className="text-center"
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <form className="dialog-form">
                    <div
                        className="dialog-header"
                        style={{
                            backgroundColor: theme?.palette?.primary?.main,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingLeft: 15
                        }}
                    >
                        <h3 className={isMobile ? "dialog-heading font-16" : "dialog-heading"}>Rate the service</h3>
                        <IconButton
                            color="inherit"
                            onClick={handleClose}
                            aria-label="Close"
                        >
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                    <div className="dialog-body">
                        <DialogContent>
                            <h4 className={isMobile && 'font-14'}>{arValue}</h4>
                            <StarRatings
                                rating={ar}
                                starDimension="40px"
                                starSpacing="15px"
                                starRatedColor={starRatedColor}
                            />
                            <br />
                            <br />
                            <div className="text-center">
                                <h4 className={isMobile && 'font-14'}>{r1Value}</h4>
                                <StarRatings
                                    rating={r1}
                                    starRatedColor={
                                        starRatedColor
                                    }
                                    changeRating={changeRating}
                                    numberOfStars={5}
                                    name="r1"
                                    starDimension="40px"
                                    starSpacing="15px"
                                    starHoverColor={
                                        starRatedColor
                                    }
                                />
                            </div>

                            <div className="text-center">
                                <h4 className={isMobile && 'font-14'}>{r2Value}</h4>
                                <StarRatings
                                    rating={r2}
                                    starRatedColor={
                                        starRatedColor
                                    }
                                    changeRating={changeRating}
                                    numberOfStars={5}
                                    name="r2"
                                    starDimension="40px"
                                    starSpacing="15px"
                                    starHoverColor={
                                        starRatedColor
                                    }
                                />
                            </div>
                            <div className="text-center">
                                <h4 className={isMobile && 'font-14'}>{r3Value}</h4>
                                <StarRatings
                                    rating={r3}
                                    starRatedColor={
                                        starRatedColor
                                    }
                                    changeRating={changeRating}
                                    numberOfStars={5}
                                    name="r3"
                                    starDimension="40px"
                                    starSpacing="15px"
                                    starHoverColor={
                                        starRatedColor
                                    }
                                />
                            </div>
                            <WSTextField
                                error={reviewError ? true : false}
                                helperText={reviewError}
                                label="Review"
                                id="review"
                                name="review"
                                value={review}
                                onChange={event => {
                                    setReview(event?.target?.value)
                                    setReviewError('')
                                }}
                            />
                        </DialogContent>

                        <DialogActions>
                            <div className="col-md-12">
                                {renderButton()}
                            </div>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
        </div>
    );
}

const composedHOC = compose(
    withTheme(),

    connect(
        null,
        actions
    )
);
export default composedHOC(RatingPopup);
