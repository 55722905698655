import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import {
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import * as actions from '../../actions';

import withUser from '../HOC/HOCwithUser.jsx';
import HOCSectionLoader from '../HOC/HOCSectionLoader';
import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';
import AddNewCard from '../Booking/AddNewCard.jsx';
import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

const styles = {
    appBar: {
        position: 'relative'
    },
    flex: {
        flex: 1
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function AddWalletMoneyForm(props) {

    const [showAddPopup, setShowAddPopup] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [amount, setAmount] = useState(300);
    const [isPayClick, setIsPayClick] = useState('');

    const walletData = useSelector(state => state?.wallet?.walletData);
    const walletLoader = useSelector(state => state?.wallet?.walletLoader);

    useEffect(() => {
        const { walletListAction } = props;
        walletListAction();
    }, []);

    const renderAddAmount = () => {
        setShowAddPopup(true)
    };

    const payViaCard = cardId => {
        const { walletAmountAddAction } = props;
        setIsPayClick(cardId)
        walletAmountAddAction(
            {
                cardId,
                amount: amount
            },
            onPaymentDone
        );
    };

    const handleClose = () => {
        const { backToList } = props;
        setShowAddPopup(false)
        backToList();
    };

    const onPaymentDone = (success, data) => {
        setIsPayClick('')
        if (success) {
            NotificationManager.success(data?.message, 'Success');
            handleClose();
        } else {
            NotificationManager.error(data?.message, 'Error');
        }
    };

    const { settings } = props;
    return (
        <div>
            <HOCSectionLoader loading={walletLoader}>
                <List>
                    <ListItem button className="dashboard-wallet-list">
                        <ListItemText
                            primary="Balance"
                            secondary={
                                settings?.constants?.CURRENCY_SYMBOL +
                                walletData?.balance
                            }
                        />
                        <WSButton
                            onClick={() => handleClose()}
                            style={{ marginRight: 10 }}
                        >
                            <FormattedMessage
                                id="walletList"
                                defaultMessage="Wallet List"
                            />
                        </WSButton>
                        <WSButton
                            variant="outlined"
                            onClick={() => renderAddAmount()}
                        >
                            <FormattedMessage
                                id="addAmount"
                                defaultMessage="Add Amount"
                            />
                        </WSButton>
                    </ListItem>
                    <Divider />
                </List>
                <div className="container pl-0 pr-0">
                    {!showAddPopup ? (
                        <div className="tableWrapper-table">
                            <Table className="dashboard-tables">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <FormattedMessage
                                                id="transactionAmount"
                                                defaultMessage="Transaction Amount"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage
                                                id="heading"
                                                defaultMessage="Heading"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage
                                                id="balance"
                                                defaultMessage="Balance"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {walletData?.transactions &&
                                        walletData?.transactions.map(
                                            transaction => {
                                                return (
                                                    <TableRow
                                                        key={
                                                            transaction?.key
                                                        }
                                                    >
                                                        <TableCell>
                                                            {settings
                                                                ?.constants
                                                                ?.CURRENCY_SYMBOL +
                                                                transaction?.amount}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                transaction?.heading
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {settings
                                                                ?.constants
                                                                ?.CURRENCY_SYMBOL +
                                                                transaction?.closing_balance}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                </TableBody>
                            </Table>
                        </div>
                    ) : (
                        <div>
                            <div className="col-md-12  pl-0 pr-0">
                                <WSTextField
                                    error={amountError ? true : false}
                                    helperText={amountError}
                                    label="Enter Amount"
                                    id="amount"
                                    name="amount"
                                    value={amount}
                                    onChange={event => {
                                        setAmount(event?.target?.value)
                                        setAmountError('')
                                    }}
                                />
                            </div>
                            <br />
                            <div className="col-md-12">
                                <AddNewCard
                                    getBack={true}
                                    redirectToList={handleClose}
                                    action='addMoney'
                                />
                            </div>
                        </div>
                    )}
                </div>
            </HOCSectionLoader>
        </div>
    );
}

AddWalletMoneyForm.propTypes = {
    classes: PropTypes?.object?.isRequired
};

const composedComponent = compose(
    withTheme(),
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default composedComponent(withUser(withCustomTheme(AddWalletMoneyForm)));
