import { combineReducers } from 'redux';

import auth from './auth_reducer';
import settings from './settings_reducer';
import bookings from './booking_reducer';
import favourites from './favourite_reducer';
import providers from './provider_reducer';
import categories from './category_reducer';
import promos from './promo_reducer';
import wallet from './wallet_reducer';
import inbox from './inbox_reducer';
import notification from './notification_reducer';

export default combineReducers({
    auth,
    settings,
    bookings,
    favourites,
    providers,
    categories,
    promos,
    wallet,
    notification,
    inbox,
});
