import {
    getThemeSettings,
    changeLanguage,
    getHeaderLinks,
    getCmsDetail,
    bannerList,
} from '../apiHelpers/settings/detail';
import { getLoggedInUser } from '../apiHelpers/user/detail';

import {
    THEME_DATA_RECEIVED,
    CHANGE_LANGUAGE,
    CMS_DATA_RECEIVED,
    MAINTAINENCE_MODE,
    HOME_BANNERS_RECEIVED,
    SETTINGS_LOADER,
} from './types';

export const getThemeSettingsAction = () => async (dispatch) => {
    const response = await getThemeSettings();
    if (response.status === 'OK') {
        dispatch({ type: THEME_DATA_RECEIVED, payload: response.data });
        return true;
    } else {
        return false;
    }
};

export const changeLanguageAction = (params) => async (dispatch) => {
    const response = await changeLanguage(params);
    if (response.status === 'OK') {
        dispatch({ type: CHANGE_LANGUAGE, payload: params.language });
        return true;
    } else {
        dispatch({ type: CHANGE_LANGUAGE, payload: params.language });
        return false;
    }
};

export const getHeaderLinksAction = () => async (dispatch) => {
    const response = await getHeaderLinks();
    if (response.status === 'OK') {
        dispatch({ type: CMS_DATA_RECEIVED, payload: response.data });
        return true;
    } else {
        return false;
    }
};

export const getCmsDetailAction = (params, done) => async (dispatch) => {
    const response = await getCmsDetail(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const checkMaintainence = (params) => async (dispatch) => {
    const response = await getLoggedInUser();
    if (response.status === 'MAINTAINENCE') {
        dispatch({ type: MAINTAINENCE_MODE, payload: true });
        return true;
    } else {
        return false;
    }
};

export const bannerListAction = (params) => async (dispatch) => {
    // dispatch({ type: SETTINGS_LOADER, payload: true });
    const response = await bannerList(params);
    if (response.status === 'OK') {
        dispatch({ type: HOME_BANNERS_RECEIVED, payload: response.data });
        dispatch({ type: SETTINGS_LOADER, payload: false });
        return true;
    } else {
        dispatch({ type: SETTINGS_LOADER, payload: false });
        return false;
    }
};
