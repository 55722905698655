import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import * as actions from '../../actions';

import HOCSectionLoader from '../../components/HOC/HOCSectionLoader.jsx';
import ServiceUpdate from '../../components/Providers/ServiceUpdate.js';

const styles = (theme) => ({
    card: {
        color: theme.palette.secondary.main,
    },
});

export class Update extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service: null,
            serviceId: props.match.params.serviceId,
            loader: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const { providerServiceDetailAction } = this.props;
        const { serviceId } = this.state;
        this.setState({ loader: true });
        providerServiceDetailAction({ proServiceId: serviceId }, this.onDone);
    };

    onDone = (success, data) => {
        this.setState({ loader: false });
        if (success) {
            this.setState({ service: data.data });
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="providerServiceFetchError"
                    defaultMessage="Provider Service Fetch Error"
                />,
                'Error'
            );
        }
    };

    render() {
        const { categoryData, subcategoryData, providerData } = this.props;
        const { service, loader } = this.state;
        return (
            <div>
                <HOCSectionLoader loading={loader}>
                    <div className={isMobile ? 'row mb-2' : 'row mb-4'}>
                        <div className="col-md-12">
                            <Card>
                                <Toolbar>
                                    <h3 className={isMobile && 'font-18'}>
                                        <FormattedMessage
                                            id="updateService"
                                            defaultMessage="Update Service"
                                        />
                                    </h3>
                                </Toolbar>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        {service && (
                            <div className="col-md-12">
                                <ServiceUpdate
                                    {...this.props}
                                    data={service}
                                    providerData={providerData}
                                    categoryData={categoryData}
                                    // subcategoryData={subcategoryData}
                                    allSubcategoryData={subcategoryData}
                                />
                            </div>
                        )}
                    </div>
                </HOCSectionLoader>
            </div>
        );
    }
}

const composedComponent = compose(withStyles(styles), connect(null, actions));

export default composedComponent(Update);
