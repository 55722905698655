import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Radio, FormControl } from '@material-ui/core';
import { compose } from 'redux';
import * as actions from '../../actions';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { withTheme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import SlotDateTimePanel from '../../components/Booking/SlotDateTimePanel';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import TimeSlotList from '../../components/Providers/TimeSlotList.jsx';
import { NotificationManager } from 'react-notifications';
import { FormattedMessage, injectIntl } from 'react-intl';
import withUser from '../../components/HOC/HOCwithUser.jsx';
import withCustomTheme from '../../components/HOC/HOCwithCustomTheme.jsx';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';
import { isMobile } from 'react-device-detect';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: 20
    },
    group: {
        margin: 20,
    },
});

function SlotList(props) {

    const [dateTimeValue, setDateTimeValue] = useState('')
    const [slots, setSlots] = useState([])
    const [sessions, setSessions] = useState(null)
    const [haveSlots, setHaveSlots] = useState(false)
    const [openDateTime, setOpenDateTime] = useState(false)
    const [dateSlots, setDateSlots] = useState([])
    const [mindate, setMindate] = useState(moment())
    const [loader, setLoader] = useState(true)
    const [value, setValue] = useState('date-range')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [isDateAvailable, setIsDateAvailable] = useState(true)
    const [isDateAvailableAll, setIsDateAvailableAll] = useState(false)
    const [disableUpdateButton, setDisableUpdateButton] = useState(false)


    const onChangeDate = event => {
        const dateTimeValue = moment(event).format('YYYY-MM-DD');
        setOpenDateTime(true)
        setDateTimeValue(dateTimeValue)
        getSlots(dateTimeValue);
    };

    const getSlots = chosenDate => {
        const { providerTimeSlotAction } = props;
        providerTimeSlotAction(
            {
                date: chosenDate,
                isDate: false
            },
            onDateTimeSelected
        );
    };

    const getSlotsByRange = (startDate, endDate) => {
        const { providerTimeSlotAction } = props;
        providerTimeSlotAction(
            {
                startDate: startDate,
                endDate: endDate,
                isDate: true,
            },
            onDateRangeSelected
        );
    };

    const onDateRangeSelected = (success, data) => {
        if (success) {
            setDateSlots([...data?.data])
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="noDatesAvailable"
                    defaultMessage="Sorry No Dates Available"
                />,
                'Error'
            );
        }
    };

    const onDateTimeSelected = (success, data) => {
        if (success) {
            setSlots([...data?.data?.slots])
            setSessions({ ...data?.data?.sessions })
            setIsDateAvailable(data?.data?.isDateAvailable)
            setHaveSlots(true)
            setOpenDateTime(false)
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="noSlotsAvailable"
                    defaultMessage="Sorry No Slots Available"
                />,
                'Error'
            );
        }
    };

    const setTimeSlot = slot => {
        const { providerSetTimeSlotAction } = props;
        providerSetTimeSlotAction(
            slot,
            onDone
        );
    };

    const onDone = (success, data) => {
        if (success) {
            NotificationManager.success(
                <FormattedMessage
                    id="SlotsAvailable"
                    defaultMessage="Slot Availability Updated."
                />,
                'Success'
            );
            setLoader(false)
            setDisableUpdateButton(false)
        } else {
            setLoader(false)
            setError(data)
            setDisableUpdateButton(false)
        }
    };

    const enumerateDaysBetweenDate = (startDate, endDate) => {
        let dates = [];
        let currDate = moment(startDate).startOf('day');
        let lastDate = moment(endDate).startOf('day');
        do {
            let slot = moment(currDate).format('YYYY-MM-DD');
            dates.push({ 'date': slot, "isDateAvailable": true });
        }
        while (currDate.add(1, 'days').diff(lastDate) <= 0);
        return dates;
    };

    //Date Range Picker Event
    const handleEvent = (event, picker) => {
        let currentDate = picker?.startDate;
        let stopDate = picker?.endDate;
        const dateArray = enumerateDaysBetweenDate(currentDate, stopDate);
        let formattedstartDate = moment(currentDate).format('Do MMMM YYYY');
        let formattedEndDate = moment(stopDate).format('Do MMMM YYYY');
        let dbstartDate = moment(new Date(currentDate)).format('YYYY-MM-DD')
        let dbEndDate = moment(new Date(stopDate)).format('YYYY-MM-DD')
        getSlotsByRange(dbstartDate, dbEndDate);

        setDateSlots([...dateArray])
        setStartDate(formattedstartDate)
        setEndDate(formattedEndDate)
    }

    //Date Range Picker Event
    const onDateSlotPress = (e, slot) => {
        let slots = dateSlots;
        slots = slots.map(slotObj => {
            if (slotObj === slot) {
                slotObj.isDateAvailable = e?.target?.checked;
            }
            return slotObj;
        })
        setDateSlots([...slots])
    };

    const updateDateRangeAvailbility = () => {
        const { providerSetTimeSlotAction } = props;
        setDisableUpdateButton(true)
        if (dateSlots && dateSlots?.length > 0) {
            providerSetTimeSlotAction({
                dateSlots: dateSlots,
            },
                onDone
            );
        }
    }

    const onAllDatesPress = (e, isDateAvailableAll) => {
        let slots = dateSlots;
        slots = slots.map(slotObj => {
            slotObj.isDateAvailable = isDateAvailableAll;
            slotObj.isDate = isDateAvailableAll;
            return slotObj;
        })

        setDateSlots([...slots])
        setIsDateAvailableAll(isDateAvailableAll)
    };

    const radiohandleChange = (event) => {
        setValue(event?.target?.value)
        setOpenDateTime(false)
    }

    let yesterday = moment().subtract(1, 'day');
    let valid = function (current) {
        return current.isAfter(yesterday);
    }
    const classes = styles();
    return (
        <div className="provider-bio">
            <Paper className="card-spacing">
                <div class="row">
                    <div className="col-md-12 mt-2  pl-4 pt-2">
                        <FormLabel className="card-title-text" component="legend">Set Availability By :</FormLabel>
                    </div>
                    <div className="col-md-12 mt-2  pl-4">
                        <RadioGroup
                            aria-label="AvailabilityOptions"
                            name="availabilityoptions"
                            className={classes?.group}
                            value={value}
                            onChange={radiohandleChange}
                            row
                        >
                            <FormControlLabel value="date_range" control={<Radio color="primary" />} label="Date Range" />
                            <FormControlLabel value="single_date" control={<Radio color="primary" />} label="Single Date" />
                        </RadioGroup>
                    </div>
                </div>
                <div className="col-md-12 mt-2">
                    <FormControl component="fieldset" >
                        <FormLabel>
                            (The priority of this feature is at higher level If the date is set unavailable from the date range then subsequently
                            all its slots are disabled.)
                        </FormLabel>
                    </FormControl>
                </div>
                {value && value === 'date_range' && (
                    <div className="col-md-12">
                        <div class="row">
                            <div className="col-md-2 mt-2 pb-3">
                                <h4>
                                    <FormattedMessage
                                        id="daterange.heading"
                                        defaultMessage="Date Range"
                                    />
                                </h4>
                                <DateRangePicker
                                    minDate={mindate}
                                    startDate={mindate}
                                    onApply={handleEvent}
                                >
                                    <WSTextField
                                        className={isMobile && 'mt-15 mb-0'}
                                        id="formControlsTextB"
                                        placeholder="Please select dates"
                                    />
                                </DateRangePicker>

                            </div>
                            <div className="col-md-10 mt-3 mb-3 pb-3">
                                {dateSlots && dateSlots?.length > 0 && (
                                    <div>
                                        <GridContainer>
                                            <GridItem>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-2" >
                                                            <Typography variant="caption" style={{ fontWeight: 700, marginBottom: 8, fontSize: 14 }}>Start Date</Typography>
                                                            <WSButton
                                                                variant="outlined"
                                                            >
                                                                {startDate}
                                                            </WSButton>
                                                        </div>

                                                        <div className="col-md-2" >
                                                            <Typography variant="caption" style={{ fontWeight: 700, marginBottom: 8, fontSize: 14 }}>End Date</Typography>
                                                            <WSButton
                                                                variant="outlined"
                                                            >
                                                                {endDate}
                                                            </WSButton>
                                                        </div>

                                                        <div className="col-md-6" style={{ marginTop: '20px' }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        // checked={slot.isDateAvailable}
                                                                        value="checkedAll"
                                                                        color="primary"
                                                                        onChange={(e) => {
                                                                            onAllDatesPress(e, !isDateAvailableAll)
                                                                        }}
                                                                    />
                                                                }
                                                                label={isDateAvailableAll ? "Available All Dates" : "Unavailable All Dates"}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div class="row">
                            <div className="col-md-12 mt-3 mb-3 pb-3">
                                {dateSlots && dateSlots?.length > 0 && (
                                    <div>
                                        <GridContainer>
                                            <GridItem>
                                                <div className="col-md-12">
                                                    {dateSlots.map((slot, index) => {
                                                        return (
                                                            <div key={index} className="row" key={slot?.date}>
                                                                <div className="col-md-6" >
                                                                    <WSButton
                                                                        variant="outlined"
                                                                        color="primary"

                                                                    >
                                                                        {slot?.date}
                                                                    </WSButton>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={slot?.isDateAvailable}
                                                                                value="checkedB"
                                                                                color="primary"
                                                                                onChange={(e) => onDateSlotPress(e, slot)}
                                                                            />
                                                                        }
                                                                        label={slot?.isDateAvailable ? "Available" : "Unavailable"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    <div class={isMobile ? "row mt-10" : "row"}>
                                                        <div className="col-md-8 text-center">
                                                            <WSButton
                                                                onClick={updateDateRangeAvailbility}
                                                                disabled={disableUpdateButton}
                                                            >
                                                                {disableUpdateButton && (
                                                                    'Loading...'
                                                                )}
                                                                {!disableUpdateButton && (
                                                                    'Update'
                                                                )}
                                                            </WSButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {/* Single Date Slot */}

                {value && value === 'single_date' && (
                    <div className="col-md-12 mt-2 pb-3">
                        <SlotDateTimePanel
                            open={openDateTime}
                            isValidDate={valid}
                            timeFormat={false}
                            onChange={onChangeDate}
                            isDateAvailable={isDateAvailable}
                            value={dateTimeValue}
                        />

                        <div className="col-md-12 mt-3 mb-3 pb-3">
                            {dateTimeValue && slots && slots?.length > 0 && (
                                <TimeSlotList
                                    dateTimeValue={dateTimeValue}
                                    isVisible={haveSlots}
                                    slots={slots}
                                    sessions={sessions}
                                    isDateAvailable={isDateAvailable}
                                    onTimeSlotSelected={setTimeSlot}
                                    disableUpdateButton={disableUpdateButton}
                                />
                            )}
                        </div>
                    </div>

                )}
            </Paper>
        </div>

    );
}

const composedComponent = compose(
    withTheme(),
    withStyles(styles),
    connect(
        null,
        actions
    )
);
export default composedComponent(withCustomTheme(injectIntl(withUser(SlotList))));
