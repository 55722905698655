import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import {
    MenuItem,
    Card,
    CircularProgress,
    Select,
    FormControl,
    InputLabel,
    Input,
    FormHelperText
} from '@material-ui/core';

import * as actions from '../../actions';

import { validateUpdateServiceDetails } from '../../utils/validators/validateForm';

import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';

import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

import MultiAddressPanel from './MultiAddressPanel';

const styles = {
    width: '100%',
    height: '100%',
    formControl: {
        minWidth: '90%',
        margin: '15px'
    }
};

class ServiceUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            category: props.data ? props.data.categoryId : '',
            categoryError: '',
            subCategory: props.data ? props.data.subcategory.key : '',
            subCategoryError: '',
            service: props.data ? props.data.serviceId : '',
            serviceError: '',
            loaderButton: false,
            pass: false,
            noResponse: false,
            errorMessage: '',
            type_id: 1,
            selectedAddresses: props.data ? props.data.address_ids : [],
            categoryData: [],
            subCategoryData: [],
            serviceData: [],
            showAddOwnSection: props.data.isCustom
                ? props.data.isCustom
                : false,
            name: props.data.isCustom ? props.data.name : '',
            nameError: '',
            description: props.data ? props.data.description : '',
            descriptionError: '',
            price: props.data ? props.data.price : '',
            priceError: '',
            validate: true,
            addAddress: false,
            addressError: '',
            displayAddress: props.userData.addresses.filter(
                address => address.is_default === 1
            )[0],
            packages: [],
            faq: [],
            proServiceId: props.data.key,
            status: props.data ? props.data.status : 1,
            statusError: '',
            redirect: false
        };
    }

    submit = async () => {
        const {
            name,
            description,
            price,
            selectedAddresses,
            category,
            subCategory,
            service,
            showAddOwnSection
        } = this.state;

        const result = await validateUpdateServiceDetails(
            {
                selectedAddresses,
                name,
                description,
                price,
                category,
                subCategory,
                service,
                showAddOwnSection
            },
            this.handleError
        );

        if (result) {
            this.addService();
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Error'
            );
        }
    };

    componentDidMount() {
        const { allSubcategoryData, data, providerData } = this.props;
        const { serviceData, subCategoryData } = this.state;
        let categories = providerData.providerTypes
        let categoryData = categories && categories.map(category => {
            return category.providerType
        })
        if (data) {
            this.handleSubCategoryChange(data.categoryId);
        }
        this.setState({
            service: data && data.serviceId,
        })
        subCategoryData.unshift({
            key: data && data.subcategory && data.subcategory.key,
            heading: data && data.subcategory && data.subcategory.heading,
        })
        serviceData.unshift({
            key: data && data.service && data.service.key,
            heading: data && data.service && data.service.heading,
        })
        allSubcategoryData.filter(cat => {
            cat.services.length === 0 && cat.services.unshift({
                key: 'noService',
                heading: 'No Available Service',
            })
        })
        this.setState({
            categoryData,
            serviceData,
            allSubcategoryData,
        })
    }

    addService = () => {
        const { updateProviderServiceAction } = this.props;
        this.setState({ loader: true });
        const {
            selectedAddresses,
            name,
            description,
            price,
            category,
            service,
            proServiceId,
            status
        } = this.state;
        updateProviderServiceAction(
            {
                proServiceId: proServiceId,
                serviceName: name,
                mainCategoryId: category,
                serviceId: service,
                categoryId: category,
                addressIds: selectedAddresses,
                hourlyPrice: price,
                description: description,
                faq: [],
                status: status,
            },
            this.onUpdateDone
        );
    };

    handleError = params => {
        this.setState(params);
    };

    onUpdateDone = (success, data) => {
        const { history } = this.props;
        this.setState({ loader: false });
        if (success) {
            NotificationManager.success(data.message, 'Success');
            history.push(`/provider/service`);
        } else {
            NotificationManager.error(data, 'Error');
        }
    };

    renderButton = () => {
        const { loader } = this.state;
        if (loader) {
            return <CircularProgress />;
        }
        return (
            <WSButton
                variant="raised"
                className="jr-btn"
                onClick={this.submit}
            >
                Update
            </WSButton>
        );
    };

    getSubCategory = catId => {
        const { allSubcategoryData } = this.state;
        const subCategories = allSubcategoryData.filter(subCat => subCat.category_id === catId)
        this.setState({
            subCategoryData: subCategories && subCategories.length && subCategories,
            subCategory: subCategories && subCategories.length && subCategories[0] && subCategories[0].key,
            serviceData: subCategories && subCategories.length && subCategories[0] && subCategories[0].services,
            service: subCategories && subCategories.length && subCategories[0] && subCategories[0].services && subCategories[0].services[0] && subCategories[0].services[0].key
        });
    };

    getServices = catId => {
        const { subCategoryData } = this.state;
        const services = subCategoryData && subCategoryData.find(cat => cat.key === catId);
        this.setState({
            serviceData: services ? services.services : [],
            service: services && services.services && services.services[0] && services.services[0].key
        });
    };

    handleCategoryChange = value => {
        this.setState({
            category: value,
            categoryError: ''
        });
        this.getSubCategory(value);
    };

    handleSubCategoryChange = value => {
        this.setState({
            subCategory: value,
            subCategoryError: ''
        });
        this.getServices(value);
    };

    handleServiceChange = value => {
        this.setState({
            service: value,
            serviceError: ''
        });
    };

    handleStatusChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            statusError: ''
        });
    };

    addYourOwnItem = () => {
        this.setState({ showAddOwnSection: true, serviceError: '' });
    };

    setAddress = keys => {
        this.setState({ selectedAddresses: keys, addressError: '' });
    };

    closeAddressPopup = () => {
        this.setState({ addAddress: false });
    };

    addNewItem = () => {
        const { packages } = this.state;
        this.setState(
            prevState => ({
                packages: [
                    ...prevState.packages,
                    {
                        name: '',
                        nameError: '',
                        description: '',
                        descriptionError: '',
                        costPrice: '',
                        costPriceError: '',
                        price: '',
                        priceError: ''
                    }
                ]
            }),
            () => console.log(packages)
        );
    };

    removeItem = index => {
        const { packages } = this.state;
        let newPackages = [...packages];
        newPackages.splice(index, 1);
        this.setState({ packages: newPackages });
    };

    addNewFAQItem = () => {
        const { faqs } = this.state;
        this.setState(
            prevState => ({
                faqs: [
                    ...prevState.faqs,
                    {
                        question: '',
                        questionError: '',
                        answer: '',
                        answerError: ''
                    }
                ]
            }),
            () => console.log(faqs)
        );
    };

    removeFAQItem = index => {
        const { faqs } = this.state;
        let newFaqs = faqs;
        newFaqs.splice(index, 1);
        this.setState({ faqs: newFaqs });
    };

    render() {
        const {
            name,
            nameError,
            description,
            descriptionError,
            price,
            priceError,
            loaderButton,
            addressError,
            subCategoryError,
            categoryError,
            serviceError,
            statusError,
            redirect,
            subCategoryData,
            category,
            categoryData,
            showAddOwnSection,
            service,
            serviceData,
            status,
            selectedAddresses
        } = this.state;
        const { classes, history, data } = this.props;
        if (redirect) {
            history.push(`/provider/service`);
        }
        return (
            <Card className="col-md-12">
                <form
                    className="row"
                    noValidate
                    autoComplete="off"
                    id="service-create"
                >
                    <div className={isMobile ? "col-md-4 col-xs-12 p-0" : "col-md-4 col-xs-12"}>
                        <FormControl
                            className={classes.formControl}
                            error={categoryError ? true : false}
                            style={{ marginVertical: isMobile && 0, marginBottom: 0 }}
                        >
                            <InputLabel shrink>Category</InputLabel>
                            {categoryData && categoryData.length > 0 &&
                                <Select
                                    fullWidth
                                    name="category"
                                    value={category}
                                    onChange={(event) => this.handleCategoryChange(event.target.value)}
                                    disabled
                                    input={
                                        <Input
                                            value={category}
                                            id="category"
                                        />
                                    }
                                >
                                    {categoryData.map(
                                        (cat, index) => {
                                            return (
                                                <MenuItem key={cat.id} value={cat.id}>
                                                    {cat.name}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            }
                            <FormHelperText>{categoryError}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className={isMobile ? "col-md-4 col-xs-12 p-0" : "col-md-4 col-xs-12"}>
                        <FormControl
                            className={classes.formControl}
                            error={subCategoryError ? true : false}
                            style={{ marginVertical: isMobile && 0, marginBottom: 0 }}
                        >
                            <InputLabel shrink>Sub Category</InputLabel>
                            {subCategoryData && subCategoryData.length > 0 &&
                                <Select
                                    fullWidth
                                    name="subCategory"
                                    value={data.subcategory.key}
                                    onChange={(event) => this.handleSubCategoryChange(event.target.value)}
                                    disabled
                                    input={
                                        <Input
                                            value={data.subcategory.key}
                                            id="subCategory"
                                        />
                                    }
                                >
                                    {subCategoryData.map(
                                        (cat, index) => {
                                            return (
                                                <MenuItem key={cat.key} value={cat.key}>
                                                    {cat.heading}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            }
                            <FormHelperText>{subCategoryError}</FormHelperText>
                        </FormControl>
                    </div>
                    {!showAddOwnSection && (
                        <div className={isMobile ? "col-md-4 col-xs-12 p-0" : "col-md-4 col-xs-12"}>
                            <FormControl
                                className={classes.formControl}
                                error={serviceError ? true : false}
                                style={{ marginVertical: isMobile && 0, marginBottom: 0 }}
                            >
                                <InputLabel shrink>Service</InputLabel>
                                <Select
                                    autoWidth={true}
                                    value={service}
                                    onChange={(event) => this.handleServiceChange(event.target.value)}
                                    inputProps={{
                                        name: 'service'
                                    }}
                                    disabled
                                >
                                    {serviceData &&
                                        serviceData.map(
                                            (service, index) => {
                                                return (
                                                    <MenuItem
                                                        value={service.key}
                                                    >
                                                        {service.heading}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                </Select>
                                <FormHelperText>{serviceError}</FormHelperText>
                            </FormControl>
                        </div>
                    )}
                    {showAddOwnSection && (
                        <div className={isMobile ? "col-md-6 col-xs-12 p-0" : "col-md-6 col-xs-12"}>
                            <WSTextField
                                className="text-box-spacing ml-0"
                                error={nameError ? true : false}
                                helperText={nameError}
                                name="name"
                                label="Name"
                                id="name"
                                placeholder="Add Name"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={event => {
                                    this.setState({
                                        name: event.target.value,
                                        nameError: ''
                                    });
                                }}
                                defaultValue={name}
                                disabled={loaderButton ? true : false}
                            />
                        </div>
                    )}
                    <div className={isMobile ? "col-md-6 col-xs-12 p-0" : "col-md-6 col-xs-12"}>
                        <WSTextField
                            className="text-box-spacing ml-0"
                            error={priceError ? true : false}
                            helperText={priceError}
                            name="price"
                            label="Starting Price / Price per hour"
                            id="price"
                            placeholder="Add Price"
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={event => {
                                this.setState({
                                    price: event.target.value,
                                    priceError: '',
                                    error: ''
                                });
                            }}
                            defaultValue={price}
                            disabled={loaderButton ? true : false}
                        />
                    </div>
                    <div className={isMobile ? "col-md-6 col-xs-12 p-0" : "col-md-6 col-xs-12"}>
                        <FormControl
                            className={classes.formControl}
                            error={statusError ? true : false}
                            style={{ marginVertical: isMobile && 0, marginBottom: 0 }}
                        >
                            <InputLabel shrink>Status</InputLabel>
                            <Select
                                autoWidth={true}
                                value={status}
                                onChange={this.handleStatusChange}
                                inputProps={{
                                    name: 'status'
                                }}
                            >
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={2}>Inactive</MenuItem>
                            </Select>
                            <FormHelperText>{statusError}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className={isMobile ? "col-md-6 col-xs-12 p-0" : "col-md-6 col-xs-12"}>
                        <WSTextField
                            className="text-box-spacing ml-0"
                            error={descriptionError ? true : false}
                            helperText={descriptionError}
                            name="description"
                            label="Description"
                            id="description"
                            placeholder="Add Description"
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={event => {
                                this.setState({
                                    description: event.target.value,
                                    descriptionError: '',
                                    error: ''
                                });
                            }}
                            defaultValue={description}
                            disabled={loaderButton ? true : false}
                        />
                    </div>
                    <div className="col-md-12">
                        <MultiAddressPanel
                            addNewAddress={this.addNewAddress}
                            setBookingAddress={keys => this.setAddress(keys)}
                            selectedKeys={selectedAddresses}
                            {...this.props}
                        />
                        {addressError && (
                            <h6 style={{ color: 'red' }}>{addressError}</h6>
                        )}
                    </div>
                    <div className="col-md-12 col-xs-12">
                        <div className={!isMobile && "mt-3"}>{this.renderButton()}</div>
                    </div>
                </form>
            </Card>
        );
    }
}

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default withCustomTheme(composedComponent(ServiceUpdate));