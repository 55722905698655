import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Home from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import { AccountBalanceWallet, CreditCard } from '@material-ui/icons';

import { ROLE_CUSTOMER } from '../../utils/config/constants.js';

import BasicCustomerProfile from '../Customer/BasicCustomerProfile.jsx';
import AddressList from '../Customer/AddressList.jsx';
import CustomerBookingList from '../Customer/CustomerBookingList';
import CustomerFavoriteList from '../Customer/CustomerFavoriteList';
import CustomerWalletList from '../Customer/CustomerWalletList.jsx';
import CustomerCardList from '../Customer/CustomerCardList.jsx';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 2 }} className={isMobile && props.mobileStyle}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: '10px',
        width: '100%',
        backgroundColor: theme.palette.background.paper
    }
});

class CustomerTab extends Component {
    state = {
        value: 0
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const {
            classes,
            userData,
            intl,
            settings
        } = this.props;
        const { formatMessage } = intl;
        const { value } = this.state;
        return (
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        className="profile-tabs-dash"
                        value={value}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    // centered
                    >
                        <Tab
                            label={formatMessage({
                                id: 'customer.basicprofile'
                            })}
                            icon={<PhoneIcon />}
                        />
                        <Tab
                            label={formatMessage({
                                id: 'customer.addresses'
                            })}
                            icon={<Home />}
                        />
                        <Tab
                            label={formatMessage({
                                id: 'customer.bookings'
                            })}
                            icon={<PersonPinIcon />}
                        />
                        <Tab
                            label={formatMessage({
                                id: 'customer.favourites'
                            })}
                            icon={<FavoriteIcon />}
                        />
                        {/* {settings.constants.CARD_ACTIVE && (
                            <Tab
                                label={formatMessage({
                                    id: 'customer.card'
                                })}
                                icon={<CreditCard />}
                            />
                        )} */}
                        {settings.constants.WALLET_ACTIVE && (
                            <Tab
                                label={formatMessage({
                                    id: 'customer.wallet'
                                })}
                                icon={<AccountBalanceWallet />}
                            />
                        )}
                    </Tabs>
                </AppBar>
                {value === 0 && (
                    <TabContainer mobileStyle='pr-0 pl-30'>
                        <BasicCustomerProfile {...this.props} />
                    </TabContainer>
                )}
                {value === 1 && (
                    <TabContainer>
                        <AddressList {...this.props} />
                    </TabContainer>
                )}
                {value === 2 && userData.role === ROLE_CUSTOMER && (
                    <TabContainer>
                        <CustomerBookingList {...this.props} />
                    </TabContainer>
                )}
                {value === 3 && (
                    <TabContainer>
                        <CustomerFavoriteList {...this.props} />
                    </TabContainer>
                )}
                {/* {value === 4 && (
                    <TabContainer>
                        <CustomerCardList {...this.props} />
                    </TabContainer>
                )} */}
                {value === 4 && (
                    <TabContainer>
                        <CustomerWalletList {...this.props} />
                    </TabContainer>
                )}
            </div>
        );
    }
}

CustomerTab.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectIntl(CustomerTab));