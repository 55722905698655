import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, CircularProgress } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import * as actions from '../../actions';

import { validatePackageCreateForm } from '../../utils/validators/validateForm';

import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';

import WSButton from '../Button/WSButton';
import WSTextField from '../TextField/WSTextField';

const styles = {
    width: '100%',
    height: '100%',
    formControl: {
        minWidth: '90%',
        margin: '15px'
    }
};

class ServicePackageUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: props.data ? props.data.name : '',
            nameError: '',
            costPrice: props.data ? props.data.costPrice : '',
            costPriceError: '',
            price: props.data ? props.data.price : '',
            priceError: '',
            description: props.data ? props.data.description : '',
            descriptionError: '',
            duration: props.data ? props.data.duration : '',
            durationError: '',
            loaderButton: false,
            pass: false,
            noResponse: false,
            errorMessage: '',
            validate: true,
            serviceId: props.data ? props.data.provider_service_id : '',
            packageId: props.data ? props.data.key : ''
        };
    }

    submit = async () => {
        const { name, costPrice, price, description, duration } = this.state;
        const result = await validatePackageCreateForm(
            {
                name,
                costPrice,
                price,
                description,
                duration
            },
            this.handleError
        );
        if (result) {
            this.updatePackage();
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="Validation Error"
                    defaultMessage="Validation Error"
                />,
                'Error'
            );
        }
    };

    updatePackage = () => {
        this.setState({ loader: true });
        const { name, costPrice, price, description, duration, packageId } = this.state;
        const { updateServicePackageAction, data } = this.props;
        updateServicePackageAction(
            {
                name,
                costPrice,
                price,
                description,
                duration,
                packageId: data.key
            },
            this.onAddDone
        );
    };

    handleError = params => {
        this.setState(params);
    };

    onAddDone = (success, data) => {
        const { serviceId } = this.state;
        const { history } = this.props;
        this.setState({ loader: false });
        if (success) {
            NotificationManager.success(data.message, 'Success');
            history.push({
                pathname: `/provider/service/package`,
                serviceId: serviceId
            });
        }
    };

    renderButton = () => {
        const { loader } = this.state;
        if (loader) {
            return <CircularProgress />;
        }
        return (
            <WSButton
                variant="raised"
                className="jr-btn"
                onClick={this.submit}
            >
                <FormattedMessage id="update" defaultMessage="Update" />
            </WSButton>
        );
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render() {
        const {
            name,
            nameError,
            costPrice,
            costPriceError,
            price,
            priceError,
            description,
            descriptionError,
            duration,
            durationError
        } = this.state;
        const { intl } = this.props;
        return (
            <Card className="col-md-12">
                <form
                    className="row"
                    noValidate
                    autoComplete="off"
                    id="package-update"
                >
                    <div className="col-md-6 col-xs-12">
                        <WSTextField
                            className={isMobile && 'mt-15 mb-0'}
                            error={nameError ? true : false}
                            helperText={nameError}
                            name="name"
                            label={intl.formatMessage({
                                id: 'name'
                            })}
                            id="name"
                            placeholder={intl.formatMessage({
                                id: 'name'
                            })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            defaultValue={name}
                            onChange={this.handleChange('name')}
                        />
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <WSTextField
                            className={isMobile && 'mt-15 mb-0'}
                            error={costPriceError ? true : false}
                            helperText={costPriceError}
                            name="costPrice"
                            label={intl.formatMessage({
                                id: 'costPrice'
                            })}
                            id="costPrice"
                            placeholder={intl.formatMessage({
                                id: 'costPrice'
                            })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            defaultValue={costPrice}
                            onChange={this.handleChange('costPrice')}
                        />
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <WSTextField
                            className={isMobile && 'mt-15 mb-0'}
                            error={priceError ? true : false}
                            helperText={priceError}
                            name="price"
                            label={intl.formatMessage({
                                id: 'advertisePrice'
                            })}
                            id="price"
                            placeholder={intl.formatMessage({
                                id: 'advertisePrice'
                            })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            defaultValue={price}
                            onChange={this.handleChange('price')}
                        />
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <WSTextField
                            className={isMobile && 'mt-15 mb-0'}
                            error={durationError ? true : false}
                            helperText={durationError}
                            name="duration"
                            label={intl.formatMessage({
                                id: 'duration',
                                defaultMessage: 'Duration (Min)'
                            })}
                            id="duration"
                            placeholder={intl.formatMessage({
                                id: 'duration',
                                defaultMessage: 'Duration'
                            })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            defaultValue={duration}
                            onChange={this.handleChange('duration')}
                        />
                    </div>
                    <div className="col-md-12 col-xs-12">
                        <WSTextField
                            className={isMobile && 'mt-15 mb-15'}
                            error={descriptionError ? true : false}
                            helperText={descriptionError}
                            name="description"
                            label={intl.formatMessage({
                                id: 'description'
                            })}
                            id="description"
                            placeholder={intl.formatMessage({
                                id: 'description'
                            })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            defaultValue={description}
                            onChange={this.handleChange('description')}
                        />
                    </div>
                    <div className="col-md-12 col-xs-12">
                        {this.renderButton()}
                    </div>
                </form>
            </Card>
        );
    }
}

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default withCustomTheme(
    composedComponent(injectIntl(ServicePackageUpdate))
);