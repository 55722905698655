import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import MessageInbox from '../../components/Customer/MessageInbox.jsx';
import ChatPanel from '../../components/Customer/ChatPanel.jsx';

const sendProps = (props) => {
    const { classes, ...rest } = props;
    return rest;
};

export class Inbox extends Component {
    render() {
        const { match } = this.props;
        return (
            <div>
                <Switch>
                    <Route
                        path={`${match.url}/`}
                        exact={true}
                        render={(props) => (
                            <MessageInbox
                                {...sendProps(this.props)}
                                {...props}
                            />
                        )}
                    />
                    <Route
                        path={`${match.url}/chat`}
                        exact={true}
                        render={(props) => (
                            <ChatPanel {...sendProps(this.props)} {...props} />
                        )}
                    />
                </Switch>
            </div>
        );
    }
}

export default Inbox;
