import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import {
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Card,
    Checkbox,
    CircularProgress,
    ListItemIcon
} from '@material-ui/core';

import * as actions from '../../actions';
import {timingData as TimingData} from '../../utils/config/constants'

import WSButton from '../Button/WSButton';

import TimePickerPopup from './TimePickerPopup';
import { useState } from 'react';

const styles = theme => ({
    root: {
        width: '100%'
    },
    inline: {
        display: 'inline'
    },
    margin: {
        margin: theme?.spacing?.unit
    },
    extendedIcon: {
        marginRight: theme?.spacing?.unit
    },
    paper: {
        backgroundColor: theme?.palette?.background?.paper,
        marginBottom: '10px'
    }
});

const BusinessHours =props=> {

    let selectedType = 'all';
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [loaderButton, setLoaderButton] = useState(false);
    const [scrollClicked, setScrollClicked] = useState(false);
    const [detailRender, setDetailRender] = useState(false);
    const [bookingSelected, setBookingSelected] = useState('');
    const [showScroll, setShowScroll] = useState(true);
    const [timeValueStart, setTimeValueStart] = useState('');
    const [timeValueEnd, setTimeValueEnd] = useState('');
    const [timing, setTiming] = useState({});
    const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
    const [timeLoader, setTimeLoader] = useState(false);
    const [timingData, setTimingData] = useState(TimingData);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const { getBusinessHoursAction } = props;
        getBusinessHoursAction(onDone);
    };

    const onDone = (success, data) => {
        if (success) {
            setTimingData(data?.data?.timing_packet)
        } else {
            NotificationManager.error(data?.message, 'Error');
        }
    };

    const handleClick = index => {
        const timingDatas = [...timingData];
        timingDatas[index].status = !timingData[index]?.status;
        setTimingData(timingDatas)
    };

    const onTimePress = index => {
        selectedType = 'all';
        setSelectedIndex(index)
        setIsDateTimePickerVisible(true)
    }

    const onChangeStartDate = event => {
        const dateTimeValue = moment(event).format('hh:mm A');
        setTimeValueStart(dateTimeValue)
    };

    const onChangeDateEnd = event => {
        const dateTimeValue = moment(event).format('hh:mm A');
        setTimeValueEnd(dateTimeValue)
    };

    const handleClose = () => {
        setIsDateTimePickerVisible(false)
    };

    const saveDate = () => {
        setTimeLoader(true)
        const timingDatas = [...timingData];
        if (selectedType === 'all') {
            timingDatas[selectedIndex].startTime = timeValueStart || timing && timing?.startTime;
            timingDatas[selectedIndex].endTime = timeValueEnd || timing && timing?.endTime;
        }
        setTimingData(timingDatas)
            setIsDateTimePickerVisible(false)
            setTimeLoader(false)
    };

    const updateBusinessHours = () => {
        const { updateTimingPacketAction } = props;
        setLoaderButton(true)
        updateTimingPacketAction(
            {
                timingPacket: timingData
            },
            onUpdateDone
        );
    };

    const onUpdateDone = (success, data) => {
        setLoaderButton(false)
        if (success) {
            NotificationManager.success(data?.message, 'Success');
        } else {
            NotificationManager.error(data?.message, 'Error');
        }
    };

   const renderButton = () => {
        if (loaderButton) {
            return <CircularProgress />;
        } else {
            return (
                <WSButton
                    style={{
                        backgroundColor: '#e24f36',
                        color: '#ffffff',
                        margin: !isMobile && 20,
                        borderRadius: 30
                    }}
                    onClick={updateBusinessHours}
                >
                    Save Updates
                </WSButton>
            );
        }
    };

        const { classes, history } = props;
        if (detailRender) {
            history.push({
                pathname: `/user/booking/detail/${bookingSelected}`,
                bookingId: bookingSelected
            });
        }
        return (
            <div>
                {window?.location?.pathname === '/user/profile' && <div className={isMobile ? "row" : "row mb-4"}>
                    <div className="col-md-12">
                        <Card className={classes?.card}>
                            <Toolbar>
                                <h3>Manage your Timings</h3>
                            </Toolbar>
                        </Card>
                    </div>
                </div>}
                <div className="row">
                    <div className="col-md-12">
                        <List className={classes?.root}>
                            {timingData.map(timing => {
                                return (
                                    <ListItem
                                        key={timing?.key}
                                        className={
                                            classes?.paper +
                                            ' dashboard-bookhr-list'
                                        }
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                color="primary"
                                                checked={timing?.status}
                                                onChange={() =>
                                                    handleClick(timing?.key - 1)
                                                }
                                                value={timing?.key}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            className="dashboard-bookhr-text"
                                            disableTypography={true}
                                            primary={
                                                <div className="row col-md-12 p-0 flex-align-center">
                                                    <div className={`col-md-2 col-xs-4`}>
                                                        <h5 className="m-0 text-black-400">{timing?.day}</h5>
                                                    </div>
                                                    <div className={`col-md-4 col-xs-8 select-time-container`}
                                                        onClick={() => {
                                                            onTimePress(
                                                                timing?.key - 1
                                                            )
                                                            setTiming(timing)
                                                        }}>
                                                        <div className="d-flex">
                                                            <p
                                                                className="m-0 text-black-300"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                {timing?.startTime}
                                                            </p>

                                                            <p className="m-0 text-black-300"> &nbsp;-&nbsp; </p>
                                                            <p
                                                                className="m-0 text-black-300"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                {timing?.endTime}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                    <div className="col-md-12">
                        {renderButton()}
                    </div>
                </div>
                <TimePickerPopup
                    isVisible={isDateTimePickerVisible}
                    onChangeStartDate={onChangeStartDate}
                    onChangeEndDate={onChangeDateEnd}
                    dateTimeValue={timeValueStart}
                    closePopup={handleClose}
                    dateFormat={false}
                    timeFormat={true}
                    open={true}
                    saveDate={saveDate}
                    loaderButton={timeLoader}
                    defaultValueStartTime={timing && timing?.startTime}
                    defaultValueEndTime={timing && timing?.endTime}
                />
            </div>
        );
}

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default composedComponent(withRouter(injectIntl(BusinessHours)));