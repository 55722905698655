import React, { Component, Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { List, ListItem, withStyles } from '@material-ui/core';
// @material-ui/icons
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import footerStyle from 'assets/jss/material-kit-react/components/footerStyle.jsx';
import { withTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import VISA from 'assets/img/visa.svg';
import SecureCode from 'assets/img/secureCode.png';
import MasterCard from 'assets/img/masterCard.png';
import VerifiedVisa from 'assets/img/verifiedVisa.png';

class Footer extends Component {
    constructor() {
        super();
        this.state = {
            providers: [],
        };
    }

    componentDidMount() {
        // this.getData();
    }

    renderCmsDetail = (slug) => {
        const { history } = this.props;
        history.push(`/page/${slug}`);
    };

    render() {
        const { classes, whiteFont, cmsLinks, constants } = this.props;
        console.log(this.props, "sss");
        const footerClasses = classNames({
            [classes?.footer]: true,
            [classes?.footerWhiteFont]: whiteFont,
        });
        const aClasses = classNames({
            [classes?.a]: true,
            [classes?.footerWhiteFont]: whiteFont,
        });
        const { providers } = this.state;
        const { userData } = this.props;

        let cmslinks = cmsLinks.filter((cms) => {
            return !cms?.showingon || cms?.showingon === 0 || cms?.showingon === 2;
        });

        return (
            <footer
                className={
                    footerClasses + isMobile
                        ? 'footer-main-mobile'
                        : 'footer-main'
                }
            >
                <div className={classes?.container}>
                    <div className="row footer-row text-left">
                        <div className="col-md-4">
                            <h4>
                                <FormattedMessage
                                    id="About us"
                                    defaultMessage="About Us"
                                />
                            </h4>
                            <p>
                                <FormattedMessage
                                    id="aboutUsContact"
                                    defaultMessage={constants?.about_us}
                                />
                            </p>
                        </div>
                        <div className="col-md-3">
                            <h4>
                                <FormattedMessage
                                    id="usefulLinks"
                                    defaultMessage="Our Policies"
                                />
                            </h4>
                            <div className="footer-main-menu mt-10">
                                <List className={classes?.list}>
                                    <ListItem className={classes?.block} />
                                    {cmslinks?.length &&
                                        cmslinks.map((link, index) => {
                                            return (
                                                <ListItem
                                                    className={classes?.block}
                                                    key={index}
                                                >
                                                    <Link
                                                        to={`/page/${link?.slug}`}
                                                        className={
                                                            classes?.block
                                                        }
                                                    >
                                                        <FormattedMessage
                                                            id={link?.title}
                                                            defaultMessage={
                                                                link?.title
                                                            }
                                                        />
                                                    </Link>
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            </div>
                        </div>
                        <div className={'col-md-5'}>
                            <h4>
                                <FormattedMessage
                                    id="contact"
                                    defaultMessage="Get in Touch"
                                />
                            </h4>
                            <div className="footer-main-menu mt-10">
                                <a className="fontlink" href="mailto:contact@photoplan8.com">
                                    <FormattedMessage
                                        id="emailUs"
                                        defaultMessage="Email us"
                                    />
                                    : {"contact@photoplan8.com"}
                                </a>
                                <br />
                                <div className="d-flex mt-10">
                                    {/* {constants?.google && (
                                        <a href={constants?.google}>
                                            <div
                                                className="social-icon-circle"
                                                style={{
                                                    backgroundColor: '#dd4b39',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: '#fff',
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    <i className="fab fa-google-plus-g" />
                                                </div>
                                            </div>
                                        </a>
                                    )} */}
                                    {(
                                        <div
                                            className="social-icon-circle"
                                            style={{
                                                backgroundColor: '#c73945',
                                            }}
                                        >
                                            <a href={"https://www.instagram.com/photoplan8/?hl=en"} target="_blank" >
                                                <div
                                                    style={{
                                                        color: '#fff',
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    <i className="fab fa-instagram" />
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                    {(
                                        <div
                                            className="social-icon-circle"
                                            style={{
                                                backgroundColor: '#395694',
                                            }}
                                        >
                                            <a href={"https://www.facebook.com/PhotoPlan8/"} target="_blank" >
                                                <div
                                                    style={{
                                                        color: '#fff',
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    <i className="fab fa-facebook" />
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                    {(
                                        <div
                                            className="social-icon-circle"
                                            style={{
                                                backgroundColor: '#ff0a00',
                                            }}
                                        >
                                            <a href={"https://www.youtube.com/channel/UCFZhamgz4YI3Y0KHGnjRBog"} target="_blank" >
                                                <div
                                                    style={{
                                                        color: '#fff',
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    <i className="fab fa-youtube" />
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                    {(
                                        <div
                                            className="social-icon-circle"
                                            style={{
                                                backgroundColor: '#009dec',
                                            }}
                                        >
                                            <a href={"https://twitter.com/photoplan8"} target="_blank" >
                                                <div
                                                    style={{
                                                        color: '#fff',
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    <i className="fab fa-twitter" />
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                                <h4 style={{ marginTop: !isMobile && 20 }}>
                                    <FormattedMessage
                                        id="acceptedCards"
                                        defaultMessage="Accepted Cards"
                                    />
                                </h4>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            marginRight: 10,
                                            width: isMobile ? '15%' : '10%',
                                            height: 35,
                                            borderRadius: 5,
                                            paddingTop: isMobile && 10,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LazyLoadImage src={VISA} alt="visa" />
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            marginRight: 10,
                                            width: isMobile ? '15%' : '10%',
                                            height: 35,
                                            paddingTop: isMobile && 3,
                                            borderRadius: 5,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LazyLoadImage
                                            src={MasterCard}
                                            alt="master card"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            marginRight: 10,
                                            width: isMobile ? '15%' : '10%',
                                            height: 35,
                                            borderRadius: 5,
                                            paddingTop: isMobile && 7,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LazyLoadImage
                                            src={VerifiedVisa}
                                            alt="verified by visa"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            marginRight: 10,
                                            width: isMobile ? '15%' : '10%',
                                            height: 35,
                                            borderRadius: 5,
                                            paddingTop: isMobile && 7,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LazyLoadImage
                                            src={SecureCode}
                                            alt="secure code"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center mt-4">
                            <div className="footer-mini">
                                &copy; {1900 + new Date().getYear()}
                                <a
                                    href="#"
                                    className={aClasses}
                                    target="_blank"
                                >
                                    &nbsp;
                                    {constants?.companyName}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes?.object?.isRequired,
    whiteFont: PropTypes?.bool,
};
const mapStateToProps = ({ settings }) => {
    return { cmsLinks: settings?.cmsLinks, constants: settings?.constants };
};

const composedComponent = compose(
    withTheme(),
    connect(mapStateToProps, actions),
    withStyles(footerStyle)
);
export default composedComponent(withRouter(Footer));
