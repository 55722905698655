import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { CircularProgress } from '@material-ui/core';

import configureStore from './store/index';

import App from './views/App.jsx';

import AnimatedLoader from '../src/components/Loader/loader';

export const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <Suspense
            fallback={
                <div
                    style={{
                        display: 'flex',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <AnimatedLoader />
                    {/* <CircularProgress style={{ color: '#e24f36' }} size={60} /> */}
                </div>
            }
        >
            <App />
        </Suspense>
    </Provider>,
    document.getElementById('root')
);
