import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isBrowser, isTablet, isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';

import Menu from '@material-ui/icons/Menu';

const drawerWidth = 260;
const container = {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    '@media (min-width: 576px)': {
        maxWidth: '540px',
    },
    '@media (min-width: 768px)': {
        maxWidth: '720px',
    },
    '@media (min-width: 992px)': {
        maxWidth: '960px',
    },
    '@media (min-width: 1200px)': {
        maxWidth: '1240px',
    },
    '@media (min-width: 1300px)': {
        maxWidth: '1340px',
    },
};
const styles = {
    flex: {
        flex: 1,
    },
    container: {
        ...container,
        minHeight: '50px',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        flexWrap: 'nowrap',
    },
    appResponsive: {
        margin: '20px 10px',
    },
    drawerPaper: {
        border: 'none',
        bottom: '0',
        transitionProperty: 'top, bottom, width',
        transitionDuration: '.2s, .2s, .35s',
        transitionTimingFunction: 'linear, linear, ease',
        width: drawerWidth,
        boxShadow:
            '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
        position: 'fixed',
        display: 'block',
        top: '0',
        height: '100vh',
        right: '0',
        left: 'auto',
        visibility: 'visible',
        overflowY: 'visible',
        borderTop: 'none',
        textAlign: 'left',
        paddingRight: '0px',
        paddingLeft: '0',
        transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    },
    appBarTransparent: {
        marginBottom: '20px',
        padding: '0.625rem 0',
        width: '100%',
        background: 'transparent',
        boxShadow: 'none',
    },
    appBarSimple: {
        marginBottom: '20px',
        padding: '0.625rem 0',
        width: '100%',
        boxShadow:
            '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
    },
};

let isMounted = false;

const logoContainerWidth = window && window.innerWidth - 30;

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            isTransparent: true,
        };
    }

    handleDrawerToggle = () => {
        const { mobileOpen } = this.state;
        this.setState({ mobileOpen: !mobileOpen });
    };

    componentDidMount() {
        const { changeColorOnScroll } = this.props;
        isMounted = true;
        if (isMounted) {
            if (changeColorOnScroll) {
                window.addEventListener('scroll', this.headerColorChange);
            }
        }
    }

    componentWillUnmount() {
        isMounted = false;
    }

    headerColorChange = () => {
        const { changeColorOnScroll } = this.props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            this.setState({ isTransparent: false });
        } else {
            this.setState({ isTransparent: true });
        }
    };

    componentWillUnmount() {
        const { changeColorOnScroll } = this.props;
        if (changeColorOnScroll) {
            window.removeEventListener('scroll', this.headerColorChange);
        }
    }

    renderHomePage = () => {
        const { history } = this.props;
        history.push(`/`);
    };

    render() {
        const { classes, rightLinks, leftLinks, brand, brandImg } = this.props;
        const { isTransparent, mobileOpen } = this.state;
        const brandComponent = (
            <Button
                className={'home-logo'}
                onClick={this.renderHomePage}
                style={{ fontSize: isMobile ? '14px' : '16px' }}
                size={isBrowser ? 'large' : isTablet ? 'medium' : 'small'}
                style={{
                    paddingLeft: isMobile && (30 * logoContainerWidth) / 100,
                }}
            >
                {brandImg && <LazyLoadImage src={brandImg} alt={'home-logo'} />}
                {brand && <h2>{brand}</h2>}
            </Button>
        );
        return (
            <AppBar
                className={
                    isTransparent &&
                    window.location.pathname !== '/user/bookingreceipt'
                        ? classes.appBarTransparent + ' header-links-menu'
                        : classes.appBarSimple + ' header-links-menu'
                }
            >
                <Toolbar className={classes.container}>
                    {leftLinks !== undefined ? brandComponent : null}
                    <div className={classes.flex}>
                        {leftLinks !== undefined ? (
                            <Hidden smDown implementation="css">
                                {leftLinks}
                            </Hidden>
                        ) : (
                            brandComponent
                        )}
                    </div>
                    <Hidden smDown implementation="css">
                        {rightLinks}
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                        >
                            <Menu />
                        </IconButton>
                    </Hidden>
                </Toolbar>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={'right'}
                        open={mobileOpen}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        onClick={this.handleDrawerToggle}
                        onClose={this.handleDrawerToggle}
                    >
                        <div className={classes.appResponsive}>
                            {leftLinks}
                            {rightLinks}
                        </div>
                    </Drawer>
                </Hidden>
            </AppBar>
        );
    }
}

Header.defaultProp = {
    color: 'white',
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'transparent',
        'white',
        'rose',
        'dark',
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // this.props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // this.props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            'primary',
            'info',
            'success',
            'warning',
            'danger',
            'transparent',
            'white',
            'rose',
            'dark',
        ]).isRequired,
    }),
};

export default withRouter(withStyles(styles)(Header));
