import React, { Fragment, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import ProgressiveImage from 'react-progressive-image';
import ContentLoader from 'react-content-loader';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { withStyles } from '@material-ui/core/styles';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    CircularProgress,
    Toolbar,
    Card,
    IconButton
} from '@material-ui/core';
import {
    KeyboardArrowDown,
    FilterList,
    Check,
    Close,
    DoneOutline,
    Person,
    LocationOn,
    Phone,
    Email,
    Camera
} from '@material-ui/icons';

import avatarImage from '../../assets/img/avatar.png';

import * as actions from '../../actions';

import {
    getBookingStatusClass,
    BOOKING_STATUS_INITIATED,
    getBookingPaymentStatus,
    getBookingPaymentStatusClass,
    convertTimestampToDate,
    tConvert
} from '../../utils/config/constants.js';
import {
    BOOKING_VIEW_ALL,
    BOOKING_VIEW_INPROGRESS,
    BOOKING_VIEW_NEW,
    BOOKING_VIEW_COMPLETED,
    BOOKING_VIEW_CANCELLED
} from '../../utils/config/constants';

import MenuButton from '../Menu/MenuButton.js';
import WSButton from '../Button/WSButton';

const styles = theme => ({
    root: {
        width: '100%'
    },
    inline: {
        display: 'inline'
    },
    margin: {
        margin: theme.spacing.unit
    },
    extendedIcon: {
        marginRight: theme.spacing.unit
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: '10px'
    }
});

function BookingList(props) {

    const [page, setPage] = useState(1)
    const [scrollClicked, setScrollClicked] = useState(false)
    const [detailRender, setDetailRender] = useState(false)
    const [bookingSelected, setBookingSelected] = useState('')
    const [showScroll, setShowScroll] = useState(true)
    const [actionLoader, setActionLoader] = useState(false)

    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        const { providerBookingListAction } = props;
        providerBookingListAction({
            page: page,
            append: false
        });
    };
    const bookingData = useSelector(state => state?.bookings?.bookingData?.bookingList);

    const renderPromoImage = () => {
        return (
            <div style={{ width: 200, height: 200 }}>
                <ContentLoader
                    height={200}
                    width={200}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="2" ry="2" width="200" height="200" />
                </ContentLoader>
            </div>
        );
    };

    const handlePageChange = () => {
        const { providerBookingListAction } = props;
        setScrollClicked(false)
        const pages = page + 1
        providerBookingListAction({
            page: pages + 1,
            append: true
        });
        setPage(pages)
    };

    const renderScrollIcon = () => {
        if (scrollClicked) {
            return <CircularProgress />;
        } else {
            return (
                <WSButton
                    variant="fab"
                    style={{
                        padding: 0,
                        height: 40,
                    }}
                >
                    <KeyboardArrowDown onClick={handlePageChange} />
                </WSButton>
            );
        }
    };

    const renderDetail = token => {
        setDetailRender(true)
        setBookingSelected(token)
    };

    const actionCallbacks = action => {
        const { providerBookingListAction } = props;
        if (action === BOOKING_VIEW_ALL) {
            setShowScroll(true)
        } else {
            setShowScroll(false)
        }
        providerBookingListAction({ type: action, append: false });
    };

    const bookingAction = (type, token) => {
        const { acceptBookingAction, rejectBookingAction, completeBookingAction } = props;
        setActionLoader(true)
        if (type === 1) {
            acceptBookingAction(
                { bookingToken: token },
                onActionDone
            );
        } else if (type === 2) {
            rejectBookingAction(
                { bookingToken: token },
                onActionDone
            );
        } else if (type === 3) {
            completeBookingAction(
                { bookingToken: token },
                onActionDone
            );
        }
    };

    const onActionDone = (success, data) => {
        if (success) {
            setActionLoader(false)
            NotificationManager.success(
                <FormattedMessage
                    id="actionCompleted"
                    defaultMessage="Action Completed"
                />,
                'Success'
            );
            getData();
        } else {
            NotificationManager.error(
                <FormattedMessage
                    id="someError"
                    defaultMessage="Some Error Occured"
                />,
                'Error'
            );
        }
    };

    const renderInitiateActions = booking => {
        if (actionLoader) {
            return <CircularProgress />;
        } else {
            return (
                <Fragment>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <IconButton
                            aria-label="Accept"
                            color="green"
                            onClick={() => bookingAction(1, booking?.token)}
                        >
                            <Check />
                        </IconButton>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <IconButton
                            aria-label="Accept"
                            color="red"
                            onClick={() => bookingAction(2, booking?.token)}
                        >
                            <Close />
                        </IconButton>
                    </div>
                </Fragment>
            );
        }
    };

    const renderPaymentStatus = booking => {
        return (
            <div
                className={getBookingPaymentStatusClass(booking?.payment_status)}
            >
                <FormattedMessage
                    id="paymentStatus"
                    defaultMessage="Payment Status"
                />
                : {getBookingPaymentStatus(booking?.payment_status)}
            </div>
        );
    };

    const {
        classes,
        intl,
        history
    } = props;
    const { formatMessage } = intl;

    if (detailRender) {
        history.push({
            pathname: `/user/booking/detail/${bookingSelected}`,
            bookingId: bookingSelected
        });
    }
    return (
        <div>
            <div className={isMobile ? "row" : "row mb-4"}>
                <div className="col-md-12">
                    <Card className={classes?.card}>
                        <Toolbar className="flex-justify-spacebetween">
                            <h3 className={isMobile && 'font-18'}>
                                <FormattedMessage
                                    id="myBookings"
                                    defaultMessage="My Bookings"
                                />
                            </h3>
                            <MenuButton
                                items={[
                                    {
                                        action: BOOKING_VIEW_ALL,
                                        label: formatMessage({
                                            id: 'booking.allbookings'
                                        })
                                    },
                                    {
                                        action: BOOKING_VIEW_NEW,
                                        label: formatMessage({
                                            id: 'booking.newbookings'
                                        })
                                    },
                                    {
                                        action: BOOKING_VIEW_INPROGRESS,
                                        label: formatMessage({
                                            id: 'booking.inprogress'
                                        })
                                    },
                                    {
                                        action: BOOKING_VIEW_COMPLETED,
                                        label: formatMessage({
                                            id: 'booking.completedbookings'
                                        })
                                    },
                                    {
                                        action: BOOKING_VIEW_CANCELLED,
                                        label: formatMessage({
                                            id: 'booking.cancelledbookings'
                                        })
                                    }
                                ]}
                                menuId={'bookingMenu'}
                                menuCallback={action => actionCallbacks(action)}
                                iconType={FilterList}
                            />
                        </Toolbar>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {bookingData.length > 0 ? (
                        <List
                            className={classes?.root + ' dashboard-booking-tabs'}
                        >
                            {bookingData.map(booking => {
                                return (
                                    <ListItem
                                        key={booking?.key}
                                        className={classes?.paper}
                                    >
                                        <ListItemAvatar>
                                            <ProgressiveImage
                                                delay={2000}
                                                src={booking && booking?.customerImage && booking?.customerImage?.medium ? booking?.customerImage?.medium : avatarImage}
                                                placeholder="promo-image"
                                            >
                                                {(src, loading) =>
                                                    loading ? (
                                                        renderPromoImage()
                                                    ) : (
                                                        <LazyLoadImage
                                                            width="200px"
                                                            height="200px"
                                                            src={src}
                                                            alt="promo image"
                                                        />
                                                    )
                                                }
                                            </ProgressiveImage>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography={true}
                                            primary={
                                                <Fragment>
                                                    {isMobile ? <Fragment><h3 style={{ marginTop: 0, marginBottom: 0 }}>
                                                        Booking ID: {booking?.token}
                                                    </h3>
                                                        <h3 style={{ marginTop: 0 }}>
                                                            {convertTimestampToDate(
                                                                booking?.datetime
                                                            )}
                                                            &nbsp;at&nbsp;
                                                            {tConvert(
                                                                new Date(
                                                                    booking?.datetime
                                                                )
                                                            )}
                                                        </h3>
                                                    </Fragment>
                                                        : <h3>Booking ID: {booking?.token} - (
                                                            {convertTimestampToDate(
                                                                booking?.datetime
                                                            )}
                                                            &nbsp;at&nbsp;
                                                            {tConvert(
                                                                new Date(
                                                                    booking?.datetime
                                                                )
                                                            )})</h3>}
                                                    <Fragment>
                                                        <p>
                                                            <Camera fontSize={isMobile ? 'small' : 'default'} />&nbsp;
                                                            {booking && booking?.services && booking?.services[0] && booking?.services[0]?.service}
                                                        </p>
                                                        <p>
                                                            <Person fontSize={isMobile ? 'small' : 'default'} />&nbsp;
                                                            {booking?.customerName}
                                                        </p>
                                                        <p>
                                                            <LocationOn fontSize={isMobile ? 'small' : 'default'} />&nbsp;
                                                            {booking?.customerAddress?.address_line1}
                                                        </p>
                                                        <p>
                                                            <Phone fontSize={isMobile ? 'small' : 'default'} />&nbsp;
                                                            {booking?.customerAddress?.phone}
                                                        </p>
                                                        <p>
                                                            <Email fontSize={isMobile ? 'small' : 'default'} />&nbsp;
                                                            {booking?.customerAddress?.email}
                                                        </p>
                                                    </Fragment>
                                                </Fragment>
                                            }
                                            secondary={
                                                <Fragment>
                                                    {renderPaymentStatus(
                                                        booking
                                                    )}
                                                    <br />
                                                    <div
                                                        className={getBookingStatusClass(
                                                            booking?.status
                                                        )}
                                                    >
                                                        <FormattedMessage
                                                            id="bookingStatus"
                                                            defaultMessage="Booking Status"
                                                        />
                                                        : {booking?.status}
                                                    </div>
                                                </Fragment>
                                            }
                                        />
                                        <ListItemSecondaryAction className="booking-detail-arrows">
                                            <div className="flex-direction-row">
                                                <div>
                                                    {isMobile && booking?.statusId ==
                                                        BOOKING_STATUS_INITIATED && (
                                                            <Fragment>
                                                                {renderInitiateActions(
                                                                    booking
                                                                )}
                                                            </Fragment>
                                                        )}
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <WSButton
                                                        className={
                                                            classes?.margin
                                                        }
                                                        onClick={() =>
                                                            renderDetail(
                                                                booking?.token
                                                            )
                                                        }
                                                    >
                                                        <FormattedMessage
                                                            id="viewDetail"
                                                            defaultMessage="View Detail"
                                                        />
                                                    </WSButton>
                                                </div>
                                            </div>
                                            {!isMobile && booking?.statusId ==
                                                BOOKING_STATUS_INITIATED && (
                                                    <Fragment>
                                                        {renderInitiateActions(
                                                            booking
                                                        )}
                                                    </Fragment>
                                                )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    ) : (
                        <p className="text-center">
                            <FormattedMessage
                                id="noBookingFound"
                                defaultMessage="No Booking found"
                            />
                        </p>
                    )}
                </div>
            </div>
            {bookingData?.length > 0 && (
                <div className="text-center">
                    {showScroll && renderScrollIcon()}
                </div>
            )}
        </div>
    );
}

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default composedComponent(withRouter(injectIntl(BookingList)));