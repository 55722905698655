import {
    PROVIDER_DATA_RECEIVED,
    PROVIDER_LOADER,
    FEATURED_LIST_RECEIVED,
    FEATURED_PROVIDER_LOADER,
    NEW_PROVIDER_LIST_RECEIVED,
    NEW_PROVIDER_LOADER,
    RECENT_LIST_RECEIVED,
    RECENT_PROVIDER_LOADER,
    FAVOURITE_LIST_RECEIVED,
    FAVOURITE_PROVIDER_LOADER,
    FAVOURITE_PROVIDER_LIST_RECEIVED,
    FAVOURITE_HOME_PROVIDER_LOADER,
    SELECTED_PROVIDER_ID,
} from '../actions/types';

const INITIAL_STATE = {
    providerList: [],
    featuredProviderLoader: false,
    featuredProviderList: [],
    newProviderLoader: false,
    newProviderList: [],
    favouriteProviderLoader: false,
    favouriteProviderList: [],
    providerLoader: false,
    recentProviderList: [],
    recentProviderLoader: false,
    favProviderList: [],
    favProviderLoader: false,
    selectedProviderId: '',
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case PROVIDER_DATA_RECEIVED:
            return { ...state, providerList: action.payload };
        case PROVIDER_LOADER:
            return { ...state, providerLoader: action.payload };
        case FEATURED_LIST_RECEIVED:
            return { ...state, featuredProviderList: action.payload };
        case FEATURED_PROVIDER_LOADER:
            return { ...state, featuredProviderLoader: action.payload };
        case NEW_PROVIDER_LIST_RECEIVED:
            return { ...state, newProviderList: action.payload };
        case NEW_PROVIDER_LOADER:
            return { ...state, newProviderLoader: action.payload };
        case FAVOURITE_PROVIDER_LIST_RECEIVED:
            return { ...state, favouriteProviderList: action.payload };
        case FAVOURITE_HOME_PROVIDER_LOADER:
            return { ...state, favouriteProviderLoader: action.payload };
        case RECENT_LIST_RECEIVED:
            return { ...state, recentProviderList: action.payload };
        case RECENT_PROVIDER_LOADER:
            return { ...state, recentProviderLoader: action.payload };
        case FAVOURITE_LIST_RECEIVED:
            return { ...state, favProviderList: action.payload };
        case FAVOURITE_PROVIDER_LOADER:
            return { ...state, favProviderLoader: action.payload };
        case SELECTED_PROVIDER_ID:
            return { ...state, selectedProviderId: action.payload };
        default:
            return state;
    }
}
