/*eslint-disable*/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
    isBrowser,
    isTablet,
    isMobile
} from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import headerLinksStyle from 'assets/jss/material-kit-react/components/headerLinksStyle.jsx';

import Button from 'components/CustomButtons/Button.jsx';

class MainLeftLinks extends Component {

    constructor(props) {
        super();
        this.state = {
            signupRedirect: false,
            dashboardRedirect: false,
            language: 'en'
        };
    }

    handleChangeLanguage = event => {
        const { changeLanguage } = this.props;
        this.setState({
            [event.target.name]: event.target.value,
            language: event.target.value
        });
        changeLanguage(event.target.value);
    };

    renderCmsDetail = slug => {
        const { history } = this.props;
        history.push(`/page/${slug}`);
    };

    renderContactUs = () => {
        const { history } = this.props;
        history.push(`/contact-us`);
    };

    isButtonRender = () => {
        const { classes, userData } = this.props;
        const name = (userData.name).split(' ')
        const firstName = name[0]
        if (!userData.name) {
            return (
                <Button
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                    style={{ fontSize: isMobile ? '14px' : '16px' }}
                    onClick={() => this.renderContactUs()}
                >
                    <FormattedMessage
                        id="Contact Us"
                        defaultMessage="Contact Us"
                    />
                </Button>
            )
        } else {
            return (
                <h4 style={{ color: isMobile ? 'black' : 'white' }}>
                    {`Welcome, ${firstName}`}
                </h4>
            )
        }
    }

    render() {
        const { classes, cmsLinks } = this.props;
        const { language } = this.state;
        let cmslinks = cmsLinks.filter(cms => {
            return (!cms.showingon || cms.showingon === 0 || cms.showingon === 1)
        });
        return (
            <List className={classes.list + ' navigation-left'}>
                {cmslinks.length > 0 &&
                    cmslinks.map((link, index) => {
                        return (
                            <ListItem
                                className={classes.listItem}
                                key={index}
                                style={{ color: '#ffffff' }}
                            >
                                <Button
                                    target="_blank"
                                    color="transparent"
                                    className={classes.navLink}
                                    onClick={() =>
                                        this.renderCmsDetail(link.slug)
                                    }
                                    style={{ fontSize: isMobile ? '14px' : '16px' }}
                                    size={isBrowser ? "lg" : isTablet ? "medium" : "small"}
                                >
                                    {language === 'en' && (
                                        <FormattedMessage
                                            id={link.title}
                                            defaultMessage={link.title}
                                        />
                                    )}
                                    {language === 'fr' && (
                                        <FormattedMessage
                                            id={link.titleFrench}
                                            defaultMessage={link.titleFrench}
                                        />
                                    )}
                                </Button>
                            </ListItem>
                        );
                    })}
                <ListItem
                    className={classes.listItem}
                    style={{ color: '#ffffff', float: 'right' }}
                >
                    {this.isButtonRender()}
                </ListItem>
            </List>
        );
    }
}

const mapStateToProps = ({ auth, settings }) => {
    return {
        userData: auth.userData
    };
};

const composedComponent = compose(
    withStyles(headerLinksStyle),
    connect(
        mapStateToProps,
        null
    )
);

export default composedComponent(withRouter(MainLeftLinks));