import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from "yup";
import { isMobile } from 'react-device-detect';
import NotificationManager from 'react-notifications/lib/NotificationManager';

import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import WSButton from "../../components/Button/WSButton";
import WSTextField from "../../components/TextField/WSTextField";

import '../../assets/css/style.css'

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme?.palette?.background?.paper
    },
});

const bankDetailValidation = yup.object().shape({
    bankName: yup.string().required('Bank Name is required.'),
    accountNo: yup.string().required('Bank Account Number is required.'),
    IBANNo: yup.string().required('IBAN Number Type is required.'),
    VATTRNNo: yup.string().required('VAT TRN Number is required.'),
});

const ProviderBankDetails = props => {
const [loaderButton, setLoaderButton] = useState(false);
const providerData = useSelector(state => state?.auth?.providerData);
    const updateBankDetailAPI = (values) => {
        const { updateFullProviderProfileAction } = props;
        const {
            bankName,
            accountNo,
            IBANNo,
            VATTRNNo
        } = values
        updateFullProviderProfileAction({
            bankName,
            bankAccountNo: accountNo,
            iBANNo: IBANNo,
            vat_TRN_No: VATTRNNo,
        }, onDone)
    }

    const onDone = (success) => {
        if (success) {
            NotificationManager.success('Bank Details Updated', null, 2000);
        } else {
            NotificationManager.error('Bank Details Update Failed', null, 2000);
        }
    };

        return (
            <Formik
                initialValues={{
                    bankName: providerData?.bankName || '',
                    accountNo: providerData?.bankAccountNo || '',
                    IBANNo: providerData?.iBANNo || '',
                    VATTRNNo: providerData?.vat_TRN_No || '',
                }}
                validationSchema={bankDetailValidation}
                onSubmit={(values) => updateBankDetailAPI(values)}
            >
                {props => (
                    <form
                        className="dialog-form dialog-form-new"
                        autoComplete="off"
                    >
                        <div className="row col-md-12 p-0">
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                                    Bank Name 
                                    <span className="primary-color">*</span>
                                </span>
                                <WSTextField
                                    error={props?.errors?.bankName && props?.touched?.bankName ? true : false}
                                    helperText={props?.errors?.bankName && props?.touched?.bankName ? props?.errors?.bankName : ''}
                                    className="login-form-textinput"
                                    id="bankName"
                                    name="bankName"
                                    placeholder={'Bank Name'}
                                    value={props?.values?.bankName}
                                    onChange={props.handleChange('bankName')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>Bank Account <span className="primary-color">*</span></span>
                                <WSTextField
                                    error={props?.errors?.accountNo && props?.touched?.accountNo ? true : false}
                                    helperText={props?.errors?.accountNo && props?.touched?.accountNo ? props?.errors?.accountNo : ''}
                                    className="login-form-textinput"
                                    id="accountNo"
                                    name="accountNo"
                                    placeholder={'Bank Account'}
                                    value={props?.values?.accountNo}
                                    onChange={props.handleChange('accountNo')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <span className='w-100'></span>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>IBAN Number <span className="primary-color">*</span></span>
                                <WSTextField
                                    error={props?.errors?.IBANNo && props?.touched?.IBANNo ? true : false}
                                    helperText={props?.errors?.IBANNo && props?.touched?.IBANNo ? props?.errors?.IBANNo : ''}
                                    className="login-form-textinput"
                                    id="IBANNo"
                                    name="IBANNo"
                                    placeholder={'IBAN Number'}
                                    value={props?.values?.IBANNo}
                                    onChange={props.handleChange('IBANNo')}
                                    disabled={loaderButton}
                                />
                            </div>
                            <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && 'pr-0 pl-30'}`}>
                                <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>VAT TRN Number <span className="primary-color">*</span></span>
                                <WSTextField
                                    error={props?.errors?.VATTRNNo && props?.touched?.VATTRNNo ? true : false}
                                    helperText={props?.errors?.VATTRNNo && props?.touched?.VATTRNNo ? props?.errors?.VATTRNNo : ''}
                                    className="login-form-textinput"
                                    id="VATTRNNo"
                                    name="VATTRNNo"
                                    placeholder={'VAT TRN Number'}
                                    value={props?.values?.VATTRNNo}
                                    onChange={props.handleChange('VATTRNNo')}
                                    disabled={loaderButton}
                                />
                            </div>
                        </div>
                        <div className="flex-justify-spacebetween mt-2">
                            <div>
                                <WSButton
                                    style={{
                                        backgroundColor: props?.theme?.palette?.primary?.main,
                                        color: '#ffffff',
                                        margin: !isMobile && 20,
                                        borderRadius: 30
                                    }}
                                    onClick={props.handleSubmit}
                                >
                                    Save Updates
                                </WSButton>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        );
    // }
}

export default withStyles(styles)(withTheme()(ProviderBankDetails));