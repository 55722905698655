import axios from 'axios';

import {
    errorResponse,
    validateApiData,
} from '../../utils/validators/validators';
import { SERVER_URL, STRIPE_SECRET_KEY } from '../../utils/config/constants';
import { getCookies } from '../../utils/getToken';

export const createBooking = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/booking/create`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const checkBookingStatus = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/provider/booking-status`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return data;
    } catch (e) {
        return errorResponse;
    }
};

export const acceptBooking = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/booking/accept`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const rejectBooking = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/booking/reject`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const completeBooking = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/booking/stop`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const payViaCard = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/payment/create`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const getStripeTokenWithoutAmount = async (params) => {
    const { number, expiry, cvc } = params;
    const expiryMonth = expiry.split('/')[0];
    const expiryYear = expiry.split('/')[1];
    try {
        const { data } = await axios(
            `https://api.stripe.com/v1/tokens?card[number]=${number}&card[exp_month]=${expiryMonth}&card[exp_year]=${expiryYear}&card[cvc]=${cvc}&currency=usd`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${STRIPE_SECRET_KEY}`,
                },
            }
        );
        return data.id ? data.id : null;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const cardAdd = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/cards/create`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const deleteCard = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/cards/remove`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const walletAmountAdd = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/wallet/add-amount`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const walletPay = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/payment/wallet`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const rateBooking = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/rating/add`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const approvePaymentBooking = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/booking/provider-approve`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        console.log(e);
        return errorResponse;
    }
};

export const cancelBooking = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/booking/cancel`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const reBooking = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post', //you can set what request you want to be
            url: `${SERVER_URL}/booking/rebook-check`,
            data: params,
            headers: {
                'x-auth-Token': authToken,
            },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};

export const bookingPaypal = async (params) => {
    let authToken = getCookies();
    try {
        const { data } = await axios({
            method: 'post',
            url: `${SERVER_URL}/paypal/notifyurl`,
            data: params,
            headers: { 'x-auth-token': authToken },
        });
        return validateApiData(data) ? data : errorResponse;
    } catch (e) {
        return errorResponse;
    }
};
