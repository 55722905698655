import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Card from '@material-ui/core/Card';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
    Tooltip,
    TableRow,
    TableSortLabel,
    Toolbar,
    TablePagination,
    TableHead,
    TableCell,
    TableBody,
    Typography,
    Table,
    Paper
} from '@material-ui/core';

import * as actions from '../../actions';

import HOCSectionLoader from '../HOC/HOCSectionLoader.jsx';

import MenuButton from '../Menu/MenuButton';
import ChangeStatusAlert from '../Alert/ChangeStatusAlert.jsx';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

class ProviderTypesListHead extends Component {

    createSortHandler = property => event => {
        const { onRequestSort } = this.props;
        onRequestSort(event, property);
    };

    render() {
        const rows = [
            {
                id: 'ProviderType',
                label: 'Provider Type'
            },
            {
                id: 'action',
                label: 'Action'
            }
        ];
        const { order, orderBy } = this.props;
        return (
            <TableHead>
                <TableRow>
                    {rows.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={
                                    row.disablePadding ? 'none' : 'default'
                                }
                                sortDirection={
                                    orderBy === row.id ? order : false
                                }
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={
                                        row.numeric
                                            ? 'bottom-end'
                                            : 'bottom-start'
                                    }
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        <FormattedMessage
                                            id={row.id}
                                            defaultMessage={row.label}
                                        />
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: '1 1 100%'
    },
    actions: {
        color: theme.palette.secondary.main
    },
    title: {
        flex: '0 0 auto'
    }
});

let ServiceListToolbar = props => {
    const { numSelected, classes } = props;
    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected}&nbsp;
                        <FormattedMessage
                            id="selected"
                            defaultMessage="selected"
                        />
                    </Typography>
                ) : (
                        <h6>
                            <FormattedMessage
                                id="ProviderPortfolio"
                                defaultMessage="Provider Portfolio"
                            />
                        </h6>
                    )}
            </div>
            <div className={classes.spacer} />
        </Toolbar>
    );
};


ServiceListToolbar = withStyles(toolbarStyles)(ServiceListToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3
    },
    table: {
        minWidth: 600
    },
    tableWrapper: {
        overflowX: 'auto'
    }
});

class ProviderTypesList extends Component {

    constructor(props) {
        super();
        this.state = {
            order: 'desc',
            orderBy: 'created_at',
            selected: [],
            providerTypeData: props.providerTypeData,
            page: 0,
            rowsPerPage: 5,
            clickValue: '',
            item: '',
            itemId: '',
            showAlert: false,
            selectedKey: '',
            loader: false
        };
    }

    actionCallbacks(action, res) {
        if (action === 'gallery') {
            this.setState({
                clickValue: 'gallery',
                item: res,
                menuState: false,
                providerTypeId: res.id
            });
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.providerTypeData.map(n => n.key) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    changeRecord = key => {
        this.setState({ showAlert: true, selectedKey: key });
    };

    changeItemStatus = () => {
        const { serviceChangeStatusAction } = this.props;
        const { selectedKey } = this.state;
        this.setState({ loader: true });
        serviceChangeStatusAction(
            {
                proServiceId: selectedKey
            },
            (success, data) => this.onChangeDone(success, data)
        );
    };

    onChangeDone = (success, data) => {
        const { getData } = this.props;
        this.setState({ loader: false });
        if (success) {
            this.setState({ showAlert: false });
            getData();
        }
    };

    render() {
        const { classes, history, match } = this.props;
        const {
            providerTypeData,
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            clickValue,
            providerTypeId,
            loader,
            showAlert
        } = this.state;
        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, providerTypeData.length - page * rowsPerPage);
        if (clickValue) {
            if (clickValue === 'gallery') {
                history.push({
                    pathname: `${match.url}/gallery`,
                    providerTypeId: providerTypeId
                });
            }
        }
        return (
            <div>
                <HOCSectionLoader loading={loader}>
                    <div className={isMobile ? "row" : "row mb-4"}>
                        <div className="col-md-12">
                            <Card className={classes.card}>
                                <Toolbar>
                                    <h3>
                                        <FormattedMessage
                                            id="managePortfolio"
                                            defaultMessage="Manage Portfolio"
                                        />
                                    </h3>
                                </Toolbar>
                            </Card>
                        </div>
                    </div>
                    <Paper className={classes.root + isMobile && "mt-10"}>
                        <ServiceListToolbar
                            numSelected={selected.length}
                            deleteAction={this.deleteRecord}
                            match={match}
                        />
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table + ' dashboard-tables'}
                                aria-labelledby="tableTitle"
                            >
                                <ProviderTypesListHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={providerTypeData.length}
                                />
                                <TableBody>
                                    {stableSort(
                                        providerTypeData,
                                        getSorting(order, orderBy)
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map(n => {
                                            const isSelected = this.isSelected(
                                                n.key
                                            );
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.key}
                                                    selected={isSelected}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {n.name}
                                                    </TableCell>
                                                    <TableCell >
                                                        <MenuButton
                                                            rowData={n}
                                                            items={
                                                                [
                                                                    {
                                                                        action: 'gallery',
                                                                        label: 'Manage Gallery'
                                                                    }
                                                                ]
                                                            }
                                                            menuId={'userMenu'}
                                                            menuCallback={(
                                                                action,
                                                                rowData
                                                            ) =>
                                                                this.actionCallbacks(
                                                                    action,
                                                                    rowData
                                                                )
                                                            }
                                                            iconType={
                                                                MoreVertIcon
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 49 * emptyRows }}
                                        >
                                            <TableCell colSpan={8} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            className="dashboard-table-pagination"
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={providerTypeData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page'
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </HOCSectionLoader>
                <ChangeStatusAlert
                    open={showAlert}
                    title="service"
                    close={() => this.setState({ showAlert: false })}
                    handleYes={this.changeItemStatus}
                    handleNo={() => this.setState({ showAlert: false })}
                />
            </div>
        );
    }
}

const composedComponent = compose(
    withStyles(styles),
    connect(
        null,
        actions
    )
);

export default composedComponent(injectIntl(ProviderTypesList));