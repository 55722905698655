import {
    bookingList,
    providerBookingList,
    bookingTimeSlot,
    promoCodeList,
    checkPromoCode,
    cardList,
    bookingDetail,
} from '../apiHelpers/booking/list';
import {
    createBooking,
    payViaCard,
    cardAdd,
    getStripeTokenWithoutAmount,
    deleteCard,
    walletAmountAdd,
    walletPay,
    rateBooking,
    acceptBooking,
    rejectBooking,
    completeBooking,
    approvePaymentBooking,
    cancelBooking,
    reBooking,
    bookingPaypal,
    checkBookingStatus,
} from '../apiHelpers/booking/create';

import { walletListAction, getLoggedInUserAction } from './User';
import {
    BOOKING_DATA_RECEIVED,
    PROMOCODE_DATA_RECEIVED,
    BOOKING_LOADER,
    CARD_LIST,
    CARD_LOADER,
} from './types';

export const bookingPaypalAction = (params, done) => async (dispatch) => {
    try {
        const response = await bookingPaypal(params);
        if (response.status === 'OK') {
            return done(true, response.data);
        }
        done(false, response.message);
    } catch (e) {
        console.log(e);
        done(false, e.message);
    }
};

export const bookingListAction = (params) => async (dispatch, getState) => {
    dispatch({ type: BOOKING_LOADER, payload: true });
    const response = await bookingList(params);
    if (response.status === 'OK') {
        if (params.append) {
            const newData = response.data.bookingList;
            const oldData = getState().bookings.bookingData.bookingList;

            const finalData = {};
            finalData.bookingList = [...oldData, ...newData];
            dispatch({
                type: BOOKING_DATA_RECEIVED,
                payload: finalData,
            });
        } else {
            dispatch({
                type: BOOKING_DATA_RECEIVED,
                payload: response.data,
            });
        }

        dispatch({ type: BOOKING_LOADER, payload: false });
    }
};

export const providerBookingListAction = (params) => async (
    dispatch,
    getState
) => {
    dispatch({ type: BOOKING_LOADER, payload: true });
    const response = await providerBookingList(params);
    if (response.status === 'OK') {
        if (params.append) {
            const newData = response.data.bookingList;
            const oldData = getState().bookings.bookingData.bookingList;

            const finalData = {};
            finalData.bookingList = [...oldData, ...newData];

            dispatch({
                type: BOOKING_DATA_RECEIVED,
                payload: finalData,
            });
        } else {
            dispatch({
                type: BOOKING_DATA_RECEIVED,
                payload: response.data,
            });
        }

        dispatch({ type: BOOKING_LOADER, payload: false });
    }
};

export const bookingDetailAction = (params, done) => async (dispatch) => {
    const response = await bookingDetail(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const bookingTimeSlotAction = (params, done) => async (dispatch) => {
    const response = await bookingTimeSlot(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const promoCodeListAction = () => async (dispatch) => {
    const response = await promoCodeList();
    if (response.status === 'OK') {
        dispatch({ type: PROMOCODE_DATA_RECEIVED, payload: response.data });
    }
};

export const checkPromoCodeAction = (params, done) => async (dispatch) => {
    const response = await checkPromoCode(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const createBookingAction = (params, done) => async (dispatch) => {
    const response = await createBooking(params);
    if (response.status === 'OK') {
        dispatch(getLoggedInUserAction());
        dispatch(bookingListAction({ append: true }));
        done(true, response);
    } else {
        done(false, response);
    }
};

export const checkBookingStatusAction = (params, done) => async (dispatch) => {
    const response = await checkBookingStatus(params);
    if (response.status === 'OK') {
        dispatch(getLoggedInUserAction());
        done(true, response);
    } else {
        done(false, response);
    }
};

export const cardListAction = (params, done) => async (dispatch) => {
    dispatch({ type: CARD_LOADER, payload: true });
    const response = await cardList(params);
    if (response.status === 'OK') {
        dispatch({ type: CARD_LIST, payload: response.data });
        dispatch({ type: CARD_LOADER, payload: false });
    } else {
        dispatch({ type: CARD_LOADER, payload: false });
    }
};

export const payViaCardAction = (params, done) => async (dispatch) => {
    const response = await payViaCard(params);
    if (response.status === 'OK') {
        done(true, response);
    } else {
        done(false, response);
    }
};

export const cardAddAction = (params, done) => async (dispatch) => {
    const token = await getStripeTokenWithoutAmount(params);
    if (token) {
        const response = await cardAdd({ stripeToken: token });
        if (response.status === 'OK') {
            dispatch(cardListAction());
            return done(true, response.data);
        }
        return done(false, response.message);
    } else {
        return done(false, { message: 'Please enter correct Card Details' });
    }
};

export const deleteCardAction = (params, done) => async (dispatch) => {
    const response = await deleteCard(params);
    if (response.status === 'OK') {
        dispatch(cardListAction());
        done(true, response);
    } else {
        done(false, response);
    }
};

export const walletAmountAddAction = (params, done) => async (dispatch) => {
    try {
        const response = await walletAmountAdd(params);
        if (response.status === 'OK') {
            dispatch(walletListAction());
            dispatch(getLoggedInUserAction());
            return done(true, response);
        } else {
            return done(false, response);
        }
    } catch (e) {
        console.log(e);
        done(false, e.message);
    }
};

export const walletPayAction = (params, done) => async (dispatch) => {
    try {
        const response = await walletPay(params);
        if (response.status === 'OK') {
            return done(true, response);
        } else {
            return done(false, response);
        }
    } catch (e) {
        console.log(e);
        done(false, e.message);
    }
};

export const rateBookingAction = (params, done) => async (dispatch) => {
    try {
        const response = await rateBooking(params);
        if (response.status === 'OK') {
            return done(true, response.data);
        } else {
            return done(false, response.message);
        }
    } catch (e) {
        console.log(e);
        done(false, e.message);
    }
};

export const acceptBookingAction = (params, done) => async (dispatch) => {
    try {
        const response = await acceptBooking(params);
        if (response.status === 'OK') {
            return done(true, response.data);
        }
        done(false, response.message);
    } catch (e) {
        console.log(e);
        done(false, e.message);
    }
};

export const rejectBookingAction = (params, done) => async (dispatch) => {
    try {
        const response = await rejectBooking(params);
        if (response.status === 'OK') {
            return done(true, response.data);
        }
        done(false, response.message);
    } catch (e) {
        console.log(e);
        done(false, e.message);
    }
};

export const completeBookingAction = (params, done) => async (dispatch) => {
    try {
        const response = await completeBooking(params);
        if (response.status === 'OK') {
            return done(true, response.data);
        }
        done(false, response.message);
    } catch (e) {
        console.log(e);
        done(false, e.message);
    }
};

export const approvePaymentBookingAction = (params, done) => async (
    dispatch
) => {
    try {
        const response = await approvePaymentBooking(params);
        if (response.status === 'OK') {
            return done(true, response.data);
        }
        done(false, response.message);
    } catch (e) {
        console.log(e);
        done(false, e.message);
    }
};

export const cancelBookingAction = (params, done) => async (dispatch) => {
    try {
        const response = await cancelBooking(params);
        if (response.status === 'OK') {
            return done(true, response.data);
        }
        done(false, response.message);
    } catch (e) {
        console.log(e);
        done(false, e.message);
    }
};

export const reBookingAction = (params, done) => async (dispatch) => {
    try {
        const response = await reBooking(params);
        if (response.status === 'OK') {
            return done(true, response.data);
        }
        return done(false, response.message);
    } catch (e) {
        console.log(e);
        return done(false, e.getMessage());
    }
};
